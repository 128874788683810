<form class="profile-form" [formGroup]="formGroup" novalidate>
  <div class="form-group">
    <div class="phone-number-form-control">
      <div class="wnd-form-control">
        <wnd-input
          type="number"
          id="phoneNumber"
          class="profile-input"
          placeholder="'-'생략하고 입력"
          formControlName="phoneNumber"
          [disabled]="isLoading || step === phoneVerifyStep.Verified"
          (keydown)="keyDownOnlyNumber($event)"
        ></wnd-input>
      </div>

      <button
        *ngIf="showSendBtn && step === phoneVerifyStep.Waiting"
        type="button"
        class="wnd-btn yellow"
        (click)="sendMessage()"
      >
        인증 요청
      </button>

      <button
        *ngIf="step !== phoneVerifyStep.Waiting"
        type="button"
        class="wnd-btn yellow"
        [disabled]="step === phoneVerifyStep.Verified"
        (click)="sendVerificationSms()"
      >
        재전송
      </button>
    </div>

    <ng-container *ngIf="step !== phoneVerifyStep.Waiting">
      <div class="wnd-form-control">
        <wnd-input
          type="number"
          id="phoneVerify"
          class="profile-input"
          placeholder="인증번호 입력"
          formControlName="phoneVerify"
          [disabled]="isLoading || step === phoneVerifyStep.Verified"
          (keydown)="keyDownOnlyNumber($event)"
        ></wnd-input>

        <span *ngIf="!isLoading" class="time-counter-text">{{
          phoneVerifyStartMinutes | phoneVerifyMinutes
        }}</span>

        <i *ngIf="isLoading" class="wnd-icn loading yellow"></i>
      </div>

      <span *ngIf="step !== phoneVerifyStep.Verified" class="phone-verify-message"
        >인증번호가 발송되었습니다.</span
      >

      <span *ngIf="step === phoneVerifyStep.Verified" class="phone-verify-message"
        >인증번호가 확인되었습니다.</span
      >
    </ng-container>
  </div>
</form>
