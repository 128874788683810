import { Pipe, PipeTransform } from '@angular/core';
import { stringConvertBytes } from '../../utils';

@Pipe({
  name: 'stringConvertBytes',
})
export class StringConvertBytesPipe implements PipeTransform {
  transform(value: string): number {
    return stringConvertBytes(value);
  }
}
