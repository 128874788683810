export const WndErrors = {
  email: {
    required: '이메일 주소를 입력해 주세요.',
    email: '올바르지 않은 이메일 주소 입니다.',
    google: 'Google로 가입한 이메일 주소입니다.',
    incorrect: '이메일이 일치하지 않습니다.',
    userDeleted: '탈퇴한 회원의 이메일 입니다.',
  },
  name: {
    required: '이름을 입력해 주세요.',
    pattern: '영문/한글만 입력이 가능합니다.(특수문자,공백 사용불가)',
    range: '최소 2글자, 최대 10글자까지만 입력이 가능합니다.',
  },
  phone: {
    required: '연락처를 입력해 주세요.',
  },
  author: {
    required: '작가명을 입력해 주세요.',
    pattern: '특수문자,공백은 사용이 불가능 합니다.',
    range: '최소 2글자, 최대 12글자 까지만 입력이 가능합니다.',
    used: '사용중인 회원이 있습니다.',
  },
  password: {
    required: '비밀번호를 입력해주세요.',
    pattern: '6~20 자의 영문대소문자, 숫자 및 특수문자 입력해 주세요',
  },
  passwordCheck: {
    required: '비밀번호를 입력해주세요.',
    pattern: '6~20 자의 영문대소문자, 숫자 및 특수문자 입력해 주세요',
  },
  currentPassword: {
    required: '비밀번호를 입력해주세요.',
    pattern: '6~20 자의 영문대소문자, 숫자 및 특수문자 입력해 주세요',
  },
  personLicenseNumber: {
    required: '주민등록번호를 입력해주세요.',
    pattern: '숫자로 13자리까지 입력해 주세요.',
  },
  phoneNumber: {
    pattern: '숫자만 입력이 가능합니다.',
    range: '10~11자리 사이로 입력해 주세요.',
    used: '이용중인 휴대폰 번호입니다.',
  },
  phoneVerify: {
    required: '인증번호를 입력해주세요.',
  },
  qnaCategory: {
    required: '문의 유형을 선택해주세요.',
  },
  qnaContents: {
    required: '문의 내용을 입력해주세요.',
  },
  gender: {
    required: '성별을 선택해주세요.',
  },
  agreeEmail: {
    required: '이메일 정보 제공에 동의해주세요.',
  },
  date: {
    year: '연도는 1950~2020 사이로 입력해주세요.',
    month: '월은 1~12월 사이로 입력해주세요.',
    date: '일자는 1~31일 사이로 입력해주세요.',
    required: '생년월일 입력해주세요.',
  },
  writer: {
    required: '작가약관에 동의 해주세요.',
  },
  terms: {
    required: '약관에 동의 해주세요.',
  },
  agree: {
    required: '개인정보 제공에 동의 해주세요.',
  },
  withdrawAgree: {
    required: '회원 탈퇴 안내사항에 동의 해주세요.',
  },
  embed: {
    required: '미디어 임베드를 입력해 주세요.',
    iframe: '유효한 미디어 임베드 코드가 아닙니다.',
    provide: '추가 가능한 미디어 임베드가 아닙니다.',
  },
  title: {
    required: '타이틀을 입력해 주세요.',
    maxlength: '타이틀은 최대 15자 까지 입력 가능합니다.',
  },
  files: {
    required: '파일을 업로드 해주세요.',
  },
  fileExtension: {
    required: '리소스 매인 파일 확장자를 1개 이상 선택해 주세요.',
  },
  fileKind: {
    required: '리소스 종류를 입력해 주세요.',
    min: '리소스 종류는 1개 보다 낮을 수 없습니다.',
    max: '리소스 종류는 99개 보다 클수 없습니다.',
  },
  programs: {
    required: '프로그램을 추가해 주세요.',
  },
  category: {
    required: '카테고리를 선택해 주세요.',
  },
  license: {
    required: '라이선스를 선택해 주세요.',
  },
  price: {
    required: '리소스 판매가격을 입력해 주세요.',
    lessThree: '리소스 판매가격은 무료 또는 3,000~999,999원까지 등록 가능합니다.',
    min: '리소스 판매가격은 0원 보다 낮을 수 없습니다.',
    max: '리소스 판매가격은 999,999원 보다 클수 없습니다.',
  },
  tags: {
    required: '태그를 추가해 주세요.',
    length: '태그는 20개까지 추가 가능합니다.',
    textLength: '태그당 글자수는 10자로 제한됩니다.',
  },
  applicationAmount: {
    required: '신청 금액을 입력해 주세요.',
    min: '최소 출금액은 30,000원 입니다.',
  },
  accountNumber: {
    required: '계좌번호를 입력해 주세요.',
  },
  bankName: {
    required: '은행을 선택해주세요.',
  },
  attentionConfirm: {
    required: '안내사항에 동의 해주세요.',
  },
  personInfoConfirm: {
    required: '개인정보 취급방침에 동의 해주세요.',
  },
  instagram: {
    socialId: '영문,숫자,특수문자 일부(-_/.)만 입력이 가능합니다.',
  },
  facebook: {
    socialId: '영문,숫자,특수문자 일부(-_/.)만 입력이 가능합니다.',
  },
  pinterest: {
    socialId: '영문,숫자,특수문자 일부(-_/.)만 입력이 가능합니다.',
  },
  behance: {
    socialId: '영문,숫자,특수문자 일부(-_/.)만 입력이 가능합니다.',
  },
  youtube: {
    socialId: '영문,숫자,특수문자 일부(-_/.)만 입력이 가능합니다.',
  },
  twitter: {
    socialId: '영문,숫자,특수문자 일부(-_/.)만 입력이 가능합니다.',
  },
  koreaTax: {
    required: '',
  },
  licenseUsedUserName: {
    required: '',
  },
  thumbnail: {
    required: '썸네일을 업로드 해주세요.',
  },
  copyrightInformation: {
    required: '저작권 정보를 입력해 주세요.',
  },
  mediaUniqueness: {
    required: '특이사항을 입력해 주세요.',
  },
  creatorIntro: {
    bytesLength: '최대 500bytes까지 입력 가능합니다.',
  },
  creatorPath: {
    startWith: '영문 소문자로 시작해야 합니다.',
    pattern: '영문/숫자만 입력이 가능합니다.(특수문자,공백 사용불가)',
    range: '최소 2글자, 최대 20글자까지만 입력이 가능합니다.',
  },
  creatorPrograms: {
    empty: '프로그램을 선택해주세요.',
  },
  creatorActivities: {
    empty: '주요활동 내용을 입력해주세요.',
  },
  website: {
    startWith: 'http(s)://로 시작해야 합니다.',
    pattern:
      '입력이 불가한 문자가 포함되어 있습니다. (영문, 숫자(0-9), ., /, -, _만 입력 가능합니다.)',
  },
  creatorTags: {
    length: '태그는 5개까지 추가 가능합니다.',
    textLength: '10글자를 초과할 수 없습니다.',
  },
  'auth/user-not-found': `이메일 주소 혹은 비밀번호가 맞지 않습니다.`,
  'auth/wrong-password': `이메일 주소 혹은 비밀번호가 맞지 않습니다.`,
  'auth/account-exists-with-different-credential': ``,
  used: '이미 가입되어 있는 이메일 주소입니다.',
  empty: '가입된 이메일 주소가 아닙니다.',
  google: 'Google로 가입한 이메일 주소입니다.',
  userDeleted: '탈퇴한 회원의 이메일 입니다.',
  match: `비밀번호가 일치하지 않습니다.`,
  samePassword: `기존 비밀번호와 변경하실 비밀번호가 일치합니다.`,
  wrong: `현재 비밀번호가 맞지 않습니다.`,
  'invalid-code': '잘못 된 코드입니다',
  'over-expiration-time': '만료 된 코드입니다',
  'user-deleted': '탈퇴한 회원입니다.',
};
