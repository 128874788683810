import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../src/core/api/api.service';
import { NgApiAdapter } from './api.adapter';

@Injectable({
  providedIn: 'root',
})
export class NgApiService extends ApiService {
  constructor(protected override apiAdapter: NgApiAdapter) {
    super(apiAdapter);
  }
}
