import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeImageCardComponent } from './components/badge-image-card/badge-image-card.component';
import { WndPipeModule } from "../../../../../weenidy/src/lib/modules/pipe/wnd.pipe.module";
import { ImagesModule } from "../../../../../admin/src/app/modules/images/images.module";



@NgModule({
  declarations: [BadgeImageCardComponent],
  imports: [CommonModule, WndPipeModule, ImagesModule],
  exports: [BadgeImageCardComponent],
})
export class BadgeModule {}
