import { AbstractControl, FormGroup } from '@angular/forms';
import { merge } from 'rxjs';

export interface ControlsConfig {
  [key: string]: any;
}

export function deleteEmptyObject(param: object): object {
  for (const key of Object.keys(param)) {
    if (param[key] === '' || param[key] === null) {
      delete param[key];
    } else if (param[key] instanceof Array && param[key].every(data => data === '')) {
      delete param[key];
    }
  }

  return param;
}

export function allFormControlMakeDirty(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(key => {
    formGroup.get(key).markAsDirty({ onlySelf: true });
    formGroup.get(key).updateValueAndValidity({ onlySelf: true });
  });
}

export function initAllCheckboxControl(
  allControl: AbstractControl,
  relateControls: AbstractControl[]
): void {
  allControl.valueChanges.subscribe((isChecked: boolean) => {
    relateControls.forEach((control: AbstractControl) => {
      control.setValue(isChecked, { emitEvent: false });
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  });

  merge(...(relateControls.map(control => control.valueChanges)))
    .subscribe(() => {
      const isValid = relateControls.every(control => control.valid);
      allControl.setValue(isValid, { emitEvent: false });
    });
}