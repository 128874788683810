import {
  Directive,
  Renderer2,
  ElementRef,
  OnInit,
  Input,
  HostBinding,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { WndRatio } from '../types';
import { imageMagnificationReplacer } from '../../upload/utils';

@Directive({
  selector: '[wndSrc]',
})
export class SrcDirective implements OnInit, OnDestroy {
  @Input()
  get wndSrc() {
    return this._src;
  }

  set wndSrc(src: string) {
    this._src = src;

    this.setImage();
  }

  @Input()
  set ratio(ratio: WndRatio) {
    this.widthRatio = parseInt(ratio.split('-')[0], 10);
    this.heightRatio = parseInt(ratio.split('-')[1], 10);
    this.ratioType = this.widthRatio > this.heightRatio ? 'width' : 'height';
  }

  @Input() useHiDpi: boolean;
  @Input() useErrorHandle = true;

  @HostBinding('class') buttonClass: string;

  private _src: string;
  private subscription: Subscription;

  private widthRatio = 4;
  private heightRatio = 3;
  private ratioType: 'width' | 'height' = 'width';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef
  ) {
    this.subscription = new Subscription();
    this.buttonClass = 'wnd-width';
  }

  ngOnInit() {
    this.subscription.add(this.onLoad());

    if (this.useErrorHandle) {
      this.subscription.add(this.onError());
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onLoad(): Subscription {
    return fromEvent(this.elementRef.nativeElement, 'load').subscribe((target: any) => {
      const image = target.path ? target.path[0] : target.target;

      const _widthRatio = image.width / this.widthRatio;
      const _heightRatio = image.height / this.heightRatio;

      // console.log('image.width', _widthRatio);
      // console.log('image.height', _heightRatio);

      if (this.ratioType === 'width') {
        this.buttonClass = _widthRatio > _heightRatio ? 'wnd-height' : 'wnd-width';
      } else {
        this.buttonClass = _widthRatio > _heightRatio ? 'wnd-height' : 'wnd-width';
      }

      this.ref.detectChanges();
    });
  }

  onError(): Subscription {
    return fromEvent(this.elementRef.nativeElement, 'error').subscribe(() => {
      this.buttonClass = 'wnd-width';
    });
  }

  private setImage(): void {
    const nativeElement = this.elementRef.nativeElement;
    this.renderer.setAttribute(nativeElement, 'src', `${this.wndSrc}`);

    if (this.useHiDpi) {
      this.renderer.setAttribute(
        nativeElement,
        'srcset',
        `${this.wndSrc} 1x, ${imageMagnificationReplacer(this.wndSrc, 2)} 2x`
      );
    }
  }
}
