import { BaseEntity } from '../../core/base-entity';
import { textObjectToOption } from '../../core/utils';
import { WndCategory } from '../payment/types';
import { Option, WndImage } from '../../core/types';
import { ResourceCategory, ResourceInfo } from '../resource/types';

export enum Role {
  User = 1,
  Writer = 10,
  ExternalWriter = 11,
  WeenidyAdmin = 80,
  Admin = 90,
  External = 100,
}

export const RoleText = {
  [Role.User]: '일반',
  [Role.Writer]: '작가',
  [Role.ExternalWriter]: '외부 관리 작가',
  [Role.WeenidyAdmin]: '위니디 관리자',
  [Role.Admin]: '관리자',
  [Role.External]: '외부 관리자',
};

export const ProviderText = {
  email: '이메일',
  google: 'Gmail',
};

export interface User extends BaseEntity, UserAgree {
  email: string;
  profileImage?: WndImage; // 유저 이미지
  role: Role; // 유저 구분
  roleAt: Date; // 유저 구분 변경 일시
  joinedAt: Date; // 회원가입 일시
  loginedAt?: Date; // 최종로그인 일시
  provider: string[]; // 가입경로
  deleted: boolean;
  block: boolean;
  withdrawReason?: WithdrawReason; // 탈퇴사유
  withdrawReasonOtherText?: string; // 탈퇴사유 기타 text
  withdrawAt?: Date; // 탈퇴 날짜
  name?: string; // 이름
  author?: string; // 작가명
  authorChangedAt?: Date; // 작가명 변경 날짜
  phoneNumber?: string; // 연락처
  gender?: string;
  birth?: string;
  birthYear?: number;
  birthMonth?: number;
  birthDate?: number;

  koreaTax: boolean; // 대한민국 세금 지불 여부
  country?: string;
  creatorPath?: string;
  creatorBannerImage?: WndImage;
  creatorIntro?: string;
  creatorSns?: CreatorSns;

  creatorPrograms: string[];
  creatorActivities: CreatorActivity[];
  creatorTags: string[];

  writerRank: WriterRank;
  writerDesignatedAt?: Date;
  externalAdminId?: string;

  scrapCount?: number; // 유저 스크랩 횟수
  downloadCount?: number; // 유저 다운로드 횟수
  payDownloadCount?: number; // 유저 결제 다운로드 횟수

  recentThirtyAttendanceCount?: number; // 월 방문 횟수

  resourceUploadedCount?: number; // 리소스 업로드 횟수
  mockupUploadedCount?: number; // 목업 업로드 횟수
  templateUploadedCount?: number; // 템플릿 업로드 횟수
  graphicUploadedCount?: number; // 그래픽 업로드 횟수
  webtoonpUploadedCount?: number; // 웹툰 업로드 횟수
  pictureUploadedCount?: number; // 사진 업로드 횟수

  portfolioUploadedCount?: number; // 사진 업로드 횟수

  writerScrapCount?: number; // 작가 스크랩 횟수
  writerTotalViewCount?: number; // 누적 조회수

  totalPurchasedAmount?: number; // 누적 판매금액
  totalPurchasedCount?: number; // 누적 판매 횟수
  totalResourceViewCount?: number; // 누적 리소스 조회수
}

export interface ExternalAdmin extends User {
  adminDesignatedAt: Date;
  externalWriterCount: number;
}

export interface ExternalWriter extends User {
  writerDesignatedAt: Date;
  externalAdminId: string;
}

export interface UserAgree {
  terms: boolean; // 이용약관 동의
  receive: boolean; // 수신동의
  writerAgree?: boolean; // 작가 동의
}

export interface UserAgreePayload extends UserAgree {
  allCheck: boolean;
}

export enum WithdrawReason {
  ReSignIn = 1,
  LessContents = 10,
  ServiceProblem = 20,
  PersonalInformation = 30,
  NotUsedId = 40,
  Other = 90,
}

export const WithdrawReasonText = {
  [WithdrawReason.ReSignIn]: '아이디 변경 / 재가입하기 위해서',
  [WithdrawReason.LessContents]: '이용하고 싶은 컨텐츠가 부족해서',
  [WithdrawReason.ServiceProblem]: '고객서비스가 마음에 들지 않아서',
  [WithdrawReason.PersonalInformation]: '개인정보 노출 우려가 있어서',
  [WithdrawReason.NotUsedId]: '사용하지 않는 아이디라서',
  [WithdrawReason.Other]: '기타',
};

export const WithdrawReasonOptions: Option[] = [
  { text: WithdrawReasonText[WithdrawReason.ReSignIn], value: WithdrawReason.ReSignIn },
  { text: WithdrawReasonText[WithdrawReason.LessContents], value: WithdrawReason.LessContents },
  { text: WithdrawReasonText[WithdrawReason.ServiceProblem], value: WithdrawReason.ServiceProblem },
  {
    text: WithdrawReasonText[WithdrawReason.PersonalInformation],
    value: WithdrawReason.PersonalInformation,
  },
  { text: WithdrawReasonText[WithdrawReason.NotUsedId], value: WithdrawReason.NotUsedId },
  { text: WithdrawReasonText[WithdrawReason.Other], value: WithdrawReason.Other },
];

export interface Content<T = any> extends BaseEntity {
  info: T;
  category: WndCategory; // 분류
  isPay: boolean; // 유료 여부
  isPayCancel: boolean; // 결제 취소 여부
  downloadCount: number | any; // 다운로드 횟수,
  currency: string; // 화폐
  price: number; // 가격,
  paymentId?: string; // 결제 키
  imp_uid?: string; // 결제 고유 키
}

export interface Proceed extends BaseEntity {
  userId: string;
  proceed: number;
  info: Partial<ResourceInfo>;
  status: ProceedStatus;
  imp_uid?: string;
  paymentId?: string;
  price?: number;
  isAdmin?: boolean;
}

export enum ProceedStatus {
  Wait = 1,
  Done = 10,
  Cancel = 20,
  Block = 30,
}

export interface CreatorSns {
  instagram?: string;
  facebook?: string;
  pinterest?: string;
  behance?: string;
  youtube?: string;
  twitter?: string;
  website?: string;
  dribble?: string;
}

export enum WriterRank {
  Empty = 'empty',
  Master5 = 'master5',
  Master6 = 'master6',
  Master7 = 'master7',
  Master8 = 'master8',
  Master9 = 'master9',
  Master10 = 'master10',
  Partner5 = 'partner5',
  Partner6 = 'partner6',
  Partner7 = 'partner7',
  Partner8 = 'partner8',
  Partner9 = 'partner9',
  Partner10 = 'partner10',
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  Level5 = 'level5',
  Level6 = 'level6',
}

export const WriterRankText = {
  [WriterRank.Master5]: '마스터5',
  [WriterRank.Master6]: '마스터6',
  [WriterRank.Master7]: '마스터7',
  [WriterRank.Master8]: '마스터8',
  [WriterRank.Master9]: '마스터9',
  [WriterRank.Master10]: '마스터10',
  [WriterRank.Partner5]: '파트너5',
  [WriterRank.Partner6]: '파트너6',
  [WriterRank.Partner7]: '파트너7',
  [WriterRank.Partner8]: '파트너8',
  [WriterRank.Partner9]: '파트너9',
  [WriterRank.Partner10]: '파트너10',
  [WriterRank.Level1]: '휴가간 작가',
  [WriterRank.Level2]: '무명 작가',
  [WriterRank.Level3]: '동네 작가',
  [WriterRank.Level4]: '소문난 작가',
  [WriterRank.Level5]: '베스트 작가',
  [WriterRank.Level6]: '모두의 작가',
};

export const WriterRankOptions: any[] = textObjectToOption(WriterRankText);

export const WriterRankRatio = {
  [WriterRank.Master5]: { weenidy: 50, writer: 50 },
  [WriterRank.Master6]: { weenidy: 40, writer: 60 },
  [WriterRank.Master7]: { weenidy: 30, writer: 70 },
  [WriterRank.Master8]: { weenidy: 20, writer: 80 },
  [WriterRank.Master9]: { weenidy: 10, writer: 90 },
  [WriterRank.Master10]: { weenidy: 0, writer: 100 },
  [WriterRank.Partner5]: { weenidy: 50, writer: 50 },
  [WriterRank.Partner6]: { weenidy: 40, writer: 60 },
  [WriterRank.Partner7]: { weenidy: 30, writer: 70 },
  [WriterRank.Partner8]: { weenidy: 20, writer: 80 },
  [WriterRank.Partner9]: { weenidy: 10, writer: 90 },
  [WriterRank.Partner10]: { weenidy: 0, writer: 100 },
  [WriterRank.Level1]: { weenidy: 40, writer: 60 },
  [WriterRank.Level2]: { weenidy: 30, writer: 70 },
  [WriterRank.Level3]: { weenidy: 27.5, writer: 72.5 },
  [WriterRank.Level4]: { weenidy: 25, writer: 75 },
  [WriterRank.Level5]: { weenidy: 22.5, writer: 77.5 },
  [WriterRank.Level6]: { weenidy: 20, writer: 80 },
};

export class CreatorActivity {
  type: CreatorActivityType;
  text: string;
}

export enum CreatorActivityType {
  Career = 1,
  Education = 10,
  Award = 20,
  Etc = 30,
}

export const CountryOptions: Option[] = [{ value: 'kr', text: '대한민국' }];

export const CreatorActivityTypeOptions: Option[] = [
  { value: CreatorActivityType.Career, text: '경력' },
  { value: CreatorActivityType.Education, text: '학력' },
  { value: CreatorActivityType.Award, text: '수상' },
  { value: CreatorActivityType.Etc, text: '기타' },
];

export const CreatorActivityImages = {
  [CreatorActivityType.Career]: '/assets/icons/activities/Icon_Business_fill.png',
  [CreatorActivityType.Education]: '/assets/icons/activities/icon_Graduation_fill.png',
  [CreatorActivityType.Award]: '/assets/icons/activities/Icon_Award_fill.png',
  [CreatorActivityType.Etc]: '/assets/icons/activities/Icon_like_fill.png',
};

export const ResourceCategoryCountKey = {
  [ResourceCategory.Mockup]: 'mockupUploadedCount',
  [ResourceCategory.Template]: 'templateUploadedCount',
  [ResourceCategory.Graphic]: 'graphicUploadedCount',
  [ResourceCategory.WebToon]: 'webtoonpUploadedCount',
  [ResourceCategory.Picture]: 'pictureUploadedCount',
  [ResourceCategory.Portfolio]: 'portfolioUploadedCount',
};

export interface Attendance extends BaseEntity {
  userId: string;
}
