import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, WndDialogData } from '../dialog/dialog.component';


@Component({
  selector: 'wnd-dialog-test-playground',
  templateUrl: './dialog-test-playground.component.html',
  styleUrls: ['./dialog-test-playground.component.scss']
})
export class DialogTestPlaygroundComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }


  openDialog() {
    this.dialog.open<DialogComponent, WndDialogData>(DialogComponent, {
      disableClose: true,
      data: {
        title: '비밀번호 변경 완료',
        contents: '비밀번호 변경이 완료 되었습니다.',
        type: 'waring'
      }
    });
  }
}
