<div *ngIf="images" class="slider-banner-box">
  <div #sliderElement class="slider-banner-image-box">
    <wnd-image-card
      *ngFor="let image of images; let idx = index"
      class="slider-image"
      [class.animation-left]="idx === currIndex + 1"
      [ratio]="currRatio"
      [src]="currImage(image)"
      (click)="imageClick(image, idx)"
    ></wnd-image-card>
  </div>

  <div *ngIf="images && images.length > 1" class="slider-dot-box">
    <ul class="slider-dot-list">
      <li
        *ngFor="let image of images; let idx = index"
        class="slider-dot"
        [class.active]="idx === currIndex"
        (click)="changeIndex(idx)"
      ></li>
    </ul>
  </div>
</div>
