import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BrowserService } from '../../../weenidy/src/lib/shared/services/browser.service';
import { UrlService } from './core/service/url.service';
import { BadgeDialogComponent } from '../../../weenidy/src/lib/modules/dialog/components/badge-dialog/badge-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgBadgeService } from '../../../weenidy/src/lib/modules/badge/services/badge.service';
import { Badge, BadgeCategory, BadgeCount } from '../../../../src/entities/badge/types';
import { SubscriptionBaseComponent } from '../../../weenidy/src/lib/core/base-components/subscription-base.component';
import { BadgeListDialogComponent } from '../../../weenidy/src/lib/modules/dialog/components/badge-list-dialog/badge-list-dialog.component';
import { NgAuthService } from '../../../weenidy/src/lib/modules/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubscriptionBaseComponent implements OnInit {
  badgeList: Badge[] = [];
  badgeCount: BadgeCount;

  constructor(
    private authService: NgAuthService,
    private urlService: UrlService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private browserService: BrowserService,
    private dialog: MatDialog,
    private badgeService: NgBadgeService
  ) {
    super();

    this.urlService.initTrackingNavigationEnd();

    if (this.browserService.isBrowser) {
      router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e: Scroll) => {
        if (this.checkNoScrollTopUrl(e.routerEvent.url)) {
          return;
        }

        if (e.anchor && !e.position) {
          return;
        } else if (e.position) {
          viewportScroller.scrollToPosition(e.position);
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
    }
  }

  ngOnInit() {
    // if ('serviceWorker' in navigator) {
    // const options: NotificationOptions = {
    //   body: 'You Success',
    //   icon: '/assets/icons/icon-96x96.png',
    //   image: '/assets/icons/icon-96x96.png',
    //   dir: 'ltr',
    //   lang: 'en-US',
    //   vibrate: [100, 50, 200],
    //   badge: '/assets/icons/icon-96x96.png',
    // };
    //
    // navigator.serviceWorker.ready.then((swreg => {
    //   console.log('enter navigator.serviceWorker.ready');
    //   swreg.showNotification('SuccessFullly', options).then();
    // }));
    //
    // alert('service worker!!!!!!!!!!!!!');
    // navigator.serviceWorker.getRegistrations().then((registrations) => {
    //   console.log('registrations', registrations);
    //
    //   for (const registration of registrations) {
    //     // unregister service worker
    //     console.log('serviceWorker unregistered');
    //     registration.unregister();
    //   }
    // });
    // } else {
    //   alert('service worker is not ready');
    // }
    this.initMyBadge();
    this.setSubscription('_initNewBadge', this.initNewBadge());
  }

  private checkNoScrollTopUrl(url: string): boolean {
    const noScrollTopUrls = ['/resource/list'];

    for (let noScrollTopUrl of noScrollTopUrls) {
      if (url.indexOf(noScrollTopUrl) === 0) {
        return true;
      }
    }

    return false;
  }

  private initNewBadge() {
    return this.authService.id$
      .pipe(
        filter((id) => Boolean(id)),
        switchMap((id) =>
          this.badgeService.listChange(
            {
              filters: [{ field: 'isNew', comparison: '==', value: true }],
              limit: 1,
            },
            {
              parentIds: [id],
            }
          )
        ),
        filter((res) => res.count > 0),
        map((res) => res.docs[0]),
        tap((badge: Badge) =>
          this.badgeService.update(badge.id, { isNew: false }, { parentIds: [this.authService.id] })
        )
      )
      .subscribe((badge: Badge) => {
        const user = this.authService.user;

        const badgeDialog: MatDialogRef<BadgeDialogComponent> = this.dialog.open(
          BadgeDialogComponent,
          {
            data: {
              badge,
              user,
            },
          }
        );

        console.log('this.badgeList', this.badgeList);
        console.log('this.badgeCount', this.badgeCount);
        badgeDialog
          .afterClosed()
          .pipe(filter((isOpen) => Boolean(isOpen)))
          .subscribe(() => {
            this.dialog.open(BadgeListDialogComponent, {
              data: {
                badgeList: this.badgeList,
                badgeCount: this.badgeCount,
                isMy: true,
              },
            });
          });
      });
  }

  private initMyBadge(): void {
    this.setSubscription('_initMyBadge', this.badgeService.initMyBadge());
    this.setSubscription('_initMyBadgeCount', this.badgeService.initMyBadgeCount());

    this.setObservable('badgeList', 'badgeList', this.badgeService.myBadgeList$);

    this.setObservable('badgeCount', 'badgeCount', this.badgeService.myBadgeCount$);
  }
}
