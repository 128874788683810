import { Pipe, PipeTransform } from '@angular/core';
import { Coupon, CouponDiscountType, LimitationsType } from 'src/entities/coupon/types';
import { ResourceCategoryText } from 'src/entities/resource/types';

@Pipe({ name: 'convertLimitationsPipe', pure: true })
export class ConvertLimitationsPipe implements PipeTransform {
  transform(coupon: Coupon): string {
    const {
      limitationKeys,
      minimumPurchaseAmount,
      maximumDiscountAmount,
      discountType,
      enableCategories,
    } = coupon;
    const isPercent = discountType === CouponDiscountType.percentage;
    const hasCategoryRange = limitationKeys.includes(String(LimitationsType.categoryRange));
    const hasAllowedItemList = limitationKeys.includes(String(LimitationsType.listOfAllowedItems));

    let limitations = [];
    if (minimumPurchaseAmount) {
      limitations.push(`${minimumPurchaseAmount.toLocaleString()}원 이상 구매시 사용 가능`);
    }
    if (isPercent) {
      limitations.push(`최대 ${maximumDiscountAmount.toLocaleString()}원 할인`);
    }
    if (hasCategoryRange) {
      const categoryInfo =
        '사용가능 카테고리 : ' +
        `${enableCategories.map((category) => ResourceCategoryText[category]).join()}`;
      limitations.push(categoryInfo);
    }
    if (hasAllowedItemList) {
      limitations.push(`특정 상품에서만 사용가능`);
    }

    if (limitations.length === 0) return '제한 조건 없음';
    return limitations.join(', \n');
  }
}
