<div class="profile-box">
  <div
    class="profile-image-box"
    (click)="openProfileImage()"
  >
    <div *ngIf="isLoading" class="loading-outer-box">
      <div class="loading-box"></div>
    </div>

    <ng-container *ngIf="currentStep === profileStep.Empty">
      <wnd-image-card
        class="profile-image"
        [src]="emptySrc"
        ratio="1-1"
      ></wnd-image-card>

      <img
        src="/assets/images/icons/icn-circle-plus.png"
        alt="프로파일 추가 아이콘"
        class="profile-add-icon"
      >
    </ng-container>

    <ng-container *ngIf="currentStep === profileStep.Preview">
      <wnd-image-card
        class="profile-image"
        [src]="previewSrc"
        ratio="1-1"
      ></wnd-image-card>
    </ng-container>

    <ng-container *ngIf="currentStep === profileStep.Profile">
      <wnd-preview-image-card
        class="profile-image"
        ratio="1-1"
        [src]="src"
      ></wnd-preview-image-card>
    </ng-container>
  </div>
</div>
