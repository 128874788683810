import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../../../../../../src/entities/user/types';
import { Writer } from '../../../../core/types/common.model';

@Component({
  selector: 'wnd-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss'],
})
export class UserProfileCardComponent implements OnInit {
  @Input() user: User | Writer;

  emptySrc = '/assets/images/icons/profile-img.png';

  constructor() {}

  ngOnInit(): void {}
}
