<div class="wnd-textarea-box">
  <textarea
    [class.error]="error"
    [class.valid]="valid"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="value"
  ></textarea>

  <p *ngIf="useBytes" class="text-bytes">{{ value | stringConvertBytes }} / 500 bytes</p>
</div>
