import { Location } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wnd-mobile-toolbar-back',
  templateUrl: './mobile-toolbar-back.component.html',
  styleUrls: ['./mobile-toolbar-back.component.scss'],
})
export class MobileToolbarBackComponent implements OnInit {
  @Output() back = new EventEmitter();

  constructor(private location: Location) {}

  ngOnInit(): void {}

  onBack() {
    // TODO: 뒤로 가기 없을시 인덱스로 처리 필요
    this.back.emit();
    this.location.back();
  }
}
