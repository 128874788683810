<wnd-toolbar
  class="wnd-toolbar"
  [class.show]="isGnb$ | async"
  (menuClick)="toggleMenu($event)"
></wnd-toolbar>

<wnd-mobile-toolbar-back
  class="wnd-mobile-toolbar"
  [class.show]="!(isGnb$ | async) && !(isSearchKeyword$ | async)"
></wnd-mobile-toolbar-back>

<section
  class="wnd-contents-section"
  [class.search-section]="isSearchKeyword$ | async"
  [class.wnd-back-contents-section]="!(isGnb$ | async)"
>
  <router-outlet></router-outlet>

  <wnd-footer
    class="footer-section"
    [class.isGnb]="(isGnb$ | async) && !(isSearch$ | async)"
  ></wnd-footer>
</section>

<div class="mobile-menu-box" [class.show]="isOpenMenu">
  <wnd-mobile-menu (closeMenu)="toggleMenu($event)"></wnd-mobile-menu>
</div>
