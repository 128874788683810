<div class="underline-box">
  <h4 class="underline-text">
    <ng-content></ng-content>
  </h4>

  <div
    class="underline-border"
    [class.show]="isUnderline"
  ></div>
</div>
