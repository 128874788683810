import { Inject, Injectable } from '@angular/core';
import { User } from '../../../../../../../src/entities/user/types';
import { BigquerySearchAdapter } from '../../../../../../../src/modules/google/bigquery-search.adapter';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgApiService } from '../../../core/services/api.service';
import { NgTimeLineDb } from './time-line.db';
import { TimeLine } from 'src/entities/time-line/types';

@Injectable({
  providedIn: 'root',
})
export class NgTimeLineSearch extends BigquerySearchAdapter<TimeLine> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override apiService: NgApiService,
    protected timeLineDb: NgTimeLineDb
  ) {
    super(
      `${environment.firebase.projectId}.firestore_export.timeLines_schema_schema_20230710_latest`,
      apiService,
      timeLineDb
    );
  }
}
