import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';


export function AppInitFactory(appInit: AppInitService) {
  return () => appInit.init();
}

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  init() {
    this.initWebFont();
  }

  private initWebFont() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    
    import('webfontloader').then((WebFont) => {
      WebFont.load({
        google: {
          families: ['Noto Sans KR:300,500,700,900', 'Open Sans:300,600,700'],
        },
      });
    });
  }
}
