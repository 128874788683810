import { Component, OnInit, Renderer2 } from '@angular/core';
import { forkJoin, from } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';
import { NgAuthService } from '../../../../../../../weenidy/src/lib/modules/auth/auth.service';
import { ResizeImageService } from '../../../../../../../weenidy/src/lib/modules/upload/services/resize-image.service';
import { NgUploader } from '../../../../../../../weenidy/src/lib/modules/upload/services/uploader.service';
import { WndImage } from '../../../../../../../../src/core/types';
import { readAsDataURL } from '../../../../../../../weenidy/src/lib/modules/upload/utils';
import { NgUserService } from '../../../../../../../weenidy/src/lib/modules/user/services/user.service';
import { UploadTask } from '../../../../../../../weenidy/src/lib/modules/upload/services/types';
import { NgBadgeService } from '../../../../../../../weenidy/src/lib/modules/badge/services/badge.service';
import { Role } from 'src/entities/user/types';

@Component({
  selector: 'wnd-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  emptySrc = '/assets/images/icons/profile-img.png';
  previewSrc: string;
  src: WndImage;
  isLoading = false;

  profileStep = ProfileStep;
  currentStep = ProfileStep.Empty;

  private inputElement: HTMLInputElement;
  private readonly maxSize = 1024 * 1024 * 2;
  private readonly typeRegexp = new RegExp(/jpg|jpeg|png/);

  constructor(
    private authService: NgAuthService,
    private userService: NgUserService,
    private renderer: Renderer2,
    private uploader: NgUploader,
    private resizeImageService: ResizeImageService,
    private badgeService: NgBadgeService
  ) {}

  ngOnInit(): void {
    this.imageExec();

    this.authService.user$
      .pipe(
        filter((user) => Boolean(user)),
        map((user) => user.profileImage),
        filter((profile: WndImage) => Boolean(profile))
      )
      .subscribe((profile) => {
        this.src = profile;
        setTimeout(() => {
          this.currentStep = ProfileStep.Profile;
        });
      });
  }

  openProfileImage(): void {
    this.inputElement.click();
  }

  uploadProfile(file: File) {
    const dir = `profile/${this.authService.id}`;
    this.isLoading = true;

    const task: UploadTask = this.uploader.upload(file, {
      dir,
      fileName: `user-profile`,
    });

    task.getDownloadURL().subscribe((test) => {
      console.log('dir', dir);
      console.log('test', test);
      this.resizeImageService
        .resizeImage(`${dir}/${test.fileName}`, test.fileName, {
          dir,
          width: 116,
          twice: true,
        })
        .pipe(
          switchMap((wndImage: WndImage) =>
            from(this.userService.update(this.authService.id, { profileImage: wndImage }))
          ),
          switchMap(() => {
            if (this.authService.user.role !== Role.User)
              return this.badgeService.addNewProfileBadge(this.authService.id);
          })
        )
        .subscribe(
          () => {
            this.isLoading = false;
          },
          (e) => {
            console.log('e', e);
            this.isLoading = false;
            alert('에러가 발생했습니다. 다시 업로드 해주세요.');
          }
        );
    });

    task.percentageChange().subscribe((percent) => {
      console.log('percentageChanges', percent);
    });
  }

  private imageExec() {
    const inputElement = this.renderer.createElement('input');
    this.renderer.setAttribute(inputElement, 'type', 'file');
    this.renderer.setAttribute(inputElement, 'accept', 'image/*');
    this.renderer.setAttribute(inputElement, 'multiple', 'true');
    inputElement.addEventListener('change', this.onFileChange.bind(this));

    this.inputElement = inputElement;
  }

  private onFileChange(event: any) {
    const files$ = [];

    for (let i = 0; i < event.target.files.length; i++) {
      const file: File = event.target.files[i];

      if (!file || (file && file.size > this.maxSize)) {
        return alert('이미지 용량 제한은 2MB입니다.');
      }

      if (!this.typeRegexp.test(file.type)) {
        return alert('JPEG, JPG, PNG만 업로드 가능합니다.');
      }

      files$.push(readAsDataURL(file).pipe(map((e) => ({ url: e.target.result, file }))));
    }

    forkJoin(files$).subscribe((files: any[]) => {
      this.previewSrc = files[0].url;
      this.currentStep = ProfileStep.Preview;
      this.uploadProfile(files[0].file);
    });
  }
}

export enum ProfileStep {
  Empty = 1,
  Preview = 10,
  Profile = 20,
}
