import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResourceCategory } from '../../../../entities/resource/types';

@Component({
  selector: 'resource-category-seletor-dialog',
  templateUrl: './resource-category-seletor-dialog.component.html',
  styleUrls: ['./resource-category-seletor-dialog.component.scss'],
})
export class ResourceCategorySeletorDialogComponent implements OnInit {
  newBadgeSrc = 'assets/icons/New_badge.svg';
  isLoading = false;
  resourceCategory = ResourceCategory;

  selectedCategory: ResourceCategory;

  constructor(
    private dialogRef: MatDialogRef<ResourceCategorySeletorDialogComponent>,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
  }

  selectCategory(category: ResourceCategory): void {
    this.selectedCategory = category;
  }

  clickSecondaryButton() {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  submit(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(this.selectedCategory);
    });
  }

  openNotion(type: 'license' | 'resource') {
    let url = '';

    if (type === 'license') {
      url = 'https://dent-price-d42.notion.site/b01aab56df0e49958571154621806811';
    } else if (type === 'resource') {
      url = 'https://dent-price-d42.notion.site/04e699acbcdb4e6c850bd8477fb75db6';
    }

    window.open(url, '_blank');
  }
}
