import { Component, forwardRef, Input, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../core/base-value-accessor';


@Component({
  selector: 'wnd-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatePickerComponent),
      multi: true
    }
  ]
})
export class InputDatePickerComponent extends BaseValueAccessor implements OnInit, OnDestroy {
  @ViewChild('wndInput', { static: true }) wndInput: ElementRef;

  @Input() type = 'text';

  private subscription = new Subscription();

  pattern = '';

  constructor() {
    super();
  }

  override ngOnInit(): void {
    if (this.type === 'number') {
      this.pattern = '\\d*';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    const target = this.wndInput.nativeElement as HTMLInputElement;
    target.value = '';
  }

  togglePassword() {
    const isPassword = this.type === 'password';
    this.type = isPassword ? 'text' : 'password';
  }
}


