import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wnd-category-btn',
  templateUrl: './category-btn.component.html',
  styleUrls: ['./category-btn.component.scss'],
})
export class CategoryBtnComponent implements OnInit {
  @Input() disabled = true;
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
