import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import Hammer from 'hammerjs';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    pan: { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 5 },
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
  };

  constructor() {
    super();
  }

  override buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y',
    });
    return mc;
  }
}
