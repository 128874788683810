import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'wnd-feedback-dialog',
  templateUrl: './feed-back-dialog.component.html',
  styleUrls: ['./feed-back-dialog.component.scss'],
})
export class FeedBackDialogComponent implements OnInit {
  title: string;
  contents: string;

  constructor(
    private dialogRef: MatDialogRef<FeedBackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone
  ) {
    this.setDialogData();
    this.dialogRef.addPanelClass('wnd-dialog-panel');
  }

  ngOnInit(): void {}

  clickPrimaryButton() {
    this.ngZone.run(() => {
      this.dialogRef.close('primary');
    });
  }

  private setDialogData() {
    this.contents = this.data.contents;
  }
}
