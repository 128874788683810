import { Injectable } from '@angular/core';
import {
  Sns,
  Notifications,
  MainBottomBanners,
  SuggestKeyword,
  MainBannerItems,
} from '../../../../../../../src/entities/setting/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class NgSettingDb extends FirestoreDbAdapter<
  Sns | Notifications | MainBottomBanners | SuggestKeyword | MainBannerItems
> {
  constructor() {
    super(firebase.firestore(), 'settings');
  }
}
