import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isSevenDay',
})
export class IsSevenDayPipe implements PipeTransform {
  transform(value: Date): any {
    if (!value) {
      return false;
    }
    return moment().diff(value, 'day') <= 7;
  }
}
