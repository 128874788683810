import { number } from 'echarts';
import { TimeLineEvent, TimeLineText } from './types';

export function isAddTimeLine(totalLikes: number) {
  // 5명 미만, 5~999명, 1000명 마다,
  return totalLikes < 5 || (totalLikes >= 5 && totalLikes < 1000) || checkIsK(totalLikes);
}

export function getAddCartText(totalLikes: number, userName: string) {
  if (totalLikes < 5) {
    return TimeLineText[TimeLineEvent.AddCart]['1<=x<5'].replace(
      '${value}',
      transferUserName(userName)
    );
  }
  if (totalLikes >= 1000) {
    return TimeLineText[TimeLineEvent.AddCart]['1000<=x'].replace(
      '${value}',
      `${transferNumberToK(totalLikes)}`
    );
  }
  return TimeLineText[TimeLineEvent.AddCart]['5<=x<1000'].replace('${value}', `${totalLikes}`);
}

export function transferUserName(name: string) {
  return `${name[0]}**`;
}

export function transferNumberToK(number: number) {
  return Math.trunc(number / 1000);
}

function checkIsK(number: number) {
  return number % 1000 === 0;
}
