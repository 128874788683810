import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'wnd-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, WndDialogData {
  title: string;
  contents: string;
  type: WndDialogDataType = 'waring';

  isHidePrimaryButton: boolean;
  primaryButtonText: string;

  isHideSecondaryButtonText: boolean;
  secondaryButtonText: string;

  onClickPrimaryButton: () => any;
  onClickSecondaryButton: () => any;

  waringIcon = 'assets/images/icons/icn-warning.png';
  checkIcon = 'assets/images/icons/icn-check.png';

  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WndDialogData,
    private ngZone: NgZone
  ) {
    this.setDialogData();
    this.dialogRef.addPanelClass('wnd-dialog-panel');
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  clickSecondaryButton() {
    if (this.onClickSecondaryButton) {
      this.onClickSecondaryButton();
    } else {
      this.ngZone.run(() => {
        this.dialogRef.close('secondary');
      });
    }
  }

  clickPrimaryButton() {
    if (this.onClickPrimaryButton) {
      this.onClickPrimaryButton();
    } else {
      this.ngZone.run(() => {
        this.dialogRef.close('primary');
      });
    }
  }

  private setDialogData() {
    this.title = this.data.title;
    this.contents = this.data.contents;
    this.type = this.data.type;

    this.isHidePrimaryButton = Boolean(this.data.isHidePrimaryButton);
    this.isHideSecondaryButtonText = Boolean(this.data.isHideSecondaryButtonText);

    this.primaryButtonText = this.data.primaryButtonText ? this.data.primaryButtonText : '확인';
    this.secondaryButtonText = this.data.secondaryButtonText ? this.data.secondaryButtonText : '닫기';

    this.onClickPrimaryButton = this.data.onClickPrimaryButton;
    this.onClickSecondaryButton = this.data.onClickSecondaryButton;
  }
}


export interface WndDialogData {
  title: string;
  contents: string;
  type: WndDialogDataType;
  isHideSecondaryButtonText?: boolean;
  secondaryButtonText?: string;
  isHidePrimaryButton?: boolean;
  primaryButtonText?: string;

  onClickPrimaryButton?: () => any;
  onClickSecondaryButton?: () => any;
}

export type WndDialogDataType = 'check' | 'waring' | 'info';
