import { forkJoin, of, map, switchMap } from 'rxjs';
import { ApiService } from '../../core/api/api.service';
import { BaseEntity } from '../../core/base-entity';
import { DbAdapter } from '../../core/db/db.adapter';
import { SearchAdapter } from '../../core/search/search.adapter';
import { SearchQuery, SearchResponse, SearchFilter } from '../../core/search/types';
import { HashMap, ColdObservableOnce } from '../../core/types';
import { DbQuery } from '../../core/db/types';

export class MysqlAdapter<E extends BaseEntity> implements SearchAdapter<E> {
  private basePerPage = 20;

  constructor(
    protected tableName: string,
    protected apiService: ApiService,
    protected dbAdapter: DbAdapter<E>
  ) {}

  search(query: SearchQuery<E>): ColdObservableOnce<SearchResponse<E>> {
    return this.apiService
      .mysql(this.convertQueryToCountQuery(query), this.convertQueryForMysql(query))
      .pipe(switchMap((response) => this.makeResponse(query, response.data)));
  }

  query(query: string): ColdObservableOnce<any[]> {
    return this.apiService.mysqlOne(query).pipe(
      switchMap((response) => {
        return this.makeQueryResponse(response.data);
      })
    );
  }

  private convertQueryToCountQuery(query: SearchQuery<E>): string {
    let countQuery = `SELECT COUNT(DISTINCT document_id) as resultsCount FROM \`${this.tableName}\``;

    if (query.filters && query.filters.length) {
      countQuery += this.makeSqlWhere(query.filters);
    }

    countQuery += ';';

    return countQuery;
  }

  private convertQueryForMysql(query: SearchQuery<E>): string {
    let q = `SELECT DISTINCT document_id`;

    if (query.sorts && query.sorts.length) {
      for (const sort of query.sorts) {
        q += `, ${sort.field}`;
      }
    }

    q += ` FROM \`${this.tableName}\``;

    if (query.filters && query.filters.length) {
      q += this.makeSqlWhere(query.filters);
    }

    if (query.sorts && query.sorts.length) {
      for (let i = 0; i < query.sorts.length; i++) {
        const sort = query.sorts[i];

        if (i === 0) {
          q += ` ORDER BY `;
        } else {
          q += `, `;
        }

        q += `${sort.field} ${sort.direction}`;
      }
    }

    const perPage = query.limit || this.basePerPage;

    q += ` Limit ${perPage}`;

    if (query.page > 0) {
      q += ` OFFSET ${query.page * perPage}`;
    }

    q += ';';

    return q;
  }

  private makeQueryResponse(docs: any[]): ColdObservableOnce<E[]> {
    let observable: ColdObservableOnce<any[]>;

    if (docs.length === 0) {
      observable = of([]);
    } else {
      observable = of(docs);
    }

    return observable.pipe(
      map((entities) => entities.filter(Boolean)),
      map((entities) => entities as any[])
    );
  }

  private makeResponse(
    query: SearchQuery<E>,
    { count, docs }: { count: number; docs: any[] }
  ): ColdObservableOnce<SearchResponse<E>> {
    // const entities = docs.map(doc => this.convertDocToEntity(doc));

    let observable: ColdObservableOnce<E[]>;

    if (docs.length === 0) {
      observable = of([]);
    } else {
      if (docs.length > 300) {
        const dbQuery: DbQuery = {
          filters: query.filters[0] as any,
          limit: 10000,
        };

        observable = this.listFromFirestore([], dbQuery).pipe(
          switchMap((firestoreDocs) => {
            const results = [];
            const docMap = {};
            const getObservables = [];

            for (const doc of firestoreDocs) {
              docMap[doc.id] = doc;
            }

            for (let i = 0; i < docs.length; i++) {
              const docId = docs[i].document_id;

              if (docMap[docId]) {
                results[i] = docMap[docId];
              } else {
                getObservables.push(this.dbAdapter.get(docId));
              }
            }

            return forkJoin([of(results), ...getObservables]);
          }),
          map(([results, ...getResults]) => {
            if (getResults.length > 0) {
              const docMap = {};

              for (const doc of getResults) {
                docMap[(doc as any).id] = doc;
              }

              for (let i = 0; i < docs.length; i++) {
                const docId = docs[i].document_id;

                if (docMap[docId]) {
                  results[i] = docMap[docId];
                }
              }

              return results as any;
            } else {
              return results as any;
            }
          })
        );
      } else {
        observable = forkJoin(docs.map((doc) => this.dbAdapter.get(doc.document_id)));
      }
    }

    return observable.pipe(
      map((entities) => entities.filter(Boolean)),
      map((entities) => ({
        items: entities as E[],
        totalCount: count,
        count: entities.length,
        page: query.page || 0,
        limit: query.limit || this.basePerPage,
      }))
    );
  }

  private listFromFirestore(docs: E[], query: DbQuery, lastDoc?: any): ColdObservableOnce<E[]> {
    if (lastDoc) {
      query.gt = lastDoc;
    }

    return this.dbAdapter.list(query).pipe(
      switchMap((response) => {
        docs = docs.concat(response.docs);

        if (response.lastDoc) {
          return this.listFromFirestore(docs, query, response.lastDoc);
        } else {
          return of(docs);
        }
      })
    );
  }

  private convertDocToEntity(doc: HashMap<any>): BaseEntity {
    const id = doc['document_id'];

    delete doc['document_id'];
    delete doc['document_name'];
    delete doc['operation'];
    delete doc['timestamp'];

    return {
      ...this.convertFlatToObject(this.convertTimestampToDate(doc)),
      id,
    };
  }

  private convertTimestampToDate(documentData: any): any {
    if (documentData && documentData.value) {
      return new Date(documentData.value);
    } else if (Array.isArray(documentData)) {
      const node = [];

      for (const data of documentData) {
        node.push(this.convertTimestampToDate(data));
      }

      return node;
    } else if (documentData === null) {
      return null;
    } else if (typeof documentData === 'object') {
      const data = { ...documentData };

      if (Object.keys(data).length > 0) {
        for (const key in data) {
          if (data.hasOwnProperty(key) && data[key]) {
            data[key] = this.convertTimestampToDate(data[key]);
          }
        }

        return data;
      }
    } else {
      return documentData;
    }
  }

  private convertFlatToObject(doc: HashMap<any>): E {
    const entity = {} as E;

    for (const key in doc) {
      if (doc.hasOwnProperty(key)) {
        const split = key.split('_');

        for (const k of split) {
          // entity[k]
        }
      }
    }

    return doc as E;
  }

  private makeSqlWhere(filters: SearchFilter[]): string {
    let where = '';

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];

      if (i === 0) {
        where += ` WHERE `;
      }

      if (filter.parenthesis) {
        if (filter.parenthesis === 'start' && i !== 0) {
          where += ` ${filter.logical ? filter.logical.toUpperCase() : 'AND'} `;
        }

        where += filter.parenthesis === 'start' ? '(' : ')';
        continue;
      }

      if (i !== 0 && where.charAt(where.length - 1) !== '(') {
        where += ` ${filter.logical ? filter.logical.toUpperCase() : 'AND'} `;
      }

      where += this.makeSqlWhereCond(filter);
    }

    return where;
  }

  private makeSqlWhereCond(filter: SearchFilter): string {
    let field = filter.field;

    if (filter.comparison === 'array-contains') {
      field = `${filter.field}_member`;
    }

    let comparison = filter.comparison === '!=' ? '<>' : filter.comparison;
    comparison = filter.comparison === 'text' ? 'LIKE' : comparison;
    comparison = filter.comparison === '==' ? '=' : comparison;
    comparison = filter.comparison === 'array-contains' ? '=' : comparison;

    let cond = `${field} ${comparison} `;

    if (typeof filter.value === 'string') {
      if (filter.comparison === 'text') {
        cond += `'%${filter.value}%'`;
      } else {
        cond += `'${filter.value}'`;
      }
    } else if (typeof filter.value === 'number') {
      cond += filter.value;
    } else if (typeof filter.value === 'boolean') {
      cond += filter.value;
    } else if (filter.value instanceof Date) {
      cond += `TIMESTAMP("${filter.value.toISOString()}")`;
    } else {
    }

    return cond;
  }
}
