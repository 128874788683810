import { OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { SubscriptionBaseService } from '../../../../../../src/core/base-services/subscription-base.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class SubscriptionBaseComponent extends SubscriptionBaseService implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
}
