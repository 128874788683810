import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  CanMatch,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { first, Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BrowserService } from '../../../../../weenidy/src/lib/shared/services/browser.service';
import { NgAuthService } from '../../../../../weenidy/src/lib/modules/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad, CanMatch {
  constructor(
    private authService: NgAuthService,
    private router: Router,
    private browserService: BrowserService
  ) {}

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.browserService.isBrowser) {
      return true;
    }
    return this.checkLoggedIn((route.data || {})['blockNavigating']);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('enter');
    if (!this.browserService.isBrowser) {
      return true;
    }
    return this.canActivate(route);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.browserService.isBrowser) {
      return true;
    }
    return this.checkLoggedIn((route.data || {})['blockNavigating']);
  }

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.browserService.isBrowser) {
      return true;
    }
    return this.checkLoggedIn((route.data || {})['blockNavigating']);
  }

  checkLoggedIn(blockNavigating = false) {
    return this.authService.state$.pipe(
      first((state) => state.authLoaded),
      switchMap(() => this.authService.isLoggedIn$),
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          if (!blockNavigating) {
            // console.log('this.router.navigated', this.router.navigated);
            this.router.navigateByUrl('/').then();
          }
          throw new Error('access denied!');
        }
      })
    );
  }
}
