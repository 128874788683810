import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { BaseSubscriptionComponent } from '../../../../core/components/base-subscription.component';
import { fadeIn, fadeOut } from '../../../../layout/components/toolbar/animation';
import { IntroToolbarService } from '../../../../layout/service/intro-toolbar.service';

@Component({
  selector: 'wnd-search-input-form',
  templateUrl: './search-input-form.component.html',
  styleUrls: ['./search-input-form.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class SearchInputFormComponent extends BaseSubscriptionComponent implements OnInit {
  @Output() onSearch = new EventEmitter<string>();

  searchFormGroup: FormGroup = this.createFormGroup();
  isFocus = false;
  isHover = false;

  private isLeaveSearch$: Observable<boolean>;

  constructor(private fb: FormBuilder, private introToolbarService: IntroToolbarService) {
    super();
    this.isLeaveSearch$ = this.introToolbarService.isLeaveSearch$.pipe(
      distinctUntilChanged(),
      filter((isLeave) => Boolean(isLeave))
    );
  }

  ngOnInit(): void {
    this.subscription.add(this.initLeaveSearch());
  }

  search(): void {
    this.isFocus = false;
    const { keyword } = this.searchFormGroup.value;

    this.onSearch.emit(keyword);
  }

  selectRecentKeyword(keyword: string) {
    this.searchFormGroup.patchValue({ keyword });
    this.onSearch.emit(keyword);
  }

  onBlur(): void {
    setTimeout(() => {
      this.isFocus = false;
    }, 50);
  }
  onMouseLeave(): void {
    setTimeout(() => {
      this.isHover = false;
    }, 50);
  }

  private initLeaveSearch(): Subscription {
    return this.isLeaveSearch$.subscribe((isLeave) => {
      this.searchFormGroup.reset({ keyword: null });
    });
  }

  private createFormGroup(): FormGroup {
    return this.fb.group({
      keyword: ['', Validators.required],
    });
  }
}
