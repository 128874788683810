<div class="user-profile-box">
  <wnd-profile-card></wnd-profile-card>

  <div class="user-info-box">
    <div class="user-name-box">
      <wnd-underline-text class="H2-900 height-6 overflow-ellipsis" [isUnderline]="true"
        >{{ name || unknownName }}
      </wnd-underline-text>
    </div>
    <p class="user-email">{{ email }}</p>
  </div>
</div>
