import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupDialogComponent } from './components/popup-dialog/popup-dialog.component';
import { LibFormModule } from '../../../../../weenidy/src/lib/shared/form/lib-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WndPipeModule } from '../../../../../weenidy/src/lib/modules/pipe/wnd.pipe.module';
import { ImagesModule } from '../../../../../weenidy/src/lib/modules/images/images.module';

@NgModule({
  declarations: [PopupDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LibFormModule,
    WndPipeModule,
    ImagesModule,
  ],
})
export class PopupModule {}
