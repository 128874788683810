import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'phoneParse'
})
export class PhoneParsePipe implements PipeTransform {
  substringIndexes = {
    [10]: [3, 3, 4],
    [11]: [3, 4, 4],
  };

  transform(value: string): unknown {
    if (!value) {
      return '';
    }

    const substringIndex: number[] = this.substringIndexes[value.length];
    const phoneNumberArray = [];

    let startNum = 0;

    substringIndex.map(index => {
      phoneNumberArray.push(value.substr(startNum, index));
      startNum += index;
    });
    return phoneNumberArray.join('-');
  }

}
