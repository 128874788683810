<button
  class="wnd-btn border-radios icn-btn"
  [type]="type"
  [ngClass]="color"
  [disabled]="disabled"
>
  <div class="icn-left-box">
    <ng-content></ng-content>
  </div>

  <div class="icn-right-box">
    <i
      class="wnd-icn button-icn"
      [ngClass]="icon"
    ></i>
  </div>
</button>
