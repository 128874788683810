import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'formHasError',
})
export class FormHasErrorPipe implements PipeTransform {
  transform(errors: ValidationErrors | null): boolean {
    return Boolean(errors);
  }
}
