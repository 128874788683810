<div class="dialog-box" *ngIf="isLoading">
  <div class="dialog-contents-box">
    <img
      class="dialog-icon"
      [src]="type === 'waring' ? waringIcon : checkIcon"
      alt="상태 아이콘"
    >
    <h4 class="dialog-title">{{ title }}</h4>

    <article class="dialog-content" [innerHTML]="contents"></article>
  </div>

  <div class="dialog-buttons-box">
    <button
      class="wnd-btn"
      [class.hide]="isHideSecondaryButtonText"
      (click)="clickSecondaryButton()"
    >{{ secondaryButtonText }}</button>

    <button
      class="wnd-btn"
      [class.hide]="isHidePrimaryButton"
      (click)="clickPrimaryButton()"
    >{{ primaryButtonText }}</button>
  </div>
</div>
