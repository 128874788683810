import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ResourceStatus } from '../../../../../../../../src/entities/resource/types';
import { NgCartOverlayService } from '../../../cart/services/cart-overlay.service';
import { NgCartService } from '../../../../../../../weenidy/src/lib/modules/cart/services/cart.service';
import { NgAuthService } from "../../../../../../../weenidy/src/lib/modules/auth/auth.service";
import { SubscriptionBaseComponent } from '../../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';
import { switchMap, tap } from 'rxjs/operators';
import { User } from '../../../../../../../../src/entities/user/types';
import { Cart, LicenseType } from '../../../../../../../../src/entities/cart/types';
import { PgType } from '../../../../../../../../src/entities/payment/types';

@Component({
  selector: 'wnd-payment-cart',
  templateUrl: './payment-cart.component.html',
  styleUrls: ['./payment-cart.component.scss'],
})
export class PaymentCartComponent extends SubscriptionBaseComponent implements OnInit {
  @Output() onFinished = new EventEmitter();

  resources: any[];
  carts: Cart[];
  status = ResourceStatus;
  user: User;
  licenseType: LicenseType;
  pg: PgType = 'nice';
  constructor(
    private cartOverlayService: NgCartOverlayService,
    private cartService: NgCartService,
    private authService: NgAuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.initResources());
  }

  close() {
    this.cartOverlayService.close();
  }

  delete(id: string): Promise<void> {
    return this.cartService.delete(this.user.id, id).toPromise();
  }

  onPaidHandler(): void {
    Promise.all(this.resources.map((resource) => this.delete(resource.id))).then(() => {
      this.onFinished.emit();
    });
  }

  onFailHandler(): void {
    this.onFinished.emit();
  }

  changePg(pg: PgType) {
    this.pg = pg;
  }

  private initResources() {
    return this.authService.user$
      .pipe(
        tap((user) => (this.user = user as any)),
        switchMap((user) => this.cartService.listChange(user.id))
      )
      .subscribe(([resources, carts]) => {
        this.resources = resources.map((resource: any) => {
          const licenseType = carts.filter((cart) => cart.id === resource.id)[0].licenseType;
          if (licenseType) {
            resource.licenseType = licenseType;
          }
          return resource;
        });

        this.carts = carts;

        carts.forEach(
          (cart: Cart, index: number) => (this.resources[index].licenseType = cart.licenseType)
        );
      });
  }
}
