<div class="wnd-input-box">
  <img
    *ngIf="isSearch"
    class="input-search-icn"
    src="/assets/input/icn-search-n.png"
    alt="돋보기 아이콘"
  />

  <input
    #wndInput
    [class.error]="error"
    [class.valid]="valid"
    [class.search-input]="isSearch"
    [type]="type"
    [id]="id"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [pattern]="pattern"
    [maxLength]="maxLength"
  />

  <div *ngIf="isSearch" class="input-remove-icn" (click)="reset()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <g fill="none" fill-rule="evenodd">
        <circle class="icn-input-delete-circle" cx="8" cy="8" r="8" />
        <path fill="#FFF" d="M4 4l9 8h-1L3 4z" />
        <path fill="#FFF" d="M13 4l-9 8H3l9-8z" />
      </g>
    </svg>
  </div>

  <div *ngIf="isPassword" class="input-password-icn" (click)="togglePassword()">
    <i *ngIf="type === 'password'" class="wnd-icn password"></i>
    <i *ngIf="type !== 'password'" class="wnd-icn password-hide"></i>
  </div>
</div>
