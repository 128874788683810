import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { WndRatio } from '../../types';
import { WndImage } from '../../../../../../../../src/core/types';

@Component({
  selector: 'wnd-preview-image-card',
  templateUrl: './preview-image-card.component.html',
  styleUrls: ['./preview-image-card.component.scss'],
})
export class PreviewImageCardComponent implements OnInit {
  @Input() alt = '이미지';
  @Input() src: WndImage;
  @Input() ratio: WndRatio;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.imageStyle();
    });
  }

  imageStyle() {
    if (!this.ratio) {
      return;
    }

    const boxRatios = this.ratio.split('-');
    const boxResult = parseInt(boxRatios[0], 10) - parseInt(boxRatios[1], 10);

    if (!this.src.ratio) {
      return;
    }

    const imageRatios = this.src.ratio.split(':');
    const imageResult = parseInt(imageRatios[0], 10) - parseInt(imageRatios[1], 10);

    let className: string;

    if (boxResult > 0) {
      // 가로큼
      className = imageResult > 0 ? 'wnd-width' : 'wnd-height';
    } else {
      //  세로큼
      className = imageResult < 0 ? 'wnd-width' : 'wnd-height';
    }

    const element = this.elementRef.nativeElement.querySelector('.origin-image');
    element.classList.add(className);
  }
}
