import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseTypeToTextPipe } from './pipes/license-type-to-text.pipe';
import { LicenseTypeToPricePipe } from './pipes/license-type-to-price.pipe';

@NgModule({
  declarations: [LicenseTypeToTextPipe, LicenseTypeToPricePipe],
  imports: [CommonModule],
  exports: [LicenseTypeToTextPipe, LicenseTypeToPricePipe],
})
export class LibLicenseModule {}
