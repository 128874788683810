import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, map } from 'rxjs/operators';
import { ScrollService } from '../../core/service/scroll.service';

@Injectable({
  providedIn: 'root',
})
export class IntroToolbarService {
  isIndex$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isHide$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLeaveSearch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSearchPage$: BehaviorSubject<boolean> = new BehaviorSubject(
    this.checkIsResourceSearch(this.router.url)
  );

  isShowSearchForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscription: Subscription = new Subscription();

  constructor(private router: Router, private scrollService: ScrollService) {
    this.subscription.add(this.initIsIndex());
    this.subscription.add(this.initIsLeaveSearch());
    this.subscription.add(this.initScrollHandler());
    this.subscription.add(this.initIsResourceSearch());
    this.subscription.add(this.initIsShowSearchForm());

    this.isIndex$.next(this.router.url === '/index');
    this.isHide$.next(window.scrollY > 45);
  }

  private initIsShowSearchForm(): Subscription {
    return this.scrollService
      .searchPageScrollHandler(this.isSearchPage$)
      .subscribe((isShow: boolean) => {
        this.isShowSearchForm$.next(isShow);
      });
  }

  private initIsIndex(): Subscription {
    let beforeIndex = -1;
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((result: NavigationEnd) => {
          const currentUrlIndex = result.urlAfterRedirects.indexOf('/search/list');

          if (beforeIndex === currentUrlIndex) {
            return false;
          } else {
            if (beforeIndex === 0 && currentUrlIndex !== 0) {
              beforeIndex = currentUrlIndex;
              return true;
            } else {
              beforeIndex = currentUrlIndex;
              return false;
            }
          }
        })
      )
      .subscribe((isIndex) => this.isLeaveSearch$.next(isIndex));
  }

  private initIsLeaveSearch(): Subscription {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((result: NavigationEnd) => result.url === '/index' || result.url === '/')
      )
      .subscribe((isIndex) => this.isIndex$.next(isIndex));
  }

  private initIsResourceSearch(): Subscription {
    return this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        map((e: NavigationEnd) => this.checkIsResourceSearch(e.url))
      )
      .subscribe((isResourceSearch: boolean) => {
        this.isSearchPage$.next(isResourceSearch);
      });
  }

  private checkIsResourceSearch(url: string): boolean {
    return url.indexOf('/resource/search') !== -1;
  }

  private initScrollHandler(): Subscription {
    return this.scrollService
      .indexScrollHandler(this.isIndex$)
      .pipe(filter((isHide) => isHide !== null))
      .subscribe((isHide) => this.isHide$.next(isHide));
  }
}
