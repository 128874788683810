import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FormErrorService } from '../../services/form.error.service';
import { DateValidator } from '../../../validator/validators';
import { inputOnlyNumber } from '../../../utils';

@Component({
  selector: 'wnd-birthday-input',
  templateUrl: './birthday-input.component.html',
  styleUrls: ['./birthday-input.component.scss'],
})
export class BirthdayInputComponent implements OnInit {
  @Input()
  get birthday() {
    return this._birthday;
  }

  set birthday(birth: string) {
    if (!birth) {
      return;
    }

    this._birthday = birth;
    const birthDateArray: number[] = birth.split('-').map((date: string) => parseInt(date, 10));

    const doc: Partial<any> = {
      birthYear: birthDateArray[0] || '',
      birthMonth: birthDateArray[1] || '',
      birthDate: birthDateArray[2] || '',
    };

    this.birthFormGroup.patchValue(doc);

    setTimeout(() => {
      this.valueChanges.emit(this.birthFormGroup.value);
      this.statusChanges.emit(false);
    });
  }

  @Output() valueChanges = new EventEmitter();
  @Output() statusChanges = new EventEmitter();

  birthFormGroup = this.fb.group({
    birthYear: [null, [Validators.required, DateValidator.year]],
    birthMonth: [null, [Validators.required, DateValidator.month]],
    birthDate: [null, [Validators.required, DateValidator.date]],
  });

  dateErrorText: string;
  dateErrorTexts = this.formErrorService.getErrorMessage({ date: true });

  private _birthday: string;

  constructor(private fb: FormBuilder, private formErrorService: FormErrorService) {}

  ngOnInit(): void {
    this.initBirthErrorControls();
    this.initValueChangesEmit();
    this.initStatusChangesEmit();
  }

  keyDownOnlyNumber(event) {
    inputOnlyNumber(event);
  }

  private initBirthErrorControls() {
    return this.birthFormGroup.valueChanges
      .pipe(
        map(() => Object.keys(this.birthFormGroup.controls)),
        map((controlKeys: string[]) => {
          const errors = {};

          controlKeys.forEach((key) => Object.assign(errors, this.birthFormGroup.get(key).errors));
          return errors;
        })
      )
      .subscribe((errors: any) => {
        if (errors.year) {
          this.dateErrorText = this.dateErrorTexts.year;
        } else if (errors.month) {
          this.dateErrorText = this.dateErrorTexts.month;
        } else if (errors.date) {
          this.dateErrorText = this.dateErrorTexts.date;
        } else if (errors.required) {
          this.dateErrorText = this.dateErrorTexts.required;
        } else {
          this.dateErrorText = '';
        }
      });
  }

  private initValueChangesEmit() {
    this.birthFormGroup.valueChanges
      .pipe(
        map((value) => {
          Object.keys(value).forEach((key) => (value[key] = parseInt(value[key], 10)));
          return value;
        })
      )
      .subscribe((value) => this.valueChanges.emit(value));
  }

  private initStatusChangesEmit() {
    this.birthFormGroup.statusChanges
      .pipe(map((status) => status === 'INVALID'))
      .subscribe((value) => this.statusChanges.emit(value));
  }
}

export interface BirthdayPayload {
  birthYear: number;
  birthMonth: number;
  birthDate: number;
}
