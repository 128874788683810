<section class="open-section">
  <div class="open-contents-box">
    <img
      src="assets/images/logo/logo.png"
      alt="위니디 로고"
      width="150"
      style="margin-bottom: 30px"
    >

    <h4 class="open-header">위니디 서비스가 업데이트</h4>

    <article class="open-article">
      사이트 업데이트를 진행중입니다.<br>
      최대한 빠르게 오픈하겠습니다,
    </article>

    <div id="lottie-box"></div>
  </div>
</section>
