import { Inject, Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment, Configuration } from '../../../../../../../src/core/environment';
import {
  ColdObservableOnce,
  WeenidyResizeImageOptions,
  WndImage,
} from '../../../../../../../src/core/types';
import { PaymentAnnotation } from '../../../../../../../src/entities/payment/types';
import { HttpClient } from '@angular/common/http';
import { ResourceStatus } from '../../../../../../../src/entities/resource/types';
import { WithdrawalStatus } from '../../../../../../../src/entities/withdrawal/types';
import firebase from 'firebase/compat/app';
import app = firebase.app;

@Injectable({
  providedIn: 'root',
})
export class NgFunctionsCaller {
  baseURL = 'https://asia-northeast3-weenidy-subscribe-8b2a7.cloudfunctions.net';

  private functions = app().functions('asia-northeast3');
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private httpClient: HttpClient
  ) {
    if (environment.configuration === Configuration.Local) {
      this.functions.useEmulator('127.0.0.1', 5001);

      this.baseURL = 'http://127.0.0.1:5001/weenidy-subscribe-8b2a7/asia-northeast3';
    } else if (environment.configuration === Configuration.Dev) {
      this.baseURL = 'https://asia-northeast3-weenidy-subscribe-8b2a7.cloudfunctions.net';
    } else {
      this.baseURL = 'https://asia-northeast3-weenidy-subscribe-prod.cloudfunctions.net';
    }
  }

  resizeImage(
    originalFilePath: string,
    sizeOptions: WeenidyResizeImageOptions[],
    originalRemoval: boolean = false,
    waterMark: boolean = false
  ): Observable<WndImage> {
    return from(
      this.functions.httpsCallable('resizeImageAsia')({
        originalFilePath,
        sizeOptions,
        originalRemoval,
        waterMark,
      })
    ).pipe(map((result: any) => result.data || result));
  }

  sendResetPasswordMail(email: string): Observable<any> {
    return from(this.functions.httpsCallable('sendResetPasswordMail')({ to: email }));
  }

  sendResourceStatusChangeMail(
    email: string,
    status: ResourceStatus,
    userName: string,
    title: string
  ): Observable<any> {
    return this.httpClient
      .post(`${this.baseURL}/sendResourceStatusChangeMail`, {
        data: {
          to: email,
          status,
          userName,
          title,
        },
      })
      .pipe(
        map((data: any) => {
          return data.result || data;
        })
      );
  }

  sendResourceStatusManageMail(
    email: string,
    status: ResourceStatus,
    userName: string,
    title: string
  ): Observable<any> {
    return this.httpClient
      .post(`${this.baseURL}/sendResourceStatusManageMail`, {
        data: {
          to: email,
          status,
          userName,
          title,
        },
      })
      .pipe(
        map((data: any) => {
          return data.result || data;
        })
      );
  }

  sendWithdrawalRequestStatusChangeMail(
    email: string,
    status: WithdrawalStatus,
    userName: string,
    applicationAmount: number
  ): Observable<any> {
    return this.httpClient
      .post(`${this.baseURL}/sendWithdrawalRequestStatusChangeMail`, {
        data: {
          to: email,
          status,
          userName,
          applicationAmount,
        },
      })
      .pipe(
        map((data: any) => {
          return data.result || data;
        })
      );
  }

  resetPassword(email: string, code: string, password: string): Observable<any> {
    return from(this.functions.httpsCallable('resetPassword')({ email, code, password }));

    // TODO: error.message [invalid-code] invalid code
    // TODO: error.message [over-expiration-time] 시간 지났을 때
  }

  requestPay({ key, userId }): Observable<any> {
    return from(this.functions.httpsCallable('requestPay')({ key, userId, version: '2' }));
  }

  iamportCancel(
    userId: string,
    paymentId: string,
    impUid: string,
    contentId: string
  ): Observable<any> {
    return from(
      this.functions.httpsCallable('iamportCancel')({
        userId,
        paymentId,
        impUid,
        contentId,
        version: '2',
      })
    );
  }

  iamportCancelAdmin(
    userId: string,
    paymentId: string,
    impUid: string,
    contentId: string,
    amount: number
  ): Observable<any> {
    // return this.functions.httpsCallable('iamportCancel')({ userId, paymentId, impUid, contentId });
    return this.httpClient
      .post(`${this.baseURL}/iamportCancel`, {
        data: { userId, paymentId, impUid, contentId, amount, version: '2' },
      })
      .pipe(
        map((data: any) => {
          return data.result || data;
        })
      );
  }

  updatePaymentStatus(
    userId: string,
    paymentId: string,
    impUid: string,
    contentId: string
  ): Observable<any> {
    return this.httpClient
      .post(`${this.baseURL}/updatePaymentStatus`, {
        data: { userId, paymentId, impUid, contentId, version: '2' },
      })
      .pipe(
        map((data: any) => {
          return data.result || data;
        })
      );
  }

  iamportOnlyAmountCancel(impUid: string): Observable<any> {
    return from(this.functions.httpsCallable('iamportOnlyAmountCancel')({ impUid, version: '2' }));
  }

  paymentData(merchantUid: string): Observable<{ data: PaymentAnnotation }> {
    return from(
      this.functions.httpsCallable('paymentData')({
        merchant_uid: merchantUid,
        version: '2',
      }) as unknown as Promise<{ data: PaymentAnnotation }>
    );
  }

  verifyPhone(id: string, code: string, phoneNumber: string): Observable<void> {
    return from(
      this.functions.httpsCallable('verifyPhone')({
        id,
        code,
        phoneNumber,
      }) as unknown as Promise<void>
    );
  }

  sendVerificationSms(id: string, phoneNumber: string): Observable<any> {
    // TODO: response.status === '0' 성공
    // TODO: response.status === '102' 받는 사람의 번호가 잘 못 됨
    // 그 외. https://directsend.co.kr//manual/api/sms_api_utf.php 아래 Status Code에 있음
    return from(
      this.functions.httpsCallable('sendPhoneVerification')({
        id,
        phoneNumber,
      }) as unknown as Promise<string>
    );
  }

  deleteUser(id: string): Observable<any> {
    return from(this.functions.httpsCallable('deleteUser')({ id }));
  }

  analyticsSearches(startDate: string, endDate: string): ColdObservableOnce<any> {
    return from(
      this.functions.httpsCallable('analyticsSearches')({
        startDate,
        endDate,
      }) as unknown as Promise<any>
    );
  }
}
