import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'wnd-error-text',
  template: `
    <div class="wnd-form-error-box" [class.show]="!_hide" [class.hide]="_hide && !_init">
      <p class="wnd-error-text">{{ _text }}</p>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./error-text.component.scss'],
})
export class ErrorTextComponent implements OnInit {
  _text: string;
  _hide = true;
  _init = true;

  @Input()
  get text() {
    return this._text;
  }

  set text(value: string) {
    if (!value) {
      this._text = undefined;
      this._hide = true;
      this.cdr.detectChanges();
      return;
    }

    if (value !== this._text) {
      this._text = value;
      this._hide = !value;
      this._init = false;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}
}
