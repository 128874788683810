import {
  Directive,
  Optional,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorTextComponent } from '../components/error-text/error-text.component';
import { WndErrors } from './form.error';
import { ControlErrorContainerDirective } from './form.error.container.directive';

@Directive({
  selector: '[formControlName]',
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  @Input() useError = true;
  ref: ComponentRef<ErrorTextComponent>;
  container: ViewContainerRef;

  private subscription = new Subscription();

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    private controlDir: NgControl
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
  }

  ngOnInit() {
    if (this.useError) {
      this.subscription.add(this.initError());
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get name(): string | number {
    return this.controlDir.name;
  }

  get control(): AbstractControl {
    return this.controlDir.control;
  }

  // @HostBinding()
  setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ErrorTextComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

  private initError(): Subscription {
    return this.control.statusChanges.subscribe(() => {
      const error = this.control.errors;
      if (error && this.control.dirty) {
        const firstKey = Object.keys(error)[0];

        const text = WndErrors[this.name] ? WndErrors[this.name][firstKey] : '';
        this.setError(text);
      } else {
        this.setError(undefined);
      }
    });
  }
}
