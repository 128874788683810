import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';
import { RecentKeyword } from "../../../../../../../src/entities/recent-keyword/types";

@Injectable({
  providedIn: 'root'
})
export class NgRecentKeywordDb extends FirestoreDbAdapter<RecentKeyword> {
  constructor() {
    super(firebase.firestore(), 'recentKeywords', { recordTime: false });
  }
}
