import { ENVIRONMENT } from './../../../../core/tokens';
import { Resource } from './../../../../../../../../src/entities/resource/types';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Coupon } from 'src/entities/coupon/types';
import { NgResourceService } from '../../../resource/services/resource.service';
import { Environment } from 'src/core/environment';
import { NbStatusService } from '@nebular/theme';

@Component({
  selector: 'wnd-coupon-detail-dialog',
  templateUrl: './coupon-detail-dialog.component.html',
  styleUrls: ['./coupon-detail-dialog.component.scss'],
  providers: [NbStatusService],
})
export class CouponDetailDialogComponent implements OnInit {
  coupon: Coupon;
  listOfAllowedItems: Resource[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { coupon: Coupon },
    private dialogRef: MatDialogRef<CouponDetailDialogComponent>,
    private resourceService: NgResourceService
  ) {
    this.coupon = data.coupon;
  }
  ngOnInit(): void {
    this.setListOfAllowedItems();
  }

  onClosedPopup() {
    this.dialogRef.close();
  }
  setListOfAllowedItems() {
    const ids = this.coupon.listOfAllowedItemIds || [];
    if (ids.length > 0) {
      this.resourceService.getMany(ids).subscribe({
        next: (resources: Resource[]) => {
          console.log('check here::', resources);
          return (this.listOfAllowedItems = resources);
        },
        error: (err) => {
          console.error(err);
          return alert(err || '쿠폰 적용가능한 상품목록 조회에 실패하였습니다.');
        },
      });
    }
  }
}
