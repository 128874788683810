import firebase from 'firebase/compat/app';
import { from, merge, Observable, partition, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthAdapter } from '../../core/auth/auth.adapter';
import { AuthSession } from '../../core/auth/types';
import { ColdObservable, ColdObservableOnce, HotObservableOnce } from '../../core/types';
import { Role, User } from '../../entities/user/types';
import UserCredential = firebase.auth.UserCredential;

export class FirebaseAuthAdapter implements AuthAdapter {
  firestore = firebase.firestore();
  constructor(protected auth: firebase.auth.Auth) {}

  sessionChange(): ColdObservable<AuthSession> {
    return new Observable((subscriber) => {
      this.auth.onAuthStateChanged(
        (next) => subscriber.next(next),
        (err) => subscriber.error(err),
        () => subscriber.complete()
      );
    }).pipe(
      switchMap((firebaseUser: firebase.User) => this.convertFirebaseUserToSession(firebaseUser))
    );
  }

  initLoggedInAtChange(): ColdObservableOnce<string> {
    return new Observable((subscriber) => {
      this.auth.onAuthStateChanged(
        (next: firebase.User) => subscriber.next(next),
        (err) => subscriber.error(err),
        () => subscriber.complete()
      );
    }).pipe(
      distinctUntilChanged(),
      filter((user) => Boolean(user)),
      map((user: firebase.User) => user.uid)
    );
  }

  signUpWithEmail(email: string, password: string): HotObservableOnce<AuthSession> {
    return from(this.auth.createUserWithEmailAndPassword(email, password)).pipe(
      switchMap((userCredential) => this.convertFirebaseUserToSession(userCredential.user))
    );
  }

  loginWithEmail(email: string, password: string): HotObservableOnce<AuthSession> {
    return from(this.auth.signInWithEmailAndPassword(email, password)).pipe(
      switchMap((userCredential) => this.convertFirebaseUserToSession(userCredential.user))
    );
  }

  loginWithGoogle(): HotObservableOnce<AuthSession> {
    const provider = new firebase.auth.GoogleAuthProvider();

    let userCredential: UserCredential;

    const loginThenHasUser$ = from(firebase.auth().signInWithPopup(provider)).pipe(
      tap((credential) => (userCredential = credential)),
      switchMap((credential) => this.checkHasUser(credential.user))
    );

    const [hasUser$, noUser$] = partition(loginThenHasUser$, (hsdUse: boolean) => {
      return Boolean(hsdUse);
    });

    return merge(
      hasUser$,
      noUser$.pipe(
        switchMap(() =>
          this.processUserUnExist(
            userCredential.user,
            userCredential.additionalUserInfo.profile,
            'google'
          )
        )
      )
    ).pipe(
      tap((user) => console.log(user, userCredential)),
      switchMap(() => this.convertFirebaseUserToSession(userCredential.user)),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  logout(): HotObservableOnce<void> {
    return from(this.auth.signOut());
  }

  sendPasswordResetEmail(email: string): HotObservableOnce<void> {
    return from(this.auth.sendPasswordResetEmail(email));
  }

  reauthenticateWithPassword(email: string, password: string): ColdObservableOnce<AuthSession> {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);

    return from(this.auth.currentUser.reauthenticateWithCredential(credential)).pipe(
      switchMap((userCredential) => this.convertFirebaseUserToSession(userCredential.user))
    );
  }

  private processUserUnExist(firebaseUser: firebase.User, firebaseProfile, provider) {
    const userInfo: any = {};

    if (firebaseProfile.email) {
      userInfo.email = firebaseProfile.email;
    }

    return from(this.addUserInFirestore(firebaseUser.uid, provider, userInfo));
  }

  private addUserInFirestore(uid: string, provider: string, userInfo?: any): Promise<void> {
    const user: Partial<User> = {
      id: uid,
      ...userInfo,
      provider: [provider],
      deleted: false,
      role: Role.User,
      joinedAt: new Date(),
      createdAt: new Date(),
      modifiedAt: new Date(),
    };

    //TODO 확인 필요
    return this.firestore.collection(`users`).doc(uid).set(user);
  }

  private checkHasUser(firebaseUser: firebase.User): Observable<boolean> {
    return from(this.firestore.doc(`users/${firebaseUser.uid}`).get({ source: 'server' })).pipe(
      map((documentSnapshot) => documentSnapshot.exists)
    );
  }

  updatePassword(newPassword: string): HotObservableOnce<void> {
    return from(this.auth.currentUser.updatePassword(newPassword));
  }

  withdraw(): HotObservableOnce<void> {
    return from(this.auth.currentUser.delete());
  }

  private async convertFirebaseUserToSession(firebaseUser: firebase.User): Promise<AuthSession> {
    if (firebaseUser) {
      const idTokenResult = await firebaseUser.getIdTokenResult();

      let lastLoggedInAt: Date;

      if (firebaseUser.metadata.lastSignInTime) {
        lastLoggedInAt = new Date(firebaseUser.metadata.lastSignInTime);
      }

      return {
        expireAt: new Date(idTokenResult.expirationTime),
        email: firebaseUser.email,
        name: firebaseUser.displayName,
        profilePicture: firebaseUser.photoURL,
        lastLoggedInAt,
        authToken: idTokenResult.token,
        refreshToken: firebaseUser.refreshToken,
        userId: firebaseUser.uid,
      };
    } else {
      return null;
    }
  }

  get currentUser(): firebase.User | null {
    return this.auth.currentUser;
  }
}
