import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'wnd-loading-dialog',
  templateUrl: './payment-loading-dialog.component.html',
  styleUrls: ['./payment-loading-dialog.component.scss']
})
export class PaymentLoadingDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PaymentLoadingDialogComponent>
  ) {
  }

  ngOnInit() {
  }

}
