export enum AppType {
  Web = 'web',
  Admin = 'admin',
  Backend = 'backend',
}

export enum Configuration {
  Local = 'local',
  Dev = 'dev',
  Stage = 'stage',
  Prod = 'prod',
}
export interface Cid {
  kakaopay: string;
  nice: string;
  paypal: string;
}
export interface Environment {
  production: boolean;
  cordova?: boolean;
  appType: AppType;
  configuration: Configuration;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId?: string;
    appId: string;
    measurementId?: string;
  };
  algolia?: {
    appId: string;
    searchKey: string;
  };
  mysql?: {
    host: string;
    user: string;
    password: string;
    database: string;
  };
  naver?: {
    clientId: string;
  };
  facebook?: {
    appId: string;
  };
  baseUrl?: string;
  weenidyUrl?: string;
  imageBucket?: string;
  cdnUrl?: string;
  iamport?: {
    id: string;
    cid: Cid;
  };
  publicData?: {
    neighborhoodForecastKey?: string;
  };
  bigquery?: {
    user: string;
  };
}
