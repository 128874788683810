<div class="wnd-preview-image-box">
  <div class="wnd-image-box">
    <div
      class="sub-box"
      [ngClass]="'ratio' + ratio"
      [ngStyle]="{ 'padding-top': ratio ? 'auto' : src.paddingTop }"
    >
      <picture>
        <source
          *ngIf="src?.multipleWebp"
          media="(min-width: 501px)"
          [srcset]="src?.multipleWebp"
          type="image/webp"
        />

        <source
          *ngIf="src?.originWebp"
          media="(max-width: 500px)"
          [srcset]="src?.originWebp"
          type="image/webp"
        />

        <source
          *ngIf="src?.multiple"
          media="(min-width: 501px)"
          [srcset]="src?.multiple"
          type="image/jpeg"
        />

        <source media="(max-width: 500px)" [srcset]="src?.origin" type="image/jpeg" />

        <img
          class="origin-image"
          [class.empty-ratio]="src?.paddingTop === ''"
          [srcset]="src?.origin + ' 500w'"
          sizes="(max-width: 500px) 500px"
          [src]="src?.multiple ? src?.multiple : src?.origin"
          [alt]="alt"
        />
      </picture>
    </div>
  </div>
</div>
