import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogTestPlaygroundComponent } from './components/dialog-test-playground/dialog-test-playground.component';
import { FeedBackDialogComponent } from './components/feed-back-dialog/feed-back-dialog.component';
import { FileUploadingComponent } from './components/file-uploading-dialog/file-uploading.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { CommonDialogModule } from '../../../../../../src/components/dialog/dialog.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BadgeDialogComponent } from './components/badge-dialog/badge-dialog.component';
import { ImagesModule } from '../images/images.module';
import { BadgeListDialogComponent } from './components/badge-list-dialog/badge-list-dialog.component';
import { WndPipeModule } from '../pipe/wnd.pipe.module';
import { BadgeModule } from '../../../../../web/src/app/modules/badge/badge.module';
import { CouponDetailDialogComponent } from './components/coupon-detail-dialog/coupon-detail-dialog.component';
import { NbCardModule, NbListModule, NbUserModule } from '@nebular/theme';
import { ConvertLimitationsPipe } from './pipe/convert-limitations.pipe';
import { ConvertTitlePipe } from './pipe/convert-title.pipe';

@NgModule({
  declarations: [
    DialogComponent,
    InfoDialogComponent,
    DialogTestPlaygroundComponent,
    FileUploadingComponent,
    FeedBackDialogComponent,
    BadgeDialogComponent,
    BadgeListDialogComponent,
    CouponDetailDialogComponent,
    ConvertLimitationsPipe,
    ConvertTitlePipe,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    CommonDialogModule,
    MatProgressBarModule,
    ImagesModule,
    WndPipeModule,
    BadgeModule,
    NbCardModule,
    NbListModule,
  ],
  exports: [
    DialogComponent,
    InfoDialogComponent,
    FileUploadingComponent,
    MatDialogModule,
    FeedBackDialogComponent,
  ],
})
export class LibDialogModule {}
