import { Component, OnInit, Input } from '@angular/core';
import { WndRatio } from '../../types';


@Component({
  selector: 'wnd-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {
  @Input() src: string | Blob;
  @Input() ratio: WndRatio;
  @Input() isGif: boolean;

  @Input() useHiDip: boolean;
  @Input() useErrorHandle = true;

  constructor() {
    this.ratio = '4-3';
  }

  ngOnInit() {
  }

}
