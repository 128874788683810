import { Injectable } from '@angular/core';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  take,
  switchMap,
  map,
  tap,
  distinctUntilChanged,
  finalize,
} from 'rxjs/operators';
import { User } from 'src/entities/user/types';
import { NgUserService } from '../../../../../weenidy/src/lib/modules/user/services/user.service';
import { ColdObservableOnce } from '../../../../../../src/core/types';
import { DbListResponse } from '../../../../../../src/core/db/types';

export function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

@Injectable({
  providedIn: 'root',
})
export class CustomEmailValidatorService {
  constructor(private userService: NgUserService) {}

  existingEmailValidator(initialEmail: string = '', target: string): AsyncValidatorFn {
    let targetElement;

    return (
      control: AbstractControl
    ): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialEmail) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          tap(() => (targetElement = this.setControlLoading(control, target))),
          tap(() => targetElement.classList.add('loading')),
          debounceTime(500),
          distinctUntilChanged(),
          take(1),
          map(() => this.emailToLowerCase(control.value)),
          switchMap((email: string) => this.getListFilterByEmail(email)),
          map((result: DbListResponse<User>) => {
            if (result.count === 0) {
              return null;
            }

            const user = result.docs[0];

            if (user.deleted) {
              return { userDeleted: true };
            }
            return user.provider.some((provide) => provide === 'google')
              ? { google: true }
              : { used: true };
          }),
          finalize(() => targetElement.classList.remove('loading'))
        );
      }
    };
  }

  unExistingEmailValidator(initialEmail: string = '', target: string): AsyncValidatorFn {
    let targetElement;

    return (
      control: AbstractControl
    ): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialEmail) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          tap(() => (targetElement = this.setControlLoading(control, target))),
          tap(() => targetElement.classList.add('loading')),
          debounceTime(500),
          take(1),
          map(() => this.emailToLowerCase(control.value)),
          switchMap((email: string) => this.getListFilterByEmail(email)),
          map((result: DbListResponse<User>) => {
            if (result.docs.length < 1) {
              return { empty: true };
            }

            const user = result.docs[0] as User;

            if (user.deleted) {
              return { userDeleted: true };
            }

            return user.provider.some((provide) => provide === 'google') ? { google: true } : null;
          }),
          finalize(() => targetElement.classList.remove('loading'))
        );
      }
    };
  }

  private setControlLoading(control: AbstractControl, target: string): HTMLElement {
    const targetElement: HTMLElement = document.querySelector(`#${target}`);
    targetElement.classList.remove('loading');
    control.setErrors({ loading: true });
    return targetElement;
  }

  private emailToLowerCase(email: string) {
    return email.toLowerCase();
  }

  private getListFilterByEmail(email: string): ColdObservableOnce<DbListResponse<User>> {
    return this.userService.list({
      filters: [{ field: 'email', comparison: '==', value: email }],
    });
  }
}
