import { Component, ElementRef, forwardRef, Input, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseValueAccessor } from '../../core/base-value-accessor';

@Component({
  selector: 'wnd-multi-check-button',
  templateUrl: './multi-check-button.component.html',
  styleUrls: ['./multi-check-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckButtonComponent),
      multi: true,
    },
  ],
})
export class MultiCheckButtonComponent extends BaseValueAccessor {
  @ViewChildren('checkInput') checkInputRefs: QueryList<ElementRef>;
  @Input()
  get radioItems(): WndRadioItem[] {
    return this._radioItems;
  }

  set radioItems(wndRadioItems: WndRadioItem[]) {
    if (!wndRadioItems) {
      return;
    }

    this.formGroup = this.createFormGroup(wndRadioItems);

    this._radioItems = wndRadioItems;

    this.formGroup.valueChanges.subscribe((values) => {
      console.log(
        Object.keys(values).reduce((prev: any[], formControlKey: string) => {
          if (Boolean(values[formControlKey])) {
            prev.push(formControlKey.split('_')[1]);
          }

          return prev;
        }, [])
      );
      this.value = Object.keys(values).reduce((prev: any[], formControlKey: string) => {
        if (Boolean(values[formControlKey])) {
          prev.push(formControlKey.split('_')[1]);
        }

        return prev;
      }, []);
    });
  }

  override set value(data: any) {
    super.value = data;

    this.formGroup.reset({}, { emitEvent: false });

    if (data) {
      data.forEach((value) => {
        this.formGroup.patchValue(
          {
            [`${this.id}_${value}`]: true,
          },
          { emitEvent: false }
        );
      });
    }
  }

  formGroup: FormGroup;

  private _radioItems: WndRadioItem[];

  constructor(private fb: FormBuilder) {
    super();
  }

  private createFormGroup(wndRadioItems: WndRadioItem[]): FormGroup {
    const fg = this.fb.group({});

    wndRadioItems.forEach((item) => {
      fg.addControl(`${this.id}_${item.value}`, this.fb.control(null));
    });
    return fg;
  }
}

export interface WndRadioItem {
  value: string | number;
  text: string;
}
