import { DbAdapter } from '../../core/db/db.adapter';
import { ColdObservable, ColdObservableOnce, HotObservableOnce } from '../../core/types';
import { Badge } from './types';
import { DbListResponse, DbOptions, DbQuery } from '../../core/db/types';

export class BadgeService {
  constructor(protected badgeDb: DbAdapter<Badge>) {}

  add(doc: Partial<Badge>, options?: DbOptions): HotObservableOnce<Badge> {
    return this.badgeDb.add(doc, options);
  }

  upsert(id: string, doc: Partial<Badge>, options?: DbOptions): HotObservableOnce<Badge> {
    return this.badgeDb.upsert(id, doc, options);
  }

  get(id: string, options?: DbOptions): ColdObservableOnce<Badge> {
    return this.badgeDb.get(id, options);
  }

  delete(id: string, options?: DbOptions): HotObservableOnce<void> {
    return this.badgeDb.delete(id, options);
  }

  update(id: string, doc: Partial<Badge>, options?: DbOptions): HotObservableOnce<void> {
    return this.badgeDb.update(id, doc, options);
  }

  listChange(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<Badge>> {
    return this.badgeDb.listChange(query, options);
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<Badge>> {
    return this.badgeDb.list(query, options);
  }
}
