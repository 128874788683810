<ng-container *ngIf="profileImage; else noProfile">
  <wnd-preview-image-card
    class="profile-image"
    ratio="1-1"
    [src]="profileImage"
  ></wnd-preview-image-card>
</ng-container>

<ng-template #noProfile>
  <wnd-image-card class="profile-image" [src]="emptySrc" ratio="1-1"></wnd-image-card>
</ng-template>
