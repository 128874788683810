import { Inject, Injectable } from '@angular/core';
import { Resource } from '../../../../../../../src/entities/resource/types';
import { BigquerySearchAdapter } from '../../../../../../../src/modules/google/bigquery-search.adapter';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgApiService } from '../../../core/services/api.service';
import { NgResourceDb } from './resource.db';
import { MysqlAdapter } from '../../../../../../../src/modules/mysql/mysql.adapter';

@Injectable({
  providedIn: 'root',
})
export class NgResourceSearch extends MysqlAdapter<Resource> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override apiService: NgApiService,
    protected resourceDb: NgResourceDb
  ) {
    super(
      `resource`,
      apiService,
      resourceDb
    );
  }
}
