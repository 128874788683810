import { Pipe, PipeTransform } from '@angular/core';
import { BadgeCategory, BadgeNameByCategory } from '../../../../../../../src/entities/badge/types';

@Pipe({
  name: 'convertBadgeName',
})
export class ConvertBadgeNamePipe implements PipeTransform {
  badgeNameByCategory = BadgeNameByCategory;
  transform(value: BadgeCategory, count: number): string {
    if (!value) {
      return '';
    }

    return this.badgeNameByCategory[value](count);
  }
}
