import { Injectable } from '@angular/core';
import { Payment } from '../../../../../../../src/entities/payment/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NgPaymentDb extends FirestoreDbAdapter<Payment> {
  constructor() {
    super(firebase.firestore(), 'payments');
  }
}
