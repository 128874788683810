import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'wnd-loading-dialog',
  templateUrl: './file-uploading.component.html',
  styleUrls: ['./file-uploading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadingComponent implements OnInit {
  type = 'file';
  percent = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FileLoadingData) {
    if (data?.type) {
      this.type = data.type;
    }

    console.log('this.type', this.type);
  }

  ngOnInit(): void {}
}

export interface FileLoadingData {
  type: 'file' | 'resource';
}
