import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { CouponHistory } from 'src/entities/coupon-history/types';

import { FirestoreDbAdapter } from 'src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root',
})
export class NgCouponHistoryDb extends FirestoreDbAdapter<CouponHistory> {
  constructor() {
    super(firebase.firestore(), 'coupon-histories');
  }
}
