<div class="payment-dialog-header-box">
  <a mat-dialog-close>
    <img wndSrc="/assets/icons/icn-cancle.png" alt="close icon" />
  </a>

  <img class="logo-image" src="assets/images/logo/logo.png" alt="위니디 로고" />

  <i class="wnd-icn empty"></i>
</div>

<section class="payment-dialog-section">
  <div class="payment-item-info-box">
    <wnd-payment-resource-card
      [resource]="resource"
    ></wnd-payment-resource-card>
  </div>

  <wnd-payment-window
    [resources]="resource"
    (onPaid)="close()"
    (onFail)="close()"
  ></wnd-payment-window>
</section>
