import { Pipe } from '@angular/core';
import { isArray } from '@datorama/akita';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { switchMap, filter, distinctUntilChanged, tap, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NgContentService } from '../../../../../weenidy/src/lib/modules/content/services/content.service';
import { Content } from '../../../../../../src/entities/user/types';
import { NgAuthService } from '../../../../../weenidy/src/lib/modules/auth/auth.service';

@Pipe({ name: 'cartCheckContent' })
export class CartCheckContentPipe {
  contentIds: string[];
  contentIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private authService: NgAuthService, private contentService: NgContentService) {
    this.initContent();
  }

  transform(resourceIds: string | string[]): Observable<boolean> {
    return this.contentIds$.pipe(
      map((contentIds: string[]) =>
        contentIds.some((contentId) => {
          if (isArray(resourceIds)) {
            return resourceIds.some((resourceId) => contentId === resourceId);
          } else {
            return contentId === resourceIds;
          }
        })
      )
    );
  }

  hasContent(resourceId: string): boolean {
    return this.contentIds.some((contentId) => contentId === resourceId);
  }

  private initContent(): Subscription {
    return this.authService.user$
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          this.contentIds = [];
          this.contentIds$.next([]);
        }),
        filter((user) => Boolean(user)),
        switchMap((user) => this.contentService.listAllChange(user.id)),
        map((contents: Content<any>[]) => contents.map((content) => content.info.id))
      )
      .subscribe((contentIds: string[]) => {
        this.contentIds = contentIds;
        this.contentIds$.next(contentIds);
      });
  }
}
