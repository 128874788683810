import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import { NgAuthService } from '../../../../../../../weenidy/src/lib/modules/auth/auth.service';
import { BaseSubscriptionComponent } from '../../../../core/components/base-subscription.component';
import { NgFunctionsCaller } from '../../../../../../../weenidy/src/lib/modules/functions-caller/services/functions-caller.service';
import { PhoneNumberValidatorService } from '../../../../core/service/phone-number-validator.service';
import { PhoneNumberValidator } from '../../../../../../../weenidy/src/lib/shared/validator/validators';
import {
  DialogComponent,
  WndDialogData,
} from '../../../../../../../weenidy/src/lib/modules/dialog/components/dialog/dialog.component';
import { inputOnlyNumber } from '../../../../../../../weenidy/src/lib/shared/utils';

@Component({
  selector: 'wnd-phone-verify-form',
  templateUrl: './phone-verify-form.component.html',
  styleUrls: ['./phone-verify-form.component.scss'],
})
export class PhoneVerifyFormComponent extends BaseSubscriptionComponent implements OnInit {
  @Input()
  get step(): PhoneVerifyStep {
    return this._step;
  }

  set step(step: PhoneVerifyStep) {
    this._step = step;

    if (step === PhoneVerifyStep.SendMessage) {
      this.sendVerificationSms();
      this.setPhoneVerifyValidators();
      this.setCurrentPhoneNumber();
    }
  }

  @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() verifyComplete: EventEmitter<string> = new EventEmitter<string>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  formGroup: FormGroup = this.createForm();
  phoneVerifyStep = PhoneVerifyStep;
  phoneVerifyStartMinutes = 1000 * 60 * 3;
  currentPhoneNumber: string;

  private _step: PhoneVerifyStep = PhoneVerifyStep.Waiting;

  // TODO: test
  private phoneVerifyInterval: any;

  constructor(
    private authService: NgAuthService,
    private fb: FormBuilder,
    private fc: NgFunctionsCaller,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private phoneNumberValidatorService: PhoneNumberValidatorService
  ) {
    super();
  }

  get phoneNumberControl() {
    return this.formGroup.get('phoneNumber');
  }

  get phoneVerifyControl() {
    return this.formGroup.get('phoneVerify');
  }

  ngOnInit(): void {
    this.initFormGroupValidChange();
  }

  keyDownOnlyNumber(event): void {
    inputOnlyNumber(event);
  }

  sendVerificationSms(): void {
    if (this.phoneNumberControl.invalid) {
      this.openDialog('휴대폰 번호가 정확하지 않습니다.', '휴대폰 번호를 확인해주세요.');
      return;
    }

    const phoneNumber = this.phoneNumberControl.value;

    this.fc
      .sendVerificationSms(this.authService.id, phoneNumber)
      .pipe(map((response) => (response ? JSON.parse(response?.data) : undefined)))
      .subscribe((result) => {
        if (result?.status === '102') {
          this.resetPhoneVerifyHandler();
          this.openDialog('유효하지 않은 휴대폰 번호 입니다.', '휴대폰 번호를 다시 확인해 주세요.');
        } else {
          this.clearInterval();

          setTimeout(() =>
            this.ngZone.run(() => {
              this.startPhoneVerifyInterval();
            })
          );
        }
      });
  }

  verifyPhoneNumber(): void {
    this.loading.emit(true);

    this.fc
      .verifyPhone(
        this.authService.id,
        this.phoneVerifyControl.value,
        this.phoneNumberControl.value
      )
      .subscribe(
        () => {
          this.loading.emit(false);
          this.successHandler(this.phoneNumberControl.value);
        },
        (error) => {
          this.loading.emit(false);
          this.errorHandler(error.code);
        }
      );
  }

  resetForm() {
    this.clearInterval();
    this.formGroup.reset({
      phoneNumber: '',
      phoneVerify: '',
    });
  }

  private successHandler(phoneNumber: string): void {
    this.clearInterval();
    this.step = PhoneVerifyStep.Waiting;

    this.ngZone.run(() => {
      this.openDialog(
        '휴대폰 번호가 저장되었습니다.',
        '휴대폰 번호는 마이페이지에서 수정하실 수 있습니다.',
        'check'
      )
        .afterClosed()
        .subscribe(() => {
          this.verifyComplete.emit(phoneNumber);
        });
    });
  }

  private errorHandler(code: string) {
    const errorTexts = {
      ['invalid-argument']: {
        title: '인증번호가 틀렸습니다.',
        contents: '다시 입력해주세요.',
      },
      ['deadline-exceeded']: {
        title: '유효시간이 초과되었습니다.',
        contents: '인증번호 재발급 진행하여 주시기 바랍니다.',
      },
    };

    const errorText = errorTexts[code];
    this.ngZone.run(() => {
      this.openDialog(errorText.title, errorText.contents);
    });
    this.step = PhoneVerifyStep.Error;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      phoneNumber: [
        '',
        [PhoneNumberValidator.pattern, PhoneNumberValidator.range],
        [this.phoneNumberValidatorService.overlap()],
      ],
      phoneVerify: [''],
    });
  }

  private initFormGroupValidChange(): Subscription {
    return this.formGroup.statusChanges
      .pipe(
        distinctUntilChanged(),
        map((status) => status === 'VALID'),
        takeUntil(this.destroy$)
      )
      .subscribe((valid: boolean) => {
        this.validChange.emit(valid);
      });
  }

  private setPhoneVerifyValidators(): void {
    this.phoneVerifyControl.setValidators([Validators.required]);
    this.phoneVerifyControl.updateValueAndValidity();
  }

  private setCurrentPhoneNumber(): void {
    this.currentPhoneNumber = this.phoneNumberControl.value;
  }

  // TODO: test
  private startPhoneVerifyInterval(): void {
    this.phoneVerifyInterval = setInterval(() => {
      if (this.phoneVerifyStartMinutes === 0) {
        this.clearInterval();
        this.openDialog('유효시간이 초과되었습니다.', '인증번호 재발급 진행하여 주시기 바랍니다.');
        return;
      }

      this.phoneVerifyStartMinutes -= 1000;
    }, 1000);
  }

  // TODO: test
  private clearInterval() {
    if (this.phoneVerifyInterval) {
      clearInterval(this.phoneVerifyInterval);
      this.phoneVerifyStartMinutes = 1000 * 60 * 3;
    }
  }

  private resetPhoneVerifyValidators(): void {
    this.phoneVerifyControl.clearValidators();
    this.phoneVerifyControl.updateValueAndValidity();
  }

  private resetPhoneVerifyHandler(): void {
    this.step = this.phoneVerifyStep.Waiting;
    this.resetPhoneVerifyValidators();
  }

  private openDialog(
    title: string,
    contents: string,
    type: 'check' | 'waring' = 'waring',
    isHideSecondaryButtonText: boolean = true
  ): MatDialogRef<any> {
    return this.dialog.open<DialogComponent, WndDialogData>(DialogComponent, {
      disableClose: true,
      data: {
        title,
        contents,
        type,
        isHideSecondaryButtonText,
      },
    });
  }
}

export enum PhoneVerifyStep {
  Waiting = 10,
  SendMessage = 20,
  RequestVerification = 30,
  Verified = 40,
  Error = 90,
}
