import {
  ChartDateFormat,
  ChartDateType,
} from '../../modules/charts/components/proceed-chart/proceed-chart.component';
import moment from 'moment';
import { Writer } from '../types/common.model';
import { User } from 'src/entities/user/types';
import { BasicUserInfo } from 'src/entities/withdrawal/types';

declare const lottie: any;

export function documentAddClass(targetClassName: string, className: string) {
  window.document.querySelector(targetClassName).classList.add(className);
}

export function documentRemoveClass(targetClassName: string, className: string) {
  window.document.querySelector(targetClassName).classList.remove(className);
}

export function getStorageTempDir() {
  const now = new Date();
  return `tmp/${now.getFullYear()}/${now.getMonth() + 1}`;
}

export function playLottie(element: HTMLElement, path: string) {
  lottie.loadAnimation({
    container: element,
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path,
  });
}

export function openNotion(
  type:
    | 'notice'
    | 'term'
    | 'person'
    | 'license'
    | 'embed'
    | 'writer'
    | 'introduce'
    | 'resource'
    | 'recruit'
) {
  let url = '';

  if (type === 'license') {
    url = 'https://dent-price-d42.notion.site/b01aab56df0e49958571154621806811';
  } else if (type === 'term') {
    url = 'https://dent-price-d42.notion.site/6902ccdd334a4458ad9f3c21a3bfe2c0';
  } else if (type === 'person') {
    url = 'https://dent-price-d42.notion.site/0acc80e8c20b4621868e311b8e7aa5d9';
  } else if (type === 'notice') {
    url =
      'https://dent-price-d42.notion.site/9691c93f4aea4460b6fec4a77656b1d0?v=fbec53c14007416d80d4a4ab30c2f61f';
  } else if (type === 'embed') {
    url = 'https://www.notion.so/diffy/f4db42e2686a4a1e8bd9f95774c218f0';
  } else if (type === 'writer') {
    url = 'https://dent-price-d42.notion.site/31ca0ec8df9f4a20a6b2f502a93a3d92';
  } else if (type === 'introduce') {
    url = 'https://dent-price-d42.notion.site/f78162bdfc024500bd51b39daaa02447';
  } else if (type === 'recruit') {
    url = 'https://www.notion.so/diffy/ab9404788e5944c68e17de9d89a5b933';
  } else if (type === 'resource') {
    url = 'https://dent-price-d42.notion.site/04e699acbcdb4e6c850bd8477fb75db6';
  }

  window.open(url, '_blank');
}

export function stopEvent(e: Event) {
  e.stopPropagation();
  e.preventDefault();
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export function convertToWriter(user: User): Writer {
  return {
    id: user.id,
    email: user.email,
    name: user.name || '',
    author: user.author || '',
    profileImage: user.profileImage || null,
  } as Writer;
}

export function convertUserToBasicUserInfo(user: User): BasicUserInfo {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    profileImage: user.profileImage || null,
    phoneNumber: user.phoneNumber || null,
  } as BasicUserInfo;
}

export function getDateArray(type: ChartDateType, format: ChartDateFormat) {
  const dateArray = [];

  if (type === 'month') {
    for (let i = 0; i < 12; i++) {
      const date = moment().month(i).format(format);
      dateArray.push(date);
    }
  } else if (type === 'recent') {
    const currDate = parseInt(moment().format('DD'), 10);
    for (let i = currDate - 6; i <= currDate; i++) {
      const date = moment().date(i).format(format);
      dateArray.push(date);
    }
  } else if (type === 'current-month') {
    const lastDate = parseInt(moment().endOf('month').format('DD'), 10);
    for (let i = 1; i <= lastDate; i++) {
      const date = moment().date(i).format(format);
      dateArray.push(date);
    }
  }

  return dateArray;
}

type MomentFormat =
  | 'YYYY'
  | 'YYYY-MM'
  | 'YYYY-MM-DD'
  | 'MM'
  | 'DD'
  | 'YYYY-MM-DD HH:mm:ss'
  | 'M월 D일';

export function convertDateToMomentFormat(format: MomentFormat, date: Date) {
  const now: Date = date;
  return moment(now).format(format);
}

export function getLastDayToDate(date: Date): Date {
  return moment(date).endOf('month').toDate();
}

export function getStartDayToDate(date: Date): Date {
  return moment(date).startOf('month').toDate();
}
