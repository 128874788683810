import { OverlayModule, FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '../modules/buttons/buttons.module';
import { ImagesModule } from '../../../../weenidy/src/lib/modules/images/images.module';
import { MenuModule } from '../modules/menu/menu.module';
import { PaymentModule } from '../modules/payment/payment.module';
import { SearchModule } from '../modules/search/search.module';
import { TextsModule } from '../../../../weenidy/src/lib/modules/texts/texts.module';
import { UserModule } from '../modules/user/user.module';
import { LibFormModule } from '../../../../weenidy/src/lib/shared/form/lib-form.module';
import { LayoutPageComponent } from './components/layout-page/layout-page.component';
import { MobileToolbarBackComponent } from './components/mobile-toolbar-back/mobile-toolbar-back.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MobileToolbarSearchComponent } from './components/mobile-toolbar-search/mobile-toolbar-search.component';
import { ToolbarModule } from '../modules/toolbar/toolbar.module';

@NgModule({
  declarations: [
    LayoutPageComponent,
    ToolbarComponent,
    FooterComponent,
    MobileToolbarSearchComponent,
    MobileToolbarBackComponent,
  ],
  exports: [MobileToolbarSearchComponent],
  imports: [
    CommonModule,
    RouterModule,
    LibFormModule,
    MenuModule,
    ReactiveFormsModule,
    TextsModule,
    ButtonsModule,
    ImagesModule,
    MatMenuModule,
    UserModule,
    SearchModule,
    SearchModule,
    OverlayModule,
    PortalModule,
    PaymentModule,
    ToolbarModule,
    ImagesModule
  ],
  providers: [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}],
})
export class LayoutModule {}
