import { query } from '@angular/animations';
import { HotObservableOnce } from './../../core/types';
import { SearchAdapter } from 'src/core/search/search.adapter';
import { TimeLine } from './types';
import { DbAdapter } from 'src/core/db/db.adapter';
import { ColdObservableOnce } from 'src/core/types';
import { DbListResponse, DbQuery, DbSortDirection } from 'src/core/db/types';
import { SearchQuery, SearchResponse } from 'src/core/search/types';

export class TimeLineService {
  constructor(
    private projectId: string,
    protected timeLineSearch: SearchAdapter<TimeLine>,
    protected timeLineDb: DbAdapter<TimeLine>
  ) {}

  get(id: string): ColdObservableOnce<TimeLine> {
    return this.timeLineDb.get(id);
  }

  getMany(ids: string[]): ColdObservableOnce<TimeLine[]> {
    return this.timeLineDb.getMany(ids);
  }

  list(query: DbQuery): ColdObservableOnce<DbListResponse<TimeLine>> {
    return this.timeLineDb.list(query, {});
  }

  search(query: SearchQuery): ColdObservableOnce<SearchResponse<TimeLine>> {
    return this.timeLineSearch.search(query);
  }

  add(timeLine: Partial<TimeLine>): ColdObservableOnce<TimeLine> {
    return this.timeLineDb.add(timeLine);
  }

  update(id: string, timeLine: Partial<TimeLine>): HotObservableOnce<void> {
    return this.timeLineDb.update(id, timeLine);
  }
}
