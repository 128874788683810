import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseValueAccessor } from '../../core/base-value-accessor';


@Component({
  selector: 'wnd-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    }
  ]
})
export class RadioButtonComponent extends BaseValueAccessor {
  @Input() radioItems: WndRadioItem[];

  constructor() {
    super();
  }

  onClickRadio(value: any) {
    this.value = value;
  }
}

export interface WndRadioItem {
  value: string | number;
  text: string;
}
