<div class="wnd-select-box">
  <mat-select
    [placeholder]="placeholder || '선택'"
    [panelClass]="panelClass"
    [disableOptionCentering]="true"
    [formControl]="formCtrl"
    [multiple]="isMultiple"
  >
    <mat-option *ngFor="let option of options" [value]="option.value">
      <ng-container *ngIf="option.type === 'optionGroup'">{{ option.text }}</ng-container>
      <ng-container *ngIf="option.type !== 'optionGroup'">
        <span class="option-group">{{ option.text }}</span>
      </ng-container>
    </mat-option>
  </mat-select>
</div>
