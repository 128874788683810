<div class="payment-dialog-header-box">
  <a (click)="close()">
    <img wndSrc="/assets/icons/icn-cancle.png" alt="close icon" />
  </a>

  <img class="logo-image" src="assets/images/logo/logo.png" alt="위니디 로고" />

  <i class="wnd-icn empty"></i>
</div>

<div class="payment-cart-section" [ngClass]="pg">
  <div class="cart-list-box">
    <div class="cart-inner-list-box">
      <p class="cart-title">장바구니 목록</p>
      <ul class="cart-list">
        <li class="cart-item" *ngFor="let resource of resources; let idx = index">
          <wnd-payment-resource-card
            [resource]="resource"
            [routerLink]="['/resource/detail', resource.id]"
            (click)="close()"
          ></wnd-payment-resource-card>

          <a class="remove-anchor" (click)="delete(resource.id)">
            <img src="/assets/icons/btn-minus_circle-n.png" alt="remove icon" />
          </a>

          <div
            *ngIf="
              resource.status !== status.Normal || (resource.id | cartCheckContent | async) === true
            "
            class="block-box"
          >
            <p>구매불가</p>
          </div>
        </li>

        <li *ngIf="!resources || resources.length === 0" class="no-cart-item">
          <div class="no-item-box">
            <img
              class="no-item-icon"
              wndSrc="/assets/images/icons/icn-%20no%20cart.png"
              useHiDpi="true"
              alt="remove icon"
            />

            <p class="no-item-info">장바구니에 담긴 상품이 없습니다.</p>

            <p class="no-item-info-sub">Resource 상세페이지에서 상품을 담으실 수 있습니다.</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="shadow-gradient"></div>
  </div>

  <div class="payment-box">
    <wnd-payment-window
      [resources]="resources"
      (onPaid)="onPaidHandler()"
      (onFail)="onFailHandler()"
      (onChangePg)="changePg($event)"
    ></wnd-payment-window>
  </div>
</div>
