import FileSaver from 'file-saver';

export function downloadFile(url: string, fileName: string): void {
  const regex = /\.zip$/;

  if (regex.test(fileName)) {
    const aElement = document.createElement('a');
    aElement.href = url;
    aElement.download = fileName;
    aElement.style.display = 'none';
    document.body.appendChild(aElement);
    aElement.click();
    URL.revokeObjectURL(url);
    aElement.remove();
  } else {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        console.log('blob', blob);
        FileSaver.saveAs(blob, fileName);
      });
  }
}
