import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControlBaseComponent } from '../../../../modules/form';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Option } from '../../../../../../../../src/core/types';
import { FormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'wnd-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutoCompleteInputComponent extends FormControlBaseComponent implements OnInit {
  @Input() options: Option[];
  @Input() selectedFormArray: FormArray;

  selectedControls: string[];

  filterOptions$: Observable<Option[]>;

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.selectedControls = this.selectedFormArray.value;
    this.selectedFormArray.valueChanges.subscribe((updated) => {
      this.selectedControls = updated;
    });

    this.filterOptions$ = this.formCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  checkValue(): void {
    const hasValue = this.options.some((option) => option.value === this.formCtrl.value);
    if (!hasValue) {
      this.formCtrl.setValue(null);
    }
  }

  private _filter(value: string): Option[] {
    const filterValue = this._normalizeValue(value);
    return this.options.filter((option: Option) =>
      this._normalizeValue(option.value).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
