import { Pipe, PipeTransform } from '@angular/core';
import { LicenseType, LicenseTypeText } from '../../../../../../../src/entities/cart/types';
import { ResourceCategory } from '../../../../../../../src/entities/resource/types';

@Pipe({
  name: 'licenseTypeToText',
})
export class LicenseTypeToTextPipe implements PipeTransform {
  transform(type: LicenseType, category: ResourceCategory): string {
    return LicenseTypeText[category][type];
  }
}
