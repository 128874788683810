import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseValueAccessor } from '../../core/base-value-accessor';
import { Option } from '../../../../../../../../src/core/types';
import { FormControlBaseComponent } from '../../../../modules/form';

@Component({
  selector: 'wnd-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends FormControlBaseComponent {
  @Input() options: Option[];
  @Input() panelClass: string | string[] = 'wnd-select-panel';
  @Input() isMultiple: boolean = false;
  @Input() disabled: boolean;
  @Input() error: boolean;

  constructor() {
    super();
  }
}
