import { Injectable } from '@angular/core';
import { NgCouponHistoryDb } from './coupon-histories.db';
import { NgCouponHistorySearch } from './coupon-histories.search';
import { CouponHistoryService } from 'src/entities/coupon-history/coupon-history.service';
import { NgCouponDb } from '../coupon/coupon.db';

@Injectable({
  providedIn: 'root',
})
export class NgCouponHistoryService extends CouponHistoryService {
  constructor(
    protected override couponHistoryDb: NgCouponHistoryDb,
    protected override couponHistorySearch: NgCouponHistorySearch,
    protected override couponDb: NgCouponDb
  ) {
    super(couponHistoryDb, couponHistorySearch, couponDb);
  }
}
