<form [formGroup]="birthFormGroup" novalidate>
  <div class="wnd-date-box">
    <wnd-input
      type="number"
      class="year-input"
      id="year"
      placeholder="년(4자리)"
      formControlName="birthYear"
      [error]="dateErrorText"
      [useError]="false"
      (keydown)="keyDownOnlyNumber($event)"
    ></wnd-input>

    <wnd-input
      type="number"
      class="month-input"
      id="month"
      placeholder="월"
      formControlName="birthMonth"
      [error]="dateErrorText"
      [useError]="false"
      (keydown)="keyDownOnlyNumber($event)"
    ></wnd-input>

    <wnd-input
      type="number"
      class="date-input"
      id="date"
      placeholder="일"
      formControlName="birthDate"
      [error]="dateErrorText"
      [useError]="false"
      (keydown)="keyDownOnlyNumber($event)"
    ></wnd-input>
  </div>

  <wnd-error-text *ngIf="dateErrorText" [text]="dateErrorText"></wnd-error-text>
</form>
