<form class="search-form" [formGroup]="formGroup" (ngSubmit)="searchKeyword()">
  <wnd-select
    class="search-form-control search-select-form"
    placeholder="전체"
    formControlName="category"
    [panelClass]='panelClass'
    [options]="categoryOption"
  ></wnd-select>

  <div class="search-input-box">
    <wnd-resource-search-input
      [isSearch]="true"
      class="search-form-control"
      id="keyword"
      formControlName="keyword"
      placeholder="검색"
      (focusChange)="updateFocus($event)"
    ></wnd-resource-search-input>

    <div
      *ngIf="isFocus || isHover"
      @fadeIn
      @fadeOut
      class="search-menu-box"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
    >
      <wnd-search-menu-box
        [isFive]="false"
        (selectRecentKeyword)="selectRecentKeyword($event)"
      ></wnd-search-menu-box>
    </div>
  </div>
</form>
