<div class="wnd-gender-box">
  <button
    type="button"
    class="gender-button"
    [class.active]="gender === 'women'"
    (click)="genderChange('women')"
  >
    여성
  </button>

  <button
    type="button"
    class="gender-button"
    [class.active]="gender === 'man'"
    (click)="genderChange('man')"
  >
    남성
  </button>
</div>
