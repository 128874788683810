import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ResourceDeleteEventBusService {
  private eventSubject = new Subject<number>();

  addDeleteEvent(index: number) {
    this.eventSubject.next(index);
  }

  listenEvent() {
    return this.eventSubject.asObservable();
  }
}
