import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { number } from 'echarts';
import { NgResourceService } from 'projects/weenidy/src/lib/modules/resource/services/resource.service';

@Component({
  selector: 'wnd-clap-button',
  templateUrl: './clap-button.component.html',
  styleUrls: ['./clap-button.component.scss'],
})
export class ClapButtonComponent implements OnInit, OnDestroy {
  @Input() totalClap: number = 0;
  @Input() resourceId: string = '';

  @Output() clapEvent = new EventEmitter();

  maxClapCount = 100;
  clickedClapCount = 0;
  accCounter = 0;
  totalCount = 127;
  minDeg = 1;
  maxDeg = 72;
  particlesClasses = [
    {
      class: 'pop-top',
    },
    {
      class: 'pop-top-left',
    },
    {
      class: 'pop-top-right',
    },
    {
      class: 'pop-bottom-right',
    },
    {
      class: 'pop-bottom-left',
    },
  ];
  constructor(private ngResourceService: NgResourceService) {}

  ngOnInit() {
    const clap = document.getElementById('clap');
    clap.addEventListener('click', () => {
      const particles = document.getElementById('particles');
      const particles2 = document.getElementById('particles-2');
      const particles3 = document.getElementById('particles-3');
      clap.classList.add('clicked');

      if (!particles.classList.contains('animating')) {
        this.animateParticles(particles, 700);
      } else if (!particles2.classList.contains('animating')) {
        this.animateParticles(particles2, 700);
      } else if (!particles3.classList.contains('animating')) {
        this.animateParticles(particles3, 700);
      }
    });
  }

  ngOnDestroy(): void {
    this.clickedClapCount = 0;
  }

  addClapEvent() {
    return this.clapEvent.emit();
  }

  increaseClapCount(id: string) {
    if (this.clickedClapCount >= this.maxClapCount) {
      return alert('박수 보내기 최대치에 도달했습니다.');
    }

    this.clickedClapCount++;
    this.addClapEvent();

    if (!this.totalClap) {
      return this.ngResourceService.update(id, { clap: 1 });
    }
    this.ngResourceService.increaseClap(id);
  }

  runAnimationCycle(el: HTMLElement, className: string, duration: number) {
    if (el && !el.classList.contains(className)) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
      void el.offsetWidth;
      el.classList.add(className);
    }
  }

  runParticleAnimationCycle(el: HTMLElement, className: string, duration: number) {
    if (el && !el.classList.contains(className)) {
      el.classList.add(className);
      setTimeout(() => {
        el.classList.remove(className);
      }, duration);
    }
  }

  animateParticles(particles: HTMLElement, dur: number) {
    this.addRandomParticlesRotation(particles.id, this.minDeg, this.maxDeg);
    for (let i = 0; i < this.particlesClasses.length; i++) {
      const particle = particles.children[i] as HTMLElement;
      this.runParticleAnimationCycle(particle, this.particlesClasses[i].class, dur);
    }
    particles.classList.add('animating');
    setTimeout(() => {
      particles.classList.remove('animating');
    }, dur);
  }

  getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  addRandomParticlesRotation(particlesName: string, minDeg: number, maxDeg: number) {
    const particles = document.getElementById(particlesName) as HTMLElement;
    const randomRotationAngle = this.getRandomInt(minDeg, maxDeg) + 'deg';
    particles.style.transform = `rotate(${randomRotationAngle})`;
  }

  transferCounting(_count: number) {
    const count = Number(_count) || 0;
    const calcCountK = Math.trunc(count / 1000);
    if (calcCountK > 0) {
      return `${calcCountK}K`;
    }
    return count;
  }
}
