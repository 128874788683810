import { Injectable } from '@angular/core';
import { NgBadgeDb } from './badge.db';
import { BadgeService } from '../../../../../../../src/entities/badge/badge.service';
import { NgBadgeCountDb } from './badge-count.db';
import { Badge, BadgeCategory, BadgeCount } from '../../../../../../../src/entities/badge/types';
import { BehaviorSubject, merge, Observable, partition, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DbSortDirection } from '../../../../../../../src/core/db/types';
import { NgAuthService } from '../../auth/auth.service';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { Role } from 'src/entities/user/types';

@Injectable({
  providedIn: 'root',
})
export class NgBadgeService extends BadgeService {
  myBadgeList$ = new BehaviorSubject<Badge[]>([]);
  myBadgeCount$ = new BehaviorSubject<BadgeCount>(undefined);

  constructor(
    protected override badgeDb: NgBadgeDb,
    private badgeCountDb: NgBadgeCountDb,
    private authService: NgAuthService
  ) {
    super(badgeDb);
  }

  getMyBadgeCount(userId: string): ColdObservable<BadgeCount> {
    return this.badgeCountDb.getChange(userId, {
      parentIds: [userId],
    }) as ColdObservable<BadgeCount>;
  }

  initMyBadge(): Subscription {
    return this.authService.id$
      .pipe(
        filter((id) => Boolean(id)),
        switchMap((id) =>
          this.listChange(
            {
              limit: 100,
            },
            {
              parentIds: [id],
            }
          )
        )
      )
      .subscribe((res) => {
        this.myBadgeList$.next(res.docs || []);
      });
  }

  initMyBadgeCount(): Subscription {
    return this.authService.id$
      .pipe(
        filter((id) => Boolean(id)),
        switchMap((id) => this.getMyBadgeCount(id))
      )
      .subscribe((badgeCount: BadgeCount) => {
        this.myBadgeCount$.next(badgeCount);
      });
  }

  initUserBadge(userId: string): Observable<Badge[]> {
    return this.listChange(
      {
        sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
        limit: 100,
      },
      {
        parentIds: [userId],
      }
    ).pipe(map((res) => res.docs || []));
  }

  initUserBadgeCount(userId: string): Observable<BadgeCount> {
    return this.getMyBadgeCount(userId);
  }

  addNewWriterBadge(userId: string): void {
    this.list(
      {
        filters: [{ field: 'category', comparison: '==', value: BadgeCategory.Writer }],
      },
      { parentIds: [userId] }
    ).subscribe((res) => {
      if (res.count === 0)
        return this.add({ category: BadgeCategory.Writer, isNew: true }, { parentIds: [userId] });
    });
  }

  // 프로파일 추가시, 일반 회원이 아닌 경우에만 이벤트 발생 하도록 호출부에서 예외 처리 추가.
  addNewProfileBadge(userId: string): Observable<any> {
    const [noBadge$, hasBadge$] = partition(
      this.list(
        {
          filters: [{ field: 'category', comparison: '==', value: BadgeCategory.ProfilePicture }],
        },
        { parentIds: [userId] }
      ),
      (res) => {
        return res.count === 0;
      }
    );

    return merge(
      hasBadge$,
      noBadge$.pipe(
        switchMap(() =>
          this.add({ category: BadgeCategory.ProfilePicture, isNew: true }, { parentIds: [userId] })
        )
      )
    );
  }
}
