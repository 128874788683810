<div class="badge-dialog-box">
  <div class="badge-dialog-body">
    <a class="badge-dialog-close-link" mat-dialog-close>
      <img wndSrc="/assets/icons/icn-gnb-cancle-n.png" alt="cancel icon" useHiDpi="true" />
    </a>

    <article class="badge-dialog-article">
      [{{ user?.author || user?.name }}]님 축하합니다🎉<br />

      <strong>‘{{ badge?.category | convertBadgeName: badge?.count }}'</strong> 뱃지를
      획득하셨습니다!
    </article>

    <div class="badge-thumbnail-box">
      <div class="badge-thumbnail-inner-box">
        <div class="badge-image-card-wrapper">
          <wnd-badge-image-card [badge]="badge"></wnd-badge-image-card>
        </div>
      </div>
    </div>

    <p class="badge-title">{{ badge?.category | convertBadgeName: badge?.count }}</p>

    <article class="badge-describe">
      작가의 획득 뱃지 히스토리는 작가 프로필<br />
      하단에서 확인하실 수 있습니다.
    </article>
  </div>

  <div class="badge-dialog-footer">
    <button type="button" class="badge-button dark-button" (click)="close(true)">
      뱃지 모아보기
    </button>

    <button type="button" class="badge-button" (click)="close(false)">확인</button>
  </div>
</div>
