import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { PaymentCartComponent } from '../../payment/components/payment-cart/payment-cart.component';
import { LibOverlayService } from '../../../../../../../src/modules/overlay/overlay.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ScrollStrategy } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class NgCartOverlayService extends LibOverlayService<PaymentCartComponent> {
  override positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  override scrollStrategy: ScrollStrategy = this.overlay.scrollStrategies.block();
  override hasBackdrop = true;
  override backdropClass: string = 'cart-backdrop';

  constructor(protected override overlay: Overlay) {
    super(overlay, PaymentCartComponent);
  }
}
