<div class="recent-search-box">
  <div class="recent-search-header-box">
    <p class="title">최근 검색어</p>

    <a class="delete" (click)="removeAllRecent()">전체 삭제</a>
  </div>

  <div class="recent-search-keyword-box">
    <button
      *ngFor="let recentKeyword of recentKeywords$ | async"
      type="button"
      class="recent-keyword-box"
      (click)="clickRecentKeyword(recentKeyword)"
    >
      <p>{{ recentKeyword }}</p>

      <img
        wndSrc="/assets/icons/gray copy.png"
        alt="remove icon"
        useHiDpi="true"
        (click)="removeRecentKeyword(recentKeyword)"
      />
    </button>
  </div>
</div>

<div class="popularity-box">
  <div class="popularity-header-box">
    <p class="title">인기검색어</p>
  </div>

  <div class="popularity-list-box">
    <ul class="popularity-list">
      <li
        *ngFor="let popularKeyword of popularKeywords$ | async | slice: 0:5; let idx = index"
        class="popularity-item"
        (click)="clickRecentKeyword(popularKeyword.keyword)"
      >
        <p class="popularity-text">{{ idx + 1 }}. {{ popularKeyword.keyword }}</p>

        <div class="rank-status-change-box">
          <div
            *ngIf="popularKeyword.prevIndex !== idx"
            class="tri"
            [class.red]="popularKeyword.prevIndex > idx"
            [class.green]="popularKeyword.prevIndex < idx"
          ></div>

          <p>
            <ng-container *ngIf="popularKeyword.prevIndex === idx; else indexChange"
              >-</ng-container
            >
            <ng-template #indexChange>
              {{
                popularKeyword.prevIndex > idx
                  ? popularKeyword.prevIndex - idx
                  : idx - popularKeyword.prevIndex
              }}
            </ng-template>
          </p>
        </div>
      </li>
    </ul>
    <ul *ngIf="!isFive" class="popularity-list">
      <li
        *ngFor="let popularKeyword of popularKeywords$ | async | slice: 5:10; let idx = index"
        class="popularity-item"
        (click)="clickRecentKeyword(popularKeyword.keyword)"
      >
        <p class="popularity-text">{{ idx + 6 }}. {{ popularKeyword.keyword }}</p>

        <div class="rank-status-change-box">
          <div
            *ngIf="popularKeyword.prevIndex !== idx + 5"
            class="tri"
            [class.red]="popularKeyword.prevIndex > idx + 5"
            [class.green]="popularKeyword.prevIndex < idx + 5"
          ></div>

          <p>
            <ng-container *ngIf="popularKeyword.prevIndex === idx + 5; else indexChange"
              >-</ng-container
            >
            <ng-template #indexChange>
              {{
                popularKeyword.prevIndex > idx + 5
                  ? popularKeyword.prevIndex - idx + 5
                  : idx + 5 - popularKeyword.prevIndex
              }}
            </ng-template>
          </p>
        </div>
      </li>
    </ul>
  </div>
</div>
