<div
  wndButtonHover
  class="upload-menu-button-box"
  (hoverEvent)="isShow = $event"
  (blurEvent)="isShow = $event"
>
  <button type="button" class="wnd-btn middle yellow">업로드</button>

  <div *ngIf="isShow" @fadeIn @fadeOut class="upload-menu-box">
    <ul class="upload-menu-list">
      <li class="upload-menu-item">
        <a href="" class="upload-menu-link">
          <p class="upload-menu-name">리소스</p>

          <p class="upload-menu-describe">리소스를 업로드하여 부가 수익을 누려보세요</p>
        </a>
      </li>

      <li class="upload-menu-item">
        <a href="" class="upload-menu-link">
          <p class="upload-menu-name">포트폴리오</p>

          <p class="upload-menu-describe">당신의 멋진 포트폴리오를 업로드하세요</p>
        </a>
      </li>
    </ul>
  </div>
</div>
