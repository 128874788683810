import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'wnd-underline-text',
  templateUrl: './underline-text.component.html',
  styleUrls: ['./underline-text.component.scss']
})
export class UnderlineTextComponent implements OnInit {
  @Input() isUnderline = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
