import { DbListResponse, DbQuery, DbSortDirection } from '../../core/db/types';
import { FirestoreDbAdapter } from '../../modules/firebase/firestore-db.adapter';
import { Cart, LicenseType } from './types';
import { ColdObservable, ColdObservableOnce, HotObservableOnce } from '../../core/types';
import { ResourceService } from '../resource/resource.service';
import { Resource } from '../resource/types';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CouponHistory } from '../coupon-history/types';
import { CouponHistoryService } from '../coupon-history/coupon-history.service';

export class CartService {
  constructor(
    protected cartDb: FirestoreDbAdapter<Cart>,
    protected resourceService: ResourceService,
    protected couponHistoryService: CouponHistoryService
  ) {}

  listChange(userId: string): ColdObservable<[Resource[], Cart[], CouponHistory[]]> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: 9999,
    };

    let carts: Cart[];
    let resourceIds: string[];
    let couponHistories: CouponHistory[];

    return this.cartDb.listChange(query, { parentIds: [userId] }).pipe(
      tap(() => {
        this.couponHistoryService
          .list({
            filters: [
              { field: 'user.id', comparison: '==', value: userId },
              { field: 'isUsed', comparison: '==', value: false },
              { field: 'isExpired', comparison: '==', value: false },
            ],
          })
          .subscribe((res) => (couponHistories = res.docs));
      }),
      tap((response: DbListResponse<Cart>) => {
        carts = response.docs;
        resourceIds = carts.map((doc) => doc.id);
      }),
      switchMap((response: DbListResponse<Cart>) => {
        return this.resourceService.getMany(response.docs.map((doc) => doc.id));
      }),
      map((resources: Resource[]) =>
        resources.map((resource: Resource, index: number) => {
          return Boolean(resource) ? resource : ({ id: resourceIds[index] } as Resource);
        })
      ),
      map((resources: Resource[]) => [resources, carts, couponHistories])
    );
  }

  countChange(userId: string): ColdObservable<number> {
    const query: DbQuery = {
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
      limit: 9999,
    };

    return this.cartDb
      .listChange(query, { parentIds: [userId] })
      .pipe(map((response) => response.count));
  }

  add(userId: string, resourceId: string, licenseType?: LicenseType): HotObservableOnce<Cart> {
    return this.cartDb.get(resourceId, { parentIds: [userId] }).pipe(
      switchMap((cart) => {
        if (cart) {
          throw new Error('이미 카트에 담겨진 리소스 입니다.');
        }

        const doc: Partial<Cart> = {
          id: resourceId,
        };

        if (licenseType) {
          doc.licenseType = licenseType;
        }

        return this.cartDb.add(doc, { parentIds: [userId] });
      })
    );
  }

  get(userId: string, cartId: string): ColdObservableOnce<Cart> {
    return this.cartDb.get(cartId, { parentIds: [userId] });
  }

  delete(userId: string, resourceId: string): HotObservableOnce<void> {
    return this.cartDb.delete(resourceId, { parentIds: [userId] });
  }

  checkHasOtherLicenseType(userId: string, licenseType: LicenseType): Observable<boolean> {
    const query: DbQuery = {
      filters: [],
      limit: 1,
    };

    if (licenseType === LicenseType.Person) {
      query.filters.push({ field: 'licenseType', comparison: '>', value: licenseType });
    } else {
      query.filters.push({ field: 'licenseType', comparison: '<', value: licenseType });
    }

    return this.cartDb.list(query, { parentIds: [userId] }).pipe(
      map((response) => {
        return response.count > 0;
      })
    );
  }
}
