import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarOverlayButtonComponent } from './components/toolbar-overlay-button/toolbar-overlay-button.component';
import { ToolbarButtonMenuDirective } from './directives/toolbar-button-menu.directive';



@NgModule({
  declarations: [
    ToolbarOverlayButtonComponent,
    ToolbarButtonMenuDirective
  ],
  exports: [
    ToolbarButtonMenuDirective
  ],
  imports: [
    CommonModule
  ]
})
export class ToolbarModule { }
