import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'wnd-more-text-btn',
  templateUrl: './more-text-btn.component.html',
  styleUrls: ['./more-text-btn.component.scss']
})
export class MoreTextBtnComponent implements OnInit {
  @Output() moreClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  clickMore() {
    this.moreClick.emit(true);
  }
}
