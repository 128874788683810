import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { PreviewImageCardComponent } from './components/preview-image-card/preview-image-card.component';
import { ProfileImageCardComponent } from './components/profile-image-card/profile-image-card.component';
import { UploadImageBoxComponent } from './components/upload-image-box/upload-image-box.component';
import { SrcDirective } from './directives/src.directive';

const commonComponent = [
  SrcDirective,
  ImageCardComponent,
  PreviewImageCardComponent,
  UploadImageBoxComponent,
  ProfileImageCardComponent,
];

@NgModule({
  declarations: commonComponent,
  exports: commonComponent,
  imports: [CommonModule],
})
export class ImagesModule {}
