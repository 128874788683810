import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { stopEvent } from 'projects/web/src/app/core/utils/utils';
import { NgRouteService } from 'projects/weenidy/src/lib/core/services/route.service';
import {
  DialogComponent,
  WndDialogData,
} from 'projects/weenidy/src/lib/modules/dialog/components/dialog/dialog.component';
import { NgResourceService } from 'projects/weenidy/src/lib/modules/resource/services/resource.service';
import { ResourceDeleteEventBusService } from 'src/entities/resource/resource-delete-event-bus.service';
import { NgUploader } from '../../../../../../../weenidy/src/lib/modules/upload/services/uploader.service';
import { Resource } from '../../../../../../../../src/entities/resource/types';
import { delay, tap } from 'rxjs';
import { LoaderService } from '../../../loading/loader.service';

@Component({
  selector: 'wnd-more-dots-button',
  templateUrl: './more-dots-button.component.html',
  styleUrls: ['./more-dots-button.component.scss'],
})
export class MoreDotsButtonComponent implements OnInit {
  @Input() resource: Resource;
  @Input() index: number;

  isClicked = false;

  constructor(
    private routeService: NgRouteService,
    private dialog: MatDialog,
    private resourceService: NgResourceService,
    private resourceDeleteEventBusService: ResourceDeleteEventBusService,
    private uploader: NgUploader,
    private loadingService: LoaderService
  ) {}
  ngOnInit(): void {}

  onToggleShowOptions(e: Event, value: boolean) {
    this.isClicked = value;
    return stopEvent(e);
  }

  onEdit(e: Event) {
    this.routeService.navigate(['/upload/portfolio', this.resource.id]);
    return stopEvent(e);
  }
  onDelete(e: Event) {
    this.openDeleteDialog();
    return stopEvent(e);
  }

  removeResource() {
    this.resourceService
      .delete(this.resource.id)
      .pipe(
        tap(() => this.loadingService.setLoading(true)),
        delay(1000)
      )
      .subscribe(() => {
        this.loadingService.setLoading(false);
        this.uploader.remove(this.resource.thumbnail, ['origin', 'originWebp']).finally(() => {
          this.resourceDeleteEventBusService.addDeleteEvent(this.index);
        });
      });
  }

  openDeleteDialog(): void {
    this.dialog
      .open<DialogComponent, WndDialogData>(DialogComponent, {
        disableClose: true,
        data: {
          title: '포트폴리오 삭제',
          contents: '정말 삭제하시겠습니까?',
          type: 'waring',
          secondaryButtonText: '취소',
        },
        panelClass: 'refused-panel',
      })
      .afterClosed()
      .subscribe((btnName) => {
        if (btnName === 'primary') {
          this.removeResource();
        }
      });
  }
}

interface Option {
  name: string;
  onClick: Function;
}
