import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MainBannerItem } from "../../../../../../../../src/entities/setting/types";


@Component({
  selector: 'wnd-slider-banner-item-pc',
  templateUrl: './slider-banner-item-pc.component.html',
  styleUrls: ['./slider-banner-item-pc.component.scss']
})
export class SliderBannerItemPcComponent implements OnInit {
  @Input() banner: MainBannerItem;
  @Output() bannerClick = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  imageClick(bannerItem: MainBannerItem) {
    this.bannerClick.emit(bannerItem.pcLink);
  }
}
