<div cdkDropList class="program-list-box" (cdkDropListDropped)="drop($event)">
  <form [formGroup]="formGroup">
    <ul class="program-list" formArrayName="programs">
      <li
        cdkDrag
        *ngFor="let control of programFormArray.controls; let i = index"
        class="program-item"
        [class.item__selected]="selectionModel.isSelected(i)"
        (click)="selectItem(i)"
      >
        <div class="program-item-placeholder" *cdkDragPlaceholder></div>

        <div class="program-item-inner-box">
          <div cdkDragHandle class="drag-holder-box">
            <i class="wnd-icn drag-icon"></i>
          </div>

          <wnd-auto-complete-input
            [selectedFormArray]="programFormArray"
            [formControlName]="i"
            [options]="baseProgramOptions"
          ></wnd-auto-complete-input>
        </div>
      </li>
    </ul>

    <div class="program-button-box">
      <button
        *ngIf="programFormArray.controls.length > 0 && selectionModel.hasValue()"
        type="button"
        class="wnd-btn program-button delete-button"
        (click)="remove()"
      >
        삭제
      </button>

      <button type="button" class="wnd-btn program-button add-button" (click)="add()">
        프로그램 추가
      </button>
    </div>
  </form>
</div>
