import { Inject, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { from, Observable } from 'rxjs';
import { WndImage } from '../../../../../../../src/core/types';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { generateDir, generateId, generateName, generateTempFileName } from '../utils';
import { UploadOptions, UploadTask } from './types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import storage = firebase.storage;
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NgUploader {
  private storage = storage();
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private deviceService: DeviceDetectorService
  ) {}

  upload(data: File | Blob | string, options: UploadOptions): UploadTask {
    if (!data) {
      throw new Error('data argument is required');
    }

    if (!options.dir) {
      options.dir = generateDir();
    }

    if (!options.fileName) {
      options.fileName = this.generateFileName(data);
    }

    let fileName = options.fileName;

    if (this.deviceService.browser === 'Safari') {
      fileName = generateTempFileName(fileName);
    }

    const filePath = `${options.dir}/${fileName}`;

    let task: storage.UploadTask;

    const ref = this.storage.ref(filePath);

    if (typeof data === 'string') {
      task = ref.putString(data, options.format, {
        contentType: options.contentType,
        contentLanguage: 'ko-KR',
      });
    } else {
      task = ref.put(data, {
        contentType: options.contentType || data.type,
      });
    }

    return {
      filePath,
      task: task,
      pause: task.pause,
      resume: task.resume,
      cancel: task.cancel,
      percentageChange: () =>
        new Observable((subscriber) => {
          task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snap) => subscriber.next(Math.floor(snap.bytesTransferred / snap.totalBytes)),
            (err) => subscriber.error(err),
            () => subscriber.complete()
          );
        }),
      getDownloadURL: () =>
        new Observable((subscriber) => {
          task
            .then((snap) => {
              return snap.ref.getDownloadURL();
            })
            .then((url) => {
              subscriber.next({
                url,
                fileName,
              });
              subscriber.complete();
            })
            .catch((err) => subscriber.error(err));
        }),
    } as UploadTask;
  }

  getDownloadUrl(filePath: string): Observable<string> {
    return from(this.storage.ref(filePath).getDownloadURL());
  }

  private generateFileName(data: File | Blob | string): string {
    let name: string;
    let ext = '.';

    if (data instanceof File) {
      const splitName = data.name.split('.');

      name = generateTempFileName(
        splitName.filter((item, index) => index !== splitName.length - 1).join('.')
      );
      ext += splitName[splitName.length - 1];
    } else if (data instanceof Blob) {
      name = generateName(generateId(4));
      if (data.type === 'unknown') {
        ext = '';
      } else {
        ext += data.type.split('/')[1];
      }
    } else if (typeof data === 'string') {
      name = generateName(generateId(4));
      ext = '';
    } else {
      throw new Error('data argument isPreview is incorrect');
    }

    return `${name}${ext}`;
  }

  remove(wndImage: WndImage, urlKeys: string[]) {
    return Promise.all(
      urlKeys
        .filter((key) => wndImage[key])
        .map((key) => {
          const splitUrl = wndImage[key].split(this.environment.imageBucket);
          const refUrl = splitUrl[splitUrl.length - 1];

          return this.storage.ref(decodeURIComponent(refUrl)).delete();
        })
    );
  }

  removeFile(url: string) {
    const splitUrl = url.split('/o/');
    const refUrl: string = splitUrl[splitUrl.length - 1].split('?')[0];

    this.storage.ref(decodeURIComponent(refUrl)).delete();
  }
}
