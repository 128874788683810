import { Component, Input } from '@angular/core';


@Component({
  selector: 'wnd-money-text',
  templateUrl: './money-text.component.html',
  styleUrls: ['./money-text.component.scss']
})
export class MoneyTextComponent {
  @Input() money: number;

  constructor() {
  }
}
