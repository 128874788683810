<div class="slider-banner-full-box">
  <div class="slider-text-info">
    <h2 class="title-en">{{banner.mobTitleEn}}</h2>

    <wnd-underline-text class="first-title" [isUnderline]="true">
      {{ banner.mobTitleFirst }}
    </wnd-underline-text>

    <wnd-underline-text class="second-title" [isUnderline]="true">
      {{ banner.mobTitleSecond }}
    </wnd-underline-text>

    <article class="banner-article">
      {{ banner.mobContent }}
    </article>
  </div>

  <wnd-image-card
    class="slider-image-full"
    ratio="4-3"
    [src]="banner.mobImage"
    (click)="imageClick(banner)"
  ></wnd-image-card>
</div>
