import { Pipe } from '@angular/core';


@Pipe({ name: 'usd' })
export class UsdPipe {
  constructor() {
  }

  transform(money: number): string {
    return parseFloat(String(money / 1000)).toFixed(2);
  }
}
