import { Component, forwardRef, Input } from '@angular/core';
import { FormControlBaseComponent } from '../../../../../projects/weenidy/src/lib/modules/form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LicenseType } from '../../../../entities/cart/types';
import { ResourceCategory } from '../../../../entities/resource/types';

@Component({
  selector: 'wnd-according-license-form',
  templateUrl: './according-license-form.component.html',
  styleUrls: ['./according-license-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccordingLicenseFormComponent),
      multi: true,
    },
  ],
})
export class AccordingLicenseFormComponent extends FormControlBaseComponent {
  @Input() price: number = 0;
  @Input() category: ResourceCategory;

  isOpen = false;
  licenseType = LicenseType;
  resourceCategory = ResourceCategory;

  constructor() {
    super();
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  setValue(type: LicenseType): void {
    this.formCtrl.setValue(type);
    this.isOpen = false;
  }

  protected override resetControl(type: LicenseType): void {
    this.formCtrl.setValue(type);
  }
}
