import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class MetaService {
  static BaseUrl = 'https://weenidy.com';
  private description = '전지적 국내 디자인 시점, 어디서도 본적 없는 새로운 디자인 리소스, 템플릿에서 목업까지!';
  private mainImage = 'https://weenidy.com/assets/images/weenidy-og-image.jpg';
  private mainKeywords = '디자인,design,resource,mockup,template,템플릿,디자인 트렌드,design trend';

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: Document,
  ) {
  }

  setOriginal(): void {
    const title = '위니디 - 실무 밀착형 디자인 리소스 서비스';
    this.setMetaTag(title);
  }

  setMetaTag(
    title: string,
    image: string = this.mainImage,
    description: string = this.description,
    keyword: string = this.mainKeywords,
    url: string = MetaService.BaseUrl,
  ) {
    this.setTitle(title);
    this.setDescription(description);
    this.setKeywords(keyword);
    this.setOgUrl(url);
    this.setOgImage(image);
  }

  private setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  private setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
  }

  private setKeywords(keywords: string) {
    this.meta.updateTag({ property: 'keywords', content: keywords });
  }

  private setOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
    this.createLinkForCanonicalURL(url);
  }

  private setOgImage(img: string) {
    this.meta.updateTag({ property: 'og:image', content: img });
    this.meta.updateTag({ property: 'og:image:width', content: '474' });
    this.meta.updateTag({ property: 'og:image:height', content: '247' });
  }

  private createLinkForCanonicalURL(url: string) {
    const mainCanonicalElement = document.querySelector('[rel="canonical"]');

    if (mainCanonicalElement) {
      mainCanonicalElement.remove();
    }

    const canonicalElement = this.doc.createElement('link');
    canonicalElement.setAttribute('rel', 'canonical');
    canonicalElement.setAttribute('href', url);
    this.doc.head.appendChild(canonicalElement);
  }
}


export const SignLoginTitle = '로그인 - 위니디';
export const SignLoginUrl = MetaService.BaseUrl + '/sign/login';

export const SignJoinTitle = '회원가입 - 위니디';
export const SignJoinUrl = MetaService.BaseUrl + '/sign/join';

export const SignFindPasswordTitle = '비밀번호 찾기 - 위니디';
export const SignFindPasswordUrl = MetaService.BaseUrl + '/sign/find-password';

export const SignJoinCompleteTitle = '가입완료 - 위니디';
export const SignJoinCompleteUrl = MetaService.BaseUrl + '/sign/join-complete';

export const SignCompleteSendEmailTitle = '비밀번호 찾기 - 위니디';
export const SignCompleteSendEmailUrl = MetaService.BaseUrl + '/sign/complete-send-email';


export const ResourceTitle = '디자인 리소스 - 위니디';
export const ResourceUrl = MetaService.BaseUrl + '/resource/list';
export const ResourceDescription = '디자이너가 자체 제작하는 유니크한 목업과 디자인 템플릿을 제공합니다.';
export const ResourceKeywords = 'mockup, resource, template, 템플릿, portfolio template, free template';
