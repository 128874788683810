import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Badge, BadgeNameByCategory } from '../../../../../../../../src/entities/badge/types';
import { User } from 'src/entities/user/types';

export interface BadgeDialogComponentPayload {
  badge: Badge;
  user: User;
}
@Component({
  selector: 'wnd-badge-dialog',
  templateUrl: './badge-dialog.component.html',
  styleUrls: ['./badge-dialog.component.scss'],
})
export class BadgeDialogComponent implements OnInit {
  badge: Badge;
  user: User;

  badgeNameByCategory = BadgeNameByCategory;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BadgeDialogComponentPayload,
    private dialogRef: MatDialogRef<BadgeDialogComponent>
  ) {
    this.badge = data.badge;
    this.user = data.user;
  }

  ngOnInit(): void {}

  close(isOpen: boolean): void {
    this.dialogRef.close(isOpen);
  }
}
