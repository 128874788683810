<div class="wnd-preview-image-box">
  <div class="wnd-image-box">
    <div
      class="sub-box"
      [ngClass]="'ratio' + ratio"
      [ngStyle]="{ 'padding-top': ratio ? 'auto' : src.paddingTop }"
    >
      <picture>
        <ng-container *ngIf="!src.multipleWebp; else multipleBox">
          <source *ngIf="src.originWebp" [srcset]="src.originWebp" type="image/webp" />

          <source [srcset]="src.origin" type="image/jpeg" />

          <img
            class="origin-image"
            [class.empty-ratio]="src?.paddingTop === ''"
            [src]="src.origin"
            loading="lazy"
            [alt]="alt"
          />
        </ng-container>

        <ng-template #multipleBox>
          <source
            *ngIf="src.multipleWebp"
            media="(min-width: 501px)"
            [srcset]="src.multipleWebp"
            type="image/webp"
          />
          <source media="(max-width: 500px)" [srcset]="src.originWebp" type="image/webp" />

          <source media="(min-width: 501px)" [srcset]="src.multiple" type="image/jpeg" />
          <source media="(max-width: 500px)" [srcset]="src.origin" type="image/jpeg" />

          <img
            class="origin-image"
            [class.empty-ratio]="src?.paddingTop === ''"
            [srcset]="src.origin + ' 500w'"
            sizes="(max-width: 500px) 500px"
            [src]="src.multiple"
            [alt]="alt"
          />
        </ng-template>
      </picture>
    </div>
  </div>
</div>
