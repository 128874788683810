import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchFilter } from '../../../../pages/resource-page/types';
import { ResourceCategory } from '../../../../../../../../src/entities/resource/types';

@Component({
  selector: 'wnd-resource-search-filter-dialog',
  templateUrl: './resource-search-filter-dialog.component.html',
  styleUrls: ['./resource-search-filter-dialog.component.scss'],
})
export class ResourceSearchFilterDialogComponent implements OnInit {
  searchFilter: SearchFilter;
  category: ResourceCategory;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResearchSearchFilterDialogData,
    private dialogRef: MatDialogRef<ResourceSearchFilterDialogComponent>
  ) {
    this.searchFilter = this.data.searchFilter;
    this.category = this.data.category;

    console.log(this.data);
  }

  ngOnInit(): void {}

  updateQuery(searchFilter: SearchFilter): void {
    this.dialogRef.close(searchFilter);
  }

  closeFilter(searchFilter: SearchFilter): void {
    this.dialogRef.close(searchFilter);
  }
}

export interface ResearchSearchFilterDialogData {
  searchFilter: SearchFilter;
  category: ResourceCategory;
}
