<div class="mobile-menu-box">
  <i class="wnd-icn close" (click)="clickClose()"></i>

  <img class="logo-image" src="assets/images/logo/logo.png" alt="위니디 로고" />

  <div class="mobile-menu-contents-box">
    <nav class="menu-nav">
      <ul class="menu-list">
        <li class="menu-item">
          <a class="menu-link" routerLink="/index" routerLinkActive="active">
            <wnd-underline-text class="h3-700">홈으로</wnd-underline-text>

            <span class="en-text">Home</span>
          </a>
        </li>

        <li class="menu-item">
          <a
            class="menu-link"
            routerLink="/resource/search"
            routerLinkActive="active"
            [queryParams]="{ category: 1 }"
          >
            <wnd-underline-text class="h3-700">목업</wnd-underline-text>

            <span class="en-text">Mockups</span>
          </a>
        </li>

        <li class="menu-item">
          <a
            class="menu-link"
            routerLink="/resource/search"
            routerLinkActive="active"
            [queryParams]="{ category: 10 }"
          >
            <wnd-underline-text class="h3-700">템플릿</wnd-underline-text>

            <span class="en-text">Templates</span>
          </a>
        </li>

        <li class="menu-item">
          <a
            class="menu-link"
            routerLink="/resource/search"
            routerLinkActive="active"
            [queryParams]="{ category: 20 }"
          >
            <wnd-underline-text class="h3-700">그래픽</wnd-underline-text>

            <span class="en-text">Graphics</span>
          </a>
        </li>

        <li class="menu-item">
          <a
            class="menu-link"
            routerLink="/resource/search"
            routerLinkActive="active"
            [queryParams]="{ category: 50 }"
          >
            <wnd-underline-text class="h3-700">웹툰</wnd-underline-text>

            <span class="en-text">Webtoon</span>
          </a>
        </li>

        <li class="menu-item">
          <a
            class="menu-link"
            routerLink="/resource/search"
            routerLinkActive="active"
            [queryParams]="{ category: 100 }"
          >
            <wnd-underline-text class="h3-700">사진</wnd-underline-text>

            <span class="en-text">Photo</span>
          </a>
        </li>
      </ul>
    </nav>

    <ng-container *ngIf="!isLoggedIn">
      <div class="menu-btn-box">
        <wnd-icon-button icon="arrow" color="gray4" (click)="googleContinue()">
          <i class="wnd-icn button-icn google"></i>
          Google로 계속
        </wnd-icon-button>

        <wnd-icon-button icon="arrow" color="navy" routerLink="/sign/login">
          로그인
        </wnd-icon-button>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoggedIn">
      <hr class="wnd-divider" />

      <div class="logged-in-menu-box">
        <a class="logged-in-menu-text" routerLink="/my-page">
          <i class="wnd-icn mypage menu-icn"></i>
          마이페이지</a
        >

        <a
          *ngIf="isWriter"
          class="logged-in-menu-text"
          [routerLink]="['/creator', user?.creatorPath || user?.id]"
        >
          <i class="wnd-icn creator menu-icn"></i>
          작가페이지</a
        >

        <a class="logged-in-menu-text" routerLink="/my-page/qna">
          <i class="wnd-icn qa menu-icn"></i>
          문의하기</a
        >

        <a class="logged-in-menu-text" (click)="logout()">
          <i class="wnd-icn logout menu-icn"></i>

          로그아웃
        </a>
      </div>
    </ng-container>
  </div>
</div>
