import { map, Observable, of, switchMap } from 'rxjs';
import { DbAdapter } from '../../core/db/db.adapter';
import { DbListResponse, DbOptions, DbQuery, DbSortDirection } from '../../core/db/types';
import { makeDbInfinityList } from '../../core/db/utils';
import { SearchAdapter } from '../../core/search/search.adapter';
import { SearchQuery, SearchResponse } from '../../core/search/types';
import { makeSearchInfinityList } from '../../core/search/utils';
import {
  ColdObservable,
  ColdObservableOnce,
  File,
  HotObservableOnce,
  InfinityList,
} from '../../core/types';
import { Resource, ResourceCategory, ResourceStatus } from './types';
import { downloadFile } from 'src/core/download-utils';

export class ResourceService {
  constructor(
    private projectId: string,
    protected resourceBigquery: SearchAdapter<Resource>,
    protected resourceAlgolia: SearchAdapter<Resource>,
    protected resourceDb: DbAdapter<Resource>
  ) {}

  createId(): string {
    return this.resourceDb.createId();
  }

  add(doc: Partial<Resource>): ColdObservableOnce<Resource> {
    return this.resourceDb.add(doc);
  }

  get(id: string): ColdObservableOnce<Resource> {
    return this.resourceDb.get(id);
  }

  getChange(id: string): ColdObservable<Resource> {
    return this.resourceDb.getChange(id);
  }

  getMany(ids: string[]): ColdObservableOnce<Resource[]> {
    return this.resourceDb.getMany(ids);
  }

  getResourceAggregation(option: 'date' | 'month' | 'year', startAt: Date, endEt?: Date) {
    const query = `WITH
  resources as (
    SELECT
      DISTINCT document_id,
      confirmedAt,
      writer_email
    FROM \`${this.projectId}.firestore_export.resources_schema_schema_20220809_latest\`
    WHERE
      status = 1 AND
      ${
        endEt
          ? `date(confirmedAt, '+09') <= date(${endEt.getFullYear()}, ${
              endEt.getMonth() + 1
            }, ${endEt.getDate()}) AND`
          : ''
      }
      date(confirmedAt, '+09') >= date(${startAt.getFullYear()}, ${
      startAt.getMonth() + 1
    }, ${startAt.getDate()})
  )
SELECT
  ${
    option === 'year' || option === 'month' ? 'LEFT(' : ''
  }FORMAT_DATE('%F', DATE(confirmedAt, '+09'))${
      option === 'year' ? ', 4)' : option === 'month' ? ', 7)' : ''
    } as date,
  COUNT(*) as totalCount,
  COUNT(CASE WHEN writer_email = 'admin@weenidy.com' THEN confirmedAt END) as adminCount,
  COUNT(CASE WHEN writer_email != 'admin@weenidy.com' THEN confirmedAt END) as writerCount
FROM resources
GROUP BY date
ORDER BY date ASC`;

    return this.resourceBigquery.query(query);
  }

  search(query: SearchQuery): ColdObservableOnce<SearchResponse<Resource>> {
    return this.resourceAlgolia.search(query);
  }

  searchByBigquery(query: SearchQuery): ColdObservableOnce<SearchResponse<Resource>> {
    return this.resourceBigquery.search(query);
  }

  searchInfinityList(query: SearchQuery): InfinityList<Resource> {
    console.log('query', query);
    return makeSearchInfinityList(this.resourceAlgolia, query);
  }

  infinityList(query: DbQuery): InfinityList<Resource> {
    return makeDbInfinityList(this.resourceDb, query);
  }

  recentList(limit: number): ColdObservableOnce<SearchResponse<Resource>> {
    const query: SearchQuery = {
      filters: [
        { field: 'status', comparison: '==', value: ResourceStatus.Normal },
        { field: 'category', comparison: '!=', value: ResourceCategory.Picture },
      ],
      limit,
    };

    return this.resourceAlgolia.search(query);
  }

  hasMyResource(userId: string): ColdObservableOnce<boolean> {
    const query: DbQuery = {
      filters: [{ field: 'writer.id', comparison: '==', value: userId }],
      limit: 1,
    };

    return this.resourceDb
      .list(query)
      .pipe(map((response: DbListResponse<Resource>) => response.count <= 0));
  }

  searchMyPortfolios(userId: string): ColdObservableOnce<SearchResponse<Resource>> {
    const query: DbQuery = {
      filters: [
        { field: 'writer.id', comparison: '==', value: userId },
        { field: 'category', comparison: '==', value: ResourceCategory.Portfolio },
        { field: 'status', comparison: '==', value: ResourceStatus.Normal },
      ],
    };

    return this.search(query);
  }

  update(id: string, resource: Partial<Resource>): HotObservableOnce<void> {
    return this.resourceDb.update(id, resource);
  }
  increase(id: string, fieldName: keyof Resource, increaseNumber: number): HotObservableOnce<void> {
    return this.resourceDb.increase(id, fieldName, increaseNumber);
  }
  increaseClap(id: string): HotObservableOnce<void> {
    return this.resourceDb.increase(id, 'clap', 1);
  }

  createProductCode(type: 'web' | 'admin'): ColdObservableOnce<string> {
    const typeCode = type === 'web' ? 'C' : 'W';
    const productCode = `${typeCode}-${this.generateCode()}`;

    const query: DbQuery = {
      filters: [{ field: 'productCode', comparison: '==', value: productCode }],
      limit: 1,
    };

    return this.resourceDb.list(query).pipe(
      switchMap((response) => {
        if (response.count === 0) {
          return of(productCode);
        } else {
          return this.createProductCode(type);
        }
      })
    );
  }

  private generateCode() {
    const length = 6;
    const string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeric = '0123456789';
    let code = '';
    let character = '';

    while (code.length < length) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());

      let hold = string.charAt(entity1);
      hold = code.length % 2 === 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);

      code = character;
    }

    code = code
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');

    return code.substr(0, length);
  }

  getStatusCount(
    status: ResourceStatus,
    status2: ResourceStatus,
    userId: string
  ): Observable<number> {
    return this.resourceAlgolia
      .search({
        filters: [
          {
            field: 'status',
            comparison: '>=',
            value: status,
          },
          {
            field: 'status',
            comparison: '<=',
            value: status2,
          },
          {
            field: 'writer.id',
            comparison: '==',
            value: userId,
          },
          { field: 'category', comparison: '<', value: ResourceCategory.Portfolio },
          { field: 'category', comparison: '>=', value: ResourceCategory.Mockup },
        ],
      })
      .pipe(map((res) => res.totalCount));
  }

  download(resource: Resource): Promise<void> {
    if (!resource.files) {
      alert('업로드된 파일이 없습니다.');
      return;
    }

    const file = resource.files[0];

    this.downloadResourceFile(file);

    return this.increase(resource.id, 'downloadCount', 1).toPromise();
  }

  private downloadResourceFile(file: File) {
    downloadFile(file.url, file.fileName);
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<Resource>> {
    return this.resourceDb.list(query, options);
  }

  getWriterPortfolios(writerId: string) {
    return this.list({
      filters: [
        { field: 'writer.id', comparison: '==', value: writerId },
        { field: 'category', comparison: '==', value: ResourceCategory.Portfolio },
      ],
      sorts: [{ field: 'createdAt', direction: DbSortDirection.Desc }],
    });
  }

  delete(id: string): HotObservableOnce<any> {
    return this.resourceDb.delete(id);
  }
  clearCache(): Readonly<Promise<void>> {
    return this.resourceAlgolia.clearCache();
  }
}
