import { Component, forwardRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlBaseComponent } from '../../../../../../../weenidy/src/lib/modules/form';

@Component({
  selector: 'wnd-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent extends FormControlBaseComponent {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() focusChange = new EventEmitter<boolean>();
  isFocus = false;

  constructor() {
    super();
  }

  onFocus(): void {
    this.isFocus = true;
    this.focusChange.emit(true);
  }

  onBlur(): void {
    setTimeout(() => {
      this.isFocus = false;
      this.focusChange.emit(false);
    }, 50);
  }
}
