import { AppType, Environment, Configuration } from '../../../../src/core/environment';

export const environment: Environment = {
  production: false,
  appType: AppType.Web,
  configuration: Configuration.Dev,
  // production: true,
  // configuration: 'prod',
  firebase: {
    apiKey: 'AIzaSyBbDJiXc6ihdpXKNlJuJs6pjw-UmX7BSP4',
    authDomain: 'weenidy-subscribe-8b2a7.firebaseapp.com',
    databaseURL: 'https://weenidy-subscribe-8b2a7.firebaseio.com',
    projectId: 'weenidy-subscribe-8b2a7',
    storageBucket: 'weenidy-subscribe-8b2a7.appspot.com',
    // messagingSenderId: '155080011301',
    appId: '1:155080011301:web:0b50a6ba496082823d59cc',
    measurementId: 'G-2BWMB0Y078',
  },
  algolia: {
    appId: '89H0HE9V7F',
    searchKey: 'eeb7ced3caf36c75f5bddf21817d3bc2',
  },
  naver: {
    clientId: 'TqY1Oht11MGykKZ1INoI',
  },
  facebook: {
    appId: '189854841735145',
  },
  baseUrl: 'https://beta.weenidy.com',
  imageBucket: 'https://beta-images.weenidy.com/',
  iamport: {
    id: 'imp85995531',
    cid: {
      kakaopay: 'TC0ONETIME',
      nice: 'iamport01m',
      paypal: '',
    },
  },
};

// import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
