<div class="wnd-input-box">
  <img
    class="input-search-icn"
    wndSrc="assets/icons/resource-search-input.png"
    [useHiDpi]="true"
    alt="돋보기 아이콘"
  />

  <input
    #wndInput
    [class.error]="error"
    [class.valid]="valid"
    [class.search-input]="isSearch"
    type="search"
    autocomplete="off"
    [id]="id"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [pattern]="pattern"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
</div>
