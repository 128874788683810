import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PopularKeyword } from '../../../../../../../../src/entities/config/recent-popular.types';
import { NgRecentPopularService } from '../../../../../../../weenidy/src/lib/modules/config/services/recent-popular.service';
import { NgRecentKeywordService } from '../../../../../../../weenidy/src/lib/modules/recent-keyword/services/recent-keyword.service';
import { NgAuthService } from "../../../../../../../weenidy/src/lib/modules/auth/auth.service";
import { BaseSubscriptionComponent } from '../../../../core/components/base-subscription.component';
import moment from 'moment';
import { User } from 'src/entities/user/types';

@Component({
  selector: 'wnd-search-menu-box',
  templateUrl: './search-menu-box.component.html',
  styleUrls: ['./search-menu-box.component.scss'],
})
export class SearchMenuBoxComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() isFive = true;
  @Output() selectRecentKeyword = new EventEmitter<string>();

  recentKeywords$: Observable<string[]> = this.getRecentKeywordList();
  popularKeywords$: Observable<PopularKeyword[]>;
  updateDate: string = moment().subtract(1, 'hour').format('MM.DD HH:00');

  constructor(
    private authService: NgAuthService,
    private recentKeywordService: NgRecentKeywordService,
    private recentPopularService: NgRecentPopularService
  ) {
    super();
  }

  ngOnInit(): void {
    this.popularKeywords$ = this.getPopularKeywordList();
  }

  removeRecentKeyword(keyword: string): void {
    this.recentKeywordService.deleteKeyword(this.authService.user.id, keyword);
  }

  removeAllRecent(): void {
    this.recentKeywordService.deleteKeywordAll(this.authService.user.id);
  }

  clickRecentKeyword(keyword: string): void {
    this.selectRecentKeyword.emit(keyword);
  }

  getRecentKeywordList(): Observable<string[]> {
    return this.authService.user$.pipe(
      filter((user: User) => Boolean(user)),
      switchMap((user: User) => this.recentKeywordService.listKeywords(user.id))
    );
  }

  getPopularKeywordList(): Observable<PopularKeyword[]> {
    return this.isFive
      ? this.recentPopularService.listPopularKeywordsLimitFive()
      : this.recentPopularService.listPopularKeywordsLimitTen();
  }
}
