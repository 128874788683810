<div
  class="wnd-checkbox-box"
  [class.none-box]="type === 'none-box'"
  (click)="clickLabel()"
>
  <label class="wnd-checkbox-label" [for]="id">
    <input
      #wndInput
      class="wnd-checkbox"
      type="checkbox"
      [id]="id"
      [checked]="!!value"
    >

    <i class="wnd-checkbox-icn"></i>


    {{ placeholder }}
    <ng-content></ng-content>
  </label>
</div>
