import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { HammerConfig } from '../../../config/hammer';
import { BrowserService } from '../../../../shared/services/browser.service';
import { WndRatio, BannerItem } from '../../types';
import { ResizeService } from '../../../../shared/services/resize.service';


@Component({
  selector: 'wnd-slider-banner',
  templateUrl: './slider-banner.component.html',
  styleUrls: ['./slider-banner.component.scss']
})
export class SliderBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sliderElement') sliderElement: ElementRef;

  @Input() ratio: WndRatio = '4-3';
  @Input() mobileRadio: WndRatio = '4-3';
  @Input() position: 'left' | 'center' | 'right' = 'center';
  @Input() images: BannerItem[];
  @Output() bannerClick = new EventEmitter<string>();

  currIndex = 0;

  private subscription = new Subscription();
  private readyEvent = false;

  private currentTranslateX: number;
  private swipeState: 'none' | 'left' | 'right' = 'none';

  private imageWidth: number;
  private wrapperBox: number;

  private isPanEvent: boolean;

  constructor(
    private resizeService: ResizeService,
    private browserService: BrowserService
  ) {
  }

  ngOnInit() {
    // this.initInterval();
  }

  ngAfterViewInit(): void {
    if (this.browserService.isBrowser) {
      const sliderImageElement: HTMLElement = document.querySelector('.slider-image') as HTMLElement;

      if (sliderImageElement) {
        this.initEvent();
      }

      fromEvent(window, 'resize')
        .pipe(
          debounceTime(50),
          map(() => window.innerWidth < 730)
        )
        .subscribe(() => {
          this.imageWidth = (document.querySelector('.slider-image') as HTMLElement).getBoundingClientRect().width;
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initEvent() {
    this.imageWidth = (document.querySelector('.slider-image') as HTMLElement).getBoundingClientRect().width;
    this.wrapperBox = this.imageWidth * (this.images.length - 1);

    const hammerConfig = new HammerConfig(),
      hammer = hammerConfig.buildHammer(this.sliderElement.nativeElement);

    this.subscription.add(this.initPanStart(hammer));
    this.subscription.add(this.initPan(hammer));
    this.subscription.add(this.initPanEnd(hammer));
    this.subscription.add(this.initSwipeLeft(hammer));
    this.subscription.add(this.initSwipeRight(hammer));

    this.readyEvent = true;
  }


  get currRatio() {
    return this.resizeService.isMobile ? this.mobileRadio : this.ratio;
  }

  currImage(banner: BannerItem) {
    return this.resizeService.isMobile ? banner.mobileImage : banner.image;
  }

  changeIndex(index: number) {
    if (index > (this.images.length - 1)) {
      this.currIndex = this.images.length - 1;
    } else if (index < 0) {
      this.currIndex = 0;
    } else {
      this.currIndex = index;
    }

    this.change(this.currIndex);
  }

  imageClick(bannerItem: BannerItem, index: number) {
    if (this.isPanEvent) {
      return;
    }

    if (this.currIndex === index) {
      this.bannerClick.emit(bannerItem.link);
    } else {
      this.changeIndex(index);
    }
  }

  private change(index: number) {
    const px = -(this.imageWidth * index);

    const sliderBoxElement = this.sliderElement.nativeElement as HTMLElement;
    sliderBoxElement.style.transform = `translate(${px}px, 0px)`;
  }

  private initPanStart(hammer) {
    return fromEvent(hammer, 'panstart').subscribe(() => {
      this.elementAddTransitionDuration(0);
      this.currentTranslateX = new WebKitCSSMatrix(window.getComputedStyle(this.sliderElement.nativeElement).transform).m41;

      this.isPanEvent = true;
    });
  }

  private initPan(hammer) {
    return fromEvent(hammer, 'pan').subscribe((event: any) => {
      if (event.center.x === 0 && event.center.y === 0) {
        return;
      }

      const sliderBoxElement = this.sliderElement.nativeElement as HTMLElement,
        translateX = this.currentTranslateX + event.deltaX;

      if (translateX >= 0 || translateX <= -this.wrapperBox) {
        return;
      }

      sliderBoxElement.style.transform = `translate(${translateX}px, 0px)`;
    });
  }

  private initPanEnd(hammer) {
    return fromEvent(hammer, 'panend').subscribe(() => {

      const translateX = new WebKitCSSMatrix(window.getComputedStyle(this.sliderElement.nativeElement).transform).m41;
      this.elementAddTransitionDuration(300);

      if (translateX > 20) {
        this.changeIndex(-1);
        return;
      }

      const result = Math.abs(Math.round(translateX / this.imageWidth));
      const isCurrent = this.currIndex === result;

      if (this.swipeState === 'left') {
        this.changeIndex(isCurrent ? this.currIndex += 1 : result);
      } else if (this.swipeState === 'right') {
        this.changeIndex(isCurrent ? this.currIndex -= 1 : result > this.currIndex ? result - 2 : result);
      } else {
        this.changeIndex(result);
      }

      setTimeout(() => this.isPanEvent = false);
    });
  }

  private initSwipeLeft(hammer) {
    return fromEvent(hammer, 'swipeleft')
      .subscribe(() => this.swipeState = 'left');
  }

  private initSwipeRight(hammer) {
    return fromEvent(hammer, 'swiperight')
      .subscribe(() => this.swipeState = 'right');
  }

  private elementAddTransitionDuration(duration: number) {
    const element = this.sliderElement.nativeElement as HTMLElement;
    element.style.transitionDuration = `${duration}ms`;
  }
}
