import { Inject, Injectable } from '@angular/core';
import { UserService } from '../../../../../../../src/entities/user/user.service';
import { NgUserSearch } from './user.search';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgUserDb } from './user.db';

@Injectable({
  providedIn: 'root',
})
export class NgUserService extends UserService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override userSearch: NgUserSearch,
    protected override userDb: NgUserDb
  ) {
    super(environment.firebase.projectId, userSearch, userDb);
  }
}
