import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'wnd-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  title: string;
  contents: string;
  info: string;
  infoIcon = 'assets/images/icons/icn-information.png';

  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WndInfoDialogData,
  ) {
    this.setDialogData();
    this.dialogRef.addPanelClass('wnd-dialog-panel');
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  close() {
    this.dialogRef.close();
  }

  private setDialogData() {
    this.title = this.data.title;
    this.contents = this.data.contents;
    this.info = this.data.info;
  }
}


export type WndInfoDialogData = {
  title: string;
  contents: string;
  info: string;
};

