import { Injectable } from '@angular/core';
import { NgContentDb } from './content.db';
import { NgContentSearch } from './content.search';
import { ContentService } from '../../../../../../../src/entities/content/content.service';

@Injectable({
  providedIn: 'root',
})
export class NgContentService extends ContentService {
  constructor(
    protected override contentDb: NgContentDb,
    protected override contentSearch: NgContentSearch
  ) {
    super(contentDb, contentSearch);
  }
}
