<div class="wnd-image-box">
  <div class="sub-box" [ngClass]="'ratio'+ratio">
    <img
      [wndSrc]="src"
      [ratio]="ratio"
      [useHiDpi]="useHiDip"
      [useErrorHandle]="useErrorHandle"
      draggable="false"
    >
  </div>

  <div *ngIf="isGif" class="gif-btn">GIF</div>
</div>
