import { Injectable, NgZone } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BrowserService } from './browser.service';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  private _isMobile = false;
  private _isPc = false;

  isPc$ = new BehaviorSubject(this.deviceDetectorService.isDesktop());
  isMobile$ = new BehaviorSubject(this.deviceDetectorService.isMobile());

  constructor(
    private ngZone: NgZone,
    private deviceService: DeviceDetectorService,
    private browserService: BrowserService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    if (this.browserService.isBrowser) {
      this.checkIsMobile();
      this.checkIsPc();
      this.initResize();

      this.initWindowResize()
        .pipe(map(() => this.checkIsPc2()))
        .subscribe((isPc) => {
          // console.log('isPc', isPc);
          this.isPc$.next(isPc);
        });

      this.initWindowResize()
        .pipe(map(() => this.checkIsMobile2()))
        .subscribe((isMobile) => {
          // console.log('isPc', isPc);
          this.isMobile$.next(isMobile);
        });
    } else {
      this.isMobile = this.deviceService.isMobile();
      this.isPc = this.deviceService.isDesktop();
    }
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  set isMobile(_isMobile: boolean) {
    this._isMobile = _isMobile;
  }

  get isPc(): boolean {
    return this._isPc;
  }

  set isPc(isPc: boolean) {
    this._isPc = isPc;
  }

  private initWindowResize() {
    return fromEvent(window, 'resize').pipe(debounceTime(50));
  }

  private checkIsPc2() {
    return window.innerWidth >= 946;
  }

  private checkIsMobile2() {
    return window.innerWidth < 616;
  }

  private initResize() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(debounceTime(50))
        .subscribe(() => {
          this.ngZone.runGuarded(() => {
            this.checkIsMobile();
            this.checkIsPc();
          });
        });
    });
  }

  private checkIsMobile() {
    this.isMobile = window.innerWidth < 616;
  }

  private checkIsPc() {
    this.isPc = window.innerWidth >= 946;
    this.isPc$.next(window.innerWidth >= 946);
  }
}
