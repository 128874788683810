<form class="profile-form" [formGroup]="formGroup" novalidate>
  <div class="form-group">
    <div class="wnd-form-control">
      <wnd-input
        type="number"
        id="phoneNumber"
        placeholder="'-'생략하고 입력"
        formControlName="phoneNumber"
        (keydown)="keyDownOnlyNumber($event)"
      ></wnd-input>
    </div>

    <ng-container *ngIf="step !== phoneVerifyStep.Waiting">
      <span class="phone-verify-message"
        >{{ currentPhoneNumber | phoneParse }}으로 인증번호가 발송되었습니다.</span
      >

      <div class="wnd-form-control">
        <wnd-input
          type="number"
          id="phoneVerify"
          placeholder="인증번호 입력"
          formControlName="phoneVerify"
          (keydown)="keyDownOnlyNumber($event)"
        ></wnd-input>

        <span class="time-counter-text">{{ phoneVerifyStartMinutes | phoneVerifyMinutes }}</span>
      </div>

      <span class="phone-verify-message">
        인증번호를 받지 못하셨나요?
        <a class="resend-phone-verify-link" (click)="sendVerificationSms()">인증번호 재전송</a>
      </span>
    </ng-container>
  </div>
</form>
