<li
  class="slider-image-item"
  [ngStyle]="{'background-image': 'url(\''+banner.pcImage+'\')'}"
  (click)="imageClick(banner)"
>
  <div class="slider-text-box">
    <div class="slider-text-info">
      <h2 class="title-en">{{banner.pcTitleEn}}</h2>

      <wnd-underline-text class="first-title" [isUnderline]="true">
        {{ banner.pcTitle }}
      </wnd-underline-text>

      <article class="banner-article">
        {{ banner.pcContent }}
      </article>
    </div>
  </div>
</li>
