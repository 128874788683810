import { Pipe, PipeTransform } from '@angular/core';
import { BadgeCategory, badgeThumbnails } from '../../../../../../../src/entities/badge/types';

@Pipe({
  name: 'badgeThumbnail',
})
export class BadgeThumbnailPipe implements PipeTransform {
  transform(category: BadgeCategory, count: number): unknown {
    if (category === BadgeCategory.Visit && count === 30) {
      return `/assets/badge/badge-visit30.png`;
    }

    return `/assets/badge/${badgeThumbnails[category]}.png`;
  }
}
