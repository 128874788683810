<div class="dialog-box" *ngIf="isLoading">
  <div class="dialog-contents-box">
    <h4 class="dialog-title"><strong>업로드하는 리소스 유형</strong>을 선택해 주세요.</h4>

    <p class="dialog-subtitle">카테고리 선택은 업로드 페이지에서도 변경이 가능합니다.</p>

    <ul class="link-list">
      <li class="link-item">
        <a (click)="openNotion('resource')">리소스 제작 가이드 보러가기</a>
      </li>
      <li class="link-item">
        <a (click)="openNotion('license')">리소스 라이선스 안내</a>
      </li>
    </ul>

    <ul class="resource-category-list">
      <li
        class="resource-category-item"
        [class.active]="selectedCategory === resourceCategory.Mockup"
        (click)="selectCategory(resourceCategory.Mockup)"
      >
        <div class="resource-category-icon-box">
          <div class="resource-category-background-box"></div>

          <i class="wnd-icn mockup-icon"></i>
        </div>

        <p class="resource-category">목업</p>

        <div class="tooltip-box">
          <article class="tooltip-article">
            옥외광고, 스마트폰 화면, 포스터, 패키지 등에<br />
            실제 모습처럼 제작물을 적용할 수 있는 리소스
          </article>
        </div>
      </li>

      <li
        class="resource-category-item"
        [class.active]="selectedCategory === resourceCategory.Template"
        (click)="selectCategory(resourceCategory.Template)"
      >
        <div class="resource-category-icon-box">
          <div class="resource-category-background-box"></div>

          <i class="wnd-icn template-icon"></i>
        </div>

        <p class="resource-category">템플릿</p>

        <div class="tooltip-box">
          <article class="tooltip-article">
            PPT, 카드뉴스, 포트폴리오, UI, 이력서 등을<br />
            제작할 때 편집의 틀로 활용할 수 있는 리소스
          </article>
        </div>
      </li>

      <li
        class="resource-category-item"
        [class.active]="selectedCategory === resourceCategory.Graphic"
        (click)="selectCategory(resourceCategory.Graphic)"
      >
        <div class="resource-category-icon-box">
          <div class="resource-category-background-box"></div>

          <i class="wnd-icn graphic-icon"></i>
        </div>

        <p class="resource-category">그래픽</p>

        <div class="tooltip-box">
          <article class="tooltip-article">
            아이콘, 픽토그램, 일러스트, 아트웍 등<br />
            디지털 제작 시 재료로 활용할 수 있는 리소스
          </article>
        </div>
      </li>

      <li
        class="resource-category-item"
        [class.active]="selectedCategory === resourceCategory.WebToon"
        (click)="selectCategory(resourceCategory.WebToon)"
      >
        <div class="resource-category-icon-box">
          <div class="resource-category-background-box"></div>

          <i class="wnd-icn webtoon-icon"></i>
        </div>

        <p class="resource-category">웹툰</p>

        <div class="tooltip-box">
          <article class="tooltip-article">
            3D 배경, 브러쉬, 패턴, 인물, 사물 등<br />
            빠른 웹툰 제작에 쉽게 활용할 수 있는 리소스
          </article>
        </div>
      </li>

      <li
        class="resource-category-item"
        [class.active]="selectedCategory === resourceCategory.Picture"
        (click)="selectCategory(resourceCategory.Picture)"
      >
        <div class="resource-category-icon-new">
          <img src="{{ newBadgeSrc }}" />
        </div>
        <div class="resource-category-icon-box">
          <div class="resource-category-background-box"></div>
          <i class="wnd-icn photo-icon"></i>
        </div>

        <p class="resource-category">사진</p>

        <div class="tooltip-box">
          <article class="tooltip-article">
            풍경, 텍스쳐, 사물, 장소, 동·식물 등<br />
            다양한 제작물에 활용할 수 있는 실사 리소스
          </article>
        </div>
      </li>

      <li class="resource-category-item describe-item">
        <article class="resource-describe">
          많은 분들이 작가님의<br />
          멋진 디지털 리소스를<br />
          기다리고 있어요 :)
        </article>

        <p class="resource-sub-describe">We need more designs</p>
      </li>
    </ul>
  </div>

  <div class="dialog-buttons-box">
    <button class="wnd-btn" (click)="clickSecondaryButton()">취소</button>

    <button class="wnd-btn confirm-button" (click)="submit()" [disabled]="!selectedCategory">
      다음
    </button>
  </div>
</div>
