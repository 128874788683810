import {
  ComponentType,
  ConnectedPosition,
  Overlay,
  OverlayRef,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injectable,
  InjectionToken,
  Injector,
  Output,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { SubscriptionBaseComponent } from '../../../projects/weenidy/src/lib/core/base-components/subscription-base.component';
import { Subscription } from 'rxjs';

export class LibOverlayService<E extends OverlayComponent> extends SubscriptionBaseComponent {
  originRef: ElementRef;
  overlayRef: OverlayRef;

  protected positions: ConnectedPosition[];
  protected scrollStrategy: ScrollStrategy = this.overlay.scrollStrategies.block();
  protected hasBackdrop = true;
  protected backdropClass: string = 'lib-overlay-backdrop';

  constructor(protected overlay: Overlay, protected component: ComponentType<E>) {
    super();
  }

  init(originRef: ElementRef) {
    this.originRef = originRef;
  }

  open(data?: any) {
    if (this.overlayRef) {
      return;
    }

    let injector;

    if (data) {
      injector = Injector.create({
        providers: [{ provide: OVERLAY_DATA_TOKEN, useValue: data }],
      });
    }

    if (this.originRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.originRef)
          .withPositions(this.positions),
        scrollStrategy: this.scrollStrategy,
        hasBackdrop: this.hasBackdrop,
        backdropClass: this.backdropClass,
      });

      this.initEventHandler();

      const compRef: ComponentRef<E> = this.overlayRef.attach(
        new ComponentPortal(this.component, undefined, injector)
      );

      compRef.instance.onFinished.subscribe(() => this.close());
    }
  }

  protected initEventHandler(): void {
    this.setSubscription('_initBackdropEvent', this.initBackdropEvent());
  }

  private initBackdropEvent(): Subscription {
    return this.overlayRef.backdropClick().subscribe(() => this.close());
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.overlayRef = null;
  }
}

@Injectable()
export class OverlayComponent extends SubscriptionBaseComponent {
  @Output() onFinished = new EventEmitter();

  constructor() {
    super();
  }
}

export const OVERLAY_DATA_TOKEN = new InjectionToken<string>('overlay-data');
