<div class="resource-search-filter">
  <div class="resource-search-filter-header">
    <div class="resource-search-filter-title-box">
      <img
        class="filter-icn"
        wndSrc="assets/icons/icn-fillter-navy-d.png"
        [useHiDpi]="true"
        alt="filter icon"
      />
      <h4 class="resource-search-filter-title">필터</h4>
    </div>

    <a class="close-filter-link" (click)="closeFilter()">
      <img
        *ngIf="!isDialog"
        class="close-filter-icn"
        src="assets/icons/icn-24px-undo-n@2x.png"
        alt="close filter icon"
      />

      <img
        *ngIf="isDialog"
        class="close-filter-icn"
        src="assets/icons/icn-gnb-cancle-n@2x.png"
        alt="close filter icon"
      />
    </a>
  </div>

  <div class="resource-search-filter-contents">
    <div *ngIf="filterKeywords.length > 0" class="applied-filter-box">
      <div class="applied-filter-header">
        <h4 class="applied-filter-title">적용된 필터</h4>

        <a class="reset-filter-link" (click)="resetFilter()">
          필터 초기화
          <img src="/assets/icons/icn-gnb-cancle-n@2x.png" alt="remove icon" />
        </a>
      </div>

      <div class="applied-filter-content">
        <div class="selected-filter-box">
          <button
            *ngFor="let filterKeyword of filterKeywords"
            type="button"
            class="selected-filter-keyword-button"
          >
            <p>{{ filterKeyword.text }}</p>

            <a class="remove-icon" (click)="removeSearchKeyword(filterKeyword)">
              <img src="/assets/icons/gray copy@2x.png" alt="remove icon" />
            </a>
          </button>
        </div>
      </div>
    </div>

    <form [formGroup]="formGroup">
      <ul class="resource-search-filter-list">
        <li class="resource-search-filter-item">
          <mat-expansion-panel class="resource-search-filter-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>구분</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="search-filter-radio-box">
              <wnd-multi-check-button
                class="search-filter-radio"
                id="author"
                formControlName="author"
                [radioItems]="authorRadioItems"
              ></wnd-multi-check-button>
            </div>
          </mat-expansion-panel>
        </li>

        <li class="resource-search-filter-item" *ngIf="isShowPicture">
          <mat-expansion-panel class="resource-search-filter-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>가격</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="search-filter-radio-box">
              <wnd-multi-check-button
                class="search-filter-radio"
                id="price"
                formControlName="price"
                [radioItems]="priceRadioItems"
              ></wnd-multi-check-button>
            </div>
          </mat-expansion-panel>
        </li>

        <li class="resource-search-filter-item">
          <mat-expansion-panel class="resource-search-filter-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>라이선스</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="search-filter-radio-box">
              <wnd-multi-check-button
                class="search-filter-radio"
                id="license"
                formControlName="license"
                [radioItems]="licenseRadioItems"
              ></wnd-multi-check-button>
            </div>
          </mat-expansion-panel>
        </li>

        <li *ngIf="baseProgramRadioItems" class="resource-search-filter-item">
          <mat-expansion-panel class="resource-search-filter-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>파일 제작 도구</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="search-filter-radio-box">
              <wnd-multi-check-button
                class="search-filter-radio"
                id="baseProgram"
                formControlName="baseProgram"
                [radioItems]="baseProgramRadioItems"
              ></wnd-multi-check-button>
            </div>
          </mat-expansion-panel>
        </li>

        <li *ngIf="!isShowPicture" class="resource-search-filter-item">
          <mat-expansion-panel class="resource-search-filter-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>확장자</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="search-filter-radio-box">
              <wnd-multi-check-button
                class="search-filter-radio"
                id="photoExtension"
                formControlName="photoExtension"
                [radioItems]="photoExtensionRadioItems"
              ></wnd-multi-check-button>
            </div>
          </mat-expansion-panel>
        </li>

        <li *ngIf="!isShowPicture" class="resource-search-filter-item">
          <mat-expansion-panel class="resource-search-filter-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>사진 형태</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="search-filter-radio-box">
              <wnd-multi-check-button
                class="search-filter-radio"
                id="photoDirection"
                formControlName="photoDirection"
                [radioItems]="photoDirectionRadioItems"
              ></wnd-multi-check-button>
            </div>
          </mat-expansion-panel>
        </li>
      </ul>
    </form>
  </div>
</div>
