import { BaseEntity } from '../../core/base-entity';
import { WriterRank } from '../user/types';
import { LicenseType } from '../cart/types';

export type PgType = 'nice' | 'kakaopay' | 'paypal';

export enum PaymentStatus {
  Pay = 10, // 결제
  UnPay = 20, // 미결제
  Failed = 30, // 실패
  Paid = 50, // 결제완료
  Canceled = 70, // 취소
  ForcedCanceled = 80, // 강제취소
  AdminCanceled = 90, // 관리자 취소
}

export const PaymentStatusText = {
  [PaymentStatus.Pay]: '결제중',
  [PaymentStatus.UnPay]: '미결제',
  [PaymentStatus.Failed]: '실패',
  [PaymentStatus.Paid]: '결제완료',
  [PaymentStatus.Canceled]: '취소',
  [PaymentStatus.ForcedCanceled]: '강제 취소',
  [PaymentStatus.AdminCanceled]: '관리자 취소',
};

export interface PaymentCancelAnnotation {
  pg_tid: string;
  amount: number;
  cancelled_at: number;
  reason: string;
  receipt_url?: string;
}

export interface PaymentAnnotation {
  imp_uid: string;
  merchant_uid: string;
  pay_method?: 'samsung' | 'card' | 'trans' | 'vbank';
  channel?: 'pc' | 'mobile' | 'api';
  pg_provider?: string;
  pg_tid?: string;
  pg_id?: string;
  pg_type?: string;
  escrow?: boolean;
  apply_num?: string;
  bank_code?: string;
  bank_name?: string;
  card_code?:
    | '361'
    | '364'
    | '365'
    | '366'
    | '367'
    | '368'
    | '369'
    | '370'
    | '371'
    | '372'
    | '373'
    | '374'
    | '381'
    | '041'
    | '071'
    | '361(BC카드)'
    | '364(광주카드)'
    | '365(삼성카드)'
    | '366(신한카드)'
    | '367(현대카드)'
    | '368(롯데카드)'
    | '369(수협카드)'
    | '370(씨티카드)'
    | '371(NH카드)'
    | '372(전북카드)'
    | '373(제주카드)'
    | '374(하나SK카드)'
    | '381(KB국민카드)'
    | '041(우리카드)'
    | '071(우체국)'; // tslint:disable-line:max-line-length
  card_name?: string;
  card_number?: string;
  card_quota?: number;
  card_type?: number;
  vbank_code?: string;
  vbank_name?: string;
  vbank_num?: string;
  vbank_holder?: string;
  vbank_date?: number;
  vbank_issued_at?: number;
  name?: string;
  amount: number;
  paid_amount?: number;
  cancel_amount: number;
  currency: string;
  buyer_name?: string;
  buyer_email?: string;
  buyer_tel?: string;
  buyer_addr?: string;
  buyer_postcode?: string;
  custom_data?: string;
  customer_uid?: string;
  customer_uid_usage?: string;
  user_agent?: string;
  status: 'ready' | 'paid' | 'cancelled' | 'failed';
  started_at?: number;
  paid_at?: number;
  failed_at?: number;
  cancelled_at?: number;
  fail_reason?: string;
  cancel_reason?: string;
  receipt_url?: string;
  cancel_history?: PaymentCancelAnnotation[];
  cancel_receipt_urls?: string[];
  cash_receipt_issued?: boolean;
  success?: boolean;
  error_code?: string;
  error_msg?: string;
}

export interface Payment<T = any> extends BaseEntity {
  merchantUid: string;
  item?: SellingItem<T>;
  userId: string;
  status: PaymentStatus;
  type: PaymentType;
  annotation?: PaymentAnnotation;
  canceledAt?: Date;
  licenseType?: LicenseType;
  licenseUsedUserName?: string;
  writerRank?: WriterRank;
  couponId?: string;
}

export enum PaymentType {
  Single = 'single',
  Multi = 'multi',
}

export interface IamportParam {
  pg: string;
  merchant_uid: string;
  name: string;
  buyer_email: string;
  buyer_name: string;
  buyer_tel?: string;
  amount: number;
  period?: {
    from: string;
    to: string;
  };
  m_redirect_url: string;
  pay_method?: string;
  app_scheme?: string;
  niceMobileV2?: boolean;
  currency?: 'USD' | 'KRW';
}

export interface SellingItem<T> {
  id: string;
  name: string;
  type: WndCategory;
  amount: number;
  currency?: string;
  discountedAmount?: number;
  couponHistoryId?: string;
  info: T;
}

export enum WndCategory {
  Field = 10,
  Resource = 20,
  ResourceLike = 30,
  EventItem = 99,
}

export const WndCategoryText = {
  [WndCategory.Field]: '피드',
  [WndCategory.Resource]: '리소스',
  [WndCategory.EventItem]: '이벤트',
};

export interface PaymentPayload {
  userId?: string;
  email: string;
  name?: string;
  phoneNumber?: string;
  licenseUsedUserName?: string;
}

export interface PaymentError {
  code: PaymentErrorCode;
  message: string;
}

export enum PaymentErrorCode {
  WND_PAYMENT_NO_PARAM = 1,
  WND_PAYMENT_CANCEL = 10,
  WND_PAYMENT_FORGERY = 20,
  WND_PAYMENT_PAID = 30,
  WND_PAYMENT_NO_MOENY = 40,
  WND_PAYMENT_IAMPORT_ERROR = 100,
}

export enum ProceedCategory {
  Proceed = 1,
  Withdrawal = 10,
}

const PaymentErrorCodeText = {
  [PaymentErrorCode.WND_PAYMENT_NO_PARAM]:
    '구매 진행에 잘못된 접근이 확인되어,<br> 구매에 실패했습니다.', // 잘못된 url 로 접근시
  [PaymentErrorCode.WND_PAYMENT_CANCEL]: ' 카드사 결제 취소가 확인되어,<br> 구매에 실패했습니다.', // 결제 중 취소시
  [PaymentErrorCode.WND_PAYMENT_FORGERY]:
    '구매 진행에 잘못된 접근이 확인되어,<br> 구매에 실패했습니다.', // 금액 위조시
  [PaymentErrorCode.WND_PAYMENT_PAID]:
    '해당 리소스는 이미 결제가 완료되어 있습니다.<br> 보관함 확인을 부탁드려요.', // 결제가 완료된 상태
  [PaymentErrorCode.WND_PAYMENT_NO_MOENY]: '카드의 잔고 부족이 확인되어,<br> 구매에 실패했습니다.', // 잔고에 금액 부족
  [PaymentErrorCode.WND_PAYMENT_IAMPORT_ERROR]:
    '결제에 실패하였습니다.<br> 해당 전체 페이지를 사진 찍어주세요.', // 아임포트에서 결제실패 시
};

export function getPaymentErrorText(code: PaymentErrorCode): string {
  return PaymentErrorCodeText[code] ? PaymentErrorCodeText[code] : '결제실패';
}

// export interface PaymentError extends BaseEntity {
//   error: any;
//   info: any;
//   paymentPayload: any;
// }

export const PaymentPgText = {
  ['nice']: '나이스',
  ['paypal']: '페이팔',
  ['kakaopay']: '카카오페이',
};

// export interface ResourceInfo {
//   id: string;
//   title: string;
//   thumbnail: WndImage;
//   category: ResourceCategory;
//   type: ResourceType;
//   tags: string[];
//   writer: Writer;
//   productCode: string;
// }
