import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { first, Observable, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BrowserService } from '../../../../../weenidy/src/lib/shared/services/browser.service';
import { Role, User } from '../../../../../../src/entities/user/types';
import { NgAuthService } from '../../../../../weenidy/src/lib/modules/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WriterGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: NgAuthService,
    private router: Router,
    private browserService: BrowserService
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.browserService.isBrowser) {
      return true;
    }

    return this.canActivate();
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.browserService.isBrowser) {
      return true;
    }

    return this.canActivate();
  }

  canActivate(): Observable<boolean> {
    return this.authService.state$.pipe(
      first((state) => state.authLoaded),
      switchMap(() => this.authService.user$),
      map((user: User) => {
        return user.role === Role.Admin || user.role === Role.Writer;
      }),
      tap((isAdmin) => {
        console.log('isAdmin', isAdmin);
        if (!isAdmin) {
          console.log('access denied!');
          this.router.navigateByUrl('/auth').then();
        }
      })
    );
  }
}
