import { Inject, Injectable } from '@angular/core';
import { ResourceService } from '../../../../../../../src/entities/resource/resource.service';
import { NgResourceDb } from './resource.db';
import { Environment } from '../../../../../../../src/core/environment';
import { NgResourceSearch } from './resource.search';
import { ENVIRONMENT } from '../../../core/tokens';
import { NgResourceAlgoliaSearch } from './resource-algolia.search';

@Injectable({
  providedIn: 'root',
})
export class NgResourceService extends ResourceService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected resourceSearch: NgResourceSearch,
    protected override resourceAlgolia: NgResourceAlgoliaSearch,
    protected override resourceDb: NgResourceDb
  ) {
    super(environment.firebase.projectId, resourceSearch, resourceAlgolia, resourceDb);
  }
}
