import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'wnd-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input() icon: 'arrow' | 'download' | 'loading' = 'arrow';
  @Input() color: 'navy' | 'gray4' | 'white' | 'yellow-navy' = 'navy';
  @Input() type = 'button';
  @Input() disabled = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
