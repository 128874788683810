<div class="mobile-toolbar-box">
  <i class="wnd-icn back-nav" (click)="onBack()"></i>

  <div class="search-box">
    <form
      action="."
      [formGroup]="formGroup"
      (submit)="onSearch()"
      novalidate
    >
      <i class="wnd-icn search" (click)="onSearch()"></i>

      <wnd-input
        id="keyword"
        type="search"
        formControlName="keyword"
        placeholder="포트폴리오, 로고, 포스터"
      ></wnd-input>

      <i class="wnd-icn-png input-clean" (click)="onClean()"></i>
    </form>
  </div>
</div>
