import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { InputDatePickerComponent } from './components/input-date-picker/input-date-picker.component';
import { InputComponent } from './components/input/input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SelectComponent } from './components/select/select.component';
import { ControlErrorContainerDirective } from './directives/form.error.container.directive';
import { ControlErrorsDirective } from './directives/form.error.directive';
import { ErrorTextComponent } from './components/error-text/error-text.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ToggleFormComponent } from './components/toggle-form/toggle-form.component';
import { BirthdayInputComponent } from './components/birthday-input/birthday-input.component';
import { ImagesModule } from '../../modules/images/images.module';
import { CommonFormModule } from '../../../../../../src/components/form/form.module';
import { AccordingLicenseFormComponent } from '../../../../../../src/components/form/components/according-license-form/according-license-form.component';
import { MultiCheckButtonComponent } from './components/multi-check-button/multi-check-button.component';
import { GenderRadioButtonComponent } from './components/gender-radio-button/gender-radio-button.component';
import { BirthSelectComponent } from './components/birth-select/birth-select.component';
import { StringConvertBytesPipe } from './pipes/string-convert-bytes.pipe';
import { IconLinkInputComponent } from './components/icon-link-input/icon-link-input.component';
import { ProgramListFormComponent } from './components/program-list-form/program-list-form.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutoCompleteInputComponent } from './components/auto-complete-input/auto-complete-input.component';
import { ActivityListFormComponent } from './components/activity-list-form/activity-list-form.component';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    ErrorTextComponent,
    ControlErrorsDirective,
    ControlErrorContainerDirective,
    TextareaComponent,
    RadioButtonComponent,
    ToggleFormComponent,
    BirthdayInputComponent,
    InputDatePickerComponent,
    MultiCheckButtonComponent,
    GenderRadioButtonComponent,
    BirthSelectComponent,
    StringConvertBytesPipe,
    IconLinkInputComponent,
    ProgramListFormComponent,
    AutoCompleteInputComponent,
    ActivityListFormComponent,
  ],
  exports: [
    InputComponent,
    SelectComponent,
    TextareaComponent,
    CheckboxComponent,
    ErrorTextComponent,
    ControlErrorsDirective,
    ControlErrorContainerDirective,
    RadioButtonComponent,
    ToggleFormComponent,
    BirthdayInputComponent,
    InputDatePickerComponent,
    AccordingLicenseFormComponent,
    MultiCheckButtonComponent,
    GenderRadioButtonComponent,
    BirthSelectComponent,
    StringConvertBytesPipe,
    IconLinkInputComponent,
    ProgramListFormComponent,
    AutoCompleteInputComponent,
    ActivityListFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatDatepickerModule,
    ImagesModule,
    CommonFormModule,
    DragDropModule,
    MatAutocompleteModule,
  ],
})
export class LibFormModule {}
