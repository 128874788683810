<div cdkDropList class="activity-list-box" (cdkDropListDropped)="drop($event)">
  <form [formGroup]="formGroup">
    <ul class="activity-list" formArrayName="activities">
      <li
        cdkDrag
        *ngFor="let control of activitiesFormArray.controls; let i = index"
        class="activity-item"
        [class.item__selected]="selectionModel.isSelected(i)"
        (click)="selectItem(i)"
      >
        <div class="activity-item-placeholder" *cdkDragPlaceholder></div>

        <div class="activity-item-inner-box" [formGroupName]="i">
          <div class="left-box">
            <div cdkDragHandle class="drag-holder-box">
              <i class="wnd-icn drag-icon"></i>
            </div>

            <mat-select formControlName="type" placeholder="선택">
              <mat-option
                *ngFor="let option of creatorActivityTypeOptions"
                [value]="option.value"
                >{{ option.text }}</mat-option
              >
            </mat-select>
          </div>

          <div class="right-box">
            <wnd-input class="profile-input" formControlName="text" [maxLength]="20"></wnd-input>
          </div>
        </div>
      </li>
    </ul>

    <div class="activity-button-box">
      <button
        *ngIf="activitiesFormArray.controls.length > 0 && selectionModel.hasValue()"
        type="button"
        class="wnd-btn activity-button delete-button"
        (click)="remove()"
      >
        삭제
      </button>

      <button type="button" class="wnd-btn activity-button add-button" (click)="add()">
        주요활동 추가
      </button>
    </div>
  </form>
</div>
