<div class="canvas">
  <div id="clap" (click)="increaseClapCount(resourceId)" class="clap-box">
    <img class="clap-badge" src="assets/icons/badge-clab-hands-hover.svg" />
    <img class="clap-hands" src="assets/icons/icn-clap-hands-white.svg" />
    <p class="clap-count">{{ transferCounting(totalClap) }}</p>
  </div>

  <div id="particles" class="particles-container">
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
  </div>

  <div id="particles-2" class="particles-container">
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
  </div>

  <div id="particles-3" class="particles-container">
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
    <div class="triangle">
      <div class="square"></div>
    </div>
  </div>
</div>
