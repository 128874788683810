import { Injectable } from '@angular/core';
import { RecentKeywordService } from "../../../../../../../src/entities/recent-keyword/recent-keyword.service";
import { NgRecentKeywordDb } from "./recent-keyword.db";

@Injectable({
  providedIn: 'root',
})
export class NgRecentKeywordService extends RecentKeywordService {
  constructor(protected override recentKeywordDb: NgRecentKeywordDb) {
    super(recentKeywordDb);
  }
}
