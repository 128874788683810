<div class="mobile-toolbar-box">
  <i class="wnd-icn back-nav" (click)="onBack()">

  </i>

  <img
    class="logo-image"
    src="assets/images/logo/logo.png"
    alt="위니디 로고"
    routerLink="/"
  >

  <i class="wnd-icn empty"></i>
</div>
