import { ColdObservable, HotObservableOnce } from "../../core/types";
import { makeHot } from "../../core/utils";
import { DbAdapter } from "../../core/db/db.adapter";
import { RecentKeyword } from "./types";
import { map, switchMap } from "rxjs/operators";

export class RecentKeywordService {
  constructor(
    protected recentKeywordDb: DbAdapter<RecentKeyword>
  ) {}

  add(userId: string, keyword: string): HotObservableOnce<string[]> {
    return makeHot(
      this.recentKeywordDb.get(userId).pipe(
        map((recentKeyword: RecentKeyword) => {
          let keywords = recentKeyword ? recentKeyword.keywords : [];

          if (keywords.indexOf(keyword) === -1) {
            if (keywords.length >= 10) {
              keywords = keywords.slice(0, 9);
            }

            keywords.unshift(keyword);
          }

          return keywords;
        }),
        switchMap(keywords =>
          this.recentKeywordDb.upsert(userId, { keywords })
        ),
        map(recentKeyword => recentKeyword.keywords)
      )
    );
  }

  listKeywords(userId: string): ColdObservable<string[]> {
    return this.recentKeywordDb.getChange(userId).pipe(
      map(recentKeyword => recentKeyword && recentKeyword.keywords)
    );
  }

  deleteKeyword(userId: string, keyword: string): HotObservableOnce<RecentKeyword> {
    return makeHot(
      this.recentKeywordDb.get(userId).pipe(
        map((recentKeyword: RecentKeyword) => {
          let keywords = recentKeyword ? recentKeyword.keywords : [];

          if (keywords.length >= 10) {
            keywords = keywords.slice(0, 9);
          }

          const index = keywords.indexOf(keyword);

          if (index > -1) {
            keywords.splice(index, 1);
          }

          return keywords;
        }),
        switchMap(keywords =>
          this.recentKeywordDb.upsert(userId, { keywords })
        )
      )
    );
  }

  deleteKeywordAll(userId: string): HotObservableOnce<void> {
    return this.recentKeywordDb.delete(userId);
  }
}