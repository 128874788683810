import { MysqlAdapter } from './../../../../../../src/modules/mysql/mysql.adapter';
import { Inject, Injectable } from '@angular/core';
import { BigquerySearchAdapter } from 'src/modules/google/bigquery-search.adapter';
import { Environment } from 'src/core/environment';
import { NgApiService } from '../../core/services/api.service';

import { ENVIRONMENT } from '../../core/tokens';
import { CouponHistory } from 'src/entities/coupon-history/types';
import { NgCouponHistoryDb } from './coupon-histories.db';

@Injectable({
  providedIn: 'root',
})
export class NgCouponHistorySearch extends MysqlAdapter<CouponHistory> {
  //BigquerySearchAdapter<CouponHistory> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override apiService: NgApiService,
    protected couponHistoryDb: NgCouponHistoryDb
  ) {
    super(
      `couponHistory`, //`${environment.firebase.projectId}.firestore_export.couponHistories_schema_schema_20230925_latest`,
      apiService,
      couponHistoryDb
    );
  }
}
