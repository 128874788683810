import { PaymentStatus } from './types';
import { SearchAdapter } from '../../core/search/search.adapter';
import { makeSearchInfinityList } from '../../core/search/utils';
import { Payment } from './types';
import {
  ColdObservable,
  ColdObservableOnce,
  HotObservableOnce,
  InfinityList,
} from '../../core/types';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchQuery, SearchResponse } from '../../core/search/types';
import { DbAdapter } from '../../core/db/db.adapter';
import { DbQuery, DbSortDirection } from '../../core/db/types';

export class PaymentService {
  constructor(
    private projectId: string,
    protected paymentSearch: SearchAdapter<Payment>,
    protected paymentDb: DbAdapter<Payment>
  ) {}

  createId(): string {
    return this.paymentDb.createId();
  }
  get(id: string): ColdObservableOnce<Payment> {
    return this.paymentDb.get(id);
  }
  update(id: string, doc: Partial<Payment>): HotObservableOnce<void> {
    return this.paymentDb.update(id, doc);
  }
  search(query: SearchQuery): ColdObservable<SearchResponse<Payment>> {
    return this.paymentSearch.search(query);
  }

  infinityListByUserId(userId: string, query: DbQuery): InfinityList<Payment> {
    query.filters.push({ field: 'userId', comparison: '==', value: userId });
    query.filters.push({ field: 'status', comparison: '>=', value: PaymentStatus.Paid });
    query.filters.push({ field: 'status', comparison: '<=', value: PaymentStatus.Canceled });
    query.sorts = [{ field: 'createdAt', direction: DbSortDirection.Desc }];

    return makeSearchInfinityList(this.paymentSearch, query);
  }

  getOverviewData(): ColdObservableOnce<any> {
    const now = new Date();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
    const lastLastMonth = new Date(now.getFullYear(), now.getMonth() - 2);

    const sumQuery = `WITH
  payments AS (
    SELECT
      DISTINCT document_id, *
    FROM \`${this.projectId}.firestore_export.payments_schema_schema_20220811_latest\`
    WHERE 
      status = 50 AND
      date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1)
  )
SELECT
  SUM(case when date(createdAt, '+09') = date(${yesterday.getFullYear()}, ${
      yesterday.getMonth() + 1
    }, ${yesterday.getDate()}) then item_amount end) as yesterday,
  SUM(case when date(createdAt, '+09') = date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, ${now.getDate()}) then item_amount end) as today,
  SUM(case when date(createdAt, '+09') >= date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, 1) then item_amount end) as month,
  ROUND(
    SAFE_DIVIDE(
      SUM(case when date(createdAt, '+09') >= date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, 1) then item_amount end) - SUM(case when date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) then item_amount end),
      SUM(case when date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) then item_amount end)
    ) * 100,
    2
  ) as ratio,
  item_currency
FROM
  payments
GROUP BY 
  item_currency`;

    const countQuery = `WITH
  payments AS (
    SELECT
      DISTINCT document_id, *
    FROM \`${this.projectId}.firestore_export.payments_schema_schema_20220811_latest\`
    WHERE 
      status = 50 AND
      date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1)
  )
SELECT
  count(case when date(createdAt, '+09') = date(${yesterday.getFullYear()}, ${
      yesterday.getMonth() + 1
    }, ${yesterday.getDate()}) then item_amount end) as yesterday,
  count(case when date(createdAt, '+09') = date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, ${now.getDate()}) then item_amount end) as today,
  count(case when date(createdAt, '+09') >= date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, 1) then item_amount end) as month,
  ROUND(
    SAFE_DIVIDE(
      count(case when date(createdAt, '+09') >= date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, 1) then item_amount end) - count(case when date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) then item_amount end),
      count(case when date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) then item_amount end)
    ) * 100,
    2
  ) as ratio
FROM
  payments`;

    return forkJoin([
      this.paymentSearch.query(sumQuery),
      this.paymentSearch.query(countQuery),
    ]).pipe(map(([sumResponse, countResponse]) => [...sumResponse, ...countResponse]));
  }

  getPaymentAmountRankingInMonth() {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1);

    const query = `WITH
  payments as (
    SELECT
      DISTINCT document_id,
      *
    FROM \`${this.projectId}.firestore_export.payments_schema_schema_20220811_latest\`
    WHERE 
      status = 50 AND
      date(createdAt, '+09') >= date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, ${lastMonth.getDate()})
  )
SELECT
  item_id,
  SUM(CASE WHEN item_currency = 'KRW' THEN item_amount END) as sumAmount
FROM payments
GROUP BY item_id
ORDER BY sumAmount DESC 
LIMIT 10`;

    return this.paymentSearch.query(query);
  }

  getPaymentCountRankingInMonth() {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1);

    const query = `WITH
  payments as (
    SELECT
      DISTINCT document_id,
      *
    FROM \`${this.projectId}.firestore_export.payments_schema_schema_20220811_latest\`
    WHERE 
      status = 50 AND
      date(createdAt, '+09') >= date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, ${lastMonth.getDate()})
  )
SELECT
  item_id,
  COUNT(*) as count
FROM payments
GROUP BY item_id
ORDER BY count DESC
LIMIT 10`;

    return this.paymentSearch.query(query);
  }

  getPaymentCount(option: 'date' | 'month' | 'year', startAt: Date, endEt?: Date) {
    const query = `WITH
  payments as (
    SELECT
      DISTINCT document_id,
      *
    FROM \`${this.projectId}.firestore_export.payments_schema_schema_20220811_latest\`
    WHERE 
      status = 50 AND
      ${
        endEt
          ? `date(createdAt, '+09') <= date(${endEt.getFullYear()}, ${
              endEt.getMonth() + 1
            }, ${endEt.getDate()}) AND`
          : ''
      }
      date(createdAt, '+09') >= date(${startAt.getFullYear()}, ${
      startAt.getMonth() + 1
    }, ${startAt.getDate()})
  )
SELECT
  ${
    option === 'year' || option === 'month' ? 'LEFT(' : ''
  }FORMAT_DATE('%F', DATE(createdAt, '+09'))${
      option === 'year' ? ', 4)' : option === 'month' ? ', 7)' : ''
    } as date,
  COUNT(*) as totalCount,
  COUNT(CASE WHEN item_info_writer_email = 'admin@weenidy.com' THEN createdAt END) as adminCount,
  COUNT(CASE WHEN item_info_writer_email != 'admin@weenidy.com' THEN createdAt END) as writerCount
FROM payments
GROUP BY date
ORDER BY date ASC`;

    return this.paymentSearch.query(query);
  }

  getPaymentAmount(option: 'date' | 'month' | 'year', startAt: Date, endEt?: Date) {
    const query = `WITH
  payments as (
    SELECT
      DISTINCT document_id,
      *
    FROM \`${this.projectId}.firestore_export.payments_schema_schema_20220811_latest\`
    WHERE 
      status = 50 AND
      ${
        endEt
          ? `date(createdAt, '+09') <= date(${endEt.getFullYear()}, ${
              endEt.getMonth() + 1
            }, ${endEt.getDate()}) AND`
          : ''
      }
      date(createdAt, '+09') >= date(${startAt.getFullYear()}, ${
      startAt.getMonth() + 1
    }, ${startAt.getDate()})
  )
SELECT
  ${
    option === 'year' || option === 'month' ? 'LEFT(' : ''
  }FORMAT_DATE('%F', DATE(createdAt, '+09'))${
      option === 'year' ? ', 4)' : option === 'month' ? ', 7)' : ''
    } as date,
  SUM(CASE WHEN item_currency = 'KRW' THEN item_amount END) as totalAmountWon,
  SUM(CASE WHEN item_currency = 'USD' THEN item_amount END) as totalAmountUsd,
  SUM(CASE WHEN item_info_writer_email = 'admin@weenidy.com' AND item_currency = 'KRW' THEN item_amount END) as adminAmountWon,
  SUM(CASE WHEN item_info_writer_email = 'admin@weenidy.com' AND item_currency = 'USD' THEN item_amount END) as adminAmountUsd,
  SUM(CASE WHEN item_info_writer_email != 'admin@weenidy.com' AND item_currency = 'KRW' THEN item_amount END) as writerAmountWon,
  SUM(CASE WHEN item_info_writer_email != 'admin@weenidy.com' AND item_currency = 'USD' THEN item_amount END) as writerAmountUsd
FROM payments
GROUP BY date
ORDER BY date ASC`;

    return this.paymentSearch.query(query);
  }
}
