import { Injectable } from '@angular/core';
import { SettingService } from '../../../../../../../src/entities/setting/setting.service';
import { NgSettingDb } from './setting.db';

@Injectable({
  providedIn: 'root',
})
export class NgSettingService extends SettingService {
  constructor(protected override settingDb: NgSettingDb) {
    super(settingDb);
  }
}
