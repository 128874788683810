import { Component } from '@angular/core';
import { LoaderService } from '../../loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { stopEvent } from 'projects/web/src/app/core/utils/utils';

@Component({
  selector: 'wnd-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class LoadingSpinnerComponent {
  constructor(public loader: LoaderService) {}
}
