import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { HammerConfig } from '../../../config/hammer';
import { BrowserService } from '../../../../shared/services/browser.service';

import { MainBannerItem } from "../../../../../../../../src/entities/setting/types";

@Component({
  selector: 'wnd-slider-banner-full-pc',
  templateUrl: './slider-banner-full-pc.component.html',
  styleUrls: ['./slider-banner-full-pc.component.scss'],
})
export class SliderBannerFullPcComponent implements OnInit, OnDestroy {
  @Input()
  get images(): MainBannerItem[] {
    return this._images;
  }

  set images(images: MainBannerItem[]) {
    if (!images) {
      return;
    }
    this._images = images;

    this.firstIndex = 0;
    this.lastIndex = images.length - 1;

    this.firstBanner = images[this.firstIndex];
    this.lastBanner = images[this.lastIndex];

    setTimeout(() => {
      if (this.browserService.isBrowser) {
        this.initEvent();
      }
    });
  }

  @Output() bannerClick = new EventEmitter<string>();
  private subscription = new Subscription();

  currentIndex = 0;
  firstIndex: number;
  lastIndex: number;

  firstBanner: MainBannerItem;
  lastBanner: MainBannerItem;

  private _images: MainBannerItem[];
  private interval;

  constructor(private browserService: BrowserService, private elementRef: ElementRef) {}

  trackByFn(index: number, item: any) {
    return item.id;
  }

  ngOnInit() {
    if (this.browserService.isBrowser) {
      this.initInterval();
    }
  }

  ngOnDestroy() {
    this.clearInterval();
    this.subscription.unsubscribe();
  }

  changeIndex(index: number, status?: 'last' | 'first' | 'move') {
    this.currentIndex = index;
    this.moveImage(this.currentIndex, status);
    this.clearInterval();
    this.initInterval();
  }

  imageClick(url: string) {
    this.bannerClick.emit(url);
  }

  private moveImage(index: number, status?: 'last' | 'first' | 'move') {
    if (!this.images) {
      return;
    }

    const sliderElement = this.elementRef.nativeElement.querySelector(
      '.slider-image-list'
    ) as HTMLElement;
    sliderElement.classList.add('duration');

    if (status === 'last') {
      sliderElement.style.transform = `translate(0%, 0px)`;

      setTimeout(() => {
        sliderElement.style.transform = `translate(-${(index + 1) * 100}%, 0px)`;
        sliderElement.classList.remove('duration');
      }, 150);
    } else if (status === 'first') {
      sliderElement.style.transform = `translate(-${(this.lastIndex + 2) * 100}%, 0px)`;

      setTimeout(() => {
        sliderElement.style.transform = `translate(-100%, 0px)`;
        sliderElement.classList.remove('duration');
      }, 150);
    } else {
      sliderElement.style.transform = `translate(-${(index + 1) * 100}%, 0px)`;
    }
  }

  private calcIndex(
    index: number,
    type: 'next' | 'prev'
  ): { index: number; status: 'first' | 'last' | 'move' } {
    index = type === 'next' ? index + 1 : index - 1;

    if (index > this.lastIndex) {
      return { index: 0, status: 'first' };
    } else if (index < 0) {
      return { index: this.lastIndex, status: 'last' };
    }
    return { index, status: 'move' };
  }

  initInterval() {
    this.interval = setInterval(() => {
      const { index, status } = this.calcIndex(this.currentIndex, 'next');
      this.changeIndex(index, status);
    }, 5000);
  }

  clearInterval() {
    clearInterval(this.interval);
  }

  private initEvent() {
    const hammerConfig = new HammerConfig();
    const sliderElement = this.elementRef.nativeElement.querySelector('.slider-image-list');
    const hammer = hammerConfig.buildHammer(sliderElement);

    this.subscription.add(this.initSwipeLeft(hammer));
    this.subscription.add(this.initSwipeRight(hammer));
  }

  private initSwipeLeft(hammer) {
    return fromEvent(hammer, 'swipeleft').subscribe(() => this.swipeChange('next'));
  }

  private initSwipeRight(hammer) {
    return fromEvent(hammer, 'swiperight').subscribe(() => this.swipeChange('prev'));
  }

  private swipeChange(type: 'next' | 'prev') {
    const { index, status } = this.calcIndex(this.currentIndex, type);
    this.changeIndex(index, status);
  }

  // private syncChange(currentIndex: number, changeIndex: number) {
  //   this.change(currentIndex);
  //   setTimeout(() => {
  //     this.elementAddTransitionDuration(0);
  //     this.change(changeIndex);
  //   }, 300);
  // }
  //
  // private elementAddTransitionDuration(duration: number) {
  //   const element = this.sliderElement.nativeElement as HTMLElement;
  //   element.style.transitionDuration = `${duration}ms`;
  // }
}
