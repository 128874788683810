import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, map, take } from 'rxjs/operators';
import { isEmptyInputValue } from './custom.email.validator.service';
import { NgAuthService } from '../../../../../weenidy/src/lib/modules/auth/auth.service';
import { NgUserService } from '../../../../../weenidy/src/lib/modules/user/services/user.service';
import { DbListResponse } from '../../../../../../src/core/db/types';
import { User } from '../../../../../../src/entities/user/types';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberValidatorService {
  constructor(private userService: NgUserService, private authService: NgAuthService) {}

  overlap(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === '') {
        return of(null);
      } else if (control.value === this.authService.user.phoneNumber) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          map((value: string) => value.toLowerCase()),
          switchMap((phoneNumber: string) =>
            this.userService.list({
              filters: [{ field: 'phoneNumber', comparison: '==', value: phoneNumber }],
            })
          ),
          map((result: DbListResponse<User>) => {
            return result.count > 0 ? { used: true } : null;
            // return result.empty ? { used: true } : null;
            // return result.docs.length > 0 ? { used: true } : null;
          })
        );
      }
    };
  }
}
