import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '../buttons/buttons.module';
import { TextsModule } from '../../../../../weenidy/src/lib/modules/texts/texts.module';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';



@NgModule({
  declarations: [MobileMenuComponent],
  exports: [
    MobileMenuComponent
  ],
  imports: [
    CommonModule,
    TextsModule,
    ButtonsModule,
    RouterModule
  ]
})
export class MenuModule { }
