import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wnd-resource-filter-button',
  templateUrl: './resource-filter-button.component.html',
  styleUrls: ['./resource-filter-button.component.scss']
})
export class ResourceFilterButtonComponent implements OnInit {
  @Input() count: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
