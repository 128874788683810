import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControlBaseComponent } from '../../../../modules/form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'icon-link-input',
  templateUrl: './icon-link-input.component.html',
  styleUrls: ['./icon-link-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconLinkInputComponent),
      multi: true
    }
  ]
})
export class IconLinkInputComponent extends FormControlBaseComponent implements OnInit {
  @Input() thirdLink: string;

  constructor() {
    super();
  }
}
