import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Coupon } from 'src/entities/coupon/types';
import { FirestoreDbAdapter } from 'src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root',
})
export class NgCouponDb extends FirestoreDbAdapter<Coupon> {
  constructor() {
    super(firebase.firestore(), 'coupons');
  }
}
