import { BaseEntity } from '../../core/base-entity';
import { ResourceCategory } from '../resource/types';

export interface Badge extends BaseEntity {
  category: BadgeCategory;
  isNew: boolean;
  count?: number;
}

export interface BadgeCount extends BaseEntity {
  monthVisitCount: number;
  portFolioCount: number;
  resourceCount: number;
  mockupCount: number;
  templateCount: number;
  graphicCount: number;
  webtoonCount: number;
  pictureCount: number;
  totalAmountCount: number;
  totalCount: number; // 총 판매 건수
  viewCount: number;
}

export const BadgeCountKey = [
  'monthVisitCount',
  'portFolioCount',
  'resourceCount',
  'mockupCount',
  'templateCount',
  'graphicCount',
  'webtoonCount',
  'pictureCount',
  'totalAmountCount', //누적 판매금
  'totalCount', //누적 판매수
  'viewCount', //조회수
];

export enum BadgeCategory {
  Writer = 1,
  ProfilePicture = 10,
  Visit = 20,
  Portfolio = 50,
  Resource = 100,
  Mockup = 110,
  Template = 120,
  Graphic = 130,
  WebToon = 140,
  Picture = 150,
  SaleAmount = 200,
  SaleCount = 210,
  ViewCount = 220,
}

export const BadgeCountCategory = {
  monthVisitCount: BadgeCategory.Visit,
  portFolioCount: BadgeCategory.Portfolio,
  resourceCount: BadgeCategory.Resource,
  mockupCount: BadgeCategory.Mockup,
  templateCount: BadgeCategory.Template,
  graphicCount: BadgeCategory.Graphic,
  webtoonCount: BadgeCategory.WebToon,
  pictureCount: BadgeCategory.Picture,
  totalAmountCount: BadgeCategory.SaleAmount,
  totalCount: BadgeCategory.SaleCount,
  viewCount: BadgeCategory.ViewCount,
};

export const monthVisitCountDivision: number[] = [3, 10, 15, 20, 30];
export const portFolioCountDivision: number[] = [1, 3, 5, 10];

export const resourceCountDivision: number[] = [1, 3, 5];
export const mockupCountDivision: number[] = [10, 15, 25, 35, 50];
export const templateCountDivision: number[] = [10, 15, 25, 35, 50];
export const graphicCountDivision: number[] = [10, 20, 30, 45, 60, 80];
export const webtoonCountDivision: number[] = [10, 15, 25, 35, 50];
export const pictureCountDivision: number[] = [10, 20, 50, 100, 200];

export const totalAmountCountDivision: number[] = [
  3000, 10000, 30000, 50000, 100000, 200000, 500000, 1000000,
];
export const totalCountDivision: number[] = [10, 25, 50, 100];
export const viewCountDivision: number[] = [30, 100, 200, 1000, 10000];

export const BadgeCountDivision = {
  monthVisitCount: monthVisitCountDivision,
  portFolioCount: portFolioCountDivision,
  resourceCount: resourceCountDivision,
  mockupCount: mockupCountDivision,
  templateCount: templateCountDivision,
  graphicCount: graphicCountDivision,
  webtoonCount: webtoonCountDivision,
  pictureCount: pictureCountDivision,
  totalAmountCount: totalAmountCountDivision,
  totalCount: totalCountDivision,
  viewCount: viewCountDivision,
};

export const BadgeNameByWriter = (count: number) => {
  return `작가 첫 출발`;
};

export const BadgeNameByProfilePicture = (count: number) => {
  return `프로필 사진 등록`;
};

export const BadgeNameByVisit = (count: number) => {
  if (count < monthVisitCountDivision[monthVisitCountDivision.length - 1]) {
    return `${count}일째 방문`;
  } else {
    return `매일`;
  }
};

export const BadgeNameByPortfolio = (count: number) => {
  return `${count}rd 포트폴리오`;
};

export const BadgeNameByResource = (count: number) => {
  return `리소스 ${count}건 승인`;
};

export const BadgeNameByMockup = (count: number) => {
  return `목업 ${count}건 승인`;
};

export const BadgeNameByTemplate = (count: number) => {
  return `템플릿 ${count}건 승인`;
};

export const BadgeNameByGraphic = (count: number) => {
  return `그래픽 ${count}건 승인`;
};

export const BadgeNameByWebToon = (count: number) => {
  return `웹툰 ${count}건 승인`;
};

export const BadgeNameByPicture = (count: number) => {
  return `사진 ${count}건 승인`;
};

export const BadgeNameByViewCount = (count: number) => {
  return `조회수 ${count}회 달성`;
};

export const BadgeNameBySaleAmount = (count: number) => {
  return `판매금 ${count.toLocaleString()}원 달성`;
};

export const BadgeNameBySaleCount = (count: number) => {
  return `판매 ${count}회 달성`;
};

export const BadgeNameByCategory = {
  [BadgeCategory.Writer]: BadgeNameByWriter,
  [BadgeCategory.ProfilePicture]: BadgeNameByProfilePicture,
  [BadgeCategory.Visit]: BadgeNameByVisit,
  [BadgeCategory.Portfolio]: BadgeNameByPortfolio,
  [BadgeCategory.Resource]: BadgeNameByResource,
  [BadgeCategory.Mockup]: BadgeNameByMockup,
  [BadgeCategory.Template]: BadgeNameByTemplate,
  [BadgeCategory.Graphic]: BadgeNameByGraphic,
  [BadgeCategory.WebToon]: BadgeNameByWebToon,
  [BadgeCategory.Picture]: BadgeNameByPicture,
  [BadgeCategory.SaleAmount]: BadgeNameBySaleAmount,
  [BadgeCategory.SaleCount]: BadgeNameBySaleCount,
  [BadgeCategory.ViewCount]: BadgeNameByViewCount,
};

export const BadgeCountKeyByResourceCategory = {
  [ResourceCategory.Mockup]: 'mockupCount',
  [ResourceCategory.Template]: 'templateCount',
  [ResourceCategory.Graphic]: 'graphicCount',
  [ResourceCategory.WebToon]: 'webtoonCount',
  [ResourceCategory.Picture]: 'pictureCount',
  [ResourceCategory.Portfolio]: 'portFolioCount',
};

export const badgeThumbnails = {
  [BadgeCategory.Writer]: 'badge-writer',
  [BadgeCategory.ProfilePicture]: 'badge-profile-picture',
  [BadgeCategory.Visit]: 'badge-visit',
  [BadgeCategory.Portfolio]: 'badge-portfolio',
  [BadgeCategory.Resource]: 'badge-resource',
  [BadgeCategory.Mockup]: 'badge-mockup',
  [BadgeCategory.Template]: 'badge-template',
  [BadgeCategory.Graphic]: 'badge-graphic',
  [BadgeCategory.WebToon]: 'badge-webtoon',
  [BadgeCategory.Picture]: 'badge-picture',
  [BadgeCategory.SaleAmount]: 'badge-sale-amount',
  [BadgeCategory.SaleCount]: 'badge-sale-count',
  [BadgeCategory.ViewCount]: 'badge-view-count',
};
