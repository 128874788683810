import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { WndRatio } from '../../types';
import { WndImage } from '../../../../../../../../src/core/types';

@Component({
  selector: 'wnd-preview-image-card',
  templateUrl: './preview-image-card.component.html',
  styleUrls: ['./preview-image-card.component.scss'],
})
export class PreviewImageCardComponent implements OnInit {
  @Input() alt = '이미지';
  @Input() ratio: WndRatio;

  @Input()
  get src(): WndImage {
    return this._src;
  }

  set src(_src: WndImage) {
    this._src = _src;
    setTimeout(() => this.setImageStyle());
  }

  private _src: WndImage;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  setImageStyle() {
    if (!this.ratio || !this.src?.ratio) {
      return;
    }

    const [boxRatioWidth, boxRatioHeight] = this.ratio
      .split('-')
      .map((ratioProperty) => parseInt(ratioProperty, 10));
    const boxPaddingTop = (100 / boxRatioWidth) * boxRatioHeight;

    const imagePaddingTop = parseInt(this.src.paddingTop, 10);

    const className: string = boxPaddingTop < imagePaddingTop ? 'wnd-width' : 'wnd-height';
    const element = this.elementRef.nativeElement.querySelector('.origin-image');
    element.classList.add(className);
  }
}
