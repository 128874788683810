import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../core/base-value-accessor';

@Component({
  selector: 'wnd-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends BaseValueAccessor implements OnInit, OnDestroy {
  @ViewChild('wndInput', { static: true }) wndInput: ElementRef;

  @Input() type = 'text';
  @Input() isSearch = false;
  @Input() isPassword = false;
  @Input() maxLength: number = 999;

  private subscription = new Subscription();

  pattern = '';

  constructor() {
    super();
  }

  override ngOnInit(): void {
    if (this.type === 'number') {
      this.pattern = '\\d*';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    const target = this.wndInput.nativeElement as HTMLInputElement;
    target.value = '';
  }

  togglePassword() {
    const isPassword = this.type === 'password';
    this.type = isPassword ? 'text' : 'password';
  }
}
