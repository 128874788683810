import { Component, OnInit, Input } from '@angular/core';
import {
  Resource,
  ResourceCategory,
  ResourceCategoryText,
  ResourceTypeText,
} from '../../../../../../../../src/entities/resource/types';
import {
  Cart,
  LicenseType,
  LicenseTypeText,
} from '../../../../../../../../src/entities/cart/types';

@Component({
  selector: 'wnd-payment-resource-card',
  templateUrl: './payment-resource-card.component.html',
  styleUrls: ['./payment-resource-card.component.scss'],
})
export class PaymentResourceCardComponent implements OnInit {
  @Input() resource: Resource;

  resourceCategory = ResourceCategory;
  resourceCategoryText = ResourceCategoryText;
  resourceTypeText = ResourceTypeText;

  licenseType = LicenseType;

  constructor() {}

  ngOnInit(): void {}
}
