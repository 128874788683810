import { Component, OnInit, Input } from '@angular/core';
import { WndImage } from '../../../../../../../../src/core/types';

@Component({
  selector: 'profile-image-card',
  templateUrl: './profile-image-card.component.html',
  styleUrls: ['./profile-image-card.component.scss'],
})
export class ProfileImageCardComponent implements OnInit {
  @Input() profileImage: WndImage;
  @Input() emptySrc = '/assets/images/icons/profile-img.png';
  constructor() {}

  ngOnInit(): void {}
}
