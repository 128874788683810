import { BaseEntity } from '../../core/base-entity';
import { CouponHistory } from '../coupon-history/types';
import { Coupon } from '../coupon/types';
import { Resource, ResourceCategory } from '../resource/types';

export interface Cart extends BaseEntity {
  licenseType?: LicenseType;
}

export interface CartItem extends Cart {
  isChecked: boolean;
  isSelectedCoupon: boolean;
  coupon?: CouponHistory;
  resource: Resource;
  discountAmount: number;
}

export enum LicenseType {
  Person = 1,
  Company = 10,
  Expansion = 100,
}

export const LicenseTypeText = {
  [ResourceCategory.WebToon]: {
    [LicenseType.Person]: '개인',
    [LicenseType.Company]: '기업(팀)',
  },
  [ResourceCategory.Picture]: {
    [LicenseType.Person]: '일반',
    [LicenseType.Expansion]: '확장',
  },
};

export const LicenseTypeMultiple = {
  [LicenseType.Person]: 1,
  [LicenseType.Company]: 5,
  [LicenseType.Expansion]: 5,
};
