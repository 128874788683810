import { User } from '../../../../../../src/entities/user/types';

export function createInitialState(): any {
  return {
    emailVerified: false,
    uid: '',
    profile: undefined,
    loading: false,
    initStore: false,
    redirectUrl: '/',
  };
}

export enum AuthLoginState {
  Email = 'email',
  Name = 'name',
  EmailName = 'emailName',
  Verified = 'verified',
  First = 'first',
  Ok = 'ok',
}

export interface AuthLoginPayload {
  state: AuthLoginState;
  user?: User;
}

export type Provider = 'email' | 'facebook' | 'google';
