<div class="popup-dialog-box">
  <a class="popup-dialog-close">
    <img
      wndSrc="/assets/icons/icn-gnb-cancle-n.png"
      alt="cancel icon"
      useHiDpi="true"
      (click)="onFinished.emit()"
    />
  </a>

  <div class="popup-contents-box" (click)="openLink()">
    <wnd-image-card
      *ngIf="popup.type === popupType.Image"
      class="slider-image-full"
      ratio="4-3"
      [src]="popup?.image"
    ></wnd-image-card>

    <article *ngIf="popup.type === popupType.Text" class="popup-text-article">
      {{ popup?.contents }}
    </article>
  </div>

  <div class="popup-footer-box">
    <wnd-checkbox class="circle-checkbox" (click)="popupDisabled()">
      오늘 하루 보지않기
    </wnd-checkbox>
  </div>
</div>
