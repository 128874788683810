import { Injectable } from '@angular/core';
import { Cart } from '../../../../../../../src/entities/cart/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NgCartDb extends FirestoreDbAdapter<Cart> {
  constructor() {
    super(firebase.firestore(), 'carts', { parentCollectionNames: ['users'] });
  }
}
