import { BaseEntity } from 'src/core/base-entity';

export interface TimeLine extends BaseEntity {
  resourceId: string;
  userId?: string;
  text: string; //타임라인 텍스트 값
  eventType: TimeLineEvent; //이벤트 타입
}

export enum TimeLineEvent {
  WndApprove = 1, //관리자의 리소스 승인
  ViewCount = 10, //조회수 특정값 돌파
  AddCart = 20, //장바구니 추가 또는 스크랩 = (관심 likes)
  Buy = 30, //구매
}

export const TimeLineText = {
  [TimeLineEvent.WndApprove]: '🙆‍♀️ 위니디 승인이 완료됐어요.',
  [TimeLineEvent.ViewCount]: '🥳 ${value} 조회수를 돌파했어요.',
  [TimeLineEvent.AddCart]: {
    '1<=x<5': '❤️ ${value}님이 관심을 보이고 있어요.',
    '5<=x<1000': '❤️ ${value}명이 관심을 보이고 있어요.',
    '1000<=x': '❤️ ${value}K 관심을 보이고 있어요.',
  },
  [TimeLineEvent.Buy]: '🎉 ${value}님이 구매했어요.',
};
