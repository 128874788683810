<div class="license-type-box" [class.open]="isOpen">
  <div class="license-item placeholder-item" (click)="toggleOpen()">
    <p class="license-text">
      <strong *ngIf="category === resourceCategory.Picture" class="license-highlight-text"
        >[특가]&nbsp;</strong
      >
      {{ value | licenseTypeToText: category }}
      라이선스
    </p>

    <img
      class="arrow-icon"
      wndSrc="/assets/icons/Icn-24px-select-bottom-n.png"
      alt="arrow bottom icon"
    />
  </div>

  <ul *ngIf="category === resourceCategory.WebToon" class="license-list" [class.open]="isOpen">
    <li class="license-item" (click)="setValue(licenseType.Person)">
      <p class="license-text">개인 라이선스</p>

      <p class="license-text">{{ price | number }}원</p>
    </li>

    <li class="license-item" (click)="setValue(licenseType.Company)">
      <p class="license-text">기업(팀) 라이선스</p>

      <p class="license-text">{{ price * 5 | number }}원</p>
    </li>
  </ul>

  <ul *ngIf="category === resourceCategory.Picture" class="license-list" [class.open]="isOpen">
    <li class="license-item" (click)="setValue(licenseType.Person)">
      <p class="license-text">
        <strong class="license-highlight-text">[특가]</strong>
        &nbsp;일반 라이선스
      </p>

      <p class="license-text">
        <strong class="license-highlight-text">50%</strong>&nbsp;{{ price | number }}원
      </p>
    </li>

    <li class="license-item" (click)="setValue(licenseType.Expansion)">
      <p class="license-text">
        <strong class="license-highlight-text">[특가]</strong>&nbsp;확장 라이선스
      </p>

      <p class="license-text">
        <strong class="license-highlight-text">37%</strong>&nbsp;{{ price * 5 | number }}원
      </p>
    </li>
  </ul>
</div>
