import { Injectable } from '@angular/core';
import { Resource } from '../../../../../../../src/entities/resource/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NgResourceDb extends FirestoreDbAdapter<Resource> {
  constructor() {
    super(firebase.firestore(), 'resources');
  }
}
