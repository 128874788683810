<form class="search-form" [formGroup]="searchFormGroup" (ngSubmit)="search()">
  <wnd-search-input (focusChange)="isFocus = $event" formControlName="keyword"></wnd-search-input>

  <div
    *ngIf="isFocus || isHover"
    @fadeIn
    @fadeOut
    class="search-menu-box"
    (mouseenter)="isHover = true"
    (mouseleave)="onMouseLeave()"
  >
    <wnd-search-menu-box (selectRecentKeyword)="selectRecentKeyword($event)"></wnd-search-menu-box>
  </div>
</form>
