import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  AuthorValidator,
  NameValidator,
  PasswordValidator,
  PhoneNumberValidator,
} from '../../../../../../../weenidy/src/lib/shared/validator/validators';
import { AuthorValidatorService } from '../../../../core/service/author-validator.service';
import { ToggleFormComponent } from '../../../../../../../weenidy/src/lib/shared/form/components/toggle-form/toggle-form.component';
import { User } from '../../../../../../../../src/entities/user/types';
import { NgAuthService } from '../../../../../../../weenidy/src/lib/modules/auth/auth.service';
import { NgUserService } from '../../../../../../../weenidy/src/lib/modules/user/services/user.service';
import {
  DialogComponent,
  WndDialogData,
} from '../../../../../../../weenidy/src/lib/modules/dialog/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import {
  PhoneVerifyFormComponent,
  PhoneVerifyStep,
} from '../../../phone-form/components/phone-verify-form/phone-verify-form.component';
import {
  BirthdayInputComponent,
  BirthdayPayload,
} from '../../../../../../../weenidy/src/lib/shared/form/components/birthday-input/birthday-input.component';
import { WndErrors } from '../../../../../../../weenidy/src/lib/shared/form/directives/form.error';
import { FormErrorService } from '../../../../../../../weenidy/src/lib/shared/form/services/form.error.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SubscriptionBaseComponent } from '../../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';
import { PhoneNumberValidatorService } from 'projects/web/src/app/core/service/phone-number-validator.service';

@Component({
  selector: 'wnd-user-info-form',
  templateUrl: './user-info-form.component.html',
  styleUrls: ['./user-info-form.component.scss'],
})
export class UserInfoFormComponent extends SubscriptionBaseComponent implements OnInit {
  @ViewChild(PhoneVerifyFormComponent) phoneVerifyFormComponent: PhoneVerifyFormComponent;

  @Input() set user(user: User) {
    this.initAuthUser(user);
  }
  @Input() isApply: boolean = true;

  @Output() validChange = new EventEmitter<boolean>();

  // TODO: 국가, 도시, 자기소개, 프로그램, 주요 활동, 태그 추가

  countryFormGroup: FormGroup = this.fb.group({
    country: ['', [NameValidator.pattern, NameValidator.range]],
  });

  cityFormGroup: FormGroup = this.fb.group({
    city: ['', [NameValidator.pattern, NameValidator.range]],
  });

  creatorIntroFormGroup: FormGroup = this.fb.group({
    creatorIntro: ['', [NameValidator.pattern, NameValidator.range]],
  });

  nameFormGroup: FormGroup = this.fb.group({
    name: ['', [NameValidator.pattern, NameValidator.range]],
  });

  authorFormGroup: FormGroup = this.fb.group({
    author: [
      '',
      [AuthorValidator.pattern, AuthorValidator.range],
      [this.authorValidatorService.overlap()],
    ],
  });

  phoneFormGroup: FormGroup = this.fb.group({
    phoneNumber: [
      '',
      [PhoneNumberValidator.pattern, PhoneNumberValidator.range],
      [this.phoneNumberValidatorService.overlap()],
    ],
  });

  genderFormGroup: FormGroup = this.fb.group({
    gender: ['', [Validators.required]],
  });

  birthFormGroup: FormGroup = this.fb.group({
    birth: ['', [Validators.required]],
  });

  passwordFormGroup = this.fb.group(
    {
      currentPassword: ['', [Validators.required, PasswordValidator.pattern]],
      password: ['', [Validators.required, PasswordValidator.pattern]],
      passwordCheck: ['', [Validators.required, PasswordValidator.pattern]],
    },
    {
      validators: [PasswordValidator.matchPassword, PasswordValidator.checkCurrentPassword],
    }
  );

  receiveFormGroup = this.fb.group({
    receive: [],
  });

  currentPasswordErrorText = WndErrors.wrong;

  phoneVerifyStep = PhoneVerifyStep;
  phoneStep = PhoneVerifyStep.Waiting;
  phoneValid = false;

  genderText: string;

  birthdayPayload: BirthdayPayload;
  birthInvalid = true;

  isLoading = false;

  get nameControl() {
    return this.nameFormGroup.get('name');
  }

  get authorControl() {
    return this.authorFormGroup.get('author');
  }

  get phoneNumberControl() {
    return this.phoneFormGroup.get('phoneNumber');
  }

  get genderControl() {
    return this.genderFormGroup.get('gender');
  }

  get birthControl() {
    return this.birthFormGroup.get('birth');
  }

  get receiveControl() {
    return this.receiveFormGroup.get('receive');
  }

  constructor(
    private fb: FormBuilder,
    private authorValidatorService: AuthorValidatorService,
    private authService: NgAuthService,
    private userService: NgUserService,
    private formErrorService: FormErrorService,
    private dialog: MatDialog,
    private phoneNumberValidatorService: PhoneNumberValidatorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setSubscription('_initFormValidation', this.initFormValidation());
  }

  resetData(formGroup: FormGroup, keys: string[]): void {
    const user = this.authService.user;

    const value = keys.reduce((prev, curr) => {
      prev[curr] = user[curr] || '';
      return prev;
    }, {});

    formGroup.setValue(value);
  }

  updateUserInfo(formGroup: FormGroup, toggleFormComponent: ToggleFormComponent): void {
    this.isLoading = true;

    const id = this.authService.id;
    const doc: Partial<User> = formGroup.value;

    // TODO: error 에 대한 대응 필요?
    this.userService.update(id, doc).subscribe(() => {
      toggleFormComponent.isChange = false;
      this.isLoading = false;
    });
  }

  updateAuthorInfo(formGroup: FormGroup, toggleFormComponent: ToggleFormComponent): void {
    this.isLoading = true;

    if (this.authService.user.authorChangedAt && this.calcDate() < 30) {
      this.openAuthorAlertDialog();
      this.resetData(formGroup, ['author']);
      toggleFormComponent.isChange = false;
      this.isLoading = false;
      return;
    }

    const id = this.authService.id;
    const doc: Partial<User> = formGroup.value;
    doc.authorChangedAt = new Date();

    this.userService.update(id, doc).subscribe(() => {
      toggleFormComponent.isChange = false;
      this.isLoading = false;
    });
  }

  calcDate(): number {
    const today = moment();
    const authorChangedAt = moment(this.authService.user.authorChangedAt);

    return today.diff(authorChangedAt, 'days');
  }

  resetPhoneVerify(): void {
    this.phoneVerifyFormComponent.resetForm();
    this.phoneStep = this.phoneVerifyStep.Waiting;
  }

  changeStep(): void {
    this.phoneStep = PhoneVerifyStep.SendMessage;
  }

  verifyStep(): void {
    this.phoneVerifyFormComponent.verifyPhoneNumber();
  }

  resetBirthday(birthdayInputComponent: BirthdayInputComponent): void {
    birthdayInputComponent.birthday = this.authService.user.birth;
  }

  setBirthdayPayload(event: BirthdayPayload) {
    this.birthdayPayload = event;
  }
  updateBirthday(toggleFormComponent: ToggleFormComponent): void {
    const id = this.authService.id;
    const doc: Partial<User> = this.birthdayPayload;
    doc.birth = `${doc.birthYear}-${doc.birthMonth}-${doc.birthDate}`;

    this.userService.update(id, doc).subscribe(() => {
      console.log(doc);
      toggleFormComponent.close();
    });
  }

  phoneVerifyComplete(phoneNumber: string, toggleFormComponent: ToggleFormComponent): void {
    this.phoneNumberControl.setValue(phoneNumber);
    toggleFormComponent.close();
  }

  updateLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  resetPasswordFormGroup(): void {
    this.passwordFormGroup.reset();

    Object.keys(this.passwordFormGroup.controls).forEach((key) => {
      this.passwordFormGroup.get(key).markAsPristine();
      this.passwordFormGroup.get(key).updateValueAndValidity();
    });
  }

  updatePassword(toggleFormComponent: ToggleFormComponent): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const currentPassword = this.passwordFormGroup.get('currentPassword').value;
    const password = this.passwordFormGroup.get('password').value;

    this.authService
      .updatePassword(currentPassword, password)
      .toPromise()
      .then(() => {
        this.isLoading = false;
        toggleFormComponent.isChange = false;
        this.resetPasswordFormGroup();
      })
      .catch(() => {
        this.isLoading = false;
        this.passwordFormGroup.setErrors({ wrong: true });
      });
  }

  getErrorMessage(error: ValidationErrors | null) {
    return this.formErrorService.getErrorMessage(error);
  }

  private openAuthorAlertDialog(): void {
    this.dialog.open<DialogComponent, WndDialogData>(DialogComponent, {
      disableClose: true,
      data: {
        title: '작가명 변경 제한',
        contents: '작가명은 변경 또는 등록 후 <br/>30일 이후 변경이 가능합니다.',
        type: 'waring',
        primaryButtonText: '확인',
        isHideSecondaryButtonText: true,
      },
    });
  }

  private initAuthUser(user: User): void {
    if (user?.name) {
      this.nameControl.setValue(user.name);
    }

    if (user?.author) {
      this.authorControl.setValue(user.author);
    }

    if (user?.phoneNumber) {
      this.phoneNumberControl.setValue(user.phoneNumber);
    }

    if (user?.gender) {
      this.genderControl.setValue(user.gender);
      this.genderText = user.gender === 'man' ? '남자' : '여자';
    }

    if (user?.birth) {
      this.birthControl.setValue(user.birth);
    }

    if (typeof user?.receive === 'boolean') {
      this.receiveControl.setValue(user.receive);
    }
  }

  private initFormValidation() {
    return combineLatest([
      this.nameFormGroup.statusChanges.pipe(map((status) => status === 'VALID')),
      this.authorFormGroup.statusChanges.pipe(map((status) => status === 'VALID')),
      this.phoneFormGroup.statusChanges.pipe(map((status) => status === 'VALID')),
      this.genderFormGroup.statusChanges.pipe(map((status) => status === 'VALID')),
      this.birthFormGroup.statusChanges.pipe(map((status) => status === 'VALID')),
    ])
      .pipe(
        map((validArray: boolean[]) => validArray.every((valid) => Boolean(valid))),
        distinctUntilChanged()
      )
      .subscribe((formValid: boolean) => {
        this.validChange.emit(formValid);
      });
  }
}
