import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ToolbarButtonOverlayService } from '../services/toolbar-button-overlay.service';

@Directive({
  selector: '[wndToolbarButtonMenu]',
})
export class ToolbarButtonMenuDirective {
  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    this.openToolbarButtonMenu();
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.openToolbarButtonMenu();
  }

  @Input() position: string = 'bottom';

  constructor(
    private elementRef: ElementRef,
    private toolbarButtonOverlayService: ToolbarButtonOverlayService
  ) {}

  private openToolbarButtonMenu(): void {
    this.toolbarButtonOverlayService.setPosition(this.position);

    if (!this.toolbarButtonOverlayService.overlayRef) {
      this.toolbarButtonOverlayService.init(this.elementRef);
    }

    this.toolbarButtonOverlayService.open(this.elementRef);
  }
}
