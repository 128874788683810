import { Injectable, Inject } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Environment } from '../../../../../../../src/core/environment';
import { AlgoliaSearchAdapter } from '../../../../../../../src/modules/aloglia/algolia-search.adapter';
import { ENVIRONMENT } from '../../../core/tokens';

@Injectable({
  providedIn: 'root'
})
export class NgResourceAlgoliaSearch extends AlgoliaSearchAdapter {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    super(
      algoliasearch(environment.algolia.appId, environment.algolia.searchKey) as any,
      'resource',
      ['createdAt', 'modifiedAt', 'examinedAt', 'confirmedAt', 'modifyAt', 'refusedAt', 'blockedAt', 'deletedAt']
    );
  }
}
