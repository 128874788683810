import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import {
  OVERLAY_DATA_TOKEN,
  OverlayComponent,
} from '../../../../../../../../src/modules/overlay/overlay.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { fadeIn, fadeOut } from '../../../../layout/components/toolbar/animation';
import { WndDialogService } from '../../../../../../../weenidy/src/lib/modules/dialog/service/wnd-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'wnd-toolbar-overlay-button',
  templateUrl: './toolbar-overlay-button.component.html',
  styleUrls: ['./toolbar-overlay-button.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class ToolbarOverlayButtonComponent extends OverlayComponent implements OnInit {
  isClosing = true;
  position = 'bottom';
  constructor(
    @Inject(OVERLAY_DATA_TOKEN) public data: { elementRef: ElementRef; position: string },
    private elementRef: ElementRef,
    private dialogService: WndDialogService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.position = this.data.position;

    this.setSubscription('_initMouseMoveEvent', this.initMouseMoveEvent());
    this.setSubscription('_initScrollEvent', this.initScrollEvent());
  }

  moveResource(): void {
    this.dialogService.openSelectCategoryDialog();
    this.close();
  }

  movePortfolio(): void {
    this.router.navigateByUrl('/upload/portfolio').then();
    this.close();
  }

  private initMouseMoveEvent(): Subscription {
    return fromEvent(document, 'mousemove')
      .pipe(debounceTime(250))
      .subscribe((e: MouseEvent) => {
        if (
          !(this.elementRef.nativeElement as HTMLElement).contains(e.target as HTMLElement) &&
          !(this.data.elementRef.nativeElement as HTMLElement).contains(e.target as HTMLElement)
        ) {
          this.close();
        }
      });
  }

  private initScrollEvent(): Subscription {
    return fromEvent(window, 'scroll').subscribe(() => {
      this.close();
    });
  }

  private close(): void {
    this.isClosing = false;
    setTimeout(() => {
      this.onFinished.emit();
    }, 200);
  }
}
