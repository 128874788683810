import { SearchAdapter } from '../../core/search/search.adapter';
import { User, WriterRank } from './types';
import { ColdObservable, ColdObservableOnce, HotObservableOnce } from '../../core/types';
import { DbAdapter } from '../../core/db/db.adapter';
import { SearchQuery, SearchResponse } from '../../core/search/types';
import { map, switchMap } from 'rxjs';
import { DbListResponse, DbOptions, DbQuery } from '../../core/db/types';
import { throwError } from 'rxjs';

export class UserService {
  constructor(
    private projectId: string,
    protected userSearch: SearchAdapter<User>,
    protected userDb: DbAdapter<User>
  ) {}

  getUserFromEmailAndUpdateWriterRank(
    email: string,
    writerRank: WriterRank
  ): ColdObservableOnce<string> {
    return this.userDb
      .list({
        filters: [{ field: 'email', comparison: '==', value: email }],
        limit: 1,
      })
      .pipe(
        switchMap((response: DbListResponse<User>) => {
          if (response.count > 0) {
            return this.userDb.update(response.docs[0].id, { writerRank }).pipe(map(() => email));
          } else {
            return throwError(email);
          }
        })
      );
  }

  getUserFromEmail(email: string): ColdObservableOnce<User> {
    return this.userDb
      .list({
        filters: [{ field: 'email', comparison: '==', value: email }],
        limit: 1,
      })
      .pipe(map((response: DbListResponse<User>) => response.docs[0]));
  }

  search(query: SearchQuery): ColdObservable<SearchResponse<User>> {
    return this.userSearch.search(query);
  }

  update(id: string, update: Partial<User>): HotObservableOnce<void> {
    return this.userDb.update(id, update);
  }

  get(id: string): ColdObservableOnce<User> {
    return this.userDb.get(id);
  }

  getChange(id: string, options?: DbOptions): ColdObservable<User> {
    return this.userDb.getChange(id, options);
  }

  getMany(ids: string[]): ColdObservableOnce<User[]> {
    return this.userDb.getMany(ids);
  }

  getOverviewData(): ColdObservableOnce<any> {
    const now = new Date();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
    const lastLastMonth = new Date(now.getFullYear(), now.getMonth() - 2);

    const countQuery = `WITH
  users AS (
    SELECT
      DISTINCT document_id,
      createdAt,
      
    FROM \`${this.projectId}.firestore_export.users_schema_schema_20220809_latest\`
    WHERE 
      date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1)
  )
SELECT
  count(case when date(createdAt, '+09') = date(${yesterday.getFullYear()}, ${
      yesterday.getMonth() + 1
    }, ${yesterday.getDate()}) then createdAt end) as yesterday,
  count(case when date(createdAt, '+09') = date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, ${now.getDate()}) then createdAt end) as today,
  count(case when date(createdAt, '+09') >= date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, 1) then createdAt end) as month,
  ROUND(
    SAFE_DIVIDE(
      count(case when date(createdAt, '+09') >= date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${now.getFullYear()}, ${
      now.getMonth() + 1
    }, 1) then createdAt end) - count(case when date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) then createdAt end),
      count(case when date(createdAt, '+09') >= date(${lastLastMonth.getFullYear()}, ${
      lastLastMonth.getMonth() + 1
    }, 1) AND date(createdAt, '+09') < date(${lastMonth.getFullYear()}, ${
      lastMonth.getMonth() + 1
    }, 1) then createdAt end)
    ) * 100,
    2
  ) as ratio
FROM
  users`;

    return this.userSearch.query(countQuery);
  }

  getUserAggregation(option: 'date' | 'month' | 'year', startAt: Date, endEt?: Date) {
    const query = `WITH
  users as (
    SELECT
      DISTINCT document_id,
      createdAt,
      withdrawAt
    FROM \`${this.projectId}.firestore_export.users_schema_schema_20220809_latest\`
  ),
  createUsers AS (
    SELECT
      ${
        option === 'year' || option === 'month' ? 'LEFT(' : ''
      }FORMAT_DATE('%F', DATE(createdAt, '+09'))${
      option === 'year' ? ', 4)' : option === 'month' ? ', 7)' : ''
    } as date,
      COUNT(createdAt) as joinCount,
    FROM users
    WHERE 
      ${
        endEt
          ? `date(createdAt, '+09') <= date(${endEt.getFullYear()}, ${
              endEt.getMonth() + 1
            }, ${endEt.getDate()}) AND`
          : ''
      }
      date(createdAt, '+09') >= date(${startAt.getFullYear()}, ${
      startAt.getMonth() + 1
    }, ${startAt.getDate()})
    GROUP BY date
  ),
  withdrawUsers AS (
    SELECT
      ${
        option === 'year' || option === 'month' ? 'LEFT(' : ''
      }FORMAT_DATE('%F', DATE(withdrawAt, '+09'))${
      option === 'year' ? ', 4)' : option === 'month' ? ', 7)' : ''
    } as date,
      COUNT(withdrawAt) as withdrawCount
    FROM users
    WHERE 
      ${
        endEt
          ? `date(withdrawAt, '+09') <= date(${endEt.getFullYear()}, ${
              endEt.getMonth() + 1
            }, ${endEt.getDate()}) AND`
          : ''
      }
      date(withdrawAt, '+09') >= date(${startAt.getFullYear()}, ${
      startAt.getMonth() + 1
    }, ${startAt.getDate()})
    GROUP BY date
  )
SELECT
  date,
  createUsers.joinCount,
  withdrawUsers.withdrawCount
FROM createUsers FULL JOIN withdrawUsers USING (date)
ORDER BY date ASC`;

    return this.userSearch.query(query);
  }

  add(user: Partial<User>): ColdObservableOnce<User> {
    return this.userDb.add(user);
  }

  increase(id: string, fieldName: keyof User, increaseNumber: number): HotObservableOnce<void> {
    return this.userDb.increase(id, fieldName, increaseNumber);
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservableOnce<DbListResponse<User>> {
    return this.userDb.list(query, options);
  }
}
