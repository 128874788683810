import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';
import { TimeLine } from 'src/entities/time-line/types';

@Injectable({
  providedIn: 'root',
})
export class NgTimeLineDb extends FirestoreDbAdapter<TimeLine> {
  constructor() {
    super(firebase.firestore(), 'time-lines');
  }
}
