import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../../../../../../src/entities/user/types';

@Component({
  selector: 'wnd-user-info-profile-card',
  templateUrl: './user-info-profile-card.component.html',
  styleUrls: ['./user-info-profile-card.component.scss'],
})
export class UserInfoProfileCardComponent implements OnInit {
  @Input()
  set user(user: User) {
    if (user) {
      this.name = user.name;
      this.email = user.email;
      this.unknownName = user.email.split('@')[0];
    }
  }

  name = '';
  email = '';
  unknownName = '';

  constructor() {}

  ngOnInit(): void {}
}
