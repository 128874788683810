import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResourceCategorySeletorDialogComponent } from '../../../../../../../src/components/dialog/components/resource-category-seletor-dialog/resource-category-seletor-dialog.component';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ResourceCategory } from '../../../../../../../src/entities/resource/types';
import { BadgeDialogComponent } from '../components/badge-dialog/badge-dialog.component';
import { CouponDetailDialogComponent } from '../components/coupon-detail-dialog/coupon-detail-dialog.component';
import { Coupon } from 'src/entities/coupon/types';
import { Environment } from 'src/core/environment';

@Injectable({
  providedIn: 'root',
})
export class WndDialogService {
  constructor(private dialog: MatDialog, private router: Router) {}

  openSelectCategoryDialog(id?: string) {
    this.dialog
      .open(ResourceCategorySeletorDialogComponent, {})
      .afterClosed()
      .pipe(filter((value) => Boolean(value)))
      .subscribe((category) => {
        const url =
          parseInt(category, 10) === ResourceCategory.Picture
            ? '/upload/media'
            : '/upload/resource';

        if (id) {
          this.router.navigate([url, id], { queryParams: { category } }).then();
        } else {
          this.router.navigate([url], { queryParams: { category } }).then();
        }
      });
  }

  openBadgeDialog(): MatDialogRef<BadgeDialogComponent> {
    return this.dialog.open(BadgeDialogComponent, {});
  }

  openCouponDetailDialog(coupon: { coupon: Coupon }): MatDialogRef<CouponDetailDialogComponent> {
    return this.dialog.open(CouponDetailDialogComponent, { data: coupon });
  }
}
