import { Directive, Renderer2, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[wndFlexibleClass]',
})
export class FlexibleClassDirective {
  @Input()
  set paddingTop(paddingTop: string) {
    let paddingTopNumber = parseInt(paddingTop, 10);

    this.setClass(paddingTopNumber > 100 ? 'wnd-flexible-height' : 'wnd-flexible-width');
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  private setClass(className: string): void {
    const nativeElement = this.elementRef.nativeElement;
    this.renderer.addClass(nativeElement, className);
  }
}
