import {
  Component,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseValueAccessor } from '../../core/base-value-accessor';

@Component({
  selector: 'wnd-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends BaseValueAccessor implements OnInit {
  @ViewChild('wndInput', { static: true }) wndInput: ElementRef;
  @Input() type: 'box' | 'none-box' = 'box';
  @Output() checkedChange = new EventEmitter<any>();

  constructor() {
    super();
  }

  override ngOnInit(): void {}

  override get value() {
    return this.data;
  }

  override set value(data: any) {
    if (data !== undefined && this.data !== data) {
      this.data = data;

      if (this.onTouch) {
        this.onTouch(data);
      }
    }
  }

  clickLabel() {
    const element: HTMLInputElement = this.wndInput.nativeElement as HTMLInputElement;
    element.checked = !element.checked;

    this.value = element.checked;
    if (this.onChange) {
      this.onChange(element.checked);
    }
    this.checkedChange.emit(element.checked);
  }
}
