import { Pipe } from '@angular/core';


@Pipe({ name: 'krwSeparator' })
export class KrwSeparatorPipe {
  constructor() {
  }

  transform(money: number): string {
    return moneyConvertKrwSeparator(money);
  }
}


export function moneyConvertKrwSeparator(money: number): string {
  if (!money) {
    return '0';
  }

  const moneyString = money.toString();
  const length: number = moneyString.length;

  if (length <= 3) {
    return moneyString;
  }

  const moneyStringArray: string[] = [];

  for (let i = length; i > 0; i -= 3) {
    const startNumber = i - 3;
    const start = startNumber > 0 ? startNumber : 0;
    moneyStringArray.unshift(moneyString.slice(start, i));
  }

  return moneyStringArray.join(',');
}
