import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UrlService } from '../../../core/service/url.service';


@Component({
  selector: 'wnd-layout-page',
  templateUrl: './layout-page.component.html',
  styleUrls: ['./layout-page.component.scss'],
})
export class LayoutPageComponent implements OnInit {
  isGnb$: Observable<boolean> = this.urlService.isGnb$;
  isSearch$: Observable<boolean> = this.urlService.isSearch$;
  isSearchKeyword$: Observable<boolean> = this.urlService.isSearchKeyword$;
  isOpenMenu = false;

  constructor(
    private urlService: UrlService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.router
      .events
      .pipe(
        filter(e => e instanceof NavigationStart)
      )
      .subscribe(() => {
        this.isOpenMenu = false;
        document.body.classList.remove('open');
      });
  }

  toggleMenu(isOpen: boolean) {
    this.isOpenMenu = isOpen;

    if (isOpen) {
      document.body.classList.add('open');
    } else {
      document.body.classList.remove('open');
    }
  }
}
