import { Pipe, PipeTransform } from '@angular/core';
import { BadgeCategory, badgeThumbnails } from '../../../../../../../src/entities/badge/types';

@Pipe({
  name: 'badgeCountThumbnail',
})
export class BadgeCountThumbnailPipe implements PipeTransform {
  transform(category: BadgeCategory, count: number): unknown {
    if (category === BadgeCategory.Visit && count === 30) {
      return `/assets/badge-count/check-icon.png`;
    } else if (category === BadgeCategory.SaleAmount) {
      const saleCount = count / 10000;
      return `/assets/badge-count/${saleCount}.png`;
    } else {
      return `/assets/badge-count/${count}.png`;
    }
  }
}
