import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  isGnb$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isSearch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isSearchKeyword$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private gnbReg = new RegExp(
    /[^=]index|resource\/list|search\/list|resource\/search|my-page\/coupon-list/
  );
  private indexReg = new RegExp(/[^=]search/);
  private searchKeywordReg = new RegExp(/[^=]search\/keyword/);

  constructor(private router: Router) {}

  initTrackingNavigationEnd(): void {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.checkIsGnb(e.urlAfterRedirects);
        this.checkIsSearch(e.urlAfterRedirects);
        this.checkIsSearchKeyword(e.urlAfterRedirects);
      });
  }

  private checkIsGnb(url: string) {
    this.isGnb$.next(this.gnbReg.test(url));
  }

  private checkIsSearch(url: string) {
    this.isSearch$.next(this.indexReg.test(url));
  }

  private checkIsSearchKeyword(url: string) {
    this.isSearchKeyword$.next(this.searchKeywordReg.test(url));
  }
}

export enum UrlNavigation {
  DEFAULT = 0,
  SEARCH = 10,
}
