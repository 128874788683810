import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'wnd-tags-text',
  templateUrl: './tags-text.component.html',
  styleUrls: ['./tags-text.component.scss']
})
export class TagsTextComponent implements OnInit {
  @Input() tags: string[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
