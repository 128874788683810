import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { WndErrors } from '../directives/form.error';

@Injectable({ providedIn: 'root' })
export class FormErrorService {
  constructor() {}

  getErrorMessage(error: ValidationErrors | null) {
    if (!error) {
      return undefined;
    }

    const key = Object.keys(error)[0];
    return WndErrors[key];
  }

  getControlError(controlName: string, error: ValidationErrors) {
    if (!controlName || !error) {
      return undefined;
    }

    const key = Object.keys(error)[0];
    return WndErrors[controlName][key];
  }
}
