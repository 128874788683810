import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Content } from '../../../../../../../src/entities/user/types';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';

@Injectable({
  providedIn: 'root'
})
export class NgContentDb extends FirestoreDbAdapter<Content> {
  constructor() {
    super(firebase.firestore(), 'contents', { parentCollectionNames: ['users'] });
  }
}
