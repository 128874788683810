import { Input, OnInit, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export class BaseValueAccessor implements ControlValueAccessor, OnInit {
  @Input() id: string;
  @Input() placeholder = '';
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() valid: boolean;

  protected onChange;
  protected onTouch;
  data: any = '';

  ngOnInit() {}

  get value() {
    return this.data;
  }

  set value(data: any) {
    if (data !== undefined && this.data !== data) {
      this.data = data;

      if (this.onChange) {
        this.onChange(data);
      }

      if (this.onTouch) {
        this.onTouch(data);
      }
    }
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
