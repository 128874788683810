import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'wnd-mobile-toolbar-search',
  templateUrl: './mobile-toolbar-search.component.html',
  styleUrls: ['./mobile-toolbar-search.component.scss']
})
export class MobileToolbarSearchComponent implements OnInit {
  @Output() back = new EventEmitter();
  @Output() search = new EventEmitter();

  formGroup: FormGroup = this.createFormGroup();

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
  }


  onBack() {
    this.back.emit();
  }

  onSearch() {
    this.search.emit(this.formGroup.value.keyword);
  }

  onClean() {
    this.formGroup.setValue({ keyword: '' });
    this.search.emit();
  }


  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      keyword: ['']
    });
  }
}
