import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GraphicBaseProgramRadioItems,
  MockupBaseProgramRadioItems,
  ResourceAuthorRadioItems,
  ResourceAuthorText,
  ResourceCategory,
  ResourceLicenseRadioItems,
  ResourceLicenseText,
  ResourcePhotoDirectionRadioItems,
  ResourcePhotoDirectionText,
  ResourcePhotoExtensionRadioItems,
  ResourcePhotoExtensionText,
  ResourcePriceRadioItems,
  ResourcePriceText,
  TemplateBaseProgramRadioItems,
  WebtoonBaseProgramRadioItems,
} from '../../../../../../../../src/entities/resource/types';
import { Option } from '../../../../../../../../src/core/types';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchFilter, SearchKeyword } from '../../../../pages/resource-page/types';
import { SubscriptionBaseComponent } from '../../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wnd-resource-search-filter',
  templateUrl: './resource-search-filter.component.html',
  styleUrls: ['./resource-search-filter.component.scss'],
})
export class ResourceSearchFilterComponent extends SubscriptionBaseComponent implements OnInit {
  @Input() isDialog: boolean = false;
  @Input()
  set category(category: ResourceCategory) {
    this.setBaseProgram(category);
    this.setPicture(category);
  }

  @Input()
  set searchFilter(searchFilter: SearchFilter) {
    this.formGroup.patchValue(searchFilter, { emitEvent: false });
    this.setSearchFilterKeyword();
  }

  @Output() onCloseFilter: EventEmitter<Partial<SearchFilter>> = new EventEmitter<
    Partial<SearchFilter>
  >();
  @Output() onFilterChange: EventEmitter<Partial<SearchFilter>> = new EventEmitter<
    Partial<SearchFilter>
  >();

  formGroup: FormGroup = this.createFormGroup();
  filterKeywords: SearchKeyword[] = [];

  authorRadioItems: Option[] = ResourceAuthorRadioItems;
  priceRadioItems: Option[] = ResourcePriceRadioItems;
  licenseRadioItems: Option[] = ResourceLicenseRadioItems;
  baseProgramRadioItems: Option[];
  photoExtensionRadioItems: Option[] = ResourcePhotoExtensionRadioItems;
  photoDirectionRadioItems: Option[] = ResourcePhotoDirectionRadioItems;
  isShowPicture: boolean = true;

  authorText = ResourceAuthorText;
  priceText = ResourcePriceText;
  licenseText = ResourceLicenseText;
  photoExtensionText = ResourcePhotoExtensionText;
  photoDirectionText = ResourcePhotoDirectionText;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.setSubscription('_initFormGroupValueChange', this.initFormGroupValueChange());
  }

  removeSearchKeyword(searchKeyword: SearchKeyword): void {
    const { type, value } = searchKeyword;

    if (type === 'keyword') {
      this.formGroup.patchValue({ keyword: null });
      return;
    }

    const values = this.formGroup.get(type).value.filter((innerValue) => innerValue !== value);

    this.formGroup.patchValue({ [type]: values });
  }

  resetFilter(): void {
    this.formGroup.reset();
  }

  closeFilter(): void {
    this.onCloseFilter.emit(this.formGroup.value);
  }

  private setSearchFilterKeyword(): void {
    const formValue = this.formGroup.value;

    this.filterKeywords = [];

    if (formValue.keyword) {
      this.filterKeywords.push({
        type: 'keyword',
        value: formValue.keyword,
        text: formValue.keyword,
      });
    }

    Object.keys(formValue)
      .filter((key) => formValue[key] !== null && key !== 'keyword')
      .forEach((key) => {
        console.log('formValue', formValue);
        console.log('key', key);

        Object.values(formValue[key]).forEach((value) => {
          console.log('value', value);
          this.filterKeywords.push({
            type: key,
            value,
            text: this[key + 'Text'] ? this[key + 'Text'][value] : value,
          });
        });
      });
  }

  private initFormGroupValueChange(): Subscription {
    return this.formGroup.valueChanges.subscribe((value) => {
      this.onFilterChange.emit(value);
    });
  }

  private createFormGroup(): FormGroup {
    return this.fb.group({
      keyword: [null],
      author: [null],
      price: [null],
      license: [null],
      baseProgram: [null],
      photoExtension: [null],
      photoDirection: [null],
    });
  }

  private setBaseProgram(category: ResourceCategory): void {
    if (category === ResourceCategory.Mockup) {
      this.baseProgramRadioItems = MockupBaseProgramRadioItems;
    } else if (category === ResourceCategory.Template) {
      this.baseProgramRadioItems = TemplateBaseProgramRadioItems;
    } else if (category === ResourceCategory.Graphic) {
      this.baseProgramRadioItems = GraphicBaseProgramRadioItems;
    } else if (category === ResourceCategory.WebToon) {
      this.baseProgramRadioItems = WebtoonBaseProgramRadioItems;
    } else {
      this.baseProgramRadioItems = undefined;
    }
  }

  private setPicture(category: ResourceCategory): void {
    this.isShowPicture = category !== ResourceCategory.Picture;
  }
}
