<section class="footer-box-section">
  <div class="footer-box-pc">
    <div class="left-box">
      <nav class="footer-nav">
        <ul class="footer-list">
          <li class="footer-item" (click)="openNotion('notice')">공지사항</li>
          <li class="footer-division"></li>
          <li class="footer-item" (click)="openNotion('term')">이용약관</li>
          <li class="footer-division"></li>
          <li class="footer-item" (click)="openNotion('writer')">작가약관</li>
          <li class="footer-division"></li>
          <li class="footer-item" (click)="openNotion('person')">개인정보취급방침</li>
          <li class="footer-division"></li>
          <li class="footer-item" (click)="openNotion('license')">라이선스</li>
          <li class="footer-division"></li>
          <li class="footer-item" (click)="openNotion('resource')">리소스 제작 가이드</li>
        </ul>
      </nav>

      <div class="footer-contents-box">
        <img
          src="assets/images/icons/weenidy-symbol-gray.png"
          alt="그레이 로고"
          class="logo-image"
        />
        <div class="company-info-box">
          <p>
            서울특별시 중구 퇴계로 173(충무로3가, 남산스퀘어 14층) | 사업자 번호 203-81-61115 |
            통신판매업번호 2021-서울중구-2417
          </p>
          <p>
            상호명 (주)케이피알앤드어소시에이츠 | 대표이사 신성인 | 개인정보보호관리자 김은용 |
            메일문의 cs@weenidy.com
          </p>

          <p class="inc-text">ⓒ2021 KPR & Associates, Inc.</p>
        </div>
      </div>
    </div>

    <div class="right-box">
      <div *ngIf="sns$ | async as sns" class="sns-box">
        <a *ngIf="sns?.facebook" [href]="sns.facebook" target="_blank">
          <img
            class="sns-icon"
            wndSrc="/assets/icons/facebook_gray_26px.png"
            alt="right arrow icon"
            [useHiDpi]="true"
          />
        </a>
        <a *ngIf="sns?.instagram" [href]="sns.instagram" target="_blank">
          <img
            class="sns-icon"
            wndSrc="/assets/icons/facebook_gray_26px copy.png"
            alt="right arrow icon"
            [useHiDpi]="true"
          />
        </a>

        <a *ngIf="sns?.pinterest" [href]="sns.pinterest" target="_blank">
          <img
            class="sns-icon"
            wndSrc="/assets/icons/facebook_gray_26px copy 12.png"
            alt="right arrow icon"
            [useHiDpi]="true"
          />
        </a>

        <a *ngIf="sns?.behance" [href]="sns.behance" target="_blank">
          <img
            class="sns-icon"
            wndSrc="/assets/icons/facebook_gray_26px copy 13.png"
            alt="right arrow icon"
            [useHiDpi]="true"
          />
        </a>
      </div>
      <p class="center-call-text">고객센터 cs@weenidy.com</p>
      <p class="center-info-text">평일 10:00~19:00 토/일 휴무</p>
    </div>
  </div>
</section>

<div class="footer-box-mobile">
  <hr class="wnd-divider" />
  <article class="footer-article">
    <p>
      <span (click)="openNotion('notice')">공지사항</span> |
      <span (click)="openNotion('term')">이용약관</span> |
      <span (click)="openNotion('writer')">작가약관</span> |
      <span (click)="openNotion('person')">개인정보취급방침</span> |
      <span (click)="openNotion('license')">라이선스</span> |
    </p>
  </article>

  <p class="footer-inc-text">ⓒ2021 KPR & Associates, Inc.</p>
</div>
