import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { HammerConfig } from '../../../config/hammer';
import { BrowserService } from '../../../../shared/services/browser.service';
import { ENVIRONMENT } from '../../../../core/tokens';
import { Environment } from '../../../../../../../../src/core/environment';
import { MainBannerItem } from "../../../../../../../../src/entities/setting/types";

@Component({
  selector: 'wnd-slider-banner-full',
  templateUrl: './slider-banner-full.component.html',
  styleUrls: ['./slider-banner-full.component.scss'],
})
export class SliderBannerFullComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sliderElement') sliderElement: ElementRef;

  @Input()
  get images(): MainBannerItem[] {
    return this._images;
  }

  set images(images: MainBannerItem[]) {
    if (!images) {
      return;
    }

    this._images = images;
    this.startIndex = 0;
    this.lastIndex = images.length - 1;

    this.firstBanner = images[this.startIndex];
    this.lastBanner = images[this.lastIndex];
  }

  @Output() bannerClick = new EventEmitter<string>();

  currIndex = 1;
  private interval;

  private subscription = new Subscription();
  private readyEvent = false;

  private currentTranslateX: number;
  private swipeState: 'none' | 'left' | 'right' = 'none';

  private imageWidth: number;
  private wrapperBox: number;

  private _images: MainBannerItem[];
  startIndex: number;
  lastIndex: number;

  firstBanner: MainBannerItem;
  lastBanner: MainBannerItem;
  isProd = this.environment.production;

  private panStart = false;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private browserService: BrowserService
  ) {}

  trackByFn(index: number, item: any) {
    return item.id;
  }

  ngOnInit() {
    if (this.browserService.isBrowser) {
      this.initInterval();
    }
  }

  ngAfterViewInit(): void {
    if (this.browserService.isBrowser) {
      const sliderImageElement: HTMLElement = document.querySelector(
        '.slider-image-full'
      ) as HTMLElement;

      if (sliderImageElement) {
        this.initEvent(sliderImageElement);
      }

      fromEvent(window, 'resize')
        .pipe(
          debounceTime(50),
          map(() => window.innerWidth < 730)
        )
        .subscribe(() => {
          this.imageWidth = sliderImageElement.getBoundingClientRect().width;
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initEvent(imageElement: HTMLElement) {
    this.imageWidth = imageElement.getBoundingClientRect().width;
    this.wrapperBox = this.imageWidth * (this.images.length - 1);

    const hammerConfig = new HammerConfig();
    const hammer = hammerConfig.buildHammer(this.sliderElement.nativeElement);

    this.subscription.add(this.initPanStart(hammer));
    // this.subscription.Add(this.initPan(hammer));
    this.subscription.add(this.initPanEnd(hammer));
    this.subscription.add(this.initSwipeLeft(hammer));
    this.subscription.add(this.initSwipeRight(hammer));

    this.readyEvent = true;
  }

  changeIndex(index: number) {
    if (index > this.lastIndex + 1) {
      this.currIndex = 1;
      this.syncChange(index, this.currIndex);
      return;
    } else if (index <= 0) {
      this.currIndex = this.lastIndex + 1;
      this.syncChange(index, this.currIndex);
      return;
    } else {
      this.currIndex = index;
    }

    this.change(this.currIndex);
  }

  imageClick(url: string) {
    this.bannerClick.emit(url);
  }

  private initInterval() {
    this.interval = setInterval(() => {
      this.elementAddTransitionDuration(300);
      if (this.currIndex > this.lastIndex) {
        this.currIndex = 1;
        this.syncChange(this.lastIndex + 2, this.currIndex);
        return;
      } else {
        this.currIndex += 1;
      }

      this.change(this.currIndex);
    }, 5000);
  }

  private syncChange(currIndex: number, changeIndex: number) {
    this.change(currIndex);
    setTimeout(() => {
      this.elementAddTransitionDuration(0);
      this.change(changeIndex);
    }, 300);
  }

  private change(index: number) {
    const px = -(index * 100);

    const sliderBoxElement = this.sliderElement.nativeElement as HTMLElement;
    sliderBoxElement.style.transform = `translate(${px}%, 0px)`;
  }

  private initPanStart(hammer) {
    return fromEvent(hammer, 'panstart').subscribe((e) => {
      this.elementAddTransitionDuration(0);
      this.currentTranslateX = new WebKitCSSMatrix(
        window.getComputedStyle(this.sliderElement.nativeElement).transform
      ).m41;
    });
  }

  private initPan(hammer) {
    return fromEvent(hammer, 'pan').subscribe((event: any) => {
      clearInterval(this.interval);

      if (event.center.x === 0 && event.center.y === 0) {
        return;
      }

      const sliderBoxElement = this.sliderElement.nativeElement as HTMLElement;
      const translateX = this.currentTranslateX + event.deltaX;

      if (translateX >= 0 || translateX <= -this.wrapperBox) {
        return;
      }

      sliderBoxElement.style.transform = `translate(${translateX}px, 0px)`;
    });
  }

  private initPanEnd(hammer) {
    return fromEvent(hammer, 'panend').subscribe(() => {
      clearInterval(this.interval);

      const translateX = new WebKitCSSMatrix(
        window.getComputedStyle(this.sliderElement.nativeElement).transform
      ).m41;
      this.elementAddTransitionDuration(300);

      if (translateX > 20) {
        this.changeIndex(-1);
        return;
      }

      const result = Math.abs(Math.round(translateX / this.imageWidth));
      const isCurrent = this.currIndex === result;

      if (this.swipeState === 'left') {
        this.changeIndex(isCurrent ? (this.currIndex += 1) : result);
      } else if (this.swipeState === 'right') {
        this.changeIndex(
          isCurrent ? (this.currIndex -= 1) : result > this.currIndex ? result - 2 : result
        );
      } else {
        this.changeIndex(result);
      }

      this.initInterval();
    });
  }

  private initSwipeLeft(hammer) {
    return fromEvent(hammer, 'swipeleft').subscribe(() => (this.swipeState = 'left'));
  }

  private initSwipeRight(hammer) {
    return fromEvent(hammer, 'swiperight').subscribe(() => (this.swipeState = 'right'));
  }

  private elementAddTransitionDuration(duration: number) {
    const element = this.sliderElement.nativeElement as HTMLElement;
    element.style.transitionDuration = `${duration}ms`;
  }
}
