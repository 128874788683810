import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryBtnComponent } from './components/category-btn/category-btn.component';
import { MoreTextBtnComponent } from './components/more-text-btn/more-text-btn.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { UploadMenuButtonComponent } from './components/upload-menu-button/upload-menu-button.component';
import { ButtonHoverDirective } from './directives/button-hover.directive';
import { ClapButtonComponent } from './components/clap-button/clap-button.component';
import { MoreDotsButtonComponent } from './components/more-dots-button/more-dots-button.component';

@NgModule({
  declarations: [
    CategoryBtnComponent,
    MoreTextBtnComponent,
    IconButtonComponent,
    UploadMenuButtonComponent,
    ButtonHoverDirective,
    ClapButtonComponent,
    MoreDotsButtonComponent,
  ],
  exports: [
    CategoryBtnComponent,
    IconButtonComponent,
    MoreTextBtnComponent,
    UploadMenuButtonComponent,
    ClapButtonComponent,
    MoreDotsButtonComponent,
  ],
  imports: [CommonModule],
})
export class ButtonsModule {}
