import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WndImage } from '../../../../../../../../src/core/types';


@Component({
  selector: 'wnd-upload-image-box',
  templateUrl: './upload-image-box.component.html',
  styleUrls: ['./upload-image-box.component.scss']
})
export class UploadImageBoxComponent {
  @Input()
  get data(): any {
    return this._data;
  }

  set data(data: any) {
    this.setImageType(data);

  }

  imageType: string;

  private _data: any;

  constructor(private sanitizer: DomSanitizer) {
  }

  setImageType(data: string | WndImage) {
    if (typeof data !== 'object') {
      this.imageType = 'string';
      this._data = this.sanitizer.bypassSecurityTrustUrl(data);
    } else {
      this.imageType = 'object';
      this._data = data;
    }
  }
}
