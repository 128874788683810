import { Component, AfterViewInit } from '@angular/core';
import { BrowserService } from '../../../../../../../weenidy/src/lib/shared/services/browser.service';
import { MetaService } from '../../../../core/service/meta.service';
import { playLottie } from '../../../../core/utils/utils';


@Component({
  selector: 'wnd-open-soon',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss']
})
export class ModifyComponent implements AfterViewInit {

  constructor(
    private browserService: BrowserService,
    private metaService: MetaService
  ) {
    this.metaService.setMetaTag('멤버십 - 위니디', MetaService.BaseUrl + '/membership/open');
  }

  ngAfterViewInit(): void {
    if (this.browserService.isBrowser) {
      const element = document.getElementById('lottie-box');
      playLottie(element, 'assets/images/svg-json/membership.json');
    }
  }

}
