<div *ngIf="images" class="slider-banner-box">
  <div
    #sliderElement
    class="slider-banner-image-box"
  >
    <wnd-slider-banner-item
      [banner]="lastBanner"
      (bannerClick)="imageClick($event)"
    ></wnd-slider-banner-item>

    <wnd-slider-banner-item
      *ngFor="let image of images; trackBy: trackByFn"
      [banner]="image"
      (bannerClick)="imageClick($event)"
    ></wnd-slider-banner-item>

    <wnd-slider-banner-item
      [banner]="firstBanner"
      (bannerClick)="imageClick($event)"
    ></wnd-slider-banner-item>

  </div>

  <div
    *ngIf="images && images.length > 1"
    class="slider-dot-box"
    [class.prod]="isProd"
  >
    <ul class="slider-dot-list">
      <li
        *ngFor="let image of images; let idx = index"
        class="slider-dot"
        [class.active]="idx === currIndex - 1"
        (click)="changeIndex(idx + 1)"
      ></li>
    </ul>
  </div>
</div>
