import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResourceBaseProgramOptions } from '../../../../../../../../src/entities/resource/types';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControlBaseComponent } from '../../../../modules/form';

@Component({
  selector: 'wnd-program-list-form',
  templateUrl: './program-list-form.component.html',
  styleUrls: ['./program-list-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProgramListFormComponent),
      multi: true,
    },
  ],
})
export class ProgramListFormComponent extends FormControlBaseComponent implements OnInit {
  @Input() items: string[];
  @Input() maxLength: number = 10;

  formGroup = this.fb.group({
    programs: this.fb.array([this.fb.control(null)]),
  });

  baseProgramOptions = ResourceBaseProgramOptions;

  selectionModel = new SelectionModel<number>(false);

  constructor(private fb: FormBuilder) {
    super();
  }

  get programFormArray(): FormArray {
    return this.formGroup.get('programs') as FormArray;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.formGroup.valueChanges.subscribe(() => {
      this.formCtrl.setValue(this.programFormArray.value);
    });

    this.convertToControlValue(this.items);
  }

  selectItem(index: number): void {
    this.selectionModel.select(index);
  }

  remove(): void {
    const index = this.selectionModel.selected[0];
    this.programFormArray.removeAt(index);

    this.selectionModel.clear();
  }

  add(): void {
    if (this.programFormArray.controls.length >= this.maxLength) {
      return;
    }

    this.programFormArray.push(this.fb.control(null));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.programFormArray.controls, event.previousIndex, event.currentIndex);

    this.programFormArray.updateValueAndValidity();

    if (this.selectionModel.hasValue()) {
      this.selectionModel.select(event.currentIndex);
    }
  }

  protected override convertToControlValue(values: string[]) {
    if (!values) {
      return values;
    }

    values.forEach((value, index) => {
      if (index > 0 && index >= this.programFormArray.controls.length) {
        this.add();
      }

      this.programFormArray.at(index).setValue(value);
    });

    return values;
  }
}
