<div class="toggle-form-control-box">
  <div class="toggle-form-label-box">
    <label class="toggle-form-label">{{ label }}</label>

    <div class="toggle-form-button-box">
      <ng-container *ngIf="!isChange">
        <button type="button" class="toggle-form-button change-button" (click)="open()">
          <img
            class="toggle-button-icon"
            src="/assets/images/icons/icn-down-yellow.png"
            alt="아래 방향 노랑색 아이콘"
          />
          변경
        </button>
      </ng-container>

      <ng-container *ngIf="isChange">
        <button type="button" class="toggle-form-button cancel-button" (click)="close()">
          <img
            class="toggle-button-icon"
            src="/assets/images/icons/icn-cancel-yellow.png"
            alt="노랑색 취소 아이콘"
          />취소
        </button>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!isChange">
    <p *ngIf="text; else placeholderText" class="toggle-form-text">{{ text }}</p>

    <ng-template #placeholderText>
      <p class="toggle-form-text placeholder">{{ placeholder }}</p>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isChange">
    <ng-content></ng-content>
  </ng-container>
</div>
