import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { LibFormModule } from '../../../../../weenidy/src/lib/shared/form/lib-form.module';
import { WndPipeModule } from '../../../../../weenidy/src/lib/modules/pipe/wnd.pipe.module';
import { PhoneVerifyFormComponent } from './components/phone-verify-form/phone-verify-form.component';
import { PhoneVerifyMinutesPipe } from './pipes/phone-verify-minutes.pipe';
import { PhoneVerifyButtonFormComponent } from './components/phone-verify-button-form/phone-verify-button-form.component';

@NgModule({
  declarations: [PhoneVerifyFormComponent, PhoneVerifyButtonFormComponent, PhoneVerifyMinutesPipe],
  exports: [PhoneVerifyFormComponent, PhoneVerifyButtonFormComponent],
  imports: [CommonModule, LibFormModule, ReactiveFormsModule, MatDialogModule, WndPipeModule],
})
export class PhoneFormModule {}
