import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ResourceCategoryOptions } from '../../../../../../../../src/entities/resource/types';
import { SearchFilter } from '../../../../pages/resource-page/types';
import { SubscriptionBaseComponent } from '../../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';
import { Subscription } from 'rxjs';
import { fadeIn, fadeOut } from '../../../../layout/components/toolbar/animation';

@Component({
  selector: 'wnd-resource-search-form',
  templateUrl: './resource-search-form.component.html',
  styleUrls: ['./resource-search-form.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class ResourceSearchFormComponent extends SubscriptionBaseComponent implements OnInit {
  @Input() panelClass: string[];
  @Input()
  set searchFilter(doc: Partial<SearchFilter>) {
    if (doc && !doc.keyword) {
      doc.keyword = null;
    }

    if (doc && !doc.category) {
      doc.category = null;
    }

    this.formGroup.patchValue(doc, { emitEvent: false });
  }

  @Output() onFilterChange: EventEmitter<Partial<SearchFilter>> = new EventEmitter<
    Partial<SearchFilter>
  >();

  @Output() onMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  formGroup: FormGroup = this.createFormGroup();

  categoryOption = ResourceCategoryOptions;

  isFocus = false;
  isHover = false;

  formGroup2: FormGroup = this.fb.group({
    test: [null],
  });
  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.setSubscription('_initCategoryValueChange', this.initCategoryValueChange());

    this.formGroup2.valueChanges.subscribe((value) => {
      console.log('formGroup2', value);
    });

    this.formGroup2.get('test').valueChanges.subscribe((value) => {
      console.log('test', value);
    });
  }

  searchKeyword(): void {
    this.onFilterChange.emit(this.formGroup.value);
    this.closeSearchMenu();
  }

  createFormGroup(): FormGroup {
    return this.fb.group(
      {
        category: [null],
        keyword: [null],
      },
      { updateOn: 'change' }
    );
  }

  onMouseEnter(): void {
    this.isHover = true;
    this.onMenuOpen.emit(this.isFocus || this.isHover);
  }

  onMouseLeave(): void {
    setTimeout(() => {
      this.isHover = false;
      this.onMenuOpen.emit(this.isFocus || this.isHover);
    }, 50);
  }

  selectRecentKeyword(keyword: string) {
    this.formGroup.patchValue({ keyword });
    this.searchKeyword();
    this.closeSearchMenu();
  }

  updateFocus(isFocus): void {
    this.isFocus = isFocus;
    this.onMenuOpen.emit(this.isFocus || this.isHover);
  }

  private closeSearchMenu(): void {
    this.isFocus = false;
    this.isHover = false;
    this.onMenuOpen.emit(false);
  }

  private initCategoryValueChange(): Subscription {
    return this.formGroup.get('category').valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }

      this.onFilterChange.emit({ category: value });
    });
  }
}
