import { Inject, Injectable } from '@angular/core';
import { Payment } from '../../../../../../../src/entities/payment/types';
import { BigquerySearchAdapter } from '../../../../../../../src/modules/google/bigquery-search.adapter';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgApiService } from '../../../core/services/api.service';
import { NgPaymentDb } from './payment.db';

@Injectable({
  providedIn: 'root',
})
export class NgPaymentSearch extends BigquerySearchAdapter<Payment> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override apiService: NgApiService,
    protected paymentDb: NgPaymentDb
  ) {
    super(
      `${environment.firebase.projectId}.firestore_export.payments_schema_schema_20231213_couponId_latest`,
      apiService,
      paymentDb
    );
  }
}
