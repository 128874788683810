import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlBaseComponent } from '../../../../modules/form';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CreatorActivityTypeOptions } from '../../../../../../../../src/entities/user/types';

@Component({
  selector: 'wnd-activity-list-form',
  templateUrl: './activity-list-form.component.html',
  styleUrls: ['./activity-list-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityListFormComponent),
      multi: true,
    },
  ],
})
export class ActivityListFormComponent extends FormControlBaseComponent implements OnInit {
  @Input() items: { type: number; text: string }[] = [];
  formGroup = this.fb.group({
    activities: this.fb.array([]),
  });

  selectionModel = new SelectionModel<number>(false);
  creatorActivityTypeOptions = CreatorActivityTypeOptions;

  constructor(private fb: FormBuilder) {
    super();
  }

  get activitiesFormArray(): FormArray {
    return this.formGroup.get('activities') as FormArray;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.formGroup.valueChanges.subscribe(() => {
      this.formCtrl.setValue(this.activitiesFormArray.value);
    });

    if (this.items.length > 0) {
      this.items.forEach((activity) => {
        this.activitiesFormArray.push(
          this.fb.group({
            type: [activity.type],
            text: [activity.text],
          })
        );
      });
    } else {
      this.activitiesFormArray.push(this.createActivityFormGroup());
    }
  }

  selectItem(index: number): void {
    this.selectionModel.select(index);
  }

  remove(): void {
    const index = this.selectionModel.selected[0];
    this.activitiesFormArray.removeAt(index);

    this.selectionModel.clear();
  }

  add(): void {
    if (this.activitiesFormArray.controls.length >= 10) {
      return;
    }

    this.activitiesFormArray.push(this.createActivityFormGroup());
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.activitiesFormArray.controls, event.previousIndex, event.currentIndex);

    this.activitiesFormArray.updateValueAndValidity();

    if (this.selectionModel.hasValue()) {
      this.selectionModel.select(event.currentIndex);
    }
  }

  private createActivityFormGroup(): FormGroup {
    return this.fb.group({
      type: [null],
      text: [null],
    });
  }
}
