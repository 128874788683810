<section class="payment-process-page-section">
  <div *ngIf="resources" class="wrapper payment-contents-box">
    <form class="payment-form" [formGroup]="formGroup" novalidate>
      <div class="payment-order-info-box">
        <h4 class="payment-order-header">{{ paymentText.orderInfo[language] }}</h4>

        <div class='form-control'>
          <wnd-input
            id="name"
            class="resource-upload-input"
            formControlName="name"
            [placeholder]="paymentText.name[language]"
          ></wnd-input>
        </div>

        <div class='form-control'>
          <wnd-input
            id="email"
            class="resource-upload-input"
            formControlName="email"
            [placeholder]="paymentText.email[language]"
          ></wnd-input>
        </div>

        <div class='form-control'>
          <wnd-input
            *ngIf="hasWebtoon"
            id="licenseUsedUserName"
            class="resource-upload-input"
            formControlName="licenseUsedUserName"
            [placeholder]="paymentText.licenseUsedUserName[licenseType][language]"
          ></wnd-input>
        </div>


        <p class="payment-order-text">{{ paymentText.orderInfoText[language] }}</p>
      </div>

      <div class="payment-method-info-box">
        <h4 class="payment-method-info-header">{{ paymentText.payType[language] }}</h4>

        <div class="payment-method-button-box">
          <button
            id="nice"
            class="payment-card-box"
            [class.active]="selectedPg === 'nice'"
            (click)="changePg('nice')"
          >
            {{ paymentText.card[language] }}
          </button>

          <button
            id="kakaopay"
            class="payment-card-box"
            [class.active]="selectedPg === 'kakaopay'"
            (click)="changePg('kakaopay')"
          >
            카카오페이
          </button>

          <button
            id="paypal"
            class="payment-card-box"
            [class.active]="selectedPg === 'paypal'"
            (click)="changePg('paypal')"
          >
            PayPal
          </button>
        </div>

        <article
          class="payment-method-attention-article"
          [innerHTML]="attentionText[selectedPg]"
        ></article>
      </div>

      <div class="payment-price-info-box">
        <wnd-checkbox
          id="agree"
          formControlName="agree"
          [placeholder]="paymentText.confirm[language]"
        ></wnd-checkbox>
      </div>

      <div class="fixed-bottom-button">
        <button
          class="wnd-btn center fixed-bottom payment-button"
          [disabled]="
            formGroup.invalid ||
            hasNotNormalResource ||
            isPayInValid ||
            (hasContents$ | async) === true
          "
          (click)="pay()"
        >
          <p *ngIf="selectedPg !== 'paypal'" class="payment-price-text">
            {{ totalAmount | number }}
          </p>
          <p *ngIf="selectedPg === 'paypal'" class="payment-price-text">
            ${{ totalAmountUSD | number }}
          </p>

          <p class="amount-separator">{{ selectedPg !== 'paypal' ? '원 결제하기' : ' Payment' }}</p>
        </button>
      </div>
    </form>
  </div>
</section>
