import { Inject, Injectable } from '@angular/core';
import { User } from '../../../../../../../src/entities/user/types';
import { BigquerySearchAdapter } from '../../../../../../../src/modules/google/bigquery-search.adapter';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgApiService } from '../../../core/services/api.service';
import { NgUserDb } from './user.db';

@Injectable({
  providedIn: 'root',
})
export class NgUserSearch extends BigquerySearchAdapter<User> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override apiService: NgApiService,
    protected userDb: NgUserDb
  ) {
    super(
      `${environment.firebase.projectId}.firestore_export.users_schema_schema_20220809_latest`,
      apiService,
      userDb
    );
  }
}
