import { Component, Input } from "@angular/core";
import { Badge, BadgeCategory } from "../../../../../../../../src/entities/badge/types";

@Component({
  selector: 'wnd-badge-image-card',
  templateUrl: './badge-image-card.component.html',
  styleUrls: ['./badge-image-card.component.scss']
})
export class BadgeImageCardComponent {
  @Input() badge: Badge;

  protected readonly BadgeCategory = BadgeCategory;
}
