import { Component, OnInit } from '@angular/core';
import { fadeIn, fadeOut } from '../../../../layout/components/toolbar/animation';

@Component({
  selector: 'wnd-upload-menu-button',
  templateUrl: './upload-menu-button.component.html',
  styleUrls: ['./upload-menu-button.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class UploadMenuButtonComponent implements OnInit {
  isShow = false;

  constructor() {}

  ngOnInit(): void {}
}
