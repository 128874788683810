import { startWith } from 'rxjs/operators';
import { FormControl, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { stringConvertBytes } from '../utils';
import { CouponDiscountType } from 'src/entities/coupon/types';

export class CheckboxHandler {
  static checkIsAll(
    allCheckboxName: string,
    firstCheckboxName: string,
    secondCheckboxName: string
  ) {
    return (fg: FormGroup) => {
      return null;
    };
  }
}

export class SocialValidator {
  static id(fc: FormControl) {
    const id = fc.value;

    if (!id || id === '') {
      return null;
    }

    if (!/^[A-Za-z0-9._]{1,30}$/.test(id)) {
      return { socialId: true };
    }

    return null;
  }
}

export class AddressValidator {
  static allRequired(fg: FormGroup) {
    const zipCodeControl = fg.get('zipCode');
    const addressControl = fg.get('address');
    const addressDetailControl = fg.get('addressDetail');

    const valid = [zipCodeControl.valid, addressControl.valid, addressDetailControl.valid].every(
      (value) => Boolean(value)
    );

    if (!valid) {
      return { addressValid: true };
    } else {
      return null;
    }
  }
}

export class PasswordValidator {
  static matchPassword(fg: FormGroup) {
    const passwordControl = fg.get('password');
    const passwordCheckControl = fg.get('passwordCheck');

    const valid = [passwordControl.valid, passwordCheckControl.valid].every((value) =>
      Boolean(value)
    );

    const password = passwordControl.value;
    const confirmPassword = passwordCheckControl.value;

    if (valid && password !== confirmPassword) {
      return { match: true };
    } else {
      return null;
    }
  }

  static checkCurrentPassword(fg: FormGroup) {
    const currentPasswordControl = fg.get('currentPassword');
    const passwordControl = fg.get('password');
    const passwordCheckControl = fg.get('passwordCheck');

    const valid = [
      currentPasswordControl.valid,
      passwordControl.valid,
      passwordCheckControl.valid,
    ].every((value) => Boolean(value));

    const currentPassword = currentPasswordControl.value;
    const password = passwordControl.value;

    if (valid && password === currentPassword) {
      return { samePassword: true };
    } else {
      return null;
    }
  }

  static pattern(fc: FormControl) {
    const password = fc.value;
    if (!/^[A-Za-z0-9!@#$%^&*+=]{6,20}$/.test(password)) {
      return { pattern: true };
    } else {
      return null;
    }
  }
}

export class NameValidator {
  static namePatternRegExp = /^[A-Za-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]+$/;
  static rangePatternRegExp = /^[A-Za-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{2,10}$/;

  // 한글, 영어
  // 공백 불가
  static pattern(fc: AbstractControl) {
    const name = fc.value;
    if (!NameValidator.namePatternRegExp.test(name)) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  // 2~10 글자
  static range(fc: AbstractControl) {
    const name = fc.value;
    if (!NameValidator.rangePatternRegExp.test(name)) {
      return { range: true };
    } else {
      return null;
    }
  }
}

export class AuthorValidator {
  static authorPatternRegExp = /^[A-Za-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|0-9]+$/;
  static rangePatternRegExp = /^[A-Za-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|0-9]{2,12}$/;

  // 한글, 영어, 숫자
  // 공백 불가
  static pattern(fc: AbstractControl) {
    const name = fc.value;
    if (!AuthorValidator.authorPatternRegExp.test(name)) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  // 2~12 글자
  static range(fc: AbstractControl) {
    const name = fc.value;
    if (!AuthorValidator.rangePatternRegExp.test(name)) {
      return { range: true };
    } else {
      return null;
    }
  }
}

export class PhoneNumberValidator {
  static patternRegExp = /^[0-9]{1,99}$/;
  static rangeRegExp = /^[0-9]{10,11}$/;

  static pattern(fc: AbstractControl) {
    const value = fc.value;

    if (!PhoneNumberValidator.patternRegExp.test(value)) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  static range(fc: AbstractControl) {
    const value = fc.value;

    if (!PhoneNumberValidator.rangeRegExp.test(value)) {
      return { range: true };
    } else {
      return null;
    }
  }
}

export class PersonLicenseNumberValidator {
  static patternRegExp = /^[0-9]{13}$/;

  static pattern(fc: AbstractControl) {
    const value = fc.value;

    if (!PersonLicenseNumberValidator.patternRegExp.test(value)) {
      return { pattern: true };
    } else {
      return null;
    }
  }
}

export class DateValidator {
  static yearPatternRegExp = /^(19[5-9][0-9]|20[0-5][0-9])$/;
  static monthPatternRegExp = /^([1-9]|0[1-9]|1[0-2])$/;
  static dataPatternRegExp = /^([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])$/;

  static year(fc: AbstractControl) {
    const value = fc.value;

    if (!DateValidator.yearPatternRegExp.test(value)) {
      return { year: true };
    } else {
      return null;
    }
  }

  static month(fc: AbstractControl) {
    const value = fc.value;

    if (!DateValidator.monthPatternRegExp.test(value)) {
      return { month: true };
    } else {
      return null;
    }
  }

  static date(fc: AbstractControl) {
    const value = fc.value;

    if (!DateValidator.dataPatternRegExp.test(value)) {
      return { date: true };
    } else {
      return null;
    }
  }
}

export class EmailValidators {
  static currentEmail(email: string) {
    return (fc: AbstractControl): ValidationErrors | null => {
      return email !== fc.value ? { incorrect: true } : null;
    };
  }
}

export class EmbedValidators {
  static iframeRegExp = new RegExp(/<iframe ([^]*)<\/iframe>/);
  static providerRegExp = new RegExp(/(youtube|vimeo)/);

  static iframeTag(fc: AbstractControl) {
    return EmbedValidators.iframeRegExp.test(fc.value) ? null : { iframe: true };
  }

  static provider(fc: AbstractControl) {
    return EmbedValidators.providerRegExp.test(fc.value) ? null : { provide: true };
  }
}

export class PriceValidators {
  static range(fc: AbstractControl) {
    const price: number = parseInt(fc.value, 10);
    if (price > 0 && price < 3000) {
      return { lessThree: true };
    } else if (price > 999999) {
      return { max: true };
    } else if (price < 0) {
      return { min: true };
    } else {
      return null;
    }
  }
}

export class TagsValidators {
  static arrayLength(maxLength: number = 10) {
    return (fc: AbstractControl) => {
      const items: string[] = fc.value;

      if (!items) {
        return null;
      }

      if (items.length > maxLength) {
        console.log('items.slice(0, maxLength)', items.slice(0, maxLength));
        fc.setValue(items.slice(0, maxLength));
        return { length: true };
      } else {
        return null;
      }
    };
  }

  static textLength(maxLength: number = 10) {
    return (fc: AbstractControl) => {
      const items: string[] = fc.value;

      if (!items) {
        return null;
      }

      const lastIndex = items.length - 1;
      const lastTag: string = items[lastIndex] as unknown as string;

      if (lastTag && lastTag.length > maxLength) {
        fc.setValue(items.slice(0, lastIndex));
        return { textLength: true };
      } else {
        return null;
      }
    };
  }
}

export class StringBytesValidators {
  static textBytes(maxBytes: number = 500) {
    return (fc: AbstractControl) => {
      if (stringConvertBytes(fc.value) > maxBytes) {
        return { bytesLength: true };
      } else {
        return null;
      }
    };
  }
}

export class FeedbackValidators {
  static isOpen(fc: AbstractControl) {
    if (fc.value === 'open') {
      return null;
    } else {
      return { notOpen: true };
    }
  }
}

export class CreatorPathValidators {
  static startWithRegExp = /^[a-z]/;
  static creatorPathRegExp = /^[a-zA-Z0-9]+$/;
  static rangePatternRegExp = /^[a-z][a-zA-Z0-9]{1,20}$/;

  //영어 소문자로 시작
  static startWith(fc: AbstractControl) {
    const name = fc.value;
    if (!CreatorPathValidators.startWithRegExp.test(name)) {
      return { startWith: true };
    } else {
      return null;
    }
  }

  // 영어, 숫자
  // 공백 불가
  static pattern(fc: AbstractControl) {
    const name = fc.value;
    if (!CreatorPathValidators.creatorPathRegExp.test(name)) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  // 2~20 글자
  static range(fc: AbstractControl) {
    const name = fc.value;
    if (!CreatorPathValidators.rangePatternRegExp.test(name)) {
      return { range: true };
    } else {
      return null;
    }
  }
}

export class CreatorSnsValidators {
  static socialIdRegExp = /^[a-zA-Z0-9./\-_]{4,}$/;

  // 영어, 숫자 , 특수문자 4가지 (. / - _ ) 허용
  // 공백 불가
  static socialId(fc: AbstractControl) {
    const name = fc.value;
    if (!name) {
      return null;
    }

    if (!CreatorSnsValidators.socialIdRegExp.test(name)) {
      return { socialId: true };
    } else {
      return null;
    }
  }
}

export class ArrayValidators {
  static full(fc: AbstractControl) {
    const values = fc.value as any[];

    return values.some((value) => !Boolean(value) || value === '') ? { empty: true } : null;
  }
}

export class CreatorWebValidators {
  static startWithRegExp = /^(http(s)?:\/\/)/;
  static webSiteRegExp = /([a-zA-Z0-9]|[\.\/\-\_])+/g;

  //http:// OR https://로 시작.

  static startWith(fc: AbstractControl) {
    const name = fc.value;

    if (!name) {
      return null;
    }

    if (!CreatorWebValidators.startWithRegExp.test(name)) {
      return { startWith: true };
    } else {
      return null;
    }
  }
  // 영어, 숫자 , 특수문자 4가지 (. / - _ ) 허용
  // 공백 불가
  static pattern(fc: AbstractControl) {
    const name = fc.value;

    if (!name) {
      return null;
    }

    if (!CreatorWebValidators.webSiteRegExp.test(name)) {
      return { pattern: true };
    } else {
      return null;
    }
  }
}

export class CouponValidators {
  // 쿠폰 할인 타입이 % 인 경우에만, 최대 할인금액 필수 입력
  static isMaxDiscountAmountRequired(fg: FormGroup) {
    const type = fg.get('discountType');
    const maxAmount = fg.get('maximumDiscountAmount');

    if (type.value !== CouponDiscountType.percentage) return null;
    if (maxAmount.value) return null;
    return { isMaxDiscountAmountRequired: true };
  }

  static isCouponCountRequired(fg: FormGroup) {
    const limitedCount = fg.get('limitedCount');
    const isUnlimited = fg.get('isUnlimited');

    if (limitedCount.value) return null;
    if (isUnlimited.value) return null;
    return { isCouponCountRequired: true };
  }
}
