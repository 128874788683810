import { Pipe, PipeTransform } from '@angular/core';
import { deepCopy } from '../../../shared/utils';


@Pipe({
  name: 'textEllipsis'
})
export class TextEllipsisPipe implements PipeTransform {

  transform(tags: string[], ...args: unknown[]): string {
    tags = deepCopy(tags);
    let isOverflow = false;

    if (tags.length > 3) {
      tags = tags.splice(0, 3);
      isOverflow = true;
    }

    tags = tags.map(tag => `#${tag}`);

    return isOverflow ? tags.join(' ') + '...' : tags.join(' ');
  }
}
