import firebase from 'firebase/compat/app';
import { BaseEntity } from '../../core/base-entity';
import { File, Option, WndImage, Writer } from '../../core/types';
import FieldValue = firebase.firestore.FieldValue;
import { LicenseType } from '../cart/types';

export interface WndDownloadInfo {
  url: string;
  fileName: string;
  fileSize?: string;
}

export interface Resource extends BaseEntity {
  thumbnail: WndImage; // 썸네일
  title: string; // 제목
  titleEn: string; // 영문 제목
  category: ResourceCategory; // 범주
  type: ResourceType; // 유형
  price: number; // 가격
  content: string | ResourceContentData[]; // 내용
  tags: string[]; // 태그

  writer: Writer;
  files: File[];
  fileExtension: FileExtension[]; // 파일 확장자
  fileKind: number; // 파일 종류
  fileSize: string; // 파일 용량(MB)
  license: ResourceLicense; // 라이선스
  status: ResourceStatus; // 상태
  refuseReason?: string; // 승인 불가 사유

  // 추가 부분
  fileName?: string;
  fileList?: string[];
  fileExtensionAll?: FileExtension[];
  programs?: string[]; // 프로그램 상세 버전명 (ex.figma 2023)
  basePrograms?: string[]; // 프로그램 명(ex.figma)
  // 추가 부분

  examinedAt?: Date; // 심사 일시
  confirmedAt?: Date; // 승인 일시
  modifyAt?: Date; // 수정 요청 일시
  refusedAt?: Date; // 승인불가 일시
  blockedAt?: Date; // 차단 일시
  deletedAt?: Date; // 삭제 일시

  metaTag?: MetaTag;
  moreResources?: string[];

  productCode?: string; // 상품코드
  externalAdminId?: string; // 외부 관리자 ID

  photoExtension: PhotoExtensionType; // 사진 확장자
  photoDirection: PhotoDirectionType; // 사진 형태
  mediaAgreements: WndDownloadInfo[]; // 재산권자/소유권자/초상권자 동의서 파일
  thumbnailWidth: number; // 썸네일 가로
  thumbnailHeight: number; // 썸네일 높이
  copyrightInformation: string; // 저작권 정보 (촬영자, 소유권 정보)
  mediaUniqueness: string; // 특이사항 (재산권 및 초상권 정보 등)

  licenseType?: LicenseType;

  views: number | FieldValue; // 조회수
  downloadCount: number; // 다운로드 횟수
  buyCount: number; // 구매 횟수
  scrap: number; // 스크랩 횟수

  //추가 부분
  portfolios?: Resource[];
  clap: number; //박수 횟수
  totalLikes: number; // 누적된 장바구니에 담긴 이벤트 & 스크랩 이벤트 횟수
}

export interface ThumbnailImageInfo {
  thumbnailWidth: number; // 썸네일 가로
  thumbnailHeight: number; // 썸네일 높이
  photoExtension: PhotoExtensionType; // 사진 확장자
  photoDirection: PhotoDirectionType; // 사진 형태

  files?: File[];
  fileKind: number; // 파일 종류
  fileSize: string; // 파일 용량(MB)
  fileName?: string;
  fileList?: string[];
  fileExtension: FileExtension[]; // 파일 확장자
  fileExtensionAll?: FileExtension[];

  content: ResourceContentData[];
}

export enum PhotoExtensionType {
  JPG = 1,
  PNG = 10,
}

export const PhotoExtensionTypeText = {
  [PhotoExtensionType.JPG]: 'jpg',
  [PhotoExtensionType.PNG]: 'png',
};

export enum PhotoDirectionType {
  FORWARD = 1,
  WIDTH = 10,
  HEIGHT = 20,
}

export interface MetaTag {
  metaTitle: string;
  keywords: string[];
  description: string;
  imageUrl?: string;
}

export enum ResourceStatus {
  Normal = 1, // 정상
  ExternalNormal = 2, // 외부 정상
  Block = 10, // 차단
  Examine = 30, // 심사중
  ExternalExamine = 31, // 외부 심사중
  Modify = 40, // 수정 요청
  ExternalModify = 41, // 외부 수정 요청
  Refused = 90, // 승인 불가
  ExternalRefused = 91, // 외부 승인 불가
  Deleted = 100, // 삭제
}

export enum ResourceLicense {
  NonCommercial = 1,
  Commercial = 10,
}

export enum ResourceType {
  Single = 1,
  Package = 10,
}

export enum ResourceCategory {
  Mockup = 1,
  Template = 10,
  Graphic = 20,
  WebToon = 50,
  Picture = 100,
  Portfolio = 500,
}

export const ResourceCategoryText = {
  ['']: '미분류',
  [ResourceCategory.Mockup]: '목업',
  [ResourceCategory.Template]: '템플릿',
  [ResourceCategory.Graphic]: '그래픽',
  [ResourceCategory.WebToon]: '웹툰',
  [ResourceCategory.Picture]: '사진',
  [ResourceCategory.Portfolio]: '포트폴리오',
};

export const ResourceCategoryTextEn = {
  [ResourceCategory.Mockup]: 'Mockup',
  [ResourceCategory.Template]: 'Template',
  [ResourceCategory.Graphic]: 'Graphic',
  [ResourceCategory.WebToon]: 'WebToon',
  [ResourceCategory.Picture]: 'Picture',
};

export const ResourceTypeText = {
  [ResourceType.Single]: '단일',
  [ResourceType.Package]: '묶음',
};

export const ResourceStatusText = {
  [ResourceStatus.Normal]: '정상',
  [ResourceStatus.Block]: '차단',
  [ResourceStatus.Examine]: '심사요청',
  [ResourceStatus.Modify]: '수정 요청',
  [ResourceStatus.Refused]: '승인 불가',
  [ResourceStatus.Deleted]: '삭제',
  [ResourceStatus.ExternalNormal]: '외부 승인',
  [ResourceStatus.ExternalExamine]: '외부 심사 요청',
  [ResourceStatus.ExternalModify]: '외부 수정 요청',
  [ResourceStatus.ExternalRefused]: '외부 승인 불가',
};

export const ResourceLicenseText = {
  [ResourceLicense.Commercial]: '상업적용도',
  [ResourceLicense.NonCommercial]: '비상업적용도',
};

export const ResourceCategoryRadioItems: Option[] = [
  { value: ResourceCategory.Mockup, text: '목업' },
  { value: ResourceCategory.Template, text: '템플릿' },
  { value: ResourceCategory.Graphic, text: '그래픽' },
  { value: ResourceCategory.WebToon, text: '웹툰' },
];

export const ResourceLicenseRadioItems: Option[] = [
  { value: ResourceLicense.Commercial, text: '상업적용도' },
  { value: ResourceLicense.NonCommercial, text: '비상업적용도' },
];

export const ResourceAuthorRadioItems: Option[] = [
  { value: 'Weenidy', text: '위니디' },
  { value: 'none', text: '작가' },
];

export const ResourceAuthorText = {
  Weenidy: '위니디',
  none: '작가',
};

export const ResourcePriceRadioItems: Option[] = [
  { value: 0, text: '무료' },
  { value: 1, text: '유료' },
];

export const ResourcePriceText = {
  0: '무료',
  1: '유료',
};

export const MockupBaseProgramRadioItems: Option[] = [
  { value: 'Adobe Photoshop', text: 'Photoshop' },
  { value: 'Adobe Aftereffect', text: 'Aftereffect' },
  { value: 'etc', text: '기타' },
];

export const TemplateBaseProgramRadioItems: Option[] = [
  { value: 'Adobe Illustrator', text: 'Illustrator' },
  { value: 'Adobe Indesign', text: 'Indesign' },
  { value: 'Adobe Photoshop', text: 'Photoshop' },
  { value: 'Adobe Xd', text: 'XD' },
  { value: 'Keynote', text: 'Keynote' },
  { value: 'Microsoft Office PowerPoint', text: 'PowerPoint' },
  { value: 'Microsoft Office Word', text: 'Word' },
  { value: 'Sketch', text: 'Sketch' },
  { value: 'etc', text: '기타' },
];

export const GraphicBaseProgramRadioItems: Option[] = [
  { value: 'Adobe Illustrator', text: 'Illustrator' },
  { value: 'Adobe Photoshop', text: 'Photoshop' },
  { value: 'etc', text: '기타' },
];

export const WebtoonBaseProgramRadioItems: Option[] = [
  { value: 'SketchUp', text: 'Sketch Up' },
  { value: 'Clip Studio Paint', text: 'Clip Studio Paint' },
  { value: 'Blender', text: 'Blender' },
  { value: 'Adobe Photoshop', text: 'Photoshop' },
  { value: 'etc', text: '기타' },
];

export const ResourcePhotoExtensionRadioItems: Option[] = [
  { value: PhotoExtensionType.JPG, text: 'JPG' },
  { value: PhotoExtensionType.PNG, text: 'PNG' },
];

export const ResourcePhotoExtensionText = {
  [PhotoExtensionType.JPG]: 'JPG',
  [PhotoExtensionType.PNG]: 'PNG',
};

export const ResourcePhotoDirectionRadioItems: Option[] = [
  { value: PhotoDirectionType.FORWARD, text: '정방향' },
  { value: PhotoDirectionType.WIDTH, text: '가로' },
  { value: PhotoDirectionType.HEIGHT, text: '세로' },
];

export const ResourcePhotoDirectionText = {
  [PhotoDirectionType.FORWARD]: '정방향',
  [PhotoDirectionType.WIDTH]: '가로',
  [PhotoDirectionType.HEIGHT]: '세로',
};

export const BaseProgramItems = {
  [ResourceCategory.Mockup]: MockupBaseProgramRadioItems,
  [ResourceCategory.Template]: TemplateBaseProgramRadioItems,
  [ResourceCategory.Graphic]: GraphicBaseProgramRadioItems,
  [ResourceCategory.WebToon]: WebtoonBaseProgramRadioItems,
};

export const ResourcePropertyText = {
  thumbnail: '썸네일',
  title: '타이틀',
  content: '내용',
  files: '리소스 파일',
  fileExtension: '확장자',
  fileKind: '리소스 종류',
  programs: '프로그램 버전',
  category: '카테고리',
  license: '라이선스',
  price: '가격',
  tags: '태그',
};

export interface ResourceContentData {
  type: ResourceContentDataType;
  data: any;
}

export enum ResourceContentDataType {
  Image = 1,
  Editor = 10,
  Embed = 20,
  Divider = 30,
}

export interface ResourceInfo {
  id: string;
  title: string;
  thumbnail: WndImage;
  category: ResourceCategory;
  type: ResourceType;
  tags: string[];
  writer: Writer;
  license: ResourceLicense;
  productCode?: string;
}

export interface ResourceTimetable<T> extends BaseEntity {
  resourceId: string;
  info?: T;
  type: TimeTableType;
}

export enum TimeTableType {
  Confirm = 1,
  Booked = 10,
  Purchased = 20,
}

export const ResourceBaseProgramOptions: Option[] = [
  { value: 'Adobe Photoshop', text: 'Photoshop' },
  { value: 'Adobe Aftereffect', text: 'Aftereffect' },
  { value: 'Adobe Illustrator', text: 'Illustrator' },
  { value: 'Adobe Indesign', text: 'Indesign' },
  { value: 'Adobe Xd', text: 'Xd' },
  { value: 'Keynote', text: 'Keynote' },
  { value: 'Microsoft Office PowerPoint', text: 'PowerPoint' },
  { value: 'Microsoft Office Word', text: 'Word' },
  { value: 'Sketch', text: 'Sketch' },
  { value: 'SketchUp', text: 'SketchUp' },
  { value: 'Clip Studio', text: 'Clip Studio' },
  { value: 'Blender', text: 'Blender' },
  { value: 'Adobe Premiere Pro', text: 'Premiere Pro' },
  { value: 'AUTODESK 3DS MAX', text: '3Ds Max' },
  { value: 'Cinema 4D', text: 'Cinema 4D' },
  { value: 'AUTODESK MAYA', text: 'Maya' },
  { value: 'Figma', text: 'Figma' },
  { value: 'other', text: '기타' },
];

export const ResourceCategoryOptions: Option[] = [
  { value: '', text: '전체', type: 'optionGroup' },
  { value: ResourceCategory.Mockup, text: '목업' },
  { value: ResourceCategory.Template, text: '템플릿' },
  { value: ResourceCategory.Graphic, text: '그래픽' },
  { value: ResourceCategory.WebToon, text: '웹툰' },
  { value: ResourceCategory.Picture, text: '사진', type: 'optionGroup' },
];

export const ResourceCategoryType = {
  null: 'all',
  [ResourceCategory.Mockup]: 'mockup',
  [ResourceCategory.Graphic]: 'graphic',
  [ResourceCategory.Template]: 'template',
  [ResourceCategory.WebToon]: 'webtoon',
  [ResourceCategory.Picture]: 'photo',
};

export interface ResourceInformation {
  fileKind: string;
  license: string;
  version?: string[];
  notice?: string;
}

export const SortOption: Option[] = [
  { value: 'createdAt', text: '최신순' },
  { value: 'views', text: '조회순' },
  { value: 'buyCount', text: '판매순' },
];

export const PortfolioSortOption: Option[] = [
  { value: 'createdAt', text: '최신순' },
  { value: 'views', text: '조회순' },
  { value: 'clap', text: '인기순' },
];

export const PhotoSortOption: Option[] = [
  { value: 'createdAt', text: '최신순' },
  { value: 'views', text: '조회순' },
  { value: 'buyCount', text: '다운로드순' },
  { value: 'priceDesc', text: '가격높은순' },
  { value: 'priceAsc', text: '가격낮은순' },
];

export interface FileExtension {
  type: string;
  count: number;
}
