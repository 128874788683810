import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserService } from '../../../weenidy/src/lib/shared/services/browser.service';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENVIRONMENT } from '../../../weenidy/src/lib/core/tokens';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { HammerConfig } from '../../../weenidy/src/lib/modules/config/hammer';
import { AppInitFactory, AppInitService } from './core/service/app.init.service';
import { LayoutModule } from './layout/layout.module';
import { LibDialogModule } from '../../../weenidy/src/lib/modules/dialog/lib-dialog.module';
import { PopupModule } from './modules/popup/popup.module';
import { HttpClientModule } from '@angular/common/http';
import { ModifyComponent } from './pages/modify/components/open-soon/modify.component';
import { NgAuthService } from '../../../weenidy/src/lib/modules/auth/auth.service';
import { LoadingSpinnerComponent } from './modules/loading/components/loading-spinner/loading-spinner.component';

registerLocaleData(localeKo, 'ko');

@NgModule({
  declarations: [AppComponent, ModifyComponent],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitFactory,
      multi: true,
      deps: [AppInitService],
    },
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    { provide: LOCALE_ID, useValue: 'ko' },
    { provide: ENVIRONMENT, useValue: environment },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    HammerModule,
    LibDialogModule,
    MatMomentDateModule,
    PopupModule,
    HttpClientModule,
    LoadingSpinnerComponent,
  ],
})
export class AppModule {
  constructor(private authService: NgAuthService, private browserService: BrowserService) {
    if (this.browserService.isBrowser) {
      this.authService.init();
      this.authService.initLoggedInAtChange();
    }
  }
}
