import { Injectable } from '@angular/core';
import { PaymentErrorService } from 'src/entities/payment-error/payment-error.service';
import { NgPaymentErrorDb } from './payment-error.db';

@Injectable({
  providedIn: 'root',
})
export class NgPaymentErrorService extends PaymentErrorService {
  constructor(protected override paymentErrorDb: NgPaymentErrorDb) {
    super(paymentErrorDb);
  }
}
