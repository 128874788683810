import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  OVERLAY_DATA_TOKEN,
  OverlayComponent,
} from '../../../../../../../../src/modules/overlay/overlay.service';
import { Popup, PopupType } from '../../../../../../../../src/entities/popup/types';
import moment from 'moment';

@Component({
  selector: 'wnd-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent extends OverlayComponent implements OnInit {
  @Output() override onFinished = new EventEmitter();
  popupType = PopupType;

  constructor(@Inject(OVERLAY_DATA_TOKEN) public popup: Popup) {
    super();
  }

  ngOnInit(): void {}

  popupDisabled(): void {
    window.localStorage.setItem('popup-disabled-date', moment().format('YYYY-MM-DD'));
    this.onFinished.emit();
  }

  openLink(): void {
    if (this.popup?.link) {
      window.open(this.popup?.link, '_blank');
    }
  }
}
