import { Component, forwardRef } from '@angular/core';
import { FormControlBaseComponent } from '../../../../modules/form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wnd-gender-radio-button',
  templateUrl: './gender-radio-button.component.html',
  styleUrls: ['./gender-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenderRadioButtonComponent),
      multi: true,
    },
  ],
})
export class GenderRadioButtonComponent extends FormControlBaseComponent {
  gender: string;

  constructor() {
    super();
  }

  genderChange(type: string) {
    this.gender = type;
    this.formCtrl.setValue(type);
  }

  protected override convertToControlValue(value: string): string {
    this.gender = value;
    return value;
  }
}
