import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { UserGuard } from './core/guard/user.guard';
import { WriterGuard } from './core/guard/writer.guard';
import { LayoutPageComponent } from './layout/components/layout-page/layout-page.component';

const routes: Routes = [
  {
    path: 'callback',
    children: [
      {
        path: 'import-redirect',
        loadChildren: () =>
          import('./pages/callback/iamport/iamport.module').then((m) => m.IamportModule),
      },
    ],
  },
  {
    path: 'sales-slip',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/sales-slip/sales-slip.module').then((m) => m.SalesSlipPageModule),
      },
    ],
  },
  {
    path: 'promotion',
    loadChildren: () => import('./pages/promotion/promotion.module').then((m) => m.PromotionModule),
  },
  {
    path: '',
    component: LayoutPageComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'index' },
      {
        path: 'index',
        loadChildren: () => import('./pages/index/index.module').then((m) => m.IndexModule),
      },
      {
        path: 'sign',
        loadChildren: () => import('./pages/sign/sign.module').then((m) => m.SignModule),
      },
      {
        path: 'resource',
        loadChildren: () =>
          import('./pages/resource-page/resource-page.module').then((m) => m.ResourcePageModule),
      },
      {
        path: 'portfolio',
        loadChildren: () =>
          import('./pages/portfolio-page/portfolio-page.module').then((m) => m.PortfolioPageModule),
      },
      {
        path: 'upload',
        loadChildren: () =>
          import('./pages/upload-page/upload-page.module').then((m) => m.UploadPageModule),
        canLoad: [AuthGuard, WriterGuard],
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./pages/membership/membership.module').then((m) => m.MembershipModule),
      },
      {
        path: 'cart',
        loadChildren: () => import('./pages/cart/cart.module').then((m) => m.CartModule),
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: 'event-payment',
        loadChildren: () =>
          import('./pages/event-payment/event-payment.module').then((m) => m.EventPaymentModule),
      },
      {
        path: 'my-page',
        loadChildren: () => import('./pages/my-page/my-page.module').then((m) => m.MyPageModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchPageModule),
      },
      {
        path: 'apply',
        loadChildren: () => import('./pages/apply/apply.module').then((m) => m.ApplyModule),
        canLoad: [AuthGuard, UserGuard],
      },
      {
        path: 'term',
        loadChildren: () => import('./pages/term/term.module').then((m) => m.TermModule),
      },
      {
        path: 'creator',
        loadChildren: () => import('./pages/creator/creator.module').then((m) => m.CreatorModule),
      },

      {
        path: 'errors',
        loadChildren: () => import('./pages/errors/errors.module').then((m) => m.ErrorsModule),
      },
    ],
  },
  // { path: 'modify', component: ModifyComponent },
  // { path: '**', redirectTo: 'modify' },
  { path: '**', redirectTo: 'errors' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
