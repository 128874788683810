import { ColdObservable, ColdObservableOnce, HotObservableOnce } from 'src/core/types';
import { FirestoreDbAdapter } from './../../modules/firebase/firestore-db.adapter';
import { PaymentErrorPayload } from './types';
import { DbListResponse, DbOptions, DbQuery } from 'src/core/db/types';
export class PaymentErrorService {
  constructor(protected paymentErrorDb: FirestoreDbAdapter<PaymentErrorPayload>) {}

  createId(): string {
    return this.paymentErrorDb.createId();
  }
  add(doc: Partial<PaymentErrorPayload>): HotObservableOnce<PaymentErrorPayload> {
    return this.paymentErrorDb.add(doc);
  }

  upsert(id: string, doc: Partial<PaymentErrorPayload>): HotObservableOnce<PaymentErrorPayload> {
    return this.paymentErrorDb.upsert(id, doc);
  }

  get(id: string): ColdObservableOnce<PaymentErrorPayload> {
    return this.paymentErrorDb.get(id);
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<PaymentErrorPayload>> {
    return this.paymentErrorDb.list(query, options);
  }

  delete(id: string): HotObservableOnce<void> {
    return this.paymentErrorDb.delete(id);
  }
}
