import { DbAdapter } from '../../core/db/db.adapter';
import { ColdObservable, HotObservableOnce } from '../../core/types';
import { PopularKeyword, RecentPopular } from './recent-popular.types';
import { map } from 'rxjs/operators';
import { SubscriptionBaseService } from '../../core/base-services/subscription-base.service';

export class RecentPopularService extends SubscriptionBaseService {
  constructor(protected configDb: DbAdapter<any>) {
    super();
  }

  listPopularKeywordsLimitFive(): ColdObservable<PopularKeyword[]> {
    return this.getPopularKeywords().pipe(
      map((popularKeywords: PopularKeyword[]) => popularKeywords && popularKeywords.slice(0, 5))
    );
  }

  listPopularKeywordsLimitTen(): ColdObservable<PopularKeyword[]> {
    return this.getPopularKeywords().pipe(
      map((popularKeywords: PopularKeyword[]) => {
        return popularKeywords && popularKeywords.slice(0, 10);
      })
    );
  }

  addNewKeyword(keyword: string) {
    return this.recentPopularTransaction((keywords, now) => {
      const index = keywords.findIndex((k) => k.keyword === keyword);

      if (index > -1) {
        keywords[index].count++;
        keywords[index].dates.push(new Date());
      } else {
        keywords.push({
          keyword,
          count: 1,
          dates: [new Date()],
          prevIndex: -1,
        });
      }

      for (let i = 0; i < keywords.length; i++) {
        keywords[i].prevIndex = i;
      }

      keywords = this.sortKeywords(keywords);

      return keywords;
    });
  }

  private recentPopularTransaction(
    fn: (keywords: PopularKeyword[], now: Date) => PopularKeyword[]
  ): HotObservableOnce<void> {
    return this.configDb.transaction((db) => async (transaction) => {
      const recentPopularRef = db.doc('config/recentPopular');
      const recentPopularSnap = await transaction.get(recentPopularRef);

      let recentPopular: RecentPopular;

      if (recentPopularSnap.exists) {
        recentPopular = recentPopularSnap.data() as RecentPopular;
      } else {
        recentPopular = { keywords: [] };
      }

      recentPopular.keywords = fn(recentPopular.keywords, new Date());

      await transaction.set(recentPopularRef, recentPopular);
    });
  }

  private sortKeywords(keywords: PopularKeyword[]): PopularKeyword[] {
    return keywords.sort((a, b) => b.count - a.count);
  }

  private getPopularKeywords(): ColdObservable<PopularKeyword[]> {
    return this.makeSingletonSubscription(
      'recent-popular',
      this.configDb.getChange('thirtyDaysPopular')
    ).valueChange.pipe(map((recentPopular) => (recentPopular ? recentPopular.thirtyDaysPopular : [])));
  }
}
