import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from 'src/core/environment';
import { NgTimeLineDb } from './time-line.db';
import { TimeLineService } from 'src/entities/time-line/time-line.service';
import { NgTimeLineSearch } from './time-line.search';

@Injectable({
  providedIn: 'root',
})
export class NgTimeLineService extends TimeLineService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override timeLineSearch: NgTimeLineSearch,
    protected timelineDb: NgTimeLineDb
  ) {
    super(environment.firebase.projectId, timeLineSearch, timelineDb);
  }
}
