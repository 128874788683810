<div class="wnd-input-box">
  <input
    #wndInput
    [class.error]="error"
    [class.valid]="valid"
    [type]="type"
    [id]="id"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [pattern]="pattern"
    [matDatepicker]="picker"
    (click)="picker.open()"
  />

  <mat-datepicker #picker></mat-datepicker>
</div>
