import {
  PhotoDirectionType,
  PhotoExtensionType,
} from '../../../../../../src/entities/resource/types';
import { fromEvent, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export function getFileExtension(fileName: string): string {
  return fileName.split('.').pop();
}

export function getPhotoExtensionType(fileExtension: string): PhotoExtensionType {
  switch (fileExtension) {
    case 'image/jpeg':
      return PhotoExtensionType.JPG;
    case 'image/png':
      return PhotoExtensionType.PNG;
    default:
      return undefined;
  }
}

export function getPhotoDirection(width: number, height: number): PhotoDirectionType {
  const paddingTop = (100 / width) * height;

  if (paddingTop < 90) {
    return PhotoDirectionType.WIDTH;
  } else if (paddingTop >= 90 && paddingTop <= 110) {
    return PhotoDirectionType.FORWARD;
  } else {
    return PhotoDirectionType.HEIGHT;
  }
}

export function initDropEventPreventDefault(): void {
  window.addEventListener('dragover', dropEventPreventDefault, false);
  window.addEventListener('drop', dropEventPreventDefault, false);
}

export function resetDropEventPreventDefault(): void {
  window.removeEventListener('dragover', dropEventPreventDefault, false);
  window.removeEventListener('drop', dropEventPreventDefault, false);
}

export function dropEventPreventDefault(e: DragEvent): void {
  e.preventDefault();
}

export function readFileToImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const image = new Image();

    fileReader.onload = () => {
      image.onload = () => {
        resolve(image);
      };
      image.onerror = (err, b, c) => {
        reject(file.name);
      };

      image.src = fileReader.result as string;
    };

    fileReader.readAsDataURL(file);
  });
}

export async function pictureThumbnailValidation(
  fileList: FileList,
  formControlLength: number
): Promise<boolean> {
  if (!fileList) {
    alert('파일만 업로드 가능합니다.');
    return false;
  }

  if (formControlLength + fileList.length > 20) {
    alert('최대 20개 까지만 업로드 가능합니다.');
    return false;
  }

  if (!checkOnlyImageFiles(fileList)) {
    alert('jpg, png 파일만 업로드 가능합니다.');
    return false;
  }

  let isValid: boolean;

  try {
    const isAllowSize = await checkIsAllowImageSize(fileList);

    if (!isAllowSize) {
      alert('이미지는 최소 1,024px 보다 커야 합니다.');
      isValid = false;
    } else {
      isValid = true;
    }
  } catch (e) {
    alert(e + ' 해당 파일은 지원하지 않는 형식입니다.');
    isValid = false;
  }

  return isValid;
}

export function checkOnlyImageFiles(fileList: FileList): boolean {
  const extensions: PhotoExtensionType[] = [];

  for (let i = 0; i < fileList.length; i++) {
    extensions.push(getPhotoExtensionType(getFileExtension(fileList[i].type)));
  }

  return extensions.some((type: PhotoExtensionType) => Boolean(type));
}

export function checkIsAllowImageSize(fileList: FileList): Promise<boolean> {
  const promises: Promise<boolean>[] = [];

  for (let i = 0; i < fileList.length; i++) {
    promises.push(
      readFileToImage(fileList[i]).then((image) => {
        return image.width > 1024 || image.height > 1024;
      })
    );
  }

  return Promise.all(promises).then((uploadAllowSizes: boolean[]) => {
    return uploadAllowSizes.every((isAllow) => Boolean(isAllow));
  });
}

export function imageMagnificationReplacer(fileName: string, magnification: number) {
  return fileName.replace(/.([^.]*)$/, `@${magnification}x.$1`);
}

export function generateTempFileName(fileName: string) {
  const splitName = fileName.split('.');
  const ext = splitName[splitName.length - 1];
  return `${Date.now().toString(16)}${generateId(10)}.${ext}`;
}

export function generateId(len: number) {
  const arr = new Uint8Array((len || 40) / 2);
  const crypto = window.crypto || (window as any).msCrypto;
  crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

function dec2hex(dec: number) {
  return ('0' + dec.toString(16)).substr(-2);
}

export function generateDir(): string {
  const now = new Date();
  return `temp/${now.getFullYear()}/${now.getMonth() + 1}`;
}

export function generateName(name: string): string {
  return `${Date.now().toString(16)}-${name}`;
}

export function readAsDataURL(file: File): Observable<any> {
  const reader = new FileReader();

  const observable = fromEvent(reader, 'load').pipe(take(1));

  reader.readAsDataURL(file);

  return observable;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}
