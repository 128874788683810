import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordingLicenseFormComponent } from './components/according-license-form/according-license-form.component';
import { SrcDirective } from './components/src.directive';
import { LibLicenseModule } from '../../../projects/weenidy/src/lib/modules/license/license.module';

@NgModule({
  declarations: [AccordingLicenseFormComponent, SrcDirective],
  imports: [CommonModule, LibLicenseModule],
  exports: [AccordingLicenseFormComponent, SrcDirective],
})
export class CommonFormModule {}
