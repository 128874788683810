import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'wndDateType',
})
export class WndDateTypePipe implements PipeTransform {
  transform(value: any, format: string = 'YYYY-MM-DD'): any {
    if (!value) {
      return '';
    }
    return moment(value).format(format);
  }
}
