import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';
import { Badge } from '../../../../../../../src/entities/badge/types';

@Injectable({
  providedIn: 'root',
})
export class NgBadgeDb extends FirestoreDbAdapter<Badge> {
  constructor() {
    super(firebase.firestore(), 'badges', { parentCollectionNames: ['users'] });
  }
}
