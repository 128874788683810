import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProfileCardModule } from '../profile-card/profile-card.module';
import { TextsModule } from '../../../../../weenidy/src/lib/modules/texts/texts.module';
import { UserInfoProfileCardComponent } from './components/user-info-profile-card/user-info-profile-card.component';
import { UserInfoFormComponent } from './components/user-info-form/user-info-form.component';
import { LibFormModule } from '../../../../../weenidy/src/lib/shared/form/lib-form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { WndPipeModule } from '../../../../../weenidy/src/lib/modules/pipe/wnd.pipe.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PhoneFormModule } from '../phone-form/phone-form.module';

@NgModule({
  imports: [
    CommonModule,
    ProfileCardModule,
    TextsModule,
    LibFormModule,
    ReactiveFormsModule,
    WndPipeModule,
    MatDialogModule,
    PhoneFormModule,
  ],
  declarations: [UserInfoProfileCardComponent, UserInfoFormComponent],
  exports: [UserInfoProfileCardComponent, UserInfoFormComponent]
})
export class UserModule {}
