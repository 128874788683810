<div class="search-box" [class.focus]="isFocus">
  <label for="search-input">
    <img wndSrc="/assets/icons/input-search-icn.png" alt="search icon" useHiDpi="true" />
  </label>

  <input
    #searchInput
    type="text"
    class="search-input"
    id="search-input"
    placeholder="검색"
    autocomplete="off"
    [formControl]="formCtrl"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
</div>
