<div class="more-text-box">
  <div class="more-inner-box" (click)="clickMore()">
    <p class="more-text">더보기</p>

    <img
      class="more-arrow-icn"
      src="assets/images/icons/icn-more-arrow-n.png"
      alt="오른쪽 방향 아이콘"
    >
  </div>
</div>
