<div class="loading-dialog-box">
  <i class="wnd-icn loading"></i>

  <ng-container *ngIf="type === 'file'">
    <p class="uploading-text">이미지가 업로드 중입니다.</p>
  </ng-container>

  <ng-container *ngIf="type === 'resource'">
    <p class="uploading-text">리소스가 업로드 중입니다.</p>
  </ng-container>

  <p class="upload-attention-text">파일 크기, 파일 수, 인터넷 연결 속도에 따라 10분 이상 걸릴 수 있습니다.</p>

  <div>
    <mat-progress-bar
      [value]="percent"
    ></mat-progress-bar>
  </div>
</div>

