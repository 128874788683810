import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NgConfigDb extends FirestoreDbAdapter<any> {
  constructor() {
    super(firebase.firestore(), 'config', { recordTime: false });
  }
}
