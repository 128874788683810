import {
  ColdObservable,
  ColdObservableOnce,
  HotObservableOnce,
  InfinityList,
} from 'src/core/types';
import { DbAdapter } from 'src/core/db/db.adapter';
import { DbListResponse, DbOptions, DbQuery } from 'src/core/db/types';
import { CouponHistory } from './types';
import { SearchAdapter } from 'src/core/search/search.adapter';
import { SearchQuery, SearchResponse } from 'src/core/search/types';
import { Coupon, CouponStatus, LimitationsType } from '../coupon/types';
import { of, switchMap } from 'rxjs';
import moment from 'moment';
import { makeSearchInfinityList } from 'src/core/search/utils';

export class CouponHistoryService {
  constructor(
    protected couponHistoryDb: DbAdapter<CouponHistory>,
    protected couponHistorySearch: SearchAdapter<CouponHistory>,
    protected couponDb: DbAdapter<Coupon>
  ) {}

  createId(): string {
    return this.couponHistoryDb.createId();
  }
  add(doc: Partial<CouponHistory>, opt?: DbOptions): HotObservableOnce<CouponHistory> {
    return this.couponHistoryDb.add(doc, opt);
  }

  update(id: string, doc: Partial<CouponHistory>, opt?: DbOptions): HotObservableOnce<void> {
    return this.couponHistoryDb.update(id, doc, opt);
  }

  upsert(id: string, doc: Partial<CouponHistory>): HotObservableOnce<CouponHistory> {
    return this.couponHistoryDb.upsert(id, doc);
  }

  get(id: string): ColdObservableOnce<CouponHistory> {
    return this.couponHistoryDb.get(id);
  }

  delete(id: string): HotObservableOnce<void> {
    return this.couponHistoryDb.delete(id);
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<CouponHistory>> {
    return this.couponHistoryDb.list(query, options);
  }

  search(query: SearchQuery): ColdObservable<SearchResponse<CouponHistory>> {
    return this.couponHistorySearch.search(query);
  }

  searchInfinityList(query: SearchQuery): InfinityList<CouponHistory> {
    return makeSearchInfinityList(this.couponHistorySearch, query);
  }

  checkCreateCouponHistoryByCouponId({ user, couponId }) {
    return this.couponDb
      .get(couponId)
      .pipe(switchMap((coupon: Coupon) => this.checkCreateCouponHistory({ user, coupon })));
  }
  checkCreateCouponHistory({ user, coupon }): HotObservableOnce<any> {
    //쿠폰 상태 검사
    const status = coupon.status;
    if (status !== CouponStatus.open) return of({ ok: false, message: '쿠폰발급이 불가합니다.' });

    //발급가능 수량 검사
    const isUnlimited = coupon.isUnlimited;
    const limitedCount = coupon.limitedCount;
    const publishedCount = coupon.publishedCount || 0;
    if (!isUnlimited && publishedCount + 1 > limitedCount)
      return of({ ok: false, message: '쿠폰발급이 마감되었습니다.' });

    //발급기간 검사
    const startAt = coupon.deliveryAbleStartAt;
    const endAt = coupon.deliveryAbleEndAt;
    if (!moment().isBetween(moment(startAt), moment(endAt)))
      return of({ ok: false, message: '발급기간이 아닙니다.' });

    //재다운로드 검사
    const limitations = coupon.limitationKeys;
    const nonReDownload = LimitationsType.nonReDownload + '';

    if (limitations.includes(nonReDownload)) {
      return this.couponHistorySearch
        .search({
          filters: [
            { field: 'user_id', comparison: '==', value: user.id, logical: 'and' },
            {
              field: 'coupon_id',
              comparison: '==',
              value: coupon.id,
              logical: 'and',
            },
          ],
        })
        .pipe(
          switchMap((data) => {
            if (data.items.length > 0)
              return of({ ok: false, message: '이미 발급받은 쿠폰입니다.' });
            return this.publishCoupon({ user, coupon });
          })
        );
    } else {
      return this.couponHistorySearch
        .search({
          filters: [
            { field: 'user_id', comparison: '==', value: user.id, logical: 'and' },
            {
              field: 'coupon_id',
              comparison: '==',
              value: coupon.id,
              logical: 'and',
            },
            {
              field: 'isUsed',
              comparison: '==',
              value: false,
              logical: 'and',
            },
          ],
        })
        .pipe(
          switchMap((data) => {
            if (data.items.length > 0)
              return of({
                ok: false,
                message: `이미 발급받은 쿠폰이 있습니다.`,
              });
            return this.publishCoupon({ user, coupon });
          })
        );
    }
  }

  publishCoupon(doc: Partial<CouponHistory>): HotObservableOnce<any> {
    this.couponDb.increase(doc.coupon.id, 'publishedCount', 1);
    return this.couponHistoryDb.add({
      ...doc,
      isUsed: false,
      isUsedAt: null,
      isExpired: false,
      useAbleStartAt: moment().startOf('day').toDate(),
      useAbleEndAt: moment().add(doc.coupon.useAbleDays, 'days').endOf('day').toDate(),
    });
  }
}
