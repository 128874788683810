<div class="dialog-box" *ngIf="isLoading">
  <img
    class="info-dialog-close-icon"
    src="/assets/images/icons/icn-cancel-gray.png"
    alt="닫기 아이콘"
    (click)="close()"
  />

  <div class="dialog-contents-box">
    <img class="dialog-icon" src="/assets/images/icons/icn-information.png" alt="상태 아이콘" />
    <h4 class="dialog-title">{{ title }}</h4>

    <article class="dialog-content" [innerHTML]="contents"></article>

    <span class="dialog-info">{{ info }}</span>
  </div>
</div>
