<div class="dialog-box">
  <div class="dialog-contents-box">
    <h4 class="dialog-title">{{ coupon | convertTitle }}</h4>

    <article class="dialog-content">
      <div class="content-title">쿠폰 제약사항</div>
      <div class="content-descriptions">
        {{ coupon | convertLimitationsPipe }}
      </div>
      <div *ngIf="coupon.listOfAllowedItemIds" class="content-title">적용가능한 상품</div>
      <nb-card *ngIf="coupon.listOfAllowedItemIds">
        <nb-card-body>
          <nb-list class="content-descriptions">
            <nb-list-item class="item-container" *ngFor="let item of listOfAllowedItems">
              <a [href]="'/resource/detail/' + item.id" target="_blank">
                <div class="item-preview">
                  <img [src]="item.thumbnail.originWebp || item.thumbnail.origin" />
                  <h3>{{ item.title }}</h3>
                </div>
              </a>
            </nb-list-item>
          </nb-list>
        </nb-card-body>
      </nb-card>
    </article>
  </div>
  <button class="close-btn" (click)="onClosedPopup()">X</button>
</div>
<button class="close-btn2" (click)="onClosedPopup()">X</button>
