import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsSevenDayPipe } from './pipes/is-seven-day.pipe';
import { KrwSeparatorPipe } from './pipes/krw-separator.pipe';
import { UsdPipe } from './pipes/usd-separator.pipe';
import { WndDateTypePipe } from './pipes/wnd-date-type.pipe';
import { WndDatePipe } from './pipes/wnd-date.pipe';
import { CodeTextPipe } from './pipes/code-text.pipe';
import { PhoneParsePipe } from './pipes/phone-parse.pipe';
import { ConvertBadgeNamePipe } from './pipes/convert-badge-name.pipe';
import { FormHasErrorPipe } from './pipes/form-has-error.pipe';
import { BadgeThumbnailPipe } from './pipes/badge-thumbnail.pipe';
import { BadgeCountThumbnailPipe } from './pipes/badge-count-thumbnail.pipe';

@NgModule({
  declarations: [
    KrwSeparatorPipe,
    WndDatePipe,
    CodeTextPipe,
    PhoneParsePipe,
    IsSevenDayPipe,
    WndDateTypePipe,
    UsdPipe,
    ConvertBadgeNamePipe,
    FormHasErrorPipe,
    BadgeThumbnailPipe,
    BadgeCountThumbnailPipe,
  ],
  imports: [CommonModule],
  exports: [
    KrwSeparatorPipe,
    WndDatePipe,
    CodeTextPipe,
    PhoneParsePipe,
    IsSevenDayPipe,
    WndDateTypePipe,
    UsdPipe,
    ConvertBadgeNamePipe,
    FormHasErrorPipe,
    BadgeThumbnailPipe,
    BadgeCountThumbnailPipe,
  ],
})
export class WndPipeModule {}
