import { Pipe, PipeTransform } from '@angular/core';
import { LicenseType, LicenseTypeMultiple } from '../../../../../../../src/entities/cart/types';

@Pipe({
  name: 'licenseTypeToPrice',
})
export class LicenseTypeToPricePipe implements PipeTransform {
  transform(type: LicenseType, price: number): number {
    return !type ? price : price * LicenseTypeMultiple[type];
  }
}
