<img
  *ngIf="imageType === 'string'"
  class="resource-detail-image"
  [src]="data"
  alt=""
>

<wnd-preview-image-card
  *ngIf="imageType === 'object'"
  [src]="data"
></wnd-preview-image-card>
