import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[wndButtonHover]',
})
export class ButtonHoverDirective {
  @HostListener('mouseenter') onHoverEnter() {
    this.isHover = true;
    this.hoverEvent.emit(true);
    console.log('enter hover', this.isHover);
  }

  @HostListener('mouseleave') onBlurLeave() {
    this.isHover = false;
    console.log('enter blur', this.isHover);

    setTimeout(() => {
      if (!this.isHover) {
        this.hoverEvent.emit(false);
      }
    }, 250);
  }

  @Output() hoverEvent = new EventEmitter<boolean>();
  @Output() blurEvent = new EventEmitter<boolean>();

  private isHover = false;

  constructor() {}
}
