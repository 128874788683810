<input
  class="auto-complete-input"
  type="text"
  [formControl]="formCtrl"
  [matAutocomplete]="auto"
  (blur)="checkValue()"
/>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option
    *ngFor="let option of filterOptions$ | async"
    [disabled]="selectedControls.includes(option.value)"
    [value]="option.value"
    >{{ option.value }}</mat-option
  >
</mat-autocomplete>
