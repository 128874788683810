import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';


@Pipe({
  name: 'phoneVerifyMinutes'
})
export class PhoneVerifyMinutesPipe implements PipeTransform {

  transform(value: number): unknown {
    return moment(value).format('mm:ss');
  }

}
