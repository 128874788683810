<div class="dialog-box">
  <div class="dialog-contents-box">
    <img
      class="dialog-icon"
      src='/assets/images/icons/icn-warning.png'
      alt="상태 아이콘"
    >
    <h4 class="dialog-title">피드백</h4>

    <article class="dialog-content" [innerHTML]="contents"></article>
  </div>

  <div class="dialog-buttons-box">
    <button
      class="wnd-btn"
      (click)="clickPrimaryButton()"
    >확인</button>
  </div>
</div>
