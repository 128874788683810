import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { AuthLoginPayload, AuthLoginState } from '../../../../akita-firebase/auth/auth.model';
import { NgAuthService } from '../../../../../../../weenidy/src/lib/modules/auth/auth.service';
import { User } from '../../../../../../../../src/entities/user/types';
import {
  DialogComponent,
  WndDialogData,
} from '../../../../../../../weenidy/src/lib/modules/dialog/components/dialog/dialog.component';
import firebase from 'firebase/compat/app';
import { SubscriptionBaseComponent } from '../../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';

declare type AuthError = firebase.auth.AuthError;

@Component({
  selector: 'wnd-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent extends SubscriptionBaseComponent implements OnInit {
  @Output() closeMenu = new EventEmitter<boolean>();
  isLoggedIn: boolean;
  isWriter: boolean;

  user: User;

  constructor(
    private authService: NgAuthService,
    private router: Router,
    private dialog: MatDialog
  ) {
    super();
  }

  get isProd() {
    return environment.production;
  }

  ngOnInit(): void {
    this.initObservable('isLoggedIn', this.authService.isLoggedIn$);
    this.initObservable('isWriter', this.authService.isWriter$);
    this.initObservable('user', this.authService.user$);
  }

  googleContinue(): void {
    let authLoginPayload: AuthLoginPayload;
    this.authService
      .loginWithGoogle()
      .pipe(
        // tap((payload: AuthLoginPayload) => (authLoginPayload = payload)),
        // switchMap(() => this.authService.user$),
        filter((user) => Boolean(user)),
        take(1)
      )
      .subscribe(
        (user: any) => {
          this.user = user;

          if (user.deleted) {
            this.closeMenu.emit(false);
            return this.openDeletedUserDialog();
          }
          if (
            authLoginPayload.state === AuthLoginState.First ||
            (authLoginPayload.state === AuthLoginState.Ok && !user.terms)
          ) {
            this.router.navigate(['/sign/confirm']).then();
          } else {
            this.router.navigate(['/index']).then();
          }

          this.closeMenu.emit(false);
        },
        (error: AuthError) => {
          let message: string;
          if (error.code === 'user-deleted') {
            message = '탈퇴한 회원입니다.';
          }

          if (message) {
            this.openErrorDialog(message);
          }
        }
      );
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/']).then();
    });
  }

  clickClose() {
    this.closeMenu.emit(false);
  }

  private openErrorDialog(message: string) {
    return this.dialog.open<DialogComponent, WndDialogData>(DialogComponent, {
      disableClose: true,
      data: {
        title: message,
        contents: '',
        isHideSecondaryButtonText: true,
        type: 'waring',
      },
    });
  }

  private openDeletedUserDialog() {
    return this.dialog
      .open<DialogComponent, WndDialogData>(DialogComponent, {
        disableClose: true,
        data: {
          title: '탈퇴한 회원입니다.',
          contents: '',
          isHideSecondaryButtonText: true,
          type: 'waring',
        },
      })
      .afterClosed()
      .subscribe(() => {
        return this.authService.logout();
      });
  }
}
