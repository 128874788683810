import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Sns } from '../../../../../../../src/entities/setting/types';
import { NgSettingService } from '../../../../../../weenidy/src/lib/modules/setting/services/setting.service';
import { openNotion } from '../../../core/utils/utils';

@Component({
  selector: 'wnd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  sns$: Observable<Sns> = this.settingService.getSns();
  constructor(private settingService: NgSettingService) {}

  ngOnInit(): void {}

  openNotion(type: 'notice' | 'term' | 'person' | 'license' | 'recruit' | 'writer' | 'resource') {
    openNotion(type as any);
  }
}
