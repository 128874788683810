<section class="wnd-wrapper">
  <div *ngIf="isIndex$ | async" class="weenidy-intro-toolbar-box" [class.hide]="isHide$ | async">
    <p class="weenidy-intro-text">위니디는 크리에이터를 위한 고퀄리티 리소스 플랫폼입니다.</p>

    <wnd-category-btn
      class="weenidy-intro-btn"
      text="위니디 소개"
      [disabled]="false"
      (click)="openNotion('introduce')"
    ></wnd-category-btn>
  </div>

  <div class="toolbar-box">
    <div
      class="toolbar-left"
      [class.show-search-form]="(isSearchPage$ | async) && (isShowSearchForm$ | async)"
    >
      <img class="logo-image" src="assets/images/logo/logo.png" alt="위니디 로고" routerLink="/" />

      <nav class="toolbar-nav">
        <ul class="menu-list">
          <li *ngFor="let menu of menus" class="menu-item">
            <a
              routerLink="/resource/search"
              class="menu-link"
              routerLinkActive="active"
              [queryParams]="{ category: menu.value }"
            >
              {{ menu.text }}
            </a>
          </li>
        </ul>
      </nav>

      <div class="toolbar-search-form-box">
        <wnd-resource-search-form
          class="toolbar-search-form"
          [panelClass]="['toolbar-search-form-panel', 'wnd-select-panel']"
          [searchFilter]="searchFilter"
          (onFilterChange)="newSearch($event)"
        ></wnd-resource-search-form>
      </div>
    </div>

    <div class="toolbar-right">
      <div class="toolbar-button-box">
        <ng-container *ngIf="!isLoggedIn">
          <button type="button" class="toolbar-creator-button" routerLink="/sign/join">
            리소스 판매하기
          </button>
        </ng-container>

        <ng-container *ngIf="isLoggedIn && !isWriter">
          <button type="button" class="toolbar-creator-button" routerLink="/apply/tutorial">
            리소스 작가 등록
          </button>
        </ng-container>

        <ng-container *ngIf="isLoggedIn && isWriter">
          <button wndToolbarButtonMenu type="button" class="toolbar-creator-button">
            새로운 작업 업로드
          </button>
        </ng-container>
      </div>

      <nav class="auth-toolbar-nav">
        <ul class="auth-menu-list">
          <ng-container *ngIf="!isLoggedIn; else loggedIn">
            <li class="auth-menu-item">
              <a class="right-menu-link" routerLink="/sign/login"> 로그인 </a>
            </li>
          </ng-container>

          <ng-template #loggedIn>
            <li class="auth-menu-item search-icon" routerLink="/resource/search">
              <i class="wnd-icn search"></i>
            </li>

            <li class="auth-menu-item cart-item">
              <!-- <i class="wnd-icn cart" #cartOverlayOrigin (click)="openCartOverlay()"></i> -->
              <a routerLink="/cart/detail"> <i class="wnd-icn cart"></i> </a>

              <p class="cart-count">{{ cartCount || 0 | number }}</p>
            </li>

            <div class="toolbar-menu-wrapper">
              <div class="toolbar-profile-image-box" (click)="showMenu()">
                <!--              (mouseleave)="closeMenu()"-->
                <profile-image-card [profileImage]="profile$ | async"></profile-image-card>
              </div>

              <div *ngIf="isMenuOpen" @fadeIn @fadeOut class="toolbar-menu-outer-box">
                <div class="toolbar-menu-box">
                  <ul class="toolbar-menu-list">
                    <li class="user-profile-box">
                      <wnd-user-info-profile-card [user]="user"></wnd-user-info-profile-card>
                    </li>

                    <li class="toolbar-menu-item divider-line"></li>

                    <ng-container *ngIf="isWriter">
                      <li class="toolbar-menu-item">
                        <p class="toolbar-menu-label">
                          <img
                            class="toolbar-menu-label-icon"
                            wndSrc="/assets/icons/toolbar/icn-resource list.png"
                            [useHiDpi]="true"
                            alt="icn-resource"
                          />

                          작가 활동 관리
                        </p>
                      </li>

                      <li class="toolbar-menu-item">
                        <a
                          class="auth-menu-link new-icon"
                          [routerLink]="['/creator', user?.creatorPath || user?.id]"
                        >
                          나의 크리에이티브
                        </a>
                      </li>

                      <li class="toolbar-menu-item">
                        <a class="auth-menu-link" routerLink="my-page/resource-list">
                          리소스 심사 현황
                        </a>
                      </li>

                      <li class="toolbar-menu-item">
                        <a class="auth-menu-link" routerLink="/my-page/calculate-page">
                          판매 및 정산
                        </a>
                      </li>

                      <li class="toolbar-menu-item divider-line"></li>
                    </ng-container>

                    <li class="toolbar-menu-item">
                      <p class="toolbar-menu-label">
                        <img
                          class="toolbar-menu-label-icon"
                          wndSrc="/assets/icons/toolbar/icn-storage%20copy.png"
                          [useHiDpi]="true"
                          alt="icn-storage"
                        />

                        내 위니디
                      </p>
                    </li>

                    <li class="toolbar-menu-item">
                      <a class="auth-menu-link" routerLink="/my-page/info"> 회원 정보 관리 </a>
                    </li>

                    <li class="toolbar-menu-item">
                      <a class="auth-menu-link" routerLink="/my-page/locker">리소스 보관함</a>
                    </li>

                    <li class="toolbar-menu-item">
                      <a class="auth-menu-link" routerLink="/my-page/payments-list">결제내역</a>
                    </li>

                    <li class="toolbar-menu-item">
                      <a class="auth-menu-link" routerLink="/my-page/coupon-list/use-able"
                        >쿠폰함</a
                      >
                    </li>

                    <li class="toolbar-menu-item divider-line"></li>

                    <li class="toolbar-menu-item">
                      <p class="toolbar-menu-label">
                        <img
                          class="toolbar-menu-label-icon"
                          wndSrc="/assets/icons/toolbar/icn_qa.png"
                          [useHiDpi]="true"
                          alt="icn-storage"
                        />

                        고객센터
                      </p>
                    </li>

                    <li class="toolbar-menu-item">
                      <a class="auth-menu-link" routerLink="/my-page/qna"> 문의하기 </a>
                    </li>

                    <li class="toolbar-menu-item">
                      <a class="auth-menu-link" (click)="openNotion('notice')"> 공지사항 </a>
                    </li>

                    <li class="toolbar-menu-item divider-line"></li>

                    <li class="toolbar-menu-item">
                      <a class="toolbar-logout-link" (click)="logout()">
                        <img
                          class="toolbar-menu-label-icon"
                          wndSrc="/assets/icons/toolbar/icn-logout.png"
                          [useHiDpi]="true"
                          alt="icn-storage"
                        />

                        로그아웃
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
        </ul>
      </nav>
    </div>
  </div>
</section>

<section class="mobile-toolbar-section">
  <div *ngIf="isIndex$ | async" class="weenidy-intro-toolbar-box" [class.hide]="isHide$ | async">
    <p class="weenidy-intro-text">크리에이터를 위한 리소스 플랫폼, 위니디!</p>

    <wnd-category-btn
      class="weenidy-intro-btn"
      text="위니디 소개"
      [disabled]="false"
      (click)="openNotion('introduce')"
    ></wnd-category-btn>
  </div>

  <div class="mobile-toolbar-box">
    <div class="mobile-toolbar-left-box">
      <i class="wnd-icn menu" (click)="menu()"> </i>
    </div>

    <img class="logo-image" src="assets/images/logo/logo.png" alt="위니디 로고" routerLink="/" />

    <div class="mobile-toolbar-right-box">
      <div class="mobile-cart-item-box">
        <a routerLink="/cart/detail">
          <i class="wnd-icn cart"></i>
        </a>
        <!-- <i class="wnd-icn cart" #cartOverlayOrigin (click)="openCartOverlay()"></i> -->

        <p class="cart-count">{{ cartCount || 0 | number }}</p>
      </div>

      <i class="wnd-icn search" routerLink="/search/keyword"> </i>
    </div>
  </div>
</section>
