<div class='radio-button-wrapper'>
  <div
    *ngFor="let item of radioItems"
    class="radio-button-box"
    (click)="onClickRadio(item.value)"
  >
    <input
      class="input-radio"
      type="radio"
      [value]="item.value"
      [ngModel]="value"
    >

    <button
      type="button"
      class="radio-button"
    >
      {{ item.text }}
    </button>
  </div>
</div>
