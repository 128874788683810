import { Content } from './types';
import {
  ColdObservable,
  ColdObservableOnce,
  HotObservableOnce,
  InfinityList,
} from 'src/core/types';
import { DbAdapter } from 'src/core/db/db.adapter';
import { DbListResponse, DbOptions, DbQuery } from 'src/core/db/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchAdapter } from 'src/core/search/search.adapter';
import { SearchQuery } from 'src/core/search/types';
import { makeDbInfinityList } from 'src/core/db/utils';
import { makeSearchInfinityList } from 'src/core/search/utils';

export class ContentService {
  constructor(protected contentDb: DbAdapter<Content>, protected contentSearch: SearchAdapter) {}

  createId(): string {
    return this.contentDb.createId();
  }
  add(doc: Partial<Content<any>>, opt?: DbOptions): HotObservableOnce<Content> {
    return this.contentDb.add(doc, opt);
  }

  update(id: string, doc: Partial<Content>, opt: DbOptions): HotObservableOnce<void> {
    return this.contentDb.update(id, doc, opt);
  }

  upsert(id: string, doc: Partial<Content>): HotObservableOnce<Content> {
    return this.contentDb.upsert(id, doc);
  }

  get(id: string): ColdObservableOnce<Content> {
    return this.contentDb.get(id);
  }

  delete(id: string): HotObservableOnce<void> {
    return this.contentDb.delete(id);
  }

  getFromPaymentId<T = any>(paymentId: string): ColdObservable<Content<T>> {
    const query: DbQuery = {
      filters: [{ field: 'paymentId', comparison: '==', value: paymentId }],
    };

    return this.contentDb
      .list(query)
      .pipe(map((response: DbListResponse<Content>) => response.docs[0]));
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<Content>> {
    return this.contentDb.list(query, options);
  }
  listAllChange<T = any>(userId: string): ColdObservable<Content<T>[]> {
    return this.contentDb
      .listChange(
        {
          filters: [{ field: 'isPayCancel', comparison: '==', value: false }],
        },
        { parentIds: [userId] }
      )
      .pipe(map((response) => response.docs));
  }

  listPaidChange<T = any>(userId: string): ColdObservable<Content<T>[]> {
    const query: DbQuery = {
      filters: [
        { field: 'isPay', comparison: '==', value: true },
        { field: 'isPayCancel', comparison: '==', value: false },
      ],
    };

    return this.contentDb
      .listChange(query, { parentIds: [userId] })
      .pipe(map((response) => response.docs));
  }

  isCartItemInContents(ids: string[]) {
    return this.contentDb.list({
      filters: [{ field: 'item.id', comparison: 'array-contains', value: ids }],
    });
  }

  isBuy(resourceId: string, userId: string): Observable<string> {
    const query: DbQuery = {
      filters: [
        { field: 'isPayCancel', comparison: '==', value: false },
        { field: 'info.id', comparison: '==', value: resourceId },
      ],
      limit: 10,
    };

    return this.contentDb
      .listChange(query, { parentIds: [userId] })
      .pipe(map((response) => response.docs[0]?.id));
  }
  searchList(query?: DbQuery, opt?: DbOptions) {
    return this.contentDb.list(query, opt);
    // TODO 구현부 확인 필요
    // return this.syncCollection(query ? convertQueryForFirestore(query) : undefined);
  }

  infinityList(query: DbQuery, opt?: DbOptions): InfinityList<Content> {
    return makeDbInfinityList(this.contentDb, query, opt);
  }

  //ContentsQuery

  selectLoading(): Observable<boolean> {
    //TODO: 구현해야함
    return of(true);
  }

  isBuyContent(id: string, userId: string): Observable<string> {
    //ContentsQuery
    const query: DbQuery = {
      filters: [
        { field: 'isPayCancel', comparison: '==', value: false },
        { field: 'info.id', comparison: '==', value: id },
      ],
    };

    return this.list(query, { parentIds: [userId] }).pipe(map((contents) => contents.docs[0]?.id));
  }

  /**
   *   
  isBuyContent(id: string): Observable<string> {
    const query: Query = {
      filters: [
        { field: 'isPayCancel', comparison: '==', value: false },
        { field: 'info.id', comparison: '==', value: id },
      ]
    };

    return this.selectAll(convertQueryForAkita(query))
      .pipe(
        map((contents: Content<any>[]) => {
          return contents[0]?.id;
        })
      );
  }
   */
}
