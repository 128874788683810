import { LicenseType } from '../../../../../../../../src/entities/cart/types';

export const PaymentI18n = {
  header: {
    ko: '주문/결제',
    en: 'Purchase item',
  },
  orderButton: {
    ko: '결제하기',
    en: 'Buy now',
  },
  orderInfo: {
    ko: '주문자 정보',
    en: 'Customer information',
  },
  orderInfoText: {
    ko: '입력하신 메일 주소로 결제 완료 메일이 발송됩니다.',
    en: 'Payment completion email will be sent to the email address you registered.',
  },
  name: {
    ko: '이름',
    en: 'Customer name',
  },
  phone: {
    ko: '휴대폰',
    en: 'Customer Phone Number',
  },
  email: {
    ko: '이메일',
    en: 'Customer email address',
  },
  payType: {
    ko: '결제 수단',
    en: 'Method of payment',
  },
  card: {
    ko: '일반카드결제',
    en: 'Card payment',
  },
  totalText: {
    ko: '총 결제 금액',
    en: 'Total Payment Amount',
  },
  confirm: {
    ko: '아래 내용에 모두 동의 합니다.',
    en: 'I agree with all of the following.',
  },
  confirmTerms: {
    ko: [
      `(필수) 결제조건 확인 및 주문 상품정보에 동의`,
      `(필수) 결제대행서비스 이용을 위한\n개인정보 제 3자 제공 및 위탁 동의`,
      `(필수) 개인정보 수집 및 이용에 대한 동의`,
    ],
    en: [
      `(Required) Confirm payment terms and agree to order product information.`,
      `(Required) Consent to providing and entrusting personal information to third parties for the use of payment`,
      `(Required) Consent to the collection and use of personal information.`,
    ],
  },
  licenseUsedUserName: {
    [LicenseType.Person]: {
      ko: '작가명 또는 필명',
      en: "Author's name or pen name",
    },
    [LicenseType.Company]: {
      ko: '기업명 또는 팀명',
      en: 'Company or team name',
    },
  },
  paymentInfo: {
    title: {
      ko: '결제 금액',
      en: 'Amount of payment',
    },
    amount: {
      ko: '상품 금액',
      en: 'Total amount',
    },
    discount: {
      ko: '할인 금액',
      en: ' Discount amount',
    },
    finalAmount: {
      ko: '최종 결제 금액',
      en: 'Final payment amount',
    },
  },
};

export const AttentionI18n = {
  nice: '·&nbsp;결제 대금은 NICEPAY 명의로 청구 됩니다. <br>·&nbsp;디지털 콘텐츠 특성상 구매한 리소스에 대해서는 결제취소가 되지 않습니다.<br>·&nbsp;결제가 완료되면 자동으로 다운로드 됩니다. 결제시 PC 사용을 권장합니다.<br>·&nbsp;리소스 구매전 <a class="open-link" href="https://dent-price-d42.notion.site/6902ccdd334a4458ad9f3c21a3bfe2c0#65931ff1d9ce4ffe9387d8d4528815c3" target="_blank">이용약관<a/> 및 <a class="open-link" href="https://dent-price-d42.notion.site/b01aab56df0e49958571154621806811" target="_blank">라이선스</a> 를 확인해보시기를 바랍니다.<br>·&nbsp;이용약관 및 라이선스를 준수하지 않고 발생한 문제의 경우 당사가 책임지지 않습니다.<br>·&nbsp;<span class="highlight-text">구매한 모든 리소스의 불법 유포 및 구매자 이외 사용에 대하여 위니디와 리소스 판매자는 어떤 경우에도 불관용의 원칙을 따를 것입니다.</span>',
  kakaopay:
    '·&nbsp;카카오페이 간편결제 혜택 및 할부 적용 여부는 해당 카드사 &nbsp;정책에 따라 변경될 수 있습니다.<br>·&nbsp;디지털 콘텐츠 특성상 구매한 리소스에 대해서는 결제취소가 되지 않습니다.<br>·&nbsp;결제가 완료되면 자동으로 다운로드 됩니다. 결제시 PC 사용을 권장합니다.<br>·&nbsp;리소스 구매전 <a class="open-link" href="https://dent-price-d42.notion.site/6902ccdd334a4458ad9f3c21a3bfe2c0#65931ff1d9ce4ffe9387d8d4528815c3" target="_blank">이용약관<a/> 및 <a class="open-link" href="https://dent-price-d42.notion.site/b01aab56df0e49958571154621806811" target="_blank">라이선스</a> 를 확인해보시기를 바랍니다.<br>·&nbsp;이용약관 및 라이선스를 준수하지 않고 발생한 문제의 경우 &nbsp;당사가 책임지지 않습니다.<br>·&nbsp;<span class="highlight-text">구매한 모든 리소스의 불법 유포 및 구매자 이외 사용에 대하여 위니디와 리소스 판매자는 어떤 경우에도 불관용의 원칙을 따를 것입니다.</span>',
  paypal:
    '·&nbsp;PayPal payment is made in US dollars (USD).<br>·&nbsp;PayPal payment may not be approved in Korea.<br>·&nbsp;Due to the nature of digital content, payment cannot be canceled for purchased resources.<br>·&nbsp;When payment is completed, it will be downloaded automatically. We recommend using a PC for payment.<br>·&nbsp;Please read the <a class="open-link" href="https://dent-price-d42.notion.site/6902ccdd334a4458ad9f3c21a3bfe2c0#65931ff1d9ce4ffe9387d8d4528815c3" target="_blank">Terms of Use<a/> and <a class="open-link" href="https://dent-price-d42.notion.site/b01aab56df0e49958571154621806811" target="_blank">license</a> before purchasing a resource.<br>·&nbsp;We are not responsible for any problems caused by non-compliance with the terms of use and license.<br>·&nbsp;<span class="highlight-text">Weenidy and the resource seller will follow the principle of no tolerance in any case against the illegal distribution of all purchased resources and the use of non-buyers.</span>',
};
