<div class="badge-image-card-box">
  <img class="badge-image" [src]="badge.category | badgeThumbnail: badge.count" alt="badge-icon" />

  <img
    *ngIf="badge.count && !(badge.category === BadgeCategory.SaleAmount && badge.count === 3000)"
    class="badge-count-image"
    [src]="badge.category | badgeCountThumbnail: badge.count"
    alt="badge-count-icon"
  />
</div>
