import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';
import { PaymentErrorPayload } from 'src/entities/payment-error/types';

@Injectable({
  providedIn: 'root',
})
export class NgPaymentErrorDb extends FirestoreDbAdapter<PaymentErrorPayload> {
  constructor() {
    super(firebase.firestore(), 'payment-errors');
  }
}
