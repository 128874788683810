import { BaseEntity } from '../../core/base-entity';

export interface Popup extends BaseEntity {
  type: PopupType;
  link?: string;
  image?: string;
  contents?: string;
}

export enum PopupType {
  Empty = 1,
  Image = 10,
  Text = 20,
}
