import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextsModule } from '../texts/texts.module';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { PreviewImageCardComponent } from './components/preview-image-card/preview-image-card.component';
import { ProfileImageCardComponent } from './components/profile-image-card/profile-image-card.component';
import { SliderBannerFullPcComponent } from './components/slider-banner-full-pc/slider-banner-full-pc.component';
import { SliderBannerFullComponent } from './components/slider-banner-full/slider-banner-full.component';
import { SliderBannerItemPcComponent } from './components/slider-banner-item-pc/slider-banner-item-pc.component';
import { SliderBannerItemComponent } from './components/slider-banner-item/slider-banner-item.component';
import { SliderBannerComponent } from './components/slider-banner/slider-banner.component';
import { UploadImageBoxComponent } from './components/upload-image-box/upload-image-box.component';
import { SrcDirective } from './directives/src.directive';
import { FlexibleClassDirective } from './directives/flexible-class.directive';

const commonComponent = [
  SrcDirective,
  FlexibleClassDirective,
  ImageCardComponent,
  SliderBannerComponent,
  SliderBannerFullComponent,
  SliderBannerFullPcComponent,
  PreviewImageCardComponent,
  SliderBannerItemPcComponent,
  SliderBannerItemComponent,
  UploadImageBoxComponent,
  ProfileImageCardComponent,
];

@NgModule({
  declarations: commonComponent,
  exports: commonComponent,
  imports: [CommonModule, TextsModule],
})
export class ImagesModule {}
