import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy, Output, EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../../../../../weenidy/src/lib/shared/form/core/base-value-accessor';

@Component({
  selector: 'wnd-resource-search-input',
  templateUrl: './resource-search-input.component.html',
  styleUrls: ['./resource-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResourceSearchInputComponent),
      multi: true,
    },
  ],
})
export class ResourceSearchInputComponent extends BaseValueAccessor implements OnInit, OnDestroy {
  @ViewChild('wndInput', { static: true }) wndInput: ElementRef;

  @Input() type = 'text';
  @Input() isSearch = false;
  @Input() isPassword = false;

  private subscription = new Subscription();

  pattern = '';

  @Output() focusChange = new EventEmitter<boolean>();
  isFocus = false;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    if (this.type === 'number') {
      this.pattern = '\\d*';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    const target = this.wndInput.nativeElement as HTMLInputElement;
    target.value = '';
  }

  togglePassword() {
    const isPassword = this.type === 'password';
    this.type = isPassword ? 'text' : 'password';
  }

  onFocus(): void {
    this.isFocus = true;
    this.focusChange.emit(true);
  }

  onBlur(): void {
    setTimeout(() => {
      this.isFocus = false;
      this.focusChange.emit(false);
    }, 50);
  }
}
