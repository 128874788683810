import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WndPipeModule } from '../pipe/wnd.pipe.module';
import { MoneyTextComponent } from './components/money-text/money-text.component';
import { TagsTextComponent } from './components/tags-text/tags-text.component';
import { UnderlineTextComponent } from './components/underline-text/underline-text.component';
import { TextEllipsisPipe } from './pipes/text-ellipsis.pipe';

@NgModule({
  declarations: [MoneyTextComponent, TagsTextComponent, UnderlineTextComponent, TextEllipsisPipe],
  exports: [MoneyTextComponent, TagsTextComponent, UnderlineTextComponent],
  imports: [CommonModule, WndPipeModule],
})
export class TextsModule {}
