<form [formGroup]="formGroup">
  <div class="wnd-date-box">
    <wnd-input
      type="number"
      class="year-input profile-input"
      id="year"
      placeholder="년(4자리)"
      formControlName="year"
      [error]="yearControl.errors && this.dateErrorText"
      [useError]="false"
      (keydown)="keyDownOnlyNumber($event)"
    ></wnd-input>

    <wnd-select
      id="month"
      class="profile-select"
      placeholder="월"
      formControlName="month"
      [error]="monthControl.errors && this.dateErrorText"
      [useError]="false"
      [options]="monthOption"
    >
    </wnd-select>

    <wnd-select
      id="date"
      class="profile-select"
      placeholder="일"
      formControlName="date"
      [error]="dateControl.errors && this.dateErrorText"
      [useError]="false"
      [options]="dateOption"
    >
    </wnd-select>
  </div>

  <wnd-error-text *ngIf="dateErrorText" [text]="dateErrorText"></wnd-error-text>
</form>
