import { ElementRef, Injectable } from '@angular/core';
import { LibOverlayService } from '../../../../../../../src/modules/overlay/overlay.service';
import { ConnectedPosition, Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { ToolbarOverlayButtonComponent } from '../components/toolbar-overlay-button/toolbar-overlay-button.component';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolbarButtonOverlayService extends LibOverlayService<ToolbarOverlayButtonComponent> {
  override positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 10,
    },
  ];

  override scrollStrategy: ScrollStrategy = this.overlay.scrollStrategies.noop();

  override hasBackdrop = false;
  override backdropClass: string = 'toolbar-button-overlay-backdrop';

  private position: string;

  constructor(protected override overlay: Overlay) {
    super(overlay, ToolbarOverlayButtonComponent);
  }

  protected override initEventHandler(): void {
    super.initEventHandler();

    this.setSubscription('_initOutsidePointerEvent', this.initOutsidePointerEvent());
  }

  private initOutsidePointerEvent(): Subscription {
    return this.overlayRef.outsidePointerEvents().subscribe(() => this.close());
  }

  override open(elementRef: ElementRef): void {
    super.open({ elementRef, position: this.position });
  }

  setPosition(position: string): void {
    this.position = position;
    if (position === 'right') {
      this.positions = [
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 10,
        },
      ];
    } else {
      this.positions = [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 10,
        },
      ];
    }
  }
}
