import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MainBannerItem } from '../../../../../../../../src/entities/setting/types';

@Component({
  selector: 'wnd-slider-banner-item',
  templateUrl: './slider-banner-item.component.html',
  styleUrls: ['./slider-banner-item.component.scss'],
})
export class SliderBannerItemComponent implements OnInit {
  @Input() banner: MainBannerItem;
  @Output() bannerClick = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  imageClick(bannerItem: MainBannerItem) {
    this.bannerClick.emit(bannerItem.pcLink);
  }
}
