import { Injectable } from '@angular/core';
import { SizeOptions, WeenidyResizeImageOptions } from '../../../../../../../src/core/types';
import { NgFunctionsCaller } from '../../functions-caller/services/functions-caller.service';
import { generateTempFileName, imageMagnificationReplacer } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class ResizeImageService {
  constructor(private caller: NgFunctionsCaller) {}

  resizeImage(
    originalFilePath: string,
    fileName: string,
    resizeImageOptions: WeenidyResizeImageOptions,
    originRemoval: boolean = false,
    waterMark: boolean = false
  ) {
    const sizeOptions: SizeOptions[] = [
      {
        type: 'origin',
        format: 'jpg',
        width: resizeImageOptions.width,
        height: resizeImageOptions.height,
        dir: resizeImageOptions.dir,
        fileName:
          resizeImageOptions.fileName ||
          imageMagnificationReplacer(fileName || generateTempFileName(fileName), 1),
      },
      {
        type: 'originWebp',
        format: 'webp',
        width: resizeImageOptions.width,
        height: resizeImageOptions.height,
        dir: resizeImageOptions.dir,
        fileName:
          resizeImageOptions.fileName ||
          imageMagnificationReplacer(fileName || generateTempFileName(fileName), 1),
      },
    ];

    if (resizeImageOptions.twice) {
      sizeOptions.push({
        type: 'multiple',
        format: 'jpg',
        width: resizeImageOptions.width ? resizeImageOptions.width * 2 : undefined,
        height: resizeImageOptions.height ? resizeImageOptions.height * 2 : undefined,
        dir: resizeImageOptions.dir,
        fileName: resizeImageOptions.fileName
          ? imageMagnificationReplacer(resizeImageOptions.fileName, 2)
          : imageMagnificationReplacer(generateTempFileName(fileName), 2),
      });

      sizeOptions.push({
        type: 'multipleWebp',
        format: 'webp',
        width: resizeImageOptions.width ? resizeImageOptions.width * 2 : undefined,
        height: resizeImageOptions.height ? resizeImageOptions.height * 2 : undefined,
        dir: resizeImageOptions.dir,
        fileName: resizeImageOptions.fileName
          ? imageMagnificationReplacer(resizeImageOptions.fileName, 2)
          : imageMagnificationReplacer(generateTempFileName(fileName), 2),
      });
    }

    return this.caller.resizeImage(originalFilePath, sizeOptions, originRemoval, waterMark);
  }
}
