import { ColdObservableOnce } from '../types';
import { ApiAdapter } from './api.adapter';
import { ApiResponse } from './types';

export class ApiService {
  constructor(protected apiAdapter: ApiAdapter) {}

  bigquery(
    countQuery: string,
    query: string
  ): ColdObservableOnce<ApiResponse<{ count: number; docs: any[] }>> {
    return this.apiAdapter.post('bigquery', { countQuery, query });
  }

  bigqueryOne(query: string): ColdObservableOnce<ApiResponse<any[]>> {
    return this.apiAdapter.post('bigqueryOne', query);
  }

  mysql(
    countQuery: string,
    query: string
  ): ColdObservableOnce<ApiResponse<{ count: number; docs: any[] }>> {
    return this.apiAdapter.post('mysql', { countQuery, query });
  }

  mysqlOne(query: string): ColdObservableOnce<ApiResponse<any[]>> {
    return this.apiAdapter.post('mysqlOne', query);
  }

  sendVerificationCode(phoneNumber: string): ColdObservableOnce<ApiResponse<void>> {
    return this.apiAdapter.post('sendVerificationCode', { phoneNumber });
  }

  checkVerificationCode(phoneNumber: string, code: string): ColdObservableOnce<ApiResponse<void>> {
    return this.apiAdapter.post('checkVerificationCode', { phoneNumber, code });
  }

  withdraw(userId: string): ColdObservableOnce<ApiResponse<void>> {
    return this.apiAdapter.post('withdraw', { userId });
    //not using anymore
  }
}
