import { Component, forwardRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { FormControlBaseComponent } from '../../../../modules/form';
import { Option } from '../../../../../../../../src/core/types';
import { DateValidator } from '../../../validator/validators';
import { inputOnlyNumber } from '../../../utils';
import { FormErrorService } from '../../services/form.error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wnd-birth-select',
  templateUrl: './birth-select.component.html',
  styleUrls: ['./birth-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthSelectComponent),
      multi: true,
    },
  ],
})
export class BirthSelectComponent extends FormControlBaseComponent implements OnInit {
  formGroup: FormGroup = this.createForm();

  monthOption: Option[] = this.createBirthOption(12, '월');
  dateOption: Option[] = this.createBirthOption(31, '일');

  dateErrorText: string;

  constructor(private fb: FormBuilder, private formErrorService: FormErrorService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.setSubscription('_initFormGroupValueChange', this.initFormGroupValueChange());
  }

  get yearControl() {
    return this.formGroup.get('year');
  }

  get monthControl() {
    return this.formGroup.get('month');
  }

  get dateControl() {
    return this.formGroup.get('date');
  }

  keyDownOnlyNumber(event) {
    inputOnlyNumber(event);
  }

  protected override convertToControlValue(value: string): string {
    if (value) {
      const [year, month, date] = value.split('-');

      this.formGroup.patchValue({
        year,
        month,
        date,
      });
    }

    return value;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      year: [null, [Validators.required, DateValidator.year]],
      month: [null, [Validators.required, DateValidator.month]],
      date: [null, [Validators.required, DateValidator.date]],
    });
  }

  private initFormGroupValueChange(): Subscription {
    return this.formGroup.valueChanges.subscribe((value) => {
      this.checkDateError();
      const { year, month, date } = value;

      let birthValue: string;
      if (year && month && date) {
        birthValue = `${year}-${month}-${date}`;
      } else {
        birthValue = null;
      }

      this.formCtrl.setValue(birthValue);
    });
  }

  private checkDateError() {
    const errors = Object.assign(
      {},
      ...[this.yearControl.errors, this.monthControl.errors, this.dateControl.errors].filter(
        (error) => Boolean(error)
      )
    );

    const dateError = this.formErrorService.getErrorMessage({ date: true });

    if (errors.year) {
      this.dateErrorText = dateError.year;
    } else if (errors.month) {
      this.dateErrorText = dateError.month;
    } else if (errors.date) {
      this.dateErrorText = dateError.date;
    } else if (errors.required) {
      this.dateErrorText = dateError.required;
    } else {
      this.dateErrorText = '';
    }
  }

  private createBirthOption(length: number, separator: string): Option[] {
    return Array(length)
      .fill(0)
      .map((item, index) => {
        const date = index < 9 ? `0${index + 1}` : `${index + 1}`;

        return {
          value: date,
          text: `${date}`,
        };
      });
  }
}
