import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImagesModule } from '../../../../../weenidy/src/lib/modules/images/images.module';
import { SearchInputFormComponent } from './components/search-input-form/search-input-form.component';
import { SearchMenuBoxComponent } from './components/search-menu-box/search-menu-box.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ResourceSearchFormComponent } from './components/resource-search-form/resource-search-form.component';
import { LibFormModule } from '../../../../../weenidy/src/lib/shared/form/lib-form.module';
import { ResourceSearchInputComponent } from './components/resource-search-input/resource-search-input.component';
import { ResourceSearchFilterComponent } from './components/resource-search-filter/resource-search-filter.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ResourceFilterButtonComponent } from './components/resource-filter-button/resource-filter-button.component';
import { RelatedSearchKeywordsComponent } from './components/related-search-keywords/related-search-keywords.component';
import { ResourceSearchFilterDialogComponent } from './components/resource-search-filter-dialog/resource-search-filter-dialog.component';

@NgModule({
  declarations: [
    SearchMenuBoxComponent,
    SearchInputFormComponent,
    SearchInputComponent,
    ResourceSearchFormComponent,
    ResourceSearchInputComponent,
    ResourceSearchFilterComponent,
    ResourceFilterButtonComponent,
    RelatedSearchKeywordsComponent,
    ResourceSearchFilterDialogComponent,
  ],
  exports: [
    SearchMenuBoxComponent,
    SearchInputFormComponent,
    SearchInputComponent,
    ResourceSearchFormComponent,
    ResourceSearchInputComponent,
    ResourceSearchFilterComponent,
    ResourceFilterButtonComponent,
    RelatedSearchKeywordsComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, ImagesModule, LibFormModule, FormsModule, MatExpansionModule]
})
export class SearchModule {}
