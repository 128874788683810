import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TimeLineEventBusService {
  private eventSubject = new Subject<void>();

  addRecordEvent() {
    this.eventSubject.next();
  }

  listenEvent() {
    return this.eventSubject.asObservable();
  }
}
