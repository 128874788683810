import { CouponHistoryService } from 'src/entities/coupon-history/coupon-history.service';
import { Injectable } from '@angular/core';
import { CartService } from '../../../../../../../src/entities/cart/cart.service';
import { NgCartDb } from './cart.db';
import { NgResourceService } from '../../resource/services/resource.service';
import { NgCouponHistoryService } from '../../coupon-histories/coupon-histories.service';

@Injectable({
  providedIn: 'root',
})
export class NgCartService extends CartService {
  constructor(
    protected override cartDb: NgCartDb,
    protected override resourceService: NgResourceService,
    protected override couponHistoryService: NgCouponHistoryService
  ) {
    super(cartDb, resourceService, couponHistoryService);
  }
}
