import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { Inject, Injectable } from '@angular/core';
import { PaymentService } from '../../../../../../../src/entities/payment/payment.service';
import { NgPaymentSearch } from './payment.search';
import { ENVIRONMENT } from '../../../core/tokens';
import { Environment } from '../../../../../../../src/core/environment';
import { NgPaymentDb } from './payment.db';
import { BrowserService } from '../../../shared/services/browser.service';
import {
  IamportParam,
  Payment,
  PaymentAnnotation,
  PaymentError,
  PaymentErrorCode,
  PaymentPayload,
  PaymentStatus,
  PaymentType,
  PgType,
  SellingItem,
  WndCategory,
  getPaymentErrorText,
} from 'src/entities/payment/types';
import { NgFunctionsCaller } from '../../functions-caller/services/functions-caller.service';
import { from, merge, Observable, of, partition, throwError } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'projects/web/src/environments/environment';
import { Content } from 'src/entities/content/types';
import { Resource, ResourceInfo } from 'src/entities/resource/types';
import { NgContentService } from '../../content/services/content.service';
import { PaymentLoadingDialogComponent } from 'projects/web/src/app/pages/payment/components/payment-loading-dialog/payment-loading-dialog.component';
import { NgAuthService } from '../../auth/auth.service';
import { NgTimeLineService } from '../../time-line/services/time-line.service';
import { NgCartService } from '../../cart/services/cart.service';
import { TimeLineEvent, TimeLineText } from 'src/entities/time-line/types';
import { transferUserName } from 'src/entities/time-line/utils';
import { User } from 'src/entities/user/types';
import { NgCouponHistoryService } from '../../coupon-histories/coupon-histories.service';

declare const IMP: any;

@Injectable({
  providedIn: 'root',
})
export class NgPaymentService extends PaymentService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override paymentSearch: NgPaymentSearch,
    protected override paymentDb: NgPaymentDb,
    protected contentService: NgContentService,
    private browserService: BrowserService,
    private caller: NgFunctionsCaller,
    private dialog: MatDialog,
    private authService: NgAuthService,
    private timeLineService: NgTimeLineService,
    private cartService: NgCartService,
    private couponHistoryService: NgCouponHistoryService
  ) {
    super(environment.firebase.projectId, paymentSearch, paymentDb);
    if (this.browserService.isBrowser) {
      IMP.init(environment.iamport.id);
    }
  }

  requestPay<T>(
    items: SellingItem<T>[],
    paymentPayload: PaymentPayload,
    pgType: PgType,
    useEventRedirectUrl?: boolean
  ): Promise<any> {
    const merchantUid = uuidv4();
    const params: IamportParam = this.makeIamportParam(
      merchantUid,
      items,
      paymentPayload,
      pgType,
      useEventRedirectUrl
    );

    return this.addPayStatusPayment<T>(merchantUid, items, paymentPayload).then(() => {
      return this.iamportRequestPay(params);
    });
  }

  iamportRequestPay(params: IamportParam): Promise<PaymentAnnotation> {
    console.log('페이 테스트 파라미터', params);
    return new Promise((resolve, reject) => {
      IMP.request_pay(params, (response: PaymentAnnotation) => {
        console.log('UID를 확인할 수 있나?', response);
        if (response.success) {
          resolve(response);
        } else {
          reject(response);
          // const id = response.merchant_uid;
          // this.caller.paymentData(id).subscribe({
          //   next: (res) => reject(res),
          //   error: (e) => {
          //     console.log('catch error', e);
          //     return reject(e);
          //   },
          // });
        }
      });
    });
  }

  getPaymentIdByMerchantUid(merchant_uid: string) {
    console.log('getPaymentIdByMerchantUid', merchant_uid);
    return this.paymentDb
      .list({
        filters: [
          {
            field: 'merchantUid',
            comparison: '==',
            value: merchant_uid,
          },
        ],
      })
      .pipe(map((response) => (response.count > 0 ? response.docs[0] : null)));
  }
  async payErrorHandle(annotation: PaymentAnnotation): Promise<PaymentError> {
    console.log('오류 핸들러');
    const payment = await this.getPaymentIdByMerchantUid(annotation.merchant_uid).toPromise();
    console.log('payment 조회 완료');

    if (!payment) {
      return of() as any;
    }

    const paymentId = payment.id;
    const paymentErrorCode = this.getPaymentErrorCode(annotation);

    const paymentError: PaymentError = {
      code: paymentErrorCode,
      message: getPaymentErrorText(paymentErrorCode),
    };

    const paymentData: Partial<Payment<any>> = {
      annotation,
    };

    console.log('paymentErrorCode', paymentErrorCode);
    // TODO: 에러시 처리
    // errorData {code: 100, message: "PG사에서 에러가 발생하였습니다."}
    if (paymentErrorCode === PaymentErrorCode.WND_PAYMENT_FORGERY) {
      return this.updateForcedCancelStatusPayment(paymentId, paymentData).then(() => paymentError);
    } else if (paymentErrorCode === PaymentErrorCode.WND_PAYMENT_NO_MOENY) {
      return this.updateFailStatusPayment(paymentId, paymentData).then(() => paymentError);
    } else if (paymentErrorCode === PaymentErrorCode.WND_PAYMENT_CANCEL) {
      return this.updateUnPayStatusPayment(paymentId, paymentData).then(() => paymentError);
    } else if (paymentErrorCode === PaymentErrorCode.WND_PAYMENT_IAMPORT_ERROR) {
      return this.abnormalPaymentProcess(annotation.imp_uid)
        .toPromise()
        .then(() => this.updateForcedCancelStatusPayment(paymentId, paymentData))
        .then(() => paymentError);
    } else {
      return new Promise((resolve) => resolve(paymentError));
    }
  }

  private getPaymentErrorCode(annotation: PaymentAnnotation): PaymentErrorCode {
    const payCancelRegExp = new RegExp(/^\[결제포기\]/);
    const noMoneyRegExp = new RegExp(/^F0004/);

    if (annotation.fail_reason === 'forgery') {
      return PaymentErrorCode.WND_PAYMENT_FORGERY;
    } else if (annotation.fail_reason === 'noParam') {
      return PaymentErrorCode.WND_PAYMENT_NO_PARAM;
    } else if (payCancelRegExp.test(annotation.fail_reason)) {
      return PaymentErrorCode.WND_PAYMENT_CANCEL;
    } else if (noMoneyRegExp.test(annotation.fail_reason)) {
      return PaymentErrorCode.WND_PAYMENT_NO_MOENY;
    } else if (annotation.status === 'ready') {
      return PaymentErrorCode.WND_PAYMENT_IAMPORT_ERROR;
    } else {
      return PaymentErrorCode.WND_PAYMENT_IAMPORT_ERROR;
    }
  }

  private addTotalLikes(user: User, resourceId: string) {
    const timeLine = {
      resourceId,
      userId: user.id,
      text: TimeLineText[TimeLineEvent.Buy].replace(
        '${value}',
        transferUserName(user.name || user.email)
      ),
      eventType: TimeLineEvent.Buy,
    };
    this.timeLineService.add(timeLine).subscribe();
  }

  taskAfterPay<T>(response: PaymentAnnotation): Observable<any> {
    let currentPayments: Payment<T>[];
    const payments$ = this.getPaymentDocs(response.merchant_uid).pipe(
      tap((payments: Payment<T>[]) => {
        currentPayments = payments;
      })
    );
    console.log('taskAfterPay');

    // 결제 금액 위조 확인
    const [normalPayment$, abnormalPayment$] = this.checkIsForgery(payments$, response);

    const normalPaymentProcess$ = normalPayment$.pipe(
      switchMap(() => this.normalPaymentProcess(response, currentPayments))
    );

    const abnormalPaymentProcess$ = abnormalPayment$.pipe(
      switchMap(() => this.abnormalPaymentProcess(response.imp_uid))
    );

    return merge(normalPaymentProcess$, abnormalPaymentProcess$);
  }

  eventTaskAfterPay<T>(response: PaymentAnnotation): Observable<any> {
    let currentPayments: Payment<T>[];
    const payments$ = this.getPaymentDocs(response.merchant_uid).pipe(
      tap((payments: Payment<T>[]) => (currentPayments = payments))
    );

    // 결제 금액 위조 확인
    const [normalPayment$, abnormalPayment$] = this.checkIsForgery(payments$, response);

    const normalPaymentProcess$ = normalPayment$.pipe(
      switchMap(() => this.eventNormalPaymentProcess(response, currentPayments))
    );

    const abnormalPaymentProcess$ = abnormalPayment$.pipe(
      switchMap(() => this.abnormalPaymentProcess(response.imp_uid))
    );

    return merge(normalPaymentProcess$, abnormalPaymentProcess$);
  }

  private makeIamportParam<T>(
    merchantUid: string,
    items: SellingItem<T>[],
    paymentPayload: PaymentPayload,
    pgType: PgType,
    useEventRedirectUrl?: boolean
  ): IamportParam {
    const param: IamportParam = {
      pg: `${pgType}.${environment.iamport.cid[pgType]}`,
      merchant_uid: merchantUid,
      name: items.length > 1 ? `${items[0].name} 외 ${items.length - 1}개` : items[0].name,
      buyer_email: paymentPayload.email,
      buyer_name: paymentPayload.name,
      buyer_tel: paymentPayload.phoneNumber,
      currency: 'KRW',
      amount: items.reduce((prev, curr) => prev + curr.amount, 0),
      m_redirect_url: environment.baseUrl + '/callback/import-redirect',
    };

    if (pgType === 'nice') {
      param.niceMobileV2 = true;
    } else if (pgType === 'paypal') {
      param.pay_method = 'card';
      param.currency = 'USD';
    }

    if (useEventRedirectUrl) {
      param.m_redirect_url = environment.baseUrl + '/callback/import-redirect/event';
    }

    return param;
  }

  private getPaymentDocs<T>(id: string): Observable<Payment<T>[]> {
    return this.paymentDb
      .list({
        filters: [
          {
            field: 'merchantUid',
            comparison: '==',
            value: id,
          },
        ],
      })
      .pipe(
        map((response) => {
          return response.docs;
        })
      );
  }

  private checkIsForgery<T>(payments$: Observable<Payment<T>[]>, response: PaymentAnnotation) {
    return partition(payments$, (payments: Payment<T>[]) => {
      const realPaidAmount = response.paid_amount || response.amount;

      // console.log('realPaidAmount', realPaidAmount);

      // console.log(
      //   'payments.reduce((prev, curr) => prev + +curr.item.amount, 0)',
      //   payments.reduce((prev, curr) => prev + +curr.item.amount, 0)
      // );

      return payments.reduce((prev, curr) => prev + +curr.item.amount, 0) === realPaidAmount;
    });
  }

  private getContentDocumentSize(id: string): Observable<number> {
    return this.contentService
      .list(
        {
          filters: [
            {
              field: 'imp_uid',
              comparison: '==',
              value: id,
            },
          ],
        },
        {
          parentIds: [this.authService.id],
        }
      )
      .pipe(map((response) => response.count));
  }

  private normalPaymentProcess<T>(response: PaymentAnnotation, payments: Payment<T>[]) {
    console.log('장바구니 비우기');

    //장바구니 비우기 &
    //리소스 구매 타임라인 추가.

    this.authService.user$.subscribe((user) => {
      for (const resourceId of payments.map((payment) => payment.item?.id)) {
        this.cartService.delete(user.id, resourceId);
        this.addTotalLikes(user, resourceId);
      }
    });

    console.log('쿠폰사용완료처리');

    console.log(' currentPayments', JSON.stringify(payments));
    //쿠폰 사용완료 처리하기
    for (const couponHistoryId of payments
      .filter((payment) => payment.item?.couponHistoryId)
      .map((payment) => payment.item?.couponHistoryId)) {
      console.log('couponHistoryId', couponHistoryId);
      this.couponHistoryService.update(couponHistoryId, {
        isUsed: true,
        isUsedAt: new Date(),
      });
    }

    return from(this.UpdatePaidStatusPayment(response, payments)).pipe(
      switchMap(() => this.getContentDocumentSize(response.imp_uid)),
      mergeMap((size: number) => {
        if (size === 0) {
          return payments.map((payment) => {
            const doc: Content<ResourceInfo> = {
              info: {
                id: payment.item.id,
                ...payment.item.info,
              },
              category: WndCategory.Resource,
              isPay: true,
              isPayCancel: false,
              downloadCount: 1,
              price: payment.item.amount,
              currency: response.currency,
              paymentId: payment.id,
              imp_uid: response.imp_uid,
            } as unknown as Content<ResourceInfo>;

            return this.contentService.add(doc, { parentIds: [payment.userId] });
          });
        } else {
          return of(true);
        }
      }),
      take(1)
    );
  }

  private eventNormalPaymentProcess<T>(response: PaymentAnnotation, payments: Payment<T>[]) {
    return from(this.UpdatePaidStatusPayment(response, payments));
  }

  private abnormalPaymentProcess(id: string) {
    return this.caller.iamportOnlyAmountCancel(id).pipe(
      map((cancelResponse: PaymentAnnotation) => {
        cancelResponse.fail_reason = 'forgery';
        return cancelResponse;
      }),
      switchMap((cancelResponse: PaymentAnnotation) => throwError(cancelResponse))
    );
  }

  updateCancel(paymentId: string, annotation: PaymentAnnotation) {
    return this.update(paymentId, {
      annotation,
      status: PaymentStatus.Canceled,
    });
  }

  private updateForcedCancelStatusPayment(
    paymentId: string,
    params?: Partial<Payment<any>>
  ): Promise<any> {
    return this.update(paymentId, {
      annotation: params?.annotation,
      status: PaymentStatus.ForcedCanceled,
    }).toPromise();
  }

  private addPayStatusPayment<T>(
    merchantUid: string,
    items: SellingItem<any>[],
    paymentPayload: PaymentPayload
  ): Promise<any> {
    return this.paymentDb
      .transaction((db) => async (transaction) => {
        for (const item of items) {
          const couponHistoryId = item.couponHistoryId;
          const partial = {
            merchantUid,
            item,
            userId: paymentPayload.userId || 'event',
            annotation: {
              buyer_email: paymentPayload.email,
              buyer_name: paymentPayload.name,
            },
            type: items.length > 1 ? PaymentType.Multi : PaymentType.Single,
            status: PaymentStatus.Pay,
            licenseType: item.info.licenseType || '', //TODO 올바른 정보 인지 확인 필요
            licenseUsedUserName: paymentPayload.licenseUsedUserName || '', //TODO 올바른 정보 인지 확인 필요
            couponId: couponHistoryId || '',
            createdAt: new Date(),
          } as any;
          const paymentId = this.paymentDb.createId();
          const ref = db.doc(`payments/${paymentId}`);
          transaction.set(ref, partial);
          if (couponHistoryId) {
            const couponRef = db.doc(`coupon-histories/${couponHistoryId}`);
            transaction.update(couponRef, { paymentId });
          }
        }
      })
      .toPromise();
  }

  updateUnPayStatusPayment(paymentId: string, params?: Partial<Payment<any>>): Promise<any> {
    return this.update(paymentId, {
      annotation: {
        ...params.annotation,
      },
      status: PaymentStatus.UnPay,
    }).toPromise();
  }

  updateFailStatusPayment(paymentId: string, params?: Partial<Payment<any>>): Promise<any> {
    return this.update(paymentId, {
      annotation: {
        ...params.annotation,
      },
      status: PaymentStatus.Failed,
    }).toPromise();
  }

  private UpdatePaidStatusPayment(
    response: PaymentAnnotation,
    payments: Payment<any>[]
  ): Promise<any> {
    return Promise.all([
      payments.map((payment) =>
        this.update(payment.id, {
          status: PaymentStatus.Paid,
          annotation: response,
        })
      ),
    ]);
  }

  openLoadingDialog(): MatDialogRef<PaymentLoadingDialogComponent> {
    return this.dialog.open(PaymentLoadingDialogComponent, {
      panelClass: 'wnd-loading-panel',
      disableClose: true,
    });
  }
}
