import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CartCheckContentPipe } from '../../core/pipes/cart-check-content.pipe';
import { LibFormModule } from '../../../../../weenidy/src/lib/shared/form/lib-form.module';
import { WndPipeModule } from '../../../../../weenidy/src/lib/modules/pipe/wnd.pipe.module';
import { ImagesModule } from '../../../../../weenidy/src/lib/modules/images/images.module';
import { PaymentWindowComponent } from './components/payment-window/payment-window.component';
import { PaymentDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { PaymentResourceCardComponent } from './components/payment-resource-card/payment-resource-card.component';
import { PaymentCartComponent } from './components/payment-cart/payment-cart.component';
import { LibLicenseModule } from '../../../../../weenidy/src/lib/modules/license/license.module';
import { TimeLineEventBusService } from 'src/entities/time-line/time-line-event-bus.service';
import { RouterLink } from "@angular/router";

@NgModule({
  declarations: [
    PaymentWindowComponent,
    PaymentDialogComponent,
    PaymentResourceCardComponent,
    PaymentCartComponent,
    CartCheckContentPipe,
  ],
  imports: [
    CommonModule,
    WndPipeModule,
    ImagesModule,
    ReactiveFormsModule,
    LibFormModule,
    MatDialogModule,
    LibLicenseModule,
    RouterLink,
  ],
  exports: [PaymentDialogComponent, PaymentWindowComponent, PaymentCartComponent],
  providers: [CartCheckContentPipe, TimeLineEventBusService],
})
export class PaymentModule {}
