import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'wnd-toggle-form',
  templateUrl: './toggle-form.component.html',
  styleUrls: ['./toggle-form.component.scss']
})
export class ToggleFormComponent {
  @Input() label: string;
  @Input() text: string;
  @Input() placeholder: string;
  @Output() cancel = new EventEmitter();

  isChange = false;

  constructor() {
  }

  open(): void {
    this.isChange = true;
  }

  close(): void {
    this.cancel.emit();
    this.isChange = false;
  }
}
