import { Injectable } from '@angular/core';
import { RecentPopularService } from "../../../../../../../src/entities/config/recent-popular.service";
import { NgConfigDb } from "./config.db";

@Injectable({
  providedIn: 'root',
})
export class NgRecentPopularService extends RecentPopularService {
  constructor(protected override configDb: NgConfigDb) {
    super(configDb);
  }
}
