<form [formGroup]="formGroup">
  <div class="radio-button-wrapper">
    <div *ngFor="let item of radioItems" class="radio-button-box">
      <input
        #checkInput
        class="input-radio"
        type="checkbox"
        [id]="id + '_' + item.value"
        [formControlName]="id + '_' + item.value"
        [value]="item.value"
      />

      <label type="button" class="radio-button" [for]="id + '_' + item.value">
        {{ item.text }}
      </label>
    </div>
  </div>
</form>
