import { publish } from 'rxjs/operators';
import { BaseEntity } from 'src/core/base-entity';
import { ResourceCategory } from '../resource/types';

export enum CouponApplicationType {
  resource = 1, //리소스 상품별
  order = 10, //주문서 별
}

export const CouponDiscountText = {
  1: '원',
  10: '%',
};
export enum CouponDiscountType {
  amount = 1, // 정해진 금액 할인
  percentage = 10, //정해진 비율로 할인
}

export enum LimitationsType {
  nonCumulative = 1, //중복사용불가
  nonReDownload = 10, //쿠폰 여러번 다운로드 불가 (동일쿠폰 재발급 불가)
  categoryRange = 20, // 특정 카테고리에만 사용 가능한 경우
  onlyOneArtist = 30, //특정 작가(아티스트) 작품만 할인
  listOfAllowedItems = 40, //리스트에 포함된 특정 작품들만 할인
}

export const LimitationText = {
  [LimitationsType.nonCumulative]: '타 쿠폰과 중복 적용 불가 합니다.',
  [LimitationsType.nonReDownload]: '재 다운로드 불가 합니다.',
  [LimitationsType.categoryRange]: '특정 카테고리에서만 사용가능 합니다.',
  [LimitationsType.onlyOneArtist]: '특정 작가의 상품만 쿠폰 적용이 가능 합니다.',
  [LimitationsType.listOfAllowedItems]: '지정된 상품들만 쿠폰적용이 가능합니다. ex.기획전',
};
// export interface Limitation {
//   type: LimitationsType;
//   description: string;
//   enableCategories?: ResourceCategory[];
// }

export enum CouponDelivery {
  /**쿠폰 지급 방법 */
  adminSelect = 1, //관리자가 선택한 회원만
  userDownload = 10, //사용자가 버튼클릭으로 신청하여 다운로드
  userUseCode = 15, // 사용자가 쿠폰코드를 입력하여 다운로드
  autoJoin = 100, //회원가입시 자동 발급
  autoBirth = 101, //생일에 자동 발급
}

export enum CouponStatus {
  open = 1, //발급중
  close = 10, //발급 (일시)중지
  end = 20, // 발급 종료
}
export const couponStatusText = {
  [CouponStatus.open]: '발급중',
  [CouponStatus.close]: '일시중지',
  [CouponStatus.end]: '발급종료',
};
export const couponDeliveryText = {
  [CouponDelivery.adminSelect]: '관리자 발급',
  [CouponDelivery.userDownload]: '다운로드',
  [CouponDelivery.userUseCode]: '코드등록',
  [CouponDelivery.autoBirth]: '자동발급 생일',
  [CouponDelivery.autoJoin]: '자동발급 가입',
};
export interface CouponCode extends BaseEntity {
  code: string;
  isMultiUse: boolean; // 코드를 여러사용자가 등록 가능한가?
  isDone: boolean; // 사용 만료가 되었는가?
}
export interface Coupon extends BaseEntity {
  name: string; //쿠폰명(관리자 구분용)
  title: string; //노출되는 쿠폰 제목
  description: string; //노출되는 쿠폰 설명
  status: CouponStatus; //쿠폰상태
  applicationType: CouponApplicationType; // 쿠폰 적용 타입
  discountType: CouponDiscountType; // 쿠폰 할인 방식
  discountAmount: number; //할인 양
  minimumPurchaseAmount: number; //사용 조건1: 최소구매금액 percentage,amount
  maximumDiscountAmount?: number; //사용 조건2: 최대할인금액 percentage
  limitationKeys?: any[]; //제한 조건들
  enableCategories?: ResourceCategory[]; //제한조건 - 특정카테고리 허용
  isUnlimited: boolean; // 무제한 발행 여부
  limitedCount?: number; // 발급가능한 갯수
  couponDelivery: CouponDelivery; //쿠폰 전달(발급) 방법
  useAbleDays: number; // 유효한 사용 기간 (ex. 발급일로부터 30일)
  deliveryAbleStartAt?: Date; //발급가능 시작시각
  deliveryAbleEndAt?: Date; //발급가능 종료시각
  couponCode?: CouponCode; //쿠폰 코드
  //추가됨
  publishedCount: number; //발행된 갯수
  usedCount: number; //사용된 갯수
  //추가됨 23.11.28
  onlyOneArtistId?: string; //제한 조건 : 특정 작가 작품만 쿠폰 적용가능시, 작가의 ID값.
  //추가됨 24.01.08
  listOfAllowedItemIds: string[]; //제한 조건 : 특정 상품만 쿠폰 적용 가능, 상품 ID목록.
}
