import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ResourceCategory,
  ResourceCategoryType,
} from '../../../../../../../../src/entities/resource/types';
import { SearchFilter } from '../../../../pages/resource-page/types';
import { NgSettingService } from '../../../../../../../weenidy/src/lib/modules/setting/services/setting.service';
import { SuggestKeyword } from '../../../../../../../../src/entities/setting/types';
import { Subscription } from 'rxjs';
import { SubscriptionBaseComponent } from '../../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';

@Component({
  selector: 'wnd-related-search-keywords',
  templateUrl: './related-search-keywords.component.html',
  styleUrls: ['./related-search-keywords.component.scss'],
})
export class RelatedSearchKeywordsComponent extends SubscriptionBaseComponent implements OnInit {
  @Input()
  set category(category: ResourceCategory) {
    this.initRelatedKeywords(category);
    this._category = category;
  }

  get category(): ResourceCategory {
    return this._category;
  }

  @Output() onKeywordClick = new EventEmitter<SearchFilter>();

  keywords: string[];

  private suggestKeyword: SuggestKeyword;
  private _category: ResourceCategory;
  currentKeywords: string[];

  constructor(private settingService: NgSettingService) {
    super();
  }

  ngOnInit(): void {
    this.setSubscription('_getSuggestKeyword', this.getSuggestKeyword());
  }

  keywordClick(keyword): void {
    this.onKeywordClick.emit({ keyword });
  }

  private initRelatedKeywords(category: ResourceCategory): void {
    if (this.suggestKeyword) {
      this.currentKeywords = this.suggestKeyword[ResourceCategoryType[category]] as string[];
    }
  }

  private getSuggestKeyword(): Subscription {
    return this.settingService.getSuggestKeyword().subscribe((suggestKeyword: SuggestKeyword) => {
      this.suggestKeyword = suggestKeyword;
      this.initRelatedKeywords(this.category);
    });
  }
}
