import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Badge,
  BadgeCount,
  BadgeCountCategory,
  BadgeCountDivision,
  BadgeCountKey,
} from '../../../../../../../../src/entities/badge/types';
import { NgBadgeService } from '../../../badge/services/badge.service';
import { SubscriptionBaseComponent } from '../../../../core/base-components/subscription-base.component';
import { NgAuthService } from '../../../auth/auth.service';

export interface BadgeListDialogComponentPayload {
  badgeList: Badge[];
  badgeCount: BadgeCount;
  isMy: boolean;
}

@Component({
  selector: 'wnd-badge-list-dialog',
  templateUrl: './badge-list-dialog.component.html',
  styleUrls: ['./badge-list-dialog.component.scss'],
})
export class BadgeListDialogComponent extends SubscriptionBaseComponent implements OnInit {
  activeBadgeList: Badge[] = [];
  lockedBadgeList: Partial<Badge>[] = [];

  isMy: boolean;

  badgeCount: BadgeCount;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BadgeListDialogComponentPayload,
    private badgeService: NgBadgeService,
    private authService: NgAuthService,
    private dialogRef: MatDialogRef<BadgeListDialogComponent>
  ) {
    super();

    this.activeBadgeList = this.data.badgeList.sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
    );
    this.badgeCount = this.data.badgeCount;
    this.isMy = this.data.isMy;
  }

  ngOnInit(): void {
    this.initBadgeCount();
  }

  close(isOpen: boolean): void {
    this.dialogRef.close(isOpen);
  }

  initBadgeCount(): void {
    if (!this.badgeCount) {
      return;
    }

    BadgeCountKey.forEach((key) => {
      BadgeCountDivision[key].forEach((division) => {
        if (division > this.badgeCount[key]) {
          this.lockedBadgeList.push({
            category: BadgeCountCategory[key],
            count: division,
          }) as Partial<Badge>;
        }
      });
    });
  }
}
