import { Injectable } from '@angular/core';
import { FirestoreDbAdapter } from '../../../../../../../src/modules/firebase/firestore-db.adapter';
import firebase from 'firebase/compat/app';
import { BadgeCount } from '../../../../../../../src/entities/badge/types';

@Injectable({
  providedIn: 'root',
})
export class NgBadgeCountDb extends FirestoreDbAdapter<BadgeCount> {
  constructor() {
    super(firebase.firestore(), 'badgeCount', { parentCollectionNames: ['users'] });
  }
}
