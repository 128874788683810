<div
  *ngIf="isClosing"
  @fadeIn
  @fadeOut
  class="toolbar-overlay-button-box"
  [class.position__bottom]="position === 'bottom'"
  [class.position__right]="position === 'right'"
  role="dialog"
  aria-label="Dialog"
>
  <button type="button" class="toolbar-button" (click)="moveResource()">
    <p class="button-title">리소스</p>

    <p class="button-subtitle">리소스를 업로드하여 부가 수익을 누려보세요</p>
  </button>

  <button type="button" class="toolbar-button" (click)="movePortfolio()">
    <p class="button-title">포트폴리오</p>

    <p class="button-subtitle">당신의 멋진 포트폴리오를 업로드하세요</p>
  </button>
</div>
