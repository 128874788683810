import { ScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { SubscriptionBaseComponent } from '../../../../../../weenidy/src/lib/core/base-components/subscription-base.component';
import { environment } from '../../../../environments/environment';
import { User } from '../../../../../../../src/entities/user/types';
import { openNotion } from '../../../core/utils/utils';
import { IntroToolbarService } from '../../service/intro-toolbar.service';
import { fadeIn, fadeOut } from './animation';
import { NgCartOverlayService } from '../../../modules/cart/services/cart-overlay.service';
import { NgCartService } from '../../../../../../weenidy/src/lib/modules/cart/services/cart.service';
import { WndDialogService } from '../../../../../../weenidy/src/lib/modules/dialog/service/wnd-dialog.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Option } from '../../../../../../../src/core/types';
import { ResourceCategoryOptions } from '../../../../../../../src/entities/resource/types';
import { SearchFilter } from '../../../pages/resource-page/types';
import { NgAuthService } from '../../../../../../weenidy/src/lib/modules/auth/auth.service';

@Component({
  selector: 'wnd-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class ToolbarComponent extends SubscriptionBaseComponent implements OnInit {
  @Output() menuClick = new EventEmitter();

  @ViewChild('cartOverlayOrigin') private cartOverlayOrigin: ElementRef;

  menus: Option[] = ResourceCategoryOptions.filter((option) => option.value !== '');

  user: User;
  isLoggedIn: boolean;
  isWriter: boolean;
  profile$ = this.authService.profile$;
  isProd = environment.production;

  isIndex$: BehaviorSubject<boolean>;
  isHide$: BehaviorSubject<boolean>;

  isMenuOpen = false;
  scrollStrategy: ScrollStrategy;

  cartCount: number;

  isSearchPage$: BehaviorSubject<boolean>;
  isShowSearchForm$: BehaviorSubject<boolean>;
  searchFilter: Partial<SearchFilter>;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private introToolbarService: IntroToolbarService,
    private cartOverlayService: NgCartOverlayService,
    private cartService: NgCartService,
    private dialogService: WndDialogService,
    private authService: NgAuthService
  ) {
    super();
    this.isIndex$ = this.introToolbarService.isIndex$;
    this.isHide$ = this.introToolbarService.isHide$;
    this.isSearchPage$ = this.introToolbarService.isSearchPage$;
    this.isShowSearchForm$ = this.introToolbarService.isShowSearchForm$;
  }

  ngOnInit(): void {
    this.initObservable('isWriter', this.authService.isWriter$);
    this.addSubscription(this.initIsLoggedIn());
    this.addSubscription(this.initUser());
    this.addSubscription(this.initMenuCloseEvent());
    this.setSubscription('_initQueryParams', this.initQueryParams());
  }

  menu() {
    this.menuClick.emit(true);
  }

  search(keyword: string) {
    keyword = keyword ? encodeURI(keyword) : '';
    this.router.navigate(['/resource/search'], { queryParams: { keyword } }).then();
  }

  newSearch(searchFilter: Partial<SearchFilter>): void {
    this.router
      .navigate(['/resource/search'], {
        queryParams: searchFilter,
        queryParamsHandling: 'merge',
        replaceUrl: false,
        fragment: 'replace',
      })
      .then();
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/']).then();
    });
  }

  openNotion(type: any) {
    openNotion(type);
  }

  showMenu(): void {
    this.isMenuOpen = true;
  }

  closeMenu(): void {
    setTimeout(() => (this.isMenuOpen = false), 250);
  }

  moveToUploadOrApply(isWriter: boolean) {
    if (isWriter) {
      this.dialogService.openSelectCategoryDialog();
    } else {
      this.router.navigateByUrl('/apply/tutorial').then();
    }
  }

  openCartOverlay() {
    //카트 팝업 더이상 사용안함.
    this.cartOverlayService.open();
  }

  private initIsLoggedIn() {
    return this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        setTimeout(() => {
          this.cartOverlayService.init(this.cartOverlayOrigin);
        });
      }
    });
  }

  private initUser() {
    return this.authService.user$.subscribe((user) => {
      this.user = user as any;

      if (user) {
        this.setObservable('cart-count', 'cartCount', this.cartService.countChange(user.id));
      }
    });
  }

  private initMenuCloseEvent(): Subscription {
    return fromEvent(window, 'click', { capture: true })
      .pipe(filter(() => this.isMenuOpen))
      .subscribe(() => {
        setTimeout(() => (this.isMenuOpen = false));
      });
  }

  private initQueryParams(): Subscription {
    let params;

    return this.route.queryParams
      .pipe(
        tap((query) => (params = query)),
        switchMap(() => this.introToolbarService.isSearchPage$),
        filter((isSearchPage) => Boolean(isSearchPage))
      )
      .subscribe(() => {
        const searchFilter: Partial<SearchFilter> = {};

        if (params.keyword) {
          searchFilter.keyword = decodeURI(params.keyword);
        }

        if (params.category) {
          searchFilter.category = parseInt(params.category, 10);
        }

        this.searchFilter = searchFilter;
      });
  }
}
