import firebase from 'firebase/compat/app';
import moment from 'moment';

export declare type Timestamp = firebase.firestore.Timestamp;
import firestore = firebase.firestore;

export function deepCopy(data: any) {
  return JSON.parse(JSON.stringify(data));
}

export function firestoreTimestampNow(): Timestamp {
  return firestore.Timestamp.now();
}

export function firestoreTimestampToData(date: Timestamp): Date {
  return date.toDate();
}

export function firestoreTimestampToString(date: Timestamp, format?: string): string {
  if (!date) {
    return '';
  }

  const m = moment(date.toDate());
  return m.format(format || 'YYYY-MM-DD');
}

export function firestoreTimestampFromDate(date: Date): Timestamp {
  return firestore.Timestamp.fromDate(date);
}

const InputKeydownNumberRegExp = /Enter|Backspace|Tab/;

export function inputOnlyNumber(event: KeyboardEvent) {
  const keyNumber = parseInt(event.key, 10);
  if (isNaN(keyNumber) && !InputKeydownNumberRegExp.test(event.key)) {
    event.returnValue = false;
  }
}

export function stringConvertBytes(value: string): number {
  return new Blob([value]).size;
}
