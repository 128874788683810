<div class="item-box">
  <div class="item-image-box">
    <wnd-preview-image-card ratio="3-2" [src]="resource?.thumbnail"></wnd-preview-image-card>
  </div>

  <div class="item-info-box">
    <p class="item-title">{{ resource.title }}</p>

    <p class="item-type">
      <ng-container *ngIf="resource.category >= resourceCategory.WebToon; else notWebtoon">
        {{ resource.category | codeText: resourceCategoryText }}/{{
          resource.licenseType | licenseTypeToText: resource.category
        }}
      </ng-container>

      <ng-template #notWebtoon>
        {{ resource.category | codeText: resourceCategoryText }}
      </ng-template>
    </p>

    <p class="item-money">
      {{ resource.licenseType | licenseTypeToPrice: resource.price | number }}원
    </p>
  </div>
</div>
