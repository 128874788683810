<div *ngIf="images" class="slider-banner-box">
  <div class="slider-banner-image-box">
    <ul
      class="slider-image-list duration"
      (mouseenter)="clearInterval()"
      (mouseleave)="initInterval()"
    >
      <wnd-slider-banner-item-pc
        [banner]="lastBanner"
        (bannerClick)="imageClick($event)"
      ></wnd-slider-banner-item-pc>

      <wnd-slider-banner-item-pc
        *ngFor="let image of images; trackBy: trackByFn"
        [banner]="image"
        (bannerClick)="imageClick($event)"
      ></wnd-slider-banner-item-pc>

      <wnd-slider-banner-item-pc
        [banner]="firstBanner"
        (bannerClick)="imageClick($event)"
      ></wnd-slider-banner-item-pc>
    </ul>

    <div class="slider-dot-box">
      <ul class="slider-dot-list">
        <li
          *ngFor="let image of images; let idx = index"
          [class.active]="idx === currentIndex"
          class="slider-dot"
          (click)="changeIndex(idx)"
        ></li>
      </ul>
    </div>
  </div>
</div>
