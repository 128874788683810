import { Pipe, PipeTransform } from '@angular/core';
import { Coupon, CouponDiscountType } from 'src/entities/coupon/types';

@Pipe({ name: 'convertTitle', pure: true })
export class ConvertTitlePipe implements PipeTransform {
  transform(coupon: Coupon): string {
    const { discountType, discountAmount } = coupon;
    const isPercentage = discountType === CouponDiscountType.percentage;
    return `${coupon.title} ${(discountAmount * 1).toLocaleString()}${isPercentage ? '%' : '원'}`;
  }
}
