import { DbListResponse, DbOptions, DbQuery } from 'src/core/db/types';
import { DbAdapter } from '../../core/db/db.adapter';
import { ColdObservable, ColdObservableOnce, HotObservableOnce } from '../../core/types';
import {
  Sns,
  Notifications,
  MainBottomBanners,
  SuggestKeyword,
  MainBannerItems,
  Setting
} from './types';

export class SettingService {
  constructor(
    protected settingDb: DbAdapter<
      Sns | Notifications | MainBottomBanners | SuggestKeyword | MainBannerItems
    >
  ) {}

  getSns(): ColdObservableOnce<Sns> {
    return this.settingDb.get('sns') as ColdObservableOnce<Sns>;
  }

  upsertSns(sns: Partial<Sns>): ColdObservableOnce<Sns> {
    return this.settingDb.upsert('sns', sns) as ColdObservableOnce<Sns>;
  }

  getNotification(): ColdObservableOnce<Notifications> {
    return this.settingDb.get('notification') as ColdObservableOnce<Notifications>;
  }

  upsertNotification(notifications: Partial<Notifications>): ColdObservableOnce<Notifications> {
    return this.settingDb.upsert(
      'notification',
      notifications
    ) as ColdObservableOnce<Notifications>;
  }

  getMainBottomBanner(): ColdObservableOnce<MainBottomBanners> {
    return this.settingDb.get('mainBottomBanners') as ColdObservableOnce<MainBottomBanners>;
  }

  getMainBanner(): ColdObservableOnce<MainBannerItems> {
    return this.settingDb.get('mainBanner') as ColdObservableOnce<MainBannerItems>;
  }

  upsertMainBottomBanner(
    mainBottomBanners: Partial<MainBottomBanners>
  ): ColdObservableOnce<MainBottomBanners> {
    return this.settingDb.upsert(
      'mainBottomBanners',
      mainBottomBanners
    ) as ColdObservableOnce<MainBottomBanners>;
  }

  getSuggestKeyword(): ColdObservableOnce<SuggestKeyword> {
    return this.settingDb.get('suggestKeyword') as ColdObservableOnce<SuggestKeyword>;
  }

  upsertSuggestKeyword(
    suggestKeyword: Partial<SuggestKeyword>
  ): ColdObservableOnce<SuggestKeyword> {
    return this.settingDb.upsert(
      'suggestKeyword',
      suggestKeyword
    ) as ColdObservableOnce<SuggestKeyword>;
  }

  createId(): string {
    return this.settingDb.createId();
  }
  add(doc: Partial<Setting>, opt: DbOptions): HotObservableOnce<Setting> {
    return this.settingDb.add(doc, opt);
  }

  update(id: string, setting: Partial<Setting>): HotObservableOnce<void> {
    return this.settingDb.update(id, setting);
  }

  upsert(id: string, doc: Partial<Setting>): HotObservableOnce<Setting> {
    return this.settingDb.upsert(id, doc);
  }

  get(id: string): ColdObservableOnce<Setting> {
    return this.settingDb.get(id);
  }

  list(query?: DbQuery, options?: DbOptions): ColdObservable<DbListResponse<Setting>> {
    return this.settingDb.list(query, options);
  }

  delete(id: string): HotObservableOnce<void> {
    return this.settingDb.delete(id);
  }
}
