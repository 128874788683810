<div class="badge-list-dialog-box">
  <div class="badge-list-dialog-header">
    <p class="badge-list-dialog-title">뱃지</p>

    <a class="badge-dialog-close-link" mat-dialog-close>
      <img wndSrc="/assets/icons/icn-gnb-cancle-n.png" alt="cancel icon" useHiDpi="true" />
    </a>
  </div>

  <div class="badge-list-dialog-body">
    <ul class="badge-list">
      <li *ngFor="let badge of activeBadgeList" class="badge-item">
        <div class="badge-thumbnail-box">
          <div class="badge-thumbnail-inner-box">
            <wnd-badge-image-card [badge]="badge"></wnd-badge-image-card>
          </div>
        </div>

        <p class="badge-title">{{ badge?.category | convertBadgeName: badge?.count }}</p>
      </li>
    </ul>

    <div *ngIf="isMy" class="locked-badge-list">
      <p class="locked-badge-list-title">획득 가능한 뱃지</p>

      <ul class="badge-list">
        <li *ngFor="let badge of lockedBadgeList" class="badge-item">
          <div class="badge-thumbnail-box close-thumbnail-box">
            <img src="/assets/creator/close-badge-icon.png" alt="close-badge-icon" />
          </div>

          <p class="badge-title">{{ badge?.category | convertBadgeName: badge?.count }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
