import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'codeText'
})
export class CodeTextPipe implements PipeTransform {

  transform(value: number, CodeText: any): string {
    return CodeText[value] || '';
  }
}
