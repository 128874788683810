<div class="form-control-box">
  <wnd-toggle-form
    #nameToggleForm
    class="name-toggle-form-box placeholder-red"
    label="이름"
    placeholder="이름을 입력해주세요."
    [text]="nameControl.value"
    (cancel)="resetData(nameFormGroup, ['name'])"
  >
    <form
      class="name-form-box"
      [formGroup]="nameFormGroup"
      novalidate
      (submit)="updateUserInfo(nameFormGroup, nameToggleForm)"
    >
      <wnd-input type="text" formControlName="name"></wnd-input>

      <button
        type="submit"
        class="wnd-btn navy confirm-button"
        [disabled]="nameFormGroup.invalid || isLoading"
      >
        확인
        <i *ngIf="isLoading" class="wnd-icn loading"></i>
      </button>
    </form>
  </wnd-toggle-form>
</div>

<div class="form-control-box">
  <wnd-toggle-form
    #authorToggleForm
    class="author-toggle-form-box placeholder-red"
    label="작가명"
    placeholder="작가명을 입력해주세요."
    [text]="authorControl.value"
    (cancel)="resetData(authorFormGroup, ['author'])"
  >
    <form
      class="author-form-box"
      [formGroup]="authorFormGroup"
      novalidate
      (submit)="updateAuthorInfo(authorFormGroup, authorToggleForm)"
    >
      <wnd-input type="text" formControlName="author"></wnd-input>

      <button
        type="submit"
        class="wnd-btn navy confirm-button"
        [disabled]="!authorFormGroup.valid || isLoading"
      >
        확인
        <i *ngIf="isLoading" class="wnd-icn loading"></i>
      </button>
    </form>
  </wnd-toggle-form>
</div>

<div *ngIf="!isApply" class="form-control-box password-control-box">
  <wnd-toggle-form
    #passwordToggleForm
    class="password-toggle-form-box"
    label="비밀번호"
    placeholder=""
    (cancel)="resetPasswordFormGroup()"
  >
    <form [formGroup]="passwordFormGroup" novalidate (submit)="updatePassword(passwordToggleForm)">
      <div class="form-control">
        <label for="currentPassword" class="form-label">기존 비밀번호</label>

        <wnd-input
          type="password"
          id="currentPassword"
          formControlName="currentPassword"
          placeholder="기존 비밀번호"
          [error]="passwordFormGroup.errors?.wrong"
        ></wnd-input>

        <wnd-error-text
          class="currentPassword-error-text"
          *ngIf="passwordFormGroup.errors?.wrong"
          [text]="currentPasswordErrorText"
        ></wnd-error-text>
      </div>

      <div class="form-control">
        <label for="currentPassword" class="form-label">새 비밀번호</label>

        <wnd-input
          type="password"
          id="password"
          formControlName="password"
          placeholder="비밀번호"
          [error]="passwordFormGroup.errors?.match || passwordFormGroup.errors?.samePassword"
        ></wnd-input>
      </div>

      <div class="form-control">
        <wnd-input
          type="password"
          id="passwordCheck"
          formControlName="passwordCheck"
          placeholder="비밀번호 확인"
          [error]="passwordFormGroup.errors?.match || passwordFormGroup.errors?.samePassword"
        ></wnd-input>

        <wnd-error-text
          *ngIf="passwordFormGroup.errors?.match || passwordFormGroup.errors?.samePassword"
          class="change-error-text"
          [text]="getErrorMessage(passwordFormGroup.errors)"
        ></wnd-error-text>
      </div>

      <button
        type="submit"
        class="wnd-btn navy submit-button"
        [disabled]="passwordFormGroup.invalid || isLoading"
      >
        확인
        <i *ngIf="isLoading" class="wnd-icn loading"></i>
      </button>
    </form>
  </wnd-toggle-form>
</div>

<div class="form-control-box">
  <wnd-toggle-form
    #phoneToggleForm
    class="phone-toggle-form-box placeholder-red"
    label="휴대폰 번호"
    placeholder="휴대폰 번호를 입력해주세요."
    [text]="phoneNumberControl.value | phoneParse"
    (cancel)="resetPhoneVerify()"
  >
    <wnd-phone-verify-form
      [step]="phoneStep"
      (validChange)="phoneValid = $event"
      (verifyComplete)="phoneVerifyComplete($event, phoneToggleForm)"
      (loading)="updateLoading($event)"
    ></wnd-phone-verify-form>

    <button
      *ngIf="phoneStep === phoneVerifyStep.Waiting"
      type="submit"
      class="wnd-btn navy confirm-button phone-verify-confirm-button"
      [disabled]="!phoneValid || isLoading"
      (click)="changeStep()"
    >
      확인
    </button>

    <button
      *ngIf="phoneStep !== phoneVerifyStep.Waiting"
      type="submit"
      class="wnd-btn navy confirm-button verify-button"
      [disabled]="!phoneValid || isLoading"
      (click)="verifyStep()"
    >
      인증번호 확인
      <i *ngIf="isLoading" class="wnd-icn loading"></i>
    </button>
  </wnd-toggle-form>
</div>

<div class="form-control-box">
  <wnd-toggle-form
    #genderToggleForm
    class="gender-toggle-form-box placeholder-red"
    label="성별"
    placeholder="성별을 선택해주세요."
    [text]="genderText"
    (cancel)="resetData(genderFormGroup, ['gender'])"
  >
    <form
      class="gender-form-box"
      [formGroup]="genderFormGroup"
      novalidate
      (submit)="updateUserInfo(genderFormGroup, genderToggleForm)"
    >
      <div class="wnd-gender-box">
        <div class="gender-item-box">
          <input
            class="gender-input"
            formControlName="gender"
            type="radio"
            name="gender"
            id="gender-man"
            value="man"
            [useError]="false"
          />
          <label class="gender-button" for="gender-man">남자</label>
        </div>

        <div class="gender-item-box">
          <input
            class="gender-input"
            formControlName="gender"
            type="radio"
            name="gender"
            id="gender-women"
            value="women"
            [useError]="false"
          />
          <label class="gender-button" for="gender-women">여자</label>
        </div>
      </div>

      <button
        type="submit"
        class="wnd-btn navy confirm-button"
        [disabled]="genderFormGroup.invalid || isLoading"
      >
        확인
        <i *ngIf="isLoading" class="wnd-icn loading"></i>
      </button>
    </form>
  </wnd-toggle-form>
</div>

<div class="form-control-box">
  <wnd-toggle-form
    #birthToggleForm
    class="birth-toggle-form-box placeholder-red"
    label="생년월일"
    placeholder="생년월일을 입력해주세요."
    [text]="birthControl.value"
    (cancel)="resetBirthday(birthdayInputComponent)"
  >
    <form class="birth-form-box" novalidate (submit)="updateBirthday(birthToggleForm)">
      <wnd-birthday-input
        #birthdayInputComponent
        [birthday]="birthControl.value"
        (valueChanges)="setBirthdayPayload($event)"
        (statusChanges)="birthInvalid = $event"
      ></wnd-birthday-input>

      <button
        type="submit"
        class="wnd-btn navy confirm-button"
        [disabled]="birthInvalid || isLoading"
      >
        확인
        <i *ngIf="isLoading" class="wnd-icn loading"></i>
      </button>
    </form>
  </wnd-toggle-form>
</div>

<div *ngIf="!isApply" class="form-control-box">
  <wnd-toggle-form
    #receiveToggleForm
    class="receive-toggle-form-box"
    label="수신 동의"
    placeholder="위니디 뉴스레터를 구독하면 디자인 정보와 실무에 도움되는 정보를 보내드립니다."
    (cancel)="resetData(receiveFormGroup, ['receive'])"
  >
    <form
      class="receive-form-box"
      [formGroup]="receiveFormGroup"
      (submit)="updateUserInfo(receiveFormGroup, receiveToggleForm)"
      novalidate
    >
      <div class="form-control">
        <wnd-checkbox type="none-box" id="receive" formControlName="receive"
          >서비스 정보 수신과 위니딜리버리 뉴스레터를 구독합니다.(선택)
        </wnd-checkbox>
      </div>

      <button type="submit" class="wnd-btn navy confirm-button" [disabled]="isLoading">
        확인
        <i *ngIf="isLoading" class="wnd-icn loading"></i>
      </button>
    </form>
  </wnd-toggle-form>
</div>
