import { OnDestroy, Directive } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

@Directive()
export class BaseSubscriptionComponent implements OnDestroy {
  protected subscription: Subscription = new Subscription();
  protected readonly destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
}
