import { Inject, Injectable } from '@angular/core';

import { NgApiService } from '../../../core/services/api.service';
import { NgContentDb } from './content.db';
import { BigquerySearchAdapter } from 'src/modules/google/bigquery-search.adapter';
import { Environment } from 'src/core/environment';
import { ENVIRONMENT } from '../../../core/tokens';
import { Content } from 'src/entities/content/types';

@Injectable({
  providedIn: 'root',
})
export class NgContentSearch extends BigquerySearchAdapter<Content> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected override apiService: NgApiService,
    protected resourceDb: NgContentDb
  ) {
    super(`${environment.firebase.projectId}.firestore_export.contents`, apiService, resourceDb);
    //TODO : 해당 경로 빅쿼리 컬렉션 존하지 않음 확인 필요.
  }
}
