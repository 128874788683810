import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesModule } from '../../../../../weenidy/src/lib/modules/images/images.module';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { UserProfileCardComponent } from './components/user-profile-card/user-profile-card.component';

@NgModule({
  declarations: [ProfileCardComponent, UserProfileCardComponent],
  exports: [ProfileCardComponent, UserProfileCardComponent],
  imports: [CommonModule, ImagesModule],
})
export class ProfileCardModule {}
